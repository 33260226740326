import { AfterViewInit, Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentService } from "../../../services/payment/payment.service";
import { ToastrService } from "ngx-toastr";
import { WaitService } from "../../../services/wait/wait.service";
import { LoginService } from "../../../services/login/login.service";
import { environment } from "../../../../environments/environment";

declare var Clover : any;

@Component({
  selector: 'app-clover-form',
  templateUrl: './clover-form.component.html',
  styleUrls: ['./clover-form.component.scss']
})
export class CloverFormComponent implements OnInit , AfterViewInit{
  @Input() orderData: any;
  // @Input() address: any;
  @Output() returnData: EventEmitter<any> = new EventEmitter();
  key:any;
  currentUser:any;
  clover :any;
  constructor(
  private modal: NgbActiveModal,
  private toastr: ToastrService,
  private WaitService: WaitService,
  private LoginService: LoginService,
  private PaymentService: PaymentService){}

  initializeClover(){
    this.clover = new Clover(this.key);
    const elements = this.clover.elements();
    const styles = {
      // 'card-number input': {
      //   'width': '90%',
      //   'font-size': '20px',
      //   'border': '1px #cccccc solid',
      //   'padding': '3px',
      //   'margin': '3px',
      //   'background-color': '#EEEEEE'
      // },
      "card-number input": {
        display: "block",
        width: "100%",
        height: "calc(1.54em + 0.876rem + 2px)",
        padding: "0.438rem 0.875rem !important",
        "font-size": ".894rem !important",
        "font-weight": "400",
        "line-height": "1.54",
        color: "#4e5155",
        "background-color": "#fff !important",
        "background-clip": "padding-box",
        border: "1px solid rgba(24,28,33,.1) !important",
        "border-radius": "0.25rem",
        transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        margin: "0px !important",
      },
      "card-number img": {
        "max-height": "25px !important",
        right: "8px !important",
        top: "7px !important",
      },
      "card-number iframe": {
        height: "40px",
        "margin-top": "10px",
      },
      "card-date input": {
        display: "block",
        width: "100%",
        height: "calc(1.54em + 0.876rem + 2px)",
        padding: "0.438rem 0.875rem !important",
        "font-size": ".894rem !important",
        "font-weight": "400",
        "line-height": "1.54",
        color: "#4e5155",
        "background-color": "#fff !important",
        "background-clip": "padding-box",
        border: "1px solid rgba(24,28,33,.1) !important",
        "border-radius": "0.25rem",
        transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        margin: "0px !important",
      },
      "card-cvv input": {
        display: "block",
        width: "100%",
        height: "calc(1.54em + 0.876rem + 2px)",
        padding: "0.438rem 0.875rem !important",
        "font-size": ".894rem !important",
        "font-weight": "400",
        "line-height": "1.54",
        color: "#4e5155",
        "background-color": "#fff !important",
        "background-clip": "padding-box",
        border: "1px solid rgba(24,28,33,.1) !important",
        "border-radius": "0.25rem",
        transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        margin: "0px !important",
      },
      "card-postal-code input": {
        display: "block",
        width: "100%",
        height: "calc(1.54em + 0.876rem + 2px)",
        padding: "0.438rem 0.875rem !important",
        "font-size": ".894rem !important",
        "font-weight": "400",
        "line-height": "1.54",
        color: "#4e5155",
        "background-color": "#fff !important",
        "background-clip": "padding-box",
        border: "1px solid rgba(24,28,33,.1) !important",
        "border-radius": "0.25rem",
        transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        margin: "0px !important",
      },
    };
    const cardNumber = elements.create('CARD_NUMBER', styles);
    const cardDate = elements.create('CARD_DATE', styles);
    const cardCvv = elements.create('CARD_CVV', styles);
    const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);
      
    cardNumber.mount('#card-number');
    cardDate.mount('#card-date');
    cardCvv.mount('#card-cvv');
    cardPostalCode.mount('#card-postal-code');
    
  }

  save(){
    let self = this;
    this.clover.createToken()
    .then(function(result) {
    if (result.errors) {
      Object.values(result.errors).forEach(function (value) {
        console.log(value);
      });
    } else {
      console.log(result.token);
      self.callPaymentCharge(result.token);
    }
  });
  }


  callPaymentCharge(token){
    let data = {
      orderId: this.orderData.id,
      token: token,
      amount: this.orderData.invoiceBalance,
      customerData: {clover:true},
    };
    this.WaitService.start();
    this.PaymentService.chargeCard(data)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Card Has Been Charged");
        this.close(true);
      })
      .catch((err) => {
        this.toastr.error("Something Went Wrong");
        this.WaitService.stop();
      });
  }

  getapikey(){
    this.WaitService.start();
    this.PaymentService.getCloverSDKKey(this.currentUser.userId)
    .then((result:any) => {
      console.log(">>>>>>>>>>>>>>>>.",result);
      this.key = result.data.apiAccessKey;
      this.initializeClover();
      this.WaitService.stop();
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  ngOnInit(){
    this.currentUser = this.LoginService.getCurrentUser();
    console.log("this.currentUser",this.currentUser);
    this.getapikey()
  }

  close(success: boolean) {
    let returnValue = success?success:false;
    this.modal.close();
    this.returnData.emit(returnValue);
  }


  ngAfterViewInit(){
    
  }
}
