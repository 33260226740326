import { Component, OnInit, HostListener } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { EditTrucksComponent } from "../../views/modals/edit-trucks/edit-trucks.component";
import { VehicalService } from "../../services/vehical/vehical.service";
import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";
import { ClassTypesService } from "src/app/services/classTypes/class-types.service";

@Component({
  selector: "app-trucks",
  templateUrl: "./trucks.component.html",
  styleUrls: ["./trucks.component.scss"],
})
export class TrucksComponent implements OnInit {
  types: any = [];
  p = 1;
  trucks: any;
  prevInd: any = -1;
  selectClick: boolean = false;
  pageList: any = [];

  searchText: string = "";
  filteredTrucks: any = [];

  totalTrucks:number=0;
  loadedData: number = 0;
  filters: any = {
    text:null,
    limit: 100,
    offset: 0,
  };
  rowClicked: number = -1;

  constructor(
    private modalService: NgbModal,
    private ToastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private VehicalService: VehicalService,
    private ClassTypesService: ClassTypesService
  ) {}

  @HostListener("document:click", [])
  documentClick() {
    if (this.prevInd != -1 && !this.selectClick)
      this.trucks[this.prevInd].selectDrop = false;
  }

  openModal() {
    const modal = this.modalService.open(EditTrucksComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modal.result.then((data) => {
      this.listAllVehicles();
    });
  }

  openDeleteModal(id) {
    let self = this;
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.message =
      "Are you sure you want to delete this truck?";

    modal.componentInstance.title = "Delete Truck";

    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.spinner.show();
      this.VehicalService.deleteById(id)
        .then((res) => {
          this.spinner.hide();
          this.listAllVehicles();
          this.p = 1;
          this.ToastrService.success("Truck Removed");
        })
        .catch((err) => {
          this.spinner.hide();
          this.ToastrService.error("Something Went Wrong");
        });
    });
  }

  openEditModal(truck) {
    const modal = this.modalService.open(EditTrucksComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.truck = truck;
    modal.result.then((data) => {
      this.listAllVehicles();
    });
  }

  selectDropDown(index) {
    this.selectClick = true;
    if (this.prevInd > -1 && index != this.prevInd) {
      this.trucks[this.prevInd].selectDrop = false;
    }
    this.trucks[index].selectDrop = !this.trucks[index].selectDrop;
    this.prevInd = index;
    setTimeout(() => (this.selectClick = false), 50);
  }

  listAllVehicles() {
    this.spinner.show();
    this.VehicalService.getVehicleTruck(this.filters)
      .then((res) => {
        this.spinner.hide();
        let result = JSON.parse(JSON.stringify(res));

        this.pageList = [];
        this.trucks = result.data;
        this.totalTrucks=parseInt(result.total.count);
        this.loadedData=result.data.length;
        let self = this;
        
        this.ClassTypesService.getClassTypes().then((resType) => {
          self.types = JSON.parse(JSON.stringify(resType)).data;
          self.trucks.forEach((truck) => {
            truck.typeName = self.types.filter((type) => {
              return type.id === parseInt(truck.type);
            })[0].name;
          });

          this.filteredTrucks = self.trucks;
        });
      })
      .catch((err) => {
        this.spinner.hide();
        console.log(err);
      });
  }

  ngOnInit() {
    this.listAllVehicles();
  }

  filterTrucks() {
    this.p = 1;
    const text = this.searchText.toLowerCase();
    this.filteredTrucks = this.trucks.filter(
      (x) =>
        (x.name && x.name.toLowerCase().includes(text)) ||
        (x.make && x.make.toLowerCase().includes(text)) ||
        (x.model && x.model.toLowerCase().includes(text)) ||
        (x.year && x.year.toString().toLowerCase().includes(text)) ||
        (x.typeName && x.typeName.toLowerCase().includes(text))
    );
  }

  getText(e) {
    this.searchText = e;
    this.filters.text= e;
    this.listAllVehicles();
  }

  paginate(offset: number) {
    if (offset > this.totalTrucks) offset = this.totalTrucks;
    if (offset <= 0) offset = 0;
    this.filters.offset = offset;
    this.listAllVehicles();
    //this.userTable.nativeElement.scrollTo(0, 0);
  }
  selectedRow(idx: any) {
    this.rowClicked = idx;
  }
}
