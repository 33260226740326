import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-stuk-search-bar",
  templateUrl: "./stuk-search-bar.component.html",
  styleUrls: ["./stuk-search-bar.component.scss"],
})
export class StukSearchBarComponent implements OnInit {
  isOpen: boolean = false;
  text: string = "";
  focusDefault:boolean=false;
  @Output() getText = new EventEmitter();
  @Output () setWidth=new EventEmitter();
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef; 
  constructor() {}

  ngOnInit() {}

  exportText(e) {
    this.getText.emit(e.target.value);
  }
  resetText(){
    this.text="";
    this.getText.emit(this.text);
  }
  hideSearchBar() {
    // this.searchInput.nativeElement.focus();
    this.isOpen = this.isOpen;
    this.setWidth.emit(this.isOpen);
  }
  setFocus(){
    this.isOpen = !this.isOpen;
    this.searchInput.nativeElement.focus();
    this.setWidth.emit(true);
  }
}
