import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LoginService } from "../login/login.service";

@Injectable({
  providedIn: "root",
})
export class DispatcherGuardService {
  constructor(private LoginService: LoginService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.LoginService.getCurrentUser();

    if (!currentUser) {
      this.router.navigate(["/login"]);
      return false;
    }

    if (!currentUser.isAdmin && !currentUser.isDispatcher) {
      this.router.navigate(["/unauthorize"]);
      return false;
    }
    return true;
  }
}
