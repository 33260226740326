import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../environments/environment";

import { ExportToCsv } from "export-to-csv";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: "root",
})
export class ReportingService {
  constructor(private httpClient: HttpClient) {}

  getDriverJobsreport(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/driverJob", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getJobsReport(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/jobsReport", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAccountReport(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/accountReport", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getDriverSummary(data) {
    console.log(data, "driver");
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/driverSummary", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getTruckSummary(data) {
    console.log(data, "truck");
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/truckSummary", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getStorageReport(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/storageReport", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getStatement(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/statements", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getPayments(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/payments", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getEtaReport(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/eta", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getFeedbacks(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/feedbacks", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getPoReport(data) {
    console.log(data, "reports");
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/po", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getSales(data) {
    let params = new HttpParams();
    if (data.fromDate) params = params.set("fromDate", data.fromDate);
    if (data.endDate) params = params.set("endDate", data.endDate);
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/reports/sales", { params: params })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCommissions(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reports/commission", data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  generatePDF(
    headerFields: string[],
    tableBody: any[],
    widths: number[],
    pageMargins,
    fileName: string,
    pageOrientation: string = 'portrait',
    title: string
  ) {
    const tableHeaders = headerFields.map((x) => {
      return { text: x };
    });

    const docDefinition = {
      pageSize: "A3",
      pageOrientation,
      content: [
        {
          text: title,
          fontSize: 18,
          bold: true,
          alignment: "center",
          margin: [0, -10, 0, 20],
        },
        {
          margin: pageMargins,
          fontSize: 10,
          table: {
            widths: widths,
            body: [tableHeaders],
          },
        },
      ],
    };

    tableBody.map((x) => {
      docDefinition.content[1].table.body.push(x);
    });

    let makePdf = pdfMake.createPdf(docDefinition);
    makePdf.download(fileName + ".pdf");
  }

  checkDateRange(fromDate, endDate) {
    if (fromDate && endDate) {
      return Date.parse(fromDate) > Date.parse(endDate);
    }
    return true;
  }

  generateCSV(data, fileName) {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: fileName,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  capitalize(str: string) {
    return str.replace(/(?:^|\s|[-"'([{])+\S/g, (match) => match.toUpperCase());
  }
}
