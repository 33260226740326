import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";

import { environment } from "../../../environments/environment";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  dispatcherSitesFilterSubject = new Subject();
  newJobsArray: any = [];
  newJobsSubject = new Subject();
  private updateSubject = new Subject<any>();
  update$ = this.updateSubject.asObservable();

  loadOrderEvent = new Subject();

  constructor(private httpClient: HttpClient) {}

  triggerUpdate(data: any) {
    this.updateSubject.next(data);
  }

  redirectToInvoice(uid: string, download: boolean = false) {
    const action = download ? "download" : "view";
    let url = "/invoice/" + uid + "?action=" + action + "&mode=provider";
    window.open(url);
  }

  //return array of new jobs id
  getNewJobs() {
    return this.newJobsArray;
  }

  loadOrderInChild(orderType: string) {
    this.loadOrderEvent.next({ orderType: orderType });
  }

  //push job id in the new job array
  setNewJob(jobId) {
    if (!jobId) this.newJobsArray = [];
    else {
      if(this.newJobsArray.indexOf(jobId)== -1)
        this.newJobsArray.push(jobId);
    }
    this.newJobsSubject.next(this.newJobsArray);
  }

  //remove order id from the new jobs array and emit subject
  removeOrder(orderId) {
    let index = this.newJobsArray.findIndex((x) => x == orderId);

    if (index == -1) return;

    this.newJobsArray.splice(index, 1);
    this.newJobsSubject.next(this.newJobsArray);
  }

  siteFilterChange(sites) {
    this.dispatcherSitesFilterSubject.next(sites);
  }

  createOrderObj(isEditFromSAdmin=false, id=null, orderId=null) {
    let reqURL = environment.baseURL + "/api/orders/st/post/create";
    if(isEditFromSAdmin) reqURL = environment.baseURL + "/api/super_admin/orders/st/post/create";
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(reqURL, { providerId:id, orderId:orderId })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  saveOrder(data, isEditFromSAdmin= false) {
    let reqUrl = environment.baseURL + "/api/orders/st/post/save";
    if(isEditFromSAdmin) reqUrl = environment.baseURL + "/api/super_admin/orders/st/post/save";
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(reqUrl, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  duplicateOrder(orderId: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/orders/st/post/duplicate", {
          id: orderId,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  editOrder(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/orders/st/post/" + data.id, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  editOrderSuperadmin(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/super_admin/orders/st/post/" + data.id, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  truckFromTracking(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/orders/st/put/tracking/assign/truck", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  requestLocation(data, isEditFromSAdmin = false) {
    let reqUrl = environment.baseURL + "/api/orders/st/post/ab/request_location";
    if(isEditFromSAdmin) reqUrl = environment.baseURL + "/api/super_admin/orders/st/post/ab/request_location";
   
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(
          reqUrl,
          data
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getOrders(
    status,
    limit,
    offset,
    text?,
    jobId?,
    fromDate?,
    endDate?,
    from?
  ) {
    let params = new HttpParams();
    if (offset) params = params.set("offset", offset);
    if (limit) params = params.set("limit", limit);
    if (jobId) params = params.set("jobId", jobId);
    if (status) params = params.set("status", status);
    if (text) params = params.set("text", text);
    if (fromDate) params = params.set("fromDate", fromDate);
    if (endDate) params = params.set("endDate", endDate);
    if (from) params = params.set("from", from);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/orders/st/get", { params: params })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getOrdersForSuperadmin(
    status,
    limit,
    offset,
    text?,
    jobId?,
    fromDate?,
    endDate?
  ) {
    let params = new HttpParams();
    if (offset) params = params.set("offset", offset);
    if (limit) params = params.set("limit", limit);
    if (jobId) params = params.set("jobId", jobId);
    if (status) params = params.set("status", status);
    if (text) params = params.set("text", text);
    if (fromDate) params = params.set("fromDate", fromDate);
    if (endDate) params = params.set("endDate", endDate);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/super_admin/orders/st/get", { params: params })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getJobById(jobId, isTracking : any = false, isEditFromSAdmin = false, id = null) {
    let reqURL = environment.baseURL + "/api/orders/st/get";
    if(isEditFromSAdmin) reqURL = environment.baseURL + "/api/super_admin/orders/st/get";
    const param = { jobId: jobId, isTracking:isTracking, providerId:id };
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(reqURL, { params: param })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getOrdersCount() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/orders/st/get/count")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getOrdersCountSuperAdmin() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/super_admin/orders/st/get/count")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteOrder(orderId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(environment.baseURL + "/api/orders/st/delete/" + orderId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteOrderSuperadmin(orderId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(environment.baseURL + "/api/super_admin/orders/st/delete/" + orderId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatedStatus(orderId, status) {
    let cancelledTime=null;
    if(status=="cancelled"){
      cancelledTime=new Date();
    }
    let data = {
      jobId: orderId,
      status: status,
      cancelledTime:cancelledTime,
      sendPushNotification:false
    };

    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/orders/st/put/status", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatedStatusSuperadmin(orderId, status) {
    let cancelledTime=null;
    if(status=="cancelled"){
      cancelledTime=new Date();
    }
    let data = {
      jobId: orderId,
      status: status,
      cancelledTime:cancelledTime,
      sendPushNotification:false
    };

    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/super_admin/orders/st/put/status", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatedAction(orderId, action, isEditFromSAdmin=false) {
    let data = {
      jobId: orderId,
      action: action,
    };

    let reqURL = environment.baseURL + "/api/orders/st/put/action";
    if(isEditFromSAdmin) reqURL = environment.baseURL + "/api/super_admin/orders/st/put/action";
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(reqURL, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  emailDetails(orderId, data, editbySAdmin = false) {
    let reqURl = environment.baseURL + "/api/orders/st/email/send_details";
    if(editbySAdmin) reqURl = environment.baseURL + "/api/super_admin/orders/st/email/send_details";
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(reqURl, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getByRequestId(requestId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/track/request/" + requestId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  postByRequestId(requestId, data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/track/request/" + requestId, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  //get order details for customer web link
  getOrderDetailsById(orderId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/track/order_details/" + orderId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  //get driver location for a order
  getDriverLocationByOrderId(orderId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/track/" + orderId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  addDocument(data, isEditFromSAdmin=false) {
    let reqURL = environment.baseURL + "/api/orders/st/put/add_document";
    if(isEditFromSAdmin) reqURL = environment.baseURL + "/api/super_admin/orders/st/put/add_document";
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(reqURL, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateDocument(data, isEditFromSAdmin=false) {
    let reqURL = environment.baseURL + "/api/orders/st/put/edit_document";
    if(isEditFromSAdmin) reqURL = environment.baseURL + "/api/super_admin/orders/st/put/edit_document";
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(reqURL, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  removeSignature(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/orders/st/put/remove_signature", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getServicesList() {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.baseURL + "/api/services/list").subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getImpounds(offset = null, limit = null, text = null, sites = null,fromDate=null,endDate=null) {
    let param = {
      offset: offset ? offset : "",
      limit: limit ? limit : "",
      text: text && text.length ? text : "",
      sites: sites && sites.length ? sites : "",
      fromDate:fromDate? fromDate:"",
      endDate:endDate? endDate:""
    };

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/impounds", { params: param })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  checkTowOutOrder(orderId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/impounds/check/" + orderId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  editAppOrder(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/orders/app/" + data.id, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
