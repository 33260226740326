import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { UiSwitchModule } from "ngx-ui-switch";
import { ProviderSettingComponent } from "./provider-setting.component";

@NgModule({
  declarations: [ProviderSettingComponent],
  imports: [CommonModule, FormsModule, UiSwitchModule],
  exports: [ProviderSettingComponent],
})
export class ProviderSettingModule {}
