import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "decimalNumber",
})
export class DecimalNumberPipe implements PipeTransform {
  transform(item): any {
    if (!item) item = 0;
    return parseFloat(item).toFixed(2);
  }
}
