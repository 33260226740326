import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { GoogleApiService } from "../../services/googleApi/google-api.service";
import { OrdersService } from "../../services/orders/orders.service";

import { ToastrService } from "ngx-toastr";

import * as mapboxgl from "mapbox-gl";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-request-location",
  templateUrl: "./request-location.component.html",
  styleUrls: ["./request-location.component.scss"],
})
export class RequestLocationComponent implements OnInit {
  user = { addressText: null, lat: null, lng: null };
  orderData: any = [];
  requestId: string = null;

  map: mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";

  constructor(
    private GoogleApiService: GoogleApiService,
    private OrdersService: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.requestId = this.route.snapshot.paramMap.get("requestId");

    this.map = new mapboxgl.Map({
      accessToken: environment.MAPBOX_TOKEN,
      container: "map",
      style: this.style,
    });

    this.OrdersService.getByRequestId(this.requestId)
      .then((order) => {
        let result = JSON.parse(JSON.stringify(order));
        this.orderData = result.data;
        if (!this.orderData) this.router.navigateByUrl("/not-found");
        this.getPosition();
      })
      .catch((err) => {
        this.router.navigateByUrl("/not-found");
      });
  }

  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          const { longitude, latitude } = resp.coords;

          this.map.setCenter([longitude, latitude]);
          this.map.setZoom(19);
          new mapboxgl.Marker({})
            .setLngLat([longitude, latitude])
            .addTo(this.map);

          this.user.lat = latitude;
          this.user.lng = longitude;

          this.GoogleApiService.getAddress(latitude, longitude).then(
            (address) => {
              let result = JSON.parse(JSON.stringify(address)).data.results;
              let addressItems = this.GoogleApiService.parseAddress(
                {},
                result[0].address_components
              );

              this.user.addressText =
                addressItems.addressLineOne +
                " " +
                addressItems.city +
                " " +
                addressItems.state +
                " " +
                addressItems.country +
                " " +
                addressItems.zip;

              if (!this.orderData.requestLocationUsed) this.setLocation();
              else this.toastr.error("Sorry this link is already used");
            }
          );
        },
        (err) => {
          this.toastr.info("Please TURN ON Your Location And Reload The Page.");
          reject(err);
        }
      );
    });
  }

  setLocation() {
    this.OrdersService.postByRequestId(this.requestId, this.user)
      .then(() => {
        this.toastr.success("Your Location Has Been Sent To Provider");
      })
      .catch((err) => {
        this.toastr.error(err.error.error);
      });
  }
}
