import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment-timezone";

@Pipe({
  name: "timeDiff",
})
export class TimeDiffPipe implements PipeTransform {
  transform(orderData): any {
    let timezone = null;

    switch (orderData.scheduledTimeZone) {
      case "AKST":
        timezone = "America/Anchorage";
        break;
      case "CST":
        timezone = "America/Chicago";
        break;
      case "EST":
        timezone = "America/New_York";
        break;
      case "HST":
        timezone = "America/Adak";
        break;
      case "MST":
        timezone = "America/Phoenix";
        break;
      case "PST":
        timezone = "America/Los_Angeles";
        break;
    }

    const scheduledDate = moment(orderData.scheduledFor).format("YYYY-MM-DD");
    const scheduledTime = moment
      .tz(scheduledDate + " " + orderData.scheduledTime, timezone)
      .local();

    const now = moment(new Date());

    const timeDiff = moment.duration(scheduledTime.diff(now));
    const diffInDays = timeDiff.asDays().toFixed(0);
    const diffInHours = timeDiff.asHours().toFixed(0);
    const diffInMinutes = timeDiff.asMinutes().toFixed(0);

    if (parseFloat(diffInDays) != 0) {
      const text = diffInDays < -1 || diffInDays > 1 ? " Days" : " Day";
      return diffInDays + text;
    }
    if (parseFloat(diffInHours) != 0) {
      const text = diffInHours < -1 || diffInHours > 1 ? " Hours" : " Hour";
      return diffInHours + text;
    }
    if (parseFloat(diffInMinutes) != 0) {
      const text = diffInMinutes < -1 || diffInMinutes > 1 ? " Mins" : " Min";
      return diffInMinutes + text;
    }
  }
}
