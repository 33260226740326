import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Router } from "@angular/router";

import { LoginService } from "../login/login.service";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private LoginService: LoginService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let token: string = sessionStorage.getItem("stuk:auth:token");
    let isSuperAdmin: boolean = false;

    if (req.url.startsWith(environment.baseURL + "/api/super_admin")) {
      token = sessionStorage.getItem("stuk:superadmin:token");
      isSuperAdmin = true;
    }

    req = req.clone({
      headers: req.headers.set("Authorization", "Bearer " + token),
    });
    req = req.clone({
      headers: req.headers.set("Content-Type", "application/json"),
    });
    req = req.clone({ headers: req.headers.set("Accept", "application/json") });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        //401 UNAUTHORIZED - SECTION 2
        if (error && error.status === 401) {
          if (isSuperAdmin) this.LoginService.logoutAll();
          this.router.navigate(["/logout"]);
        }
        return throwError(error);
      })
    );
  }
}
