import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from "ngx-pagination";
import { UiSwitchModule } from "ngx-ui-switch";
import { ImageCropperModule } from "ngx-image-cropper";

// Search input component
import { StukSearchBarModule } from "../components/stuk-search-bar/stuk-search-bar.module";
//Custom pipes
import { FormatePhonePipe } from "../pipes/formatePhone/formate-phone.pipe";

import { ProviderSettingsRoutingModule } from "./provider-settings-routing.module";
import { ProviderSettingsComponent } from "./provider-settings.component";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { ProviderSettingModule } from "../components/provider-setting/provider-setting.module";
import { ServicePriceComponent } from "../components/service-price/service-price.component";
// import { AppServicesComponent } from "../components/app-services/app-services.component";
import { UserDispalyComponent } from "../components/user-dispaly/user-dispaly.component";
import { EditUserComponent } from "../views/modals/edit-user/edit-user.component";
import { SitesComponent } from "./sites/sites.component";
import { TrucksComponent } from "../components/trucks/trucks.component";
import { EditTrucksComponent } from "../views/modals/edit-trucks/edit-trucks.component";
import { SalesTaxComponent } from "./sales-tax/sales-tax.component";
import { LinkBankComponent } from "./link-bank/link-bank.component";
import { DriversComponent } from "./drivers/drivers.component";
import { ClassTypesComponent } from "./class-types/class-types.component";
import { CropImageComponent } from "../views/modals/crop-image/crop-image.component";

// Modals
import { PaymentSetupComponent } from "../views/modals/payment-setup/payment-setup.component";
import { AuthDirectComponent } from './auth-direct/auth-direct.component';
import { AppServicesModule } from "../components/app-services/app-services.module";

@NgModule({
  declarations: [
    ProviderSettingsComponent,
    SideNavComponent,

    ServicePriceComponent,
    // AppServicesComponent,
    UserDispalyComponent,
    EditUserComponent,
    CropImageComponent,
    SitesComponent,
    TrucksComponent,
    EditTrucksComponent,

    //pipe
    FormatePhonePipe,
    SalesTaxComponent,
    LinkBankComponent,
    DriversComponent,
    ClassTypesComponent,

    // modals
    PaymentSetupComponent,

    AuthDirectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPaginationModule,
    ImageCropperModule,
    ProviderSettingsRoutingModule,
    UiSwitchModule,
    ProviderSettingModule,
    StukSearchBarModule,
    AppServicesModule
  ],
  bootstrap: [EditUserComponent, EditTrucksComponent, CropImageComponent],
  exports: [PaymentSetupComponent, LinkBankComponent],
  entryComponents: [PaymentSetupComponent],
})
export class ProviderSettingsModule {}
