import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { PaymentService } from "../../../services/payment/payment.service";
import { WaitService } from "../../../services/wait/wait.service";

@Component({
  selector: "app-pay-cash",
  templateUrl: "./pay-cash.component.html",
  styleUrls: ["./pay-cash.component.scss"],
})
export class PayCashComponent implements OnInit {
  @Output() payCash: EventEmitter<any> = new EventEmitter();
  @Input() orderData: any;
  data: any = { country: "US" };

  payments: any = {
    amount: null,
    memo: null,
  };

  initialBalance = 0;

  constructor(
    private modal: NgbActiveModal,
    private PaymentService: PaymentService,
    private WaitService: WaitService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.payments.orderId = this.orderData.id;
    this.payments.amount = this.orderData.invoiceBalance;
    this.payments.memo = this.orderData.memo;
    this.initialBalance = this.payments.amount;
  }

  close(success: boolean) {
    this.modal.close();
    this.data.success = success;
    this.payCash.emit(this.data);
  }

  onPaymentInput(){
    this.payments.amount= Number(this.payments.amount);
  }

  save() {
    if (this.payments.amount && parseFloat(this.payments.amount) > 0) {
      this.WaitService.start();
      console.log(this.payments.amount,typeof this.payments.amount);
      // if (parseFloat(this.payments.amount) <= this.initialBalance)
        this.PaymentService.payCash(this.payments)
          .then(() => {
            this.WaitService.stop();
            this.toastr.success("Payment Saved");
            this.close(true);
          })
          .catch((err) => {
            this.WaitService.stop();
            this.toastr.error("Something Went Wrong");
          });
    }
  }
}
