import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(private httpClient: HttpClient) {}

  getUserAccounts(id=null,isEditFromSuperAdmin=false) {
    let params = new HttpParams();
    if (isEditFromSuperAdmin) {
      params = params.set("providerId", id);
    }

    let reqURL = environment.baseURL + "/api/settings/accounts";
    if(isEditFromSuperAdmin)  reqURL = environment.baseURL + "/api/super_admin/settings/accounts";
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(reqURL,{params})
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAccountsDetail(filters?: any) {
    let params = new HttpParams();
    if (filters) {
      if (filters.offset) params = params.set("offset", filters.offset);
      if (filters.limit) params = params.set("limit", filters.limit);
    }

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/accounts/ac/details",{params})
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getUserAccount(id: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/accounts/" + id)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  copyUserAccount(data){
    return new Promise((resolve,reject)=>{
      this.httpClient
      .post(environment.baseURL + "/api/settings/accounts/copy",data)
      .subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    })
  }

  copyServices(data){
    return new Promise((resolve,reject)=>{
      this.httpClient
      .post(environment.baseURL + "/api/settings/accounts/copyServices",data)
      .subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    })
  }

  getAccountTypes() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/accounts/accountTypes")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  saveAccountData(data) {
    return new Promise((resolve, reject) => {
      let request;
      if (data.id)
        request = this.httpClient.put(
          environment.baseURL + "/api/settings/accounts",
          data
        );
      else
        request = this.httpClient.post(
          environment.baseURL + "/api/settings/accounts",
          data
        );

      request.subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteAccount(id) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(environment.baseURL + "/api/settings/accounts/" + id)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
