import { Component, OnInit } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { WaitService } from "../../services/wait/wait.service";
import { UserService } from "../../services/user/user.service";
import { SitesService } from "../../services/sites/sites.service";
import { LoginService } from "../../services/login/login.service";
import { OrdersService } from "../../services/orders/orders.service";
import { SocketService } from "../../services/socket/socket.service";
import { SettingService } from "../../services/setting/setting.service";
import { UserSettingsService } from "../../services/user-settings/user-settings.service";
import { VehicalService } from "../../services/vehical/vehical.service";

import { CreateJobComponent } from "../../views/modals/create-job/create-job.component";
import { ClassTypesService } from "src/app/services/classTypes/class-types.service";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent implements OnInit {
  view: string = "inProgress";
  totalJobs: any = {
    pending: 0,
    inProgress: 0,
    draft: 0,
    scheduled: 0,
    escalations: 0,
    monitoring: 0,
  };

  searchText: string = null;
  sites: any = [];
  dispatchers: any = [];
  currentUser: any = null;
  selectedSites: number;
  selectedDispatchers: number;
  services: any = [];
  selectedServices: number;
  sitesDropDownSettings: any;
  dispatchersDropDownSettings: any;
  servicesDropDownSettings: any;
  dropdownSettings = {
    singleSelection: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true,
    searchBy: ["name"],
    labelKey: "name",
    badgeShowLimit: 1,
  };
  blinkPending: boolean = false;
  newJobsArray: any = [];
  soundNotificationSetting: any;
  alertSound: any = null;

  filtersSettings: any = {};
  tabsSettings: any = {
    cancelledTab: true,
    draftTab: true,
    pendingTab: true,
    scheduledTab: true,
  };
  enableSearch: boolean = true;

  trucks: any = [];
  classTypes:any=[];
  drivers: any = [];

  viewMeta: any = {
    title: "IN-PROGRESS",
    type: "active",
  };

  constructor(
    private modalService: NgbModal,
    private WaitService: WaitService,
    private toastr: ToastrService,
    private UserService: UserService,
    private SitesService: SitesService,
    private LoginService: LoginService,
    private OrdersService: OrdersService,
    private SocketService: SocketService,
    private SettingService: SettingService,
    private UserSettingsService: UserSettingsService,
    private VehicalService: VehicalService,
    private ClassTypesService:ClassTypesService
  ) {
    this.currentUser = this.LoginService.getCurrentUser();
    this.OrdersService.newJobsSubject.subscribe((data) => {
      this.newJobsArray = data ? data : [];
      this.blinkPending = this.newJobsArray && this.newJobsArray.length;
    });

    this.SettingService.onSoundSettingChange.subscribe(() => {
      this.getSettings();
    });

    setTimeout(() => {
      let socket = this.SocketService.getSocket();

      socket.on("newJob", (order) => {
        let result = JSON.parse(JSON.stringify(order));
        console.log("result.data.serviceTime");
        console.log(result.data.serviceTime);
        if (result && result.data) {
          if (result.data.orderType == "app")
            this.toastr.info("New Job Received");

          //if (result.data.isImpound || result.data.driverId) return;
        }

        this.countOrders();
        if (!result.data.isImpound && !result.data.driverId && !result.data.isDraft && !result.data.includeStorage && result.data.serviceTime == "asap") {
          this.playSound();
          this.OrdersService.setNewJob(result.data.id);
        }
      });

      socket.on("orderStatus", (order) => {
        let result = JSON.parse(JSON.stringify(order));
        this.countOrders();
        this.OrdersService.removeOrder(result.data.orderId);
      });

      socket.on("jobUpdated", (order) => {
        let result = JSON.parse(JSON.stringify(order));
        this.countOrders();
        this.OrdersService.removeOrder(result.data.orderId);
      });
    }, 300);
  }

  ngOnInit() {
    this.newJobsArray = this.OrdersService.getNewJobs();
    this.blinkPending = this.newJobsArray && this.newJobsArray.length;

    this.sitesDropDownSettings = Object.assign({}, this.dropdownSettings);
    this.dispatchersDropDownSettings = Object.assign({}, this.dropdownSettings);
    this.servicesDropDownSettings = Object.assign({}, this.dropdownSettings);

    this.dispatchersDropDownSettings.text = "Select Dispatcher";
    this.sitesDropDownSettings.text = "Select Dispatcher Site";

    this.servicesDropDownSettings.text = "Select Services";
    this.servicesDropDownSettings.primaryKey = "name";

    this.getTrucks();
    this.getDashboardSettings();
    this.loadData();
    this.countOrders();
    this.getSettings();
    this.getClassTypes();
  }

  getDashboardSettings() {
    const getFiltersSettings = this.UserSettingsService.getFilters();

    this.WaitService.start();
    return Promise.all([getFiltersSettings])
      .then((res) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        if (result[0].data) {
          this.filtersSettings = result[0].data;
          this.enableSearch =
            this.filtersSettings && this.filtersSettings.activeJobSearch;
        }
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  getSettings() {
    this.SettingService.getSoundNotificationSetting()
      .then((data) => {
        let result = JSON.parse(JSON.stringify(data));
        this.soundNotificationSetting = result.data;
        if (this.soundNotificationSetting.enableSound) {
          this.alertSound = new Audio();
          this.alertSound.src = "../../../assets/audio/button-6.mp3";
          this.alertSound.load();
        }
      })
      .catch((err) => {
        this.toastr.error(err.error.error);
      });
  }

  playSound() {
    if (
      this.soundNotificationSetting &&
      this.soundNotificationSetting.enableSound
    ) {
      if (this.soundNotificationSetting.repeat) {
        let count = 0;
        var interval = setInterval(() => {
          this.alertSound.currentTime = 0;

          if (count >= this.soundNotificationSetting.repeat - 1)
            clearInterval(interval);

          this.alertSound.play();
          count++;
        }, 1000);
      }
    }
  }

  loadData() {
    let loadSites = this.SitesService.loadSites();
    let loadUsers = this.UserService.getAllUsers();
    let loadServices = this.OrdersService.getServicesList();
    this.WaitService.start();
    return Promise.all([loadSites, loadUsers, loadServices])
      .then((data) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(data));
        this.sites = [{ id: null, name: "Unselected" }];
        result[0].data.sites.forEach((x) => {
          this.sites.push(x);
        });

        this.dispatchers = [
          {
            id: this.currentUser.userId,
            name: "Assigned To Me",
          },
          {
            id: null,
            name: "Unassigned",
          },
        ];
        if (result[1].users)
          result[1].users.forEach((x) => {
            if (x.roleId && x.roleId == 6) this.dispatchers.push(x);
            if ((x.roleId && x.roleId == 2) || x.roleId == 3)
              this.drivers.push(x);
          });

        this.services = [];
        result[2].data.forEach((ele) => {
          let index = this.services.findIndex((x) => x.name == ele.name);
          if (index == -1) this.services.push(ele);
        });
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  openJobModal() {
    this.modalService.open(CreateJobComponent, {
      backdropClass: "light-blue-backdrop",
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  setTotalJobs(jobs) {
    const type = this.viewMeta.type;

    if (type == "waiting") this.totalJobs.pending = jobs;
    if (type == "active") this.totalJobs.inProgress = jobs;
    if (type == "draft") this.totalJobs.draft = jobs;
    if (type == "scheduled") this.totalJobs.scheduled = jobs;
    if (type == "escalations") this.totalJobs.escalations = jobs;
    if (type == "monitoring") this.totalJobs.monitoring = jobs;
  }

  changeView(view) {
    let loadText = document.getElementById("invisible");
    if (loadText) loadText.style.visibility = "hidden";

    this.view = view;
    this.searchText = null;
    this.selectedSites = null;
    this.selectedDispatchers = null;
    this.selectedServices = null;
    if (view == "pending" || view == "active")
      this.OrdersService.setNewJob(null);

    if (view == "draft") {
      this.viewMeta.title = "DRAFT";
      this.viewMeta.type = "draft";
    }

    if (view == "scheduled") {
      this.viewMeta.title = "SCHEDULED";
      this.viewMeta.type = "scheduled";
    }

    if (view == "cancelled") {
      this.viewMeta.title = "Canceled";
      this.viewMeta.type = "cancelled";
    }

    if (view == "pending") {
      this.viewMeta.title = "PENDING";
      this.viewMeta.type = "waiting";
    }

    if (view == "inProgress") {
      this.viewMeta.title = "IN-PROGRESS";
      this.viewMeta.type = "active";
    }

    if (view == "escalations") {
      this.viewMeta.title = "ESCALATIONS";
      this.viewMeta.type = "escalations";
    }
    if (view == "monitoring") {
      this.viewMeta.title = "MONITORING";
      this.viewMeta.type = "monitoring";
    }

    if (view == "done") {
      this.viewMeta.title = "COMPLETED";
      this.viewMeta.type = "completed";
    }

    this.OrdersService.loadOrderInChild(this.viewMeta.type);

    let search = document.querySelector("section.searchbox");
    search.querySelector("input").value = "";
    search.classList.remove("searchbox-open");

    let firstTR = document.querySelector("tr.pointer");
    if (firstTR) firstTR.scrollIntoView({ block: "center" });
  }

  changeFilters() {
    let data = {
      sites: this.selectedSites,
      text: this.searchText,
      dispatchers: this.selectedDispatchers,
      service: this.selectedServices,
    };

    this.OrdersService.siteFilterChange(data);
  }

  countOrders() {
    this.OrdersService.getOrdersCount()
      .then((response) => {
        let result = JSON.parse(JSON.stringify(response));
        result = result.data;

        this.totalJobs.pending = result.pending;

        this.totalJobs.inProgress = result.inProgress;

        this.totalJobs.draft = result.draft;

        this.totalJobs.scheduled = result.scheduled;

        this.totalJobs.monitoring = result.monitoring;

        this.totalJobs.escalations = result.escalations;

        // if(result.pending == 0)this.blinkPending = false;
        // if(result.pending > 0)this.blinkPending = true;
        this.blinkPending = this.newJobsArray && this.newJobsArray.length;
      })
      .catch((err) => {
        this.toastr.error(err.error.error);
      });
  }

  getTrucks() {
    this.WaitService.start();
    this.VehicalService.getVehicleTruck()
      .then((trucks) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(trucks));
        this.trucks = result.data;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }
  getClassTypes(){
    this.WaitService.start();
    this.ClassTypesService.getClassTypes()
      .then((classTypes) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(classTypes));
        this.classTypes = result.data;
        this.classTypes = this.classTypes.filter((data) => {
          if (data.providerClassType.isActive) {
            return data
          }
        })
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }
}
