import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WaitService {
  subject = new Subject();
  constructor() {}

  start() {
    this.subject.next(true);
  }

  stop() {
    this.subject.next(false);
  }
}
