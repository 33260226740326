import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { StukDatePickerComponent } from "./stuk-date-picker.component";

@NgModule({
  declarations: [StukDatePickerComponent],
  imports: [CommonModule, FormsModule, NgbModule],
  exports: [StukDatePickerComponent],
  entryComponents: [StukDatePickerComponent],
})
export class StukDatePickerModule {}
