import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { StukSearchBarComponent } from "./stuk-search-bar.component";

@NgModule({
  declarations: [StukSearchBarComponent],
  imports: [CommonModule, FormsModule],
  exports: [StukSearchBarComponent],
})
export class StukSearchBarModule {}
