import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatePhone",
})
export class FormatePhonePipe implements PipeTransform {
  transform(rawNum) {
    if (!rawNum || !rawNum.length || rawNum.length < 10) return rawNum;

    let newStr = "";
    if (rawNum > 10) {
      newStr =
        "(" +
        rawNum.substring(0, 3) +
        ") " +
        rawNum.substring(3, 6) +
        "-" +
        rawNum.substring(6, 9) +
        "-" +
        rawNum.substring(9, rawNum.length);
    } else {
      newStr =
        "(" +
        rawNum.substring(0, 3) +
        ") " +
        rawNum.substring(3, 6) +
        "-" +
        rawNum.substring(6, rawNum.length);
    }
    return newStr;
  }
}
