import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent implements OnInit {
  @Input() title: string = null;
  @Input() message: string;
  @Input() data: any = {
    id: null,
    companyName: null
  };
  @Input() message2: string = null;
  @Output() reason: EventEmitter<any> = new EventEmitter();
  constructor(private modal: NgbActiveModal) { }

  ngOnInit() { }
  close(reason: string) {
    this.modal.close();
    this.reason.emit(reason);
  }
}
