import { Component, OnInit, Input } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { PriceService } from "../../../services/price/price.service";

@Component({
  selector: "app-edit-rates",
  templateUrl: "./edit-rates.component.html",
  styleUrls: ["./edit-rates.component.scss"],
})
export class EditRatesComponent implements OnInit {
  @Input() accountId;
  @Input() serviceId;
  @Input() isActive;
  @Input() serviceType: String = null;
  @Input("isMainService") isMainService;
  @Input() serviceName: String;
  currentRateTypeId: Number;
  classType: Number;
  selectedRateType: any = [];
  rateTypes: any = [];
  classTypes: any = [];
  additionalItems: any = [];
  filteredItems: any = [];
  classTypeItemsValue: any = [];
  defaultStorageOptions: any = [
    { label: "Free Pickup Window", type: "custom", value: "0" },
    {
      label: "Charge for 2nd Day",
      type: "fixed",
      value: "24",
      allowedValue: [
        { value: "24", label: "After 24 Hours" },
        { value: "12", label: "After 12 Hours" },
        { value: "nextcalenderday", label: "Next Calender Day" },
      ],
    },
    {
      label: "Charge After That",
      type: "fixed",
      value: "24",
      allowedValue: [
        { value: "24", label: "Per 24 Hours" },
        { value: "12", label: "Per 12 Hours" },
        { value: "percalenderday", label: "Per Calender Day" },
      ],
    },
  ];

  constructor(
    private modal: NgbActiveModal,
    private WaitService: WaitService,
    private PriceService: PriceService,
    private toastr: ToastrService
  ) {}

  close() {
    this.modal.close();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.WaitService.start();
    const getData = this.PriceService.getService(
      this.accountId,
      this.serviceId
    );
    const getAdditionalServices = this.PriceService.getServices(
      this.accountId,
      {
        isActive: true,
        serviceType: "additional",
      }
    );

    return Promise.all([getData, getAdditionalServices])
      .then((result: any) => {
        this.WaitService.stop();

        if (result[0].data) {
          this.rateTypes = result[0].data.rates;

          if (this.serviceType != "storage")
            this.rateTypes = this.rateTypes.filter((x) => x.rateTypesId != 7);

          this.classTypes = result[0].data.classes;

          this.classType = this.classTypes[0].id;

          this.currentRateTypeId = this.rateTypes[0].rateTypesId;
          this.changeRateType();

          if (!this.selectedRateType.additionalItems)
            this.selectedRateType.additionalItems = [];
        }

        if (result[1].data) {
          this.additionalItems = result[1].data;
          this.filteredItems = result[1].data;
        }
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  changeRateType() {
    this.selectedRateType = this.rateTypes.filter(
      (x) => x.rateTypesId == this.currentRateTypeId
    )[0];

    if (this.selectedRateType.rateTypesId == 1) {
      this.selectedRateType.roundMiles =
        this.selectedRateType.roundMiles || "5";
    } else {
      this.selectedRateType.roundMiles =
        this.selectedRateType.roundMiles || "to nearest tenth";
    }

    if (!this.selectedRateType.additionalItems)
      this.selectedRateType.additionalItems = [];

    if (!this.selectedRateType.quantityMinPrice)
      this.selectedRateType.quantityMinPrice = 0;

    this.changeClassType();
  }

  changeClassType() {
    this.WaitService.start();
    this.PriceService.getClassTypeRate(
      this.accountId,
      this.serviceId,
      this.selectedRateType.rateTypesId,
      this.classType
    )
      .then((result: any) => {
        this.WaitService.stop();
        // let result = JSON.parse(JSON.stringify(response));
        if (!result.data) {
          this.classTypeItemsValue = this.selectedRateType.items;
          this.classTypeItemsValue.forEach((x) => {
            x.value = 0;
          });

          this.selectedRateType.storageOptions = this.defaultStorageOptions;
          this.selectedRateType.additionalItems = [];
        } else {
          this.classTypeItemsValue = result.data.items;
          this.selectedRateType.storageOptions = result.data.storageOptions;
          this.selectedRateType.additionalItems = result.data.additionalItems ? result.data.additionalItems : [];
        }

        // this.selectedRateType.additionalItems = [];
        this.updateAdditionalItemList();
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  addItem(id) {
    let item = this.additionalItems.filter((x) => x.id == id);
    if (item && item.length) {
      let newItem = { ...item[0] };
      newItem.type = "flat";
      newItem.value = 0;
      this.selectedRateType.additionalItems.push(newItem);
      this.updateAdditionalItemList();
    }
  }

  removeAdditionalItem(item) {
    const index = this.selectedRateType.additionalItems.findIndex(
      (x) => x.id == item.id
    );

    this.selectedRateType.additionalItems.splice(index, 1);
    this.updateAdditionalItemList();
  }

  save() {
    if (!this.validateData()) return;
    let data = {
      accountId: this.accountId,
      serviceId: this.serviceId,
      rateTypeId: this.selectedRateType.rateTypesId,
      classTypeId: this.classType,
      items: this.classTypeItemsValue,
      storageOptions: this.selectedRateType.storageOptions,
      data: {
        isActive: this.isActive,
        quantityMinPrice: this.selectedRateType.quantityMinPrice
          ? this.selectedRateType.quantityMinPrice
          : 0,
        incrementBy: this.selectedRateType.incrementBy,
      },
    };

    if (!data.items[0].value) data.items[0].value = 0;

    if (
      this.selectedRateType.additionalItems &&
      this.selectedRateType.additionalItems.length
    ) {
      this.selectedRateType.additionalItems =
        this.selectedRateType.additionalItems.map((x) => {
          x.name = x.label;
          return x;
        });
      Object.assign(data.data, {
        additionalItems: this.selectedRateType.additionalItems,
      });
    }

    if (this.selectedRateType.roundMiles)
      Object.assign(data.data, {
        roundMiles: this.selectedRateType.roundMiles,
      });

    this.WaitService.start();
    this.PriceService.editServicePrice(data)
      .then((response) => {
        this.WaitService.stop();
        this.toastr.success("Changes Saved");
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  validateData() {
    var regexp = new RegExp(/^\d*\.?\d*$/);
    let isDataValid = true;

    //validate items
    this.classTypeItemsValue.forEach((item) => {
      if (item.value < 0) {
        item.error = "Invalid value";
        isDataValid = false;
      } else if (
        (parseFloat(item.value) > 0 && !regexp.test(item.value)) ||
        isNaN(item.value) ||
        (String(item.value).length > 1 &&
          String(item.value).startsWith("0") &&
          !String(item.value).startsWith("0."))
      ) {
        item.error = "Invalid value";
        isDataValid = false;

        if (String(item.value).startsWith("0")) {
          item.value = item.value.replace(/^0+/, '');
          delete item.error;
          isDataValid = true;
        }
      } else if (
        String(item.value).indexOf(".") != -1 &&
        String(item.value).split(".")[1].length > 2
      ) {
        item.error = "Only two decimal allowed";
        isDataValid = false;
      } else {
        delete item.error;
      }
    });

    //validate additional items
    this.selectedRateType.additionalItems.forEach((item) => {
      if (item.value < 0) {
        item.error = "Invalid value";
        isDataValid = false;
      } else if (
        (parseFloat(item.value) > 0 && !regexp.test(item.value)) ||
        isNaN(item.value) ||
        (String(item.value).length > 1 &&
          String(item.value).startsWith("0") &&
          !String(item.value).startsWith("0."))
      ) {
        item.error = "Invalid value";
        isDataValid = false;
      } else if (
        String(item.value).indexOf(".") != -1 &&
        String(item.value).split(".")[1].length > 2
      ) {
        item.error = "Only two decimal allowed";
        isDataValid = false;
      } else {
        delete item.error;
      }
    });

    if (
      this.selectedRateType.quantityMinPrice < 0 ||
      (String(this.selectedRateType.quantityMinPrice).length > 1 &&
        String(this.selectedRateType.quantityMinPrice).startsWith("0") &&
        !String(this.selectedRateType.quantityMinPrice).startsWith("0."))
    ) {
      this.selectedRateType.quantityError = "Invalid value";
      isDataValid = false;
    } else if (
      parseFloat(this.selectedRateType.quantityMinPrice) > 0 &&
      (!regexp.test(this.selectedRateType.quantityMinPrice) ||
        isNaN(this.selectedRateType.quantityMinPrice))
    ) {
      this.selectedRateType.quantityError = "Invalid value";
      isDataValid = false;
    } else if (
      String(this.selectedRateType.quantityMinPrice).indexOf(".") != -1 &&
      String(this.selectedRateType.quantityMinPrice).split(".")[1].length > 2
    ) {
      this.selectedRateType.quantityError = "Only two decimal allowed";
      isDataValid = false;
    } else {
      delete this.selectedRateType.quantityError;
    }

    if (
      this.selectedRateType.storageOptions &&
      this.selectedRateType.storageOptions.length
    ) {
      this.selectedRateType.storageOptions.forEach((item) => {
        if (item.type == "custom") {
          if (item.value < 0) {
            item.error = "Invalid value";
            isDataValid = false;
          } else if (
            (parseFloat(item.value) > 0 && !regexp.test(item.value)) ||
            isNaN(item.value) ||
            (String(item.value).length > 1 &&
              String(item.value).startsWith("0") &&
              !String(item.value).startsWith("0."))
          ) {
            item.error = "Invalid value";
            isDataValid = false;
          } else if (
            String(item.value).indexOf(".") != -1 &&
            String(item.value).split(".")[1].length > 2
          ) {
            item.error = "Only two decimal allowed";
            isDataValid = false;
          } else {
            delete item.error;
          }
        }
      });
    }

    return isDataValid;
  }

  updateAdditionalItemList() {
    const additionalItemsIds = this.selectedRateType.additionalItems.map((x) => x.id);

    this.filteredItems = this.additionalItems.filter((x) => !additionalItemsIds.includes(x.id));

    this.selectedRateType.additionalItems.forEach(item => {
      let reqItem = this.additionalItems.find(x => x.id == item.id);
      item.label = reqItem.label;
    });
  }

  // onMousechange(item) {
  //   let inputValue = parseFloat(item.value);

  //   if (inputValue == 0) {
  //     this.classTypeItemsValue[0].value = null;
  //   }
  // }
}
