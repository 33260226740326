import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

const options = {
  preserveWhitespaces: false,
};

if (environment.production) {
  enableProdMode();
} else {
  options.preserveWhitespaces = true;
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, options)
  .then(() => {
    if ("serviceWorker" in navigator && environment.production) {
      navigator.serviceWorker.register("/ngsw-worker.js");
    }
  })
  .catch((err) => console.log(err));
