import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-provider-settings",
  templateUrl: "./provider-settings.component.html",
  styleUrls: ["./provider-settings.component.scss"],
})
export class ProviderSettingsComponent implements OnInit {
  showNav: boolean = true;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params && params.view == "app") this.showNav = false;
    });
  }
}
