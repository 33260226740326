import { Injectable } from "@angular/core";
const PNF = require("google-libphonenumber").PhoneNumberFormat;
const PNV = require("google-libphonenumber").PhoneNumberUtil.ValidationResult;
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

@Injectable({
  providedIn: "root",
})
export class ValidateService {
  constructor() {}

  onMobileInput(phone?) {
    try {
      let pattenInternational =
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
      const number = phoneUtil.parseAndKeepRawInput(phone, "US");
      if (
        phoneUtil.isValidNumber(phoneUtil.parse(phone, "US")) &&
        phoneUtil.getRegionCodeForNumber(number) == "US" &&
        phone.length >= 10
      ) {
        phone = phoneUtil.format(number, PNF.NATIONAL);
      } else if (
        phoneUtil.getRegionCodeForNumber(number) != "US" &&
        phone.length >= 10
      ) {
        if (phoneUtil.getRegionCodeForNumber(number) == "IN") {
          let RawFormat = number.getNationalNumber().toString();
          let USFormat = phoneUtil.parse(RawFormat, "US");
          let INDFormat = phoneUtil.format(USFormat, PNF.INTERNATIONAL);
          INDFormat = INDFormat.slice(0, 1) + "9" + INDFormat.slice(1);
          phone = INDFormat;
        } else phone = phoneUtil.format(number, PNF.INTERNATIONAL);
      } else if (
        phoneUtil.getRegionCodeForNumber(number) == "US" &&
        !pattenInternational.test(phone) &&
        phone.length >= 10
      ) {
        phone = phoneUtil.format(number, PNF.INTERNATIONAL);
      }
    } catch (err) {}
    return phone;
  }

  isValid(phone?) {
    let patternNational = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    let pattenInternational =
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    let patternIND =
      /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
    try {
      if (
        !patternNational.test(phone) &&
        !pattenInternational.test(phone) &&
        !patternIND.test(phone)
      )
        return true;
    } catch (err) {
      return false;
    }
    return false;
  }

  format(phone?) {
    let number = phoneUtil.parseAndKeepRawInput(phone, "US");
    if (phoneUtil.isValidNumber(phoneUtil.parse(phone, "US"))) {
      phone = phoneUtil.format(number, PNF.INTERNATIONAL);
    }
    phone = phone.replaceAll("-", "");
    phone = phone.replaceAll(" ", "");
    return phone;
  }

  onEdit(phone?) {
    try {
      const number = phoneUtil.parseAndKeepRawInput(phone, "US");
      if (
        phoneUtil.isValidNumber(phoneUtil.parse(phone, "US")) &&
        phoneUtil.getRegionCodeForNumber(number) == "US"
      ) {
        phone = phoneUtil.format(number, PNF.NATIONAL);
      } else if (
        phoneUtil.getRegionCodeForNumber(number) != "US"
      ) {
        if (phoneUtil.getRegionCodeForNumber(number) == "IN") {
          let RawFormat = number.getNationalNumber().toString();
          let USFormat = phoneUtil.parse(RawFormat, "US");
          let INDFormat = phoneUtil.format(USFormat, PNF.INTERNATIONAL);
          INDFormat = INDFormat.slice(0, 1) + "9" + INDFormat.slice(1);
          phone = INDFormat;
        } else phone = phoneUtil.format(number, PNF.INTERNATIONAL);
      }
    } catch (err) {}
    return phone;
  }
}
