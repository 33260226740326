import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppServicesComponent } from "./app-services.component";

@NgModule({
  declarations: [AppServicesComponent],
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule],
  entryComponents: [AppServicesComponent],
  exports: [AppServicesComponent],
})
export class AppServicesModule {}
