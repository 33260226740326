import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { WaitService } from "../../../services/wait/wait.service";
import { OrdersService } from "../../../services/orders/orders.service";
import { ClassTypesService } from "../../../services/classTypes/class-types.service";

import * as moment from "moment";

@Component({
  selector: "app-job-details",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-details.component.scss"],
})
export class JobDetailsComponent implements OnInit {
  @Input() orderId: number = null;
  @Input() trucks: any = [];
  @Input() drivers: any = [];
  @Input() fromTracking: boolean = false;

  classTypes = [];
  data: any = null;

  constructor(
    private modal: NgbActiveModal,
    private WaitService: WaitService,
    private OrdersService: OrdersService,
    private toastr: ToastrService,
    private ClassTypesService: ClassTypesService
  ) {}

  ngOnInit() {
    if (this.orderId) this.loadData();
  }

  close() {
    this.modal.close();
  }

  loadData() {
    this.WaitService.start();
    this.OrdersService.getJobById(this.orderId, this.fromTracking)
      .then((res) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        if (result.data && result.data.length) this.data = result.data[0];

        if (this.data.pickUpLat && this.data.pickUpLon) {
          this.data.mapDefault = {
            lat: parseFloat(this.data.pickUpLat),
            lng: parseFloat(this.data.pickUpLon),
          };
        } else if (this.data.dropOffLat && this.data.dropOffLon) {
          this.data.mapDefault = {
            lat: parseFloat(this.data.dropOffLat),
            lng: parseFloat(this.data.dropOffLon),
          };
        }

        if (this.data && this.data.history)
          this.data.history.forEach((element) => {
            element.date = moment(element.date).format("MM/DD/YY hh:mm A");
          });
      })
      .then(() => {
        this.ClassTypesService.getClassTypes()
          .then((result: any) => {
            this.classTypes = result.data;
          });
      })
      .catch((err) => {
        console.log(">>>>>",err);
        this.toastr.error(err.error.error);
        this.WaitService.stop();
      });
  }
}
