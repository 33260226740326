import { AfterViewInit, Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentService } from "../../../services/payment/payment.service";
import { ToastrService } from "ngx-toastr";
import { WaitService } from "../../../services/wait/wait.service";
import { environment } from "../../../../environments/environment";

declare var SqPaymentForm : any; //magic to allow us to access the SquarePaymentForm lib
declare const window: any;
@Component({
  selector: 'app-sq-form',
  templateUrl: './sq-form.component.html',
  styleUrls: ['./sq-form.component.scss']
})
export class SqFormComponent implements OnInit , AfterViewInit{
  @Input() orderData: any;
  // @Input() address: any;
  @Output() returnData: EventEmitter<any> = new EventEmitter();
  isEmpty:any = {
    number:true,
    cvv:true,
    date:true
  };
  errormsg:any={};
  card:any;
  constructor(
  private modal: NgbActiveModal,
  private toastr: ToastrService,
  private WaitService: WaitService,
  private PaymentService: PaymentService){}

  paymentForm; //this is our payment form object

  ngOnInit(){
  }

  close(success: boolean) {
    let returnValue = success?success:false;
    this.modal.close();
    this.returnData.emit(returnValue);
  }

  callPaymentCharge(noance){
    let data = {
      orderId: this.orderData.id,
      token: noance,
      amount: this.orderData.invoiceBalance,
      customerData: {square:true},
    };
    this.WaitService.start();
    this.PaymentService.chargeCard(data, this.orderData.isEditFromSuperAdmin)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Card Has Been Charged");
        this.close(true);
      })
      .catch((err) => {
        this.toastr.error("Something Went Wrong");
        this.WaitService.stop();
      });
  }

  // callSquare(){
  //   let self = this;
  //   // Set the application ID
  //   var applicationId = environment.SQUARE_SANBOX_APP_KEY;

  //   // Set the location ID
  //   var locationId = environment.SQUARE_SANBOX_LOCATION_KEY;
  //   if(environment.squarePROD){
  //     applicationId = environment.SQUARE_SANBOX_APP_KEY_PROD;
  //     locationId = environment.SQUARE_SANBOX_LOCATION_KEY_PROD;
  //   }
  //   var paForm = new SqPaymentForm({

  //     // Initialize the payment form elements
  //     autoBuild: false,
  //     applicationId: applicationId,
  //     locationId: locationId,
  //     inputClass: 'sq-input',

  //     // Customize the CSS for SqPaymentForm iframe elements
  //     inputStyles: [{
  //         fontSize: '.9em'
  //     }],



  //     // Initialize the credit card placeholders
  //     cardNumber: {
  //       elementId: 'sq-card-number'
  //     },
  //     cvv: {
  //       elementId: 'sq-cvv'
  //     },
  //     expirationDate: {
  //       elementId: 'sq-expiration-date'
  //     },
  //     postalCode: false,

  //     // SqPaymentForm callback functions
  //     callbacks: {

  //       /*
  //        * callback function: cardNonceResponseReceived
  //        * Triggered when: SqPaymentForm completes a card nonce request
  //        */
  //       cardNonceResponseReceived: function (errors, nonce, cardData)  {
  //         console.log("cardData",cardData);
  //         // this.callPaymentCharge();
  //         self.errormsg={};
  //         if (errors) {
  //           // Log errors from nonce generation to the Javascript console
  //           console.log("Encountered errors:");
  //           errors.forEach(function(error) {
  //             console.log(error);
  //             if(error.field=="cardNumber") {
  //               self.errormsg.card=self.isEmpty.number?'Required':'Invalid';
  //             };
  //             if(error.field=="cvv"){
  //               self.errormsg.cvv=self.isEmpty.cvv?'Required':'Invalid';
  //             }
  //             if(error.field=="expirationDate"){
  //                self.errormsg.date=self.isEmpty.date?'Required':'Invalid';
  //              }
  //           });

  //           return;
  //         }

  //         // alert('Nonce received: ' + nonce); /* FOR TESTING ONLY */

  //         // Assign the nonce value to the hidden form field
  //         // document.getElementById('card-nonce').value = nonce;
  //         //needs to be extracted from the
  //         (<HTMLInputElement>document.getElementById('card-nonce')).value = nonce; //casting so .value will work
  //         //get this value from the database when the user is logged in
  //         // (<HTMLInputElement>document.getElementById('sq-id')).value = "CBASEC8F-Phq5_pV7UNi64_kX_4gAQ";
  //         //
  //         // // POST the nonce form to the payment processing page
  //         // (<HTMLFormElement>document.getElementById('nonce-form')).submit();
  //         self.callPaymentCharge(nonce);
  //       },

  //       /*
  //        * callback function: unsupportedBrowserDetected
  //        * Triggered when: the page loads and an unsupported browser is detected
  //        */
  //       unsupportedBrowserDetected: function() {
  //         /* PROVIDE FEEDBACK TO SITE VISITORS */
  //       },

  //       /*
  //        * callback function: inputEventReceived
  //        * Triggered when: visitors interact with SqPaymentForm iframe elements.
  //        */
  //       inputEventReceived: function(inputEvent) {
  //         console.log('inputEvent',inputEvent);
  //         switch (inputEvent.eventType) {
  //           case 'focusClassAdded':
  //             /* HANDLE AS DESIRED */
  //             if(inputEvent.field=="cardNumber"){
  //               self.isEmpty.number = inputEvent.previousState.isEmpty;
  //             }
  //             if(inputEvent.field=="expirationDate"){
  //               self.isEmpty.date = inputEvent.previousState.isEmpty;
  //             }
  //             if(inputEvent.field=="cvv"){
  //               self.isEmpty.cvv = inputEvent.previousState.isEmpty;
  //             }

  //             break;
  //           case 'focusClassRemoved':
  //             /* HANDLE AS DESIRED */
  //             if(inputEvent.field=="cardNumber"){
  //               self.isEmpty.number = inputEvent.previousState.isEmpty;
  //             }
  //             if(inputEvent.field=="expirationDate"){
  //               self.isEmpty.date = inputEvent.previousState.isEmpty;
  //             }
  //             if(inputEvent.field=="cvv"){
  //               self.isEmpty.cvv = inputEvent.previousState.isEmpty;
  //             }
  //             break;
  //           case 'errorClassAdded':
  //             /* HANDLE AS DESIRED */
  //             break;
  //           case 'errorClassRemoved':
  //             /* HANDLE AS DESIRED */
  //             break;
  //           case 'cardBrandChanged':
  //             /* HANDLE AS DESIRED */
  //             break;
  //           case 'postalCodeChanged':
  //             /* HANDLE AS DESIRED */
  //             break;
  //         }
  //       },

  //       /*
  //        * callback function: paymentFormLoaded
  //        * Triggered when: SqPaymentForm is fully loaded
  //        */
  //       paymentFormLoaded: function() {
  //         /* HANDLE AS DESIRED */
  //         console.log('initiated');
  //       }
  //     }
  //   });
  //   this.paymentForm = paForm;
  //   this.paymentForm.build();
  // }
  // requestCardNonce(event) {

  //   // Don't submit the form until SqPaymentForm returns with a nonce
  //   event.preventDefault();
  //   console.log(event);
  //   // Request a nonce from the SqPaymentForm object
  //   this.paymentForm.requestCardNonce();
  // }

  tokenize = async (paymentMethod) => {
    let self = this;

    const tokenResult = await paymentMethod.tokenize();
    console.log("tokenResult",tokenResult);
    if (tokenResult.status === 'OK') {
      return self.callPaymentCharge(tokenResult.token);
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }

      throw new Error(errorMessage);
    }
  }

  handlePaymentMethodSubmission = async(event, paymentMethod) => {
    // event.preventDefault();

    try {
      const token = await this.tokenize(paymentMethod);
      
    } catch (e) {
      console.error(e.message);
    }
  }

  initializeCard = async(payments) =>{
    const card = await payments.card();
    await card.attach('#card-container');

    return card;
  }

  webSdkInitialize  = async() =>{
    let self = this;
    // Set the application ID
    var applicationId = environment.SQUARE_SANBOX_APP_KEY;

    // Set the location ID
    var locationId = environment.SQUARE_SANBOX_LOCATION_KEY;
    if(environment.squarePROD){
      applicationId = environment.SQUARE_SANBOX_APP_KEY_PROD;
      locationId = environment.SQUARE_SANBOX_LOCATION_KEY_PROD;
    }

    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    let payments;
    try {
      payments = window.Square.payments(applicationId, locationId);
    } catch {
      const statusContainer = document.getElementById(
        'payment-status-container'
      );
      statusContainer.className = 'missing-credentials';
      statusContainer.style.visibility = 'visible';
      return;
    }

    // let card;
    try {
      this.card = await this.initializeCard(payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
  }

  ngAfterViewInit(){
    //this.callSquare();
    this.webSdkInitialize();
  }
}
