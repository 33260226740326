import { Component, Output, Input, EventEmitter } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-stuk-time-picker",
  templateUrl: "./stuk-time-picker.component.html",
  styleUrls: ["./stuk-time-picker.component.scss"],
})
export class StukTimePickerComponent {
  @Input() inputtime;
  @Output() onTimeChange = new EventEmitter();

  time:any = { hour: null, minute: null };

  constructor() {}

  ngOnInit() {
    if(!this.inputtime) return;
    let splitArr = this.inputtime.split(":");
    this.time = { hour: parseInt(splitArr[0]) , minute: parseInt(splitArr[1])};
  }

  formatTime(e) {
    if (e === null) return;

    this.onTimeChange.emit(moment().set(e).format("HH:mm"));
  }
}
