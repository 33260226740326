import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ForgotService {
  constructor(private httpClient: HttpClient) {}

  sendRestLink(email: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reset/email", { email: email })
        .subscribe(
          (response) => resolve(response),
          (err) => reject(err)
        );
    });
  }

  verifyResetLink(token: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/reset/email/verifyLink", {
          token: token,
        })
        .subscribe(
          (response) => resolve(response),
          (err) => reject(err)
        );
    });
  }

  changePassword(data: object) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/reset/email/resetPassword", data)
        .subscribe(
          (response) => resolve(response),
          (err) => reject(err)
        );
    });
  }
}
