import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { LoginService } from "../../services/login/login.service";
import { SocketService } from "../../services/socket/socket.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;
  showPassword: boolean = false;
  user = {
    username: "",
    password: "",
  };

  rememberCheck: boolean = false;

  errors: any = {};
  errorMessage: string = "";

  constructor(
    private LoginService: LoginService,
    private router: Router,
    private SocketService: SocketService
  ) {}

  ngOnInit() {
    let existingUser = localStorage.getItem("username");

    if (existingUser) {
      this.user.username = existingUser;
      this.rememberCheck = true;
    }

    if (this.LoginService.isLoggedIn()) {
      let superAdmin = this.LoginService.getSuperAdmin();
      let currentUser = this.LoginService.getCurrentUser();

      if (superAdmin) this.router.navigate(["/admin"]);
      else if (currentUser) this.router.navigate(["/dashboard"]);
    }
  }

  login(e) {
    e.preventDefault();
    this.errorMessage = "";

    if (!this.validateUser(this.user)) return;
    let rememberChecked = <HTMLInputElement>document.getElementById("remember");

    this.isLoading = true;
    this.LoginService.login(this.user)
      .then((data: any) => {
        // let data = JSON.parse(JSON.stringify(result));

        this.isLoading = false;
        this.LoginService.setUser(data.data);
        if (rememberChecked.checked) localStorage.setItem("username", this.user.username);
        else localStorage.removeItem("username");

        let socket = this.SocketService.getSocket();

        this.SocketService.login(data.data.userId);

        socket.on("login", (data) => {
          console.log("socket login");
          console.log(data);
        });

        if (data.data.superAdmin) this.router.navigate(["/admin"]);
        else this.router.navigate(["/dashboard"]);
      })
      .catch((error) => {
        this.isLoading = false;
        this.errorMessage = error.error;
      });
  }

  validateUser(user) {
    this.errors = {};

    if (!user.password.length) this.errors.password = "Password is required";
    if (!user.username.length)
      this.errors.email = "Email or Username is required";

    return !Object.keys(this.errors).length;
  }
}
