import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { LoginService } from "../../services/login/login.service";
import { SocketService } from "../../services/socket/socket.service";

@Component({
  template: "",
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private LoginService: LoginService,
    private SocketService: SocketService
  ) {}

  ngOnInit() {
    let currentUser = this.LoginService.getCurrentUser();
    if (currentUser) this.SocketService.logout(currentUser.userId);
    this.LoginService.logout();
    this.router.navigate(["/login"]);
  }
}
