import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PriceService {
  constructor(private httpClient: HttpClient) {}

  getServices(acccountId, params?, isEditFromSuperAdmin = false) {
    let reqURL = environment.baseURL + "/api/settings/rates/get/" + acccountId;
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/settings/rates/get/" + acccountId;
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(reqURL, {
          params: params || {},
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getService(acccountId, serviceId, isEditFromSuperAdmin=false, id= null) {
    let baseURL = environment.baseURL + "/api/settings/rates/get/";
    if(isEditFromSuperAdmin) baseURL = environment.baseURL + "/api/super_admin/settings/rates/get/";

    let params = new HttpParams();

    if (isEditFromSuperAdmin) {
      params = params.set("providerId", id);
    }

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          baseURL +
            acccountId +
            "/service/" +
            serviceId,
            {params}
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getClassTypeRate(acccountId, serviceId, rateId = null, classTypeId, isEditFromSuperAdmin=false, id = null) {
    let baseURL = environment.baseURL + "/api/settings/rates/get/account/";
    if(isEditFromSuperAdmin) baseURL = environment.baseURL + "/api/super_admin/settings/rates/get/account/";

    let params = new HttpParams();

    if (isEditFromSuperAdmin) {
      params = params.set("providerId", id);
    }

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          baseURL +
            acccountId +
            "/" +
            serviceId +
            "/" +
            rateId +
            "/" +
            classTypeId,
            { params }
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  changeStatus(acccountId, data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(
          environment.baseURL +
            "/api/settings/rates/put/" +
            acccountId +
            "/status",
          { serviceIds: data }
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  editServicePrice(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/settings/rates/put", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createCustomService(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/customServices", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getApplicationServices() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/rates/app")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => reject(err)
        );
    });
  }

  setApplicationServices(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/rates/app", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => reject(err)
        );
    });
  }

  deleteService(acccountId, serviceId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(
          environment.baseURL +
            "/api/settings/rates/delete/" +
            acccountId +
            "/service/" +
            serviceId
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getStorageRate(accountId, classTypeId, isEditFromSuperAdmin=false) {
    let reqURL = environment.baseURL + "/api/settings/rates/get/storage/" + accountId;
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/settings/rates/get/storage/" + accountId;
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          reqURL
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getServiceCharge() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/serviceCharge/")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getRateTypesList() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/rates/get/rateTypes/list")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getClassTypesList() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/rates/get/classTypes/list")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  calculatePrice(
    orderData,
    selectedRateType,
    exemptTax: boolean,
    fixedServiceFee: number,
    perMileFee: number
  ) {
    let currentTax = 0,
      total = 0,
      subTotal = 0,
      discountAmmount = 0,
      mileageFee = 0,
      taxAmount = 0,
      towingFee = 0,
      AdditionalPercentAmount=0;

    if (orderData.orderType && orderData.orderType == "app") {
      subTotal += parseFloat(orderData.appServiceCharge);

      if (orderData.appServiceId == 1) {
        mileageFee += orderData.milesCustomerToShop * perMileFee;
        towingFee =
          orderData.milesCustomerToShop *
          parseFloat(orderData.appServicePerMileCharge);

        subTotal += towingFee;
      }
    }

    if (orderData.orderMainItems && orderData.orderMainItems.length)
      orderData.orderMainItems.forEach((x) => {
        let itemCost = 0;
        let itemPrice: any = 0;
        if (x.chargePerQty) mileageFee += parseFloat(x.qty) * perMileFee;

        if (x.price) itemPrice = x.price;

        itemCost += parseFloat(itemPrice) * parseFloat(x.qty);
        subTotal = subTotal + itemCost;
      });

    //Add additional items on orders price
    // console.log(selectedRateType,'SelectedRateType');
    if (selectedRateType && selectedRateType.additionalItems)
      selectedRateType.additionalItems.forEach((x) => {
        if (!x.type) x.type="flat";
        if (x.type == "flat") {
          if (!x.qty) x.qty = 1;

          // if (!x.value && !x.price) x.price=0;x.value=0;
          // if (!x.price) x.price = x.value;

          if (x.category && x.category == "qty")
            mileageFee += parseFloat(x.qty) * perMileFee;

          let itemCost = 0;
          let itemPrice: any = 0;
          if (x.price) itemPrice = x.price;
          itemCost += parseFloat(itemPrice) * parseFloat(x.qty);
          subTotal = subTotal + itemCost;
        }
      });

    if (orderData.includeStorage && !orderData.removeStorageCharges && (orderData.status=="completed" || orderData.status=="released" || orderData.isImpound)) {
      if (!orderData.storedDays) orderData.storedDays = 0;
      if(!orderData.storedPerDayPrice) orderData.storedPerDayPrice = 0;
      subTotal =
        Math.abs(parseFloat(orderData.storedPerDayPrice)) *
          Math.abs(parseFloat(orderData.storedDays)) +
        subTotal;

      if(orderData.storedPerDayPrice==0) orderData.storedPerDayPrice=null;
    }

    // calculate tax amount
    taxAmount = (subTotal / 100) * orderData.taxPercentage;

    //remove tax
    if (!orderData.orderType || orderData.orderType != "app")
      currentTax = exemptTax ? 0 : taxAmount;

    //Add discount on onrder subTotal
    if (orderData.discountType == "percent") {
      let amount = (subTotal / 100) * orderData.discountValue;
      discountAmmount = amount;
    } else discountAmmount = orderData.discountValue;

    //Automatically Add Additional Item Amount
    if (selectedRateType && selectedRateType.additionalItems) {
      let percentAmount = 0;
      selectedRateType.additionalItems.forEach((x) => {
        if (x.type == "percent") {
          if (!x.price) x.price = x.value = 0;
          percentAmount +=
            ((subTotal + fixedServiceFee) / 100) * parseFloat(x.price);
        }
      });
      // subTotal += percentAmount;
      AdditionalPercentAmount += percentAmount;
    }

    // For only Storage Jobs
    if(orderData.service && orderData.service.serviceType=="storage"){
      fixedServiceFee=0;
      orderData.serviceCharge=0;
    }

    total =
      subTotal + currentTax + fixedServiceFee - discountAmmount + mileageFee + AdditionalPercentAmount;

    let dataObj = {
      subTotal: Math.abs(subTotal),
      taxAmount: Math.abs(taxAmount),
      discountAmmount: Math.abs(discountAmmount),
      fixedServiceFee: Math.abs(fixedServiceFee),
      mileageFee: Math.abs(mileageFee),
      towingFee: Math.abs(towingFee),
      total: Math.abs(total),
    };

    return dataObj;
  }

  getAppServicePageContent() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/content/appServiceFee")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => reject(err)
        );
    });
  }
}
