import { Component, OnInit, Input } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { InvoicesService } from "../../../services/invoices/invoices.service";
import { ValidateService } from "src/app/services/phone-validate/validate.service";

@Component({
  selector: "app-email-invoice",
  templateUrl: "./email-invoice.component.html",
  styleUrls: ["./email-invoice.component.scss"],
})
export class EmailInvoiceComponent implements OnInit {
  @Input() data: any;
  @Input() showSaveSendButon: boolean = true;

  errors: any = { to: false, cc: false, bcc: false, phone: false };
  separator = "";

  constructor(
    private modal: NgbActiveModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private InvoicesService: InvoicesService,
    private ValidateService: ValidateService
  ) {}

  ngOnInit() {
    // console.log(this.data, "email data");
    this.data.email_to =
      this.data.email_bcc =
      this.data.email_cc =
      this.data.phone =
      this.data.to =
      this.data.bcc =
      this.data.cc =
        null;
  }

  send() {
    if (this.validate()) {
      this.WaitService.start();

      this.InvoicesService.emailInvoice(this.data)
        .then(() => {
          this.WaitService.stop();
          this.InvoicesService.updateSentTime(this.data.id);
          this.toastr.success("Sent");
          this.close();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    } else
      this.data.phone = this.data.phone.join(this.separator);
  }

  save() {
    if (this.validate()) {
      this.WaitService.start();

      this.InvoicesService.save(this.data)
        .then(() => {
          this.WaitService.stop();
          this.toastr.success("Changes Saved");
          this.close();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    } else
      this.data.phone = this.data.phone.join(this.separator);
  }

  close() {
    this.modal.close();
  }

  validate() {
    var regEx = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var to = this.data.to,
      cc = this.data.cc,
      bcc = this.data.bcc,
      phone = this.data.phone;

    // let data = {
    //   to: [],
    //   cc: [],
    //   bcc: [],
    // };

    this.errors = { to: false, cc: false, bcc: false, phone: false };

    if (phone) {
      // this.errors.phone = this.ValidateService.isValid(this.data.phone);
      // if (!this.errors.phone)
      //   this.data.phone = this.ValidateService.format(this.data.phone);

      this.onMultiplePhoneInput();
      this.data.phone.split(/[;,]+/).forEach(number => {
        if (this.ValidateService.isValid(number)) {
          this.errors.phone = true;
          return;
        }
      });

      if (!this.errors.phone)
        this.data.phone = this.data.phone.split(/[;,]+/).map(number => this.ValidateService.format(number));
    }

    if (to && to.length) {
      to.split(/[\s,;]+/).forEach((x) => {
        if (!regEx.test(x)) this.errors.to = true;
        // data.to.push(x);
      });
    }

    if (cc && cc.length) {
      cc.split(/[\s,;]+/).forEach((x) => {
        if (!regEx.test(x)) this.errors.cc = true;
        // data.cc.push(x);
      });
    }

    if (bcc && bcc.length) {
      bcc.split(/[\s,;]+/).forEach((x) => {
        if (!regEx.test(x)) this.errors.bcc = true;
        // data.bcc.push(x);
      });
    }

    this.data.invoiceId = this.data.id;
    this.data.email_to = this.data.to;
    this.data.email_cc = this.data.cc;
    this.data.email_bcc = this.data.bcc;

    if (
      this.errors.to ||
      this.errors.cc ||
      this.errors.bcc ||
      this.errors.phone
    )
      return false;
    return true;
  }

  // onPhoneInput() {
  //   this.errors.phone = false;
  //   this.data.phone = this.ValidateService.onMobileInput(this.data.phone);
  // }

  onMultiplePhoneInput() {
    this.errors.phone = false;

    if (!this.separator) {
      if (this.data.phone.includes(","))
        this.separator = ",";
      else if (this.data.phone.includes(";"))
        this.separator = ";";
    }

    this.data.phone = this.data.phone.split(/[;,]+/).map(number => this.ValidateService.onMobileInput(number)).join(this.separator);
  }
}
