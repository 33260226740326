import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CountdownEvent } from "ngx-countdown";

import { WaitService } from "../../services/wait/wait.service";
import { OrdersService } from "../../services/orders/orders.service";
import { SocketService } from "../../services/socket/socket.service";
import { InvoicesService } from "../../services/invoices/invoices.service";
import { LoginService } from "../../services/login/login.service";
import { PaymentService } from "../../services/payment/payment.service";

import { CreateJobComponent } from "../../views/modals/create-job/create-job.component";
import { EmailJobComponent } from "../../views/modals/email-job/email-job.component";
import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";
import { AssignDriverComponent } from "../../views/modals/assign-driver/assign-driver.component";
import { PayCashComponent } from "../../views/modals/pay-cash/pay-cash.component";
import { CollectDataComponent } from "../../views/modals/collect-data/collect-data.component";
import { EmailInvoiceComponent } from "../../views/modals/email-invoice/email-invoice.component";
import { SqFormComponent } from "../../views/modals/sq-form/sq-form.component";
import { CloverFormComponent } from "../../views/modals/clover-form/clover-form.component";

import * as moment from "moment-timezone";
// import * as moment from "moment";
import { environment } from "../../../environments/environment";

declare let SpreedlyExpress: any;
import * as momentbasic from "moment";

@Component({
  selector: "app-orders-list",
  templateUrl: "./orders-list.component.html",
  styleUrls: ["./orders-list.component.scss"],
})
export class OrdersListComponent implements OnInit {
  @Output() updateTotalJobs: EventEmitter<any> = new EventEmitter();
  @Input() type: string;
  @Input() title: string = "";
  @Input() enableSearch: boolean;
  @Input() trucks: any = [];
  @Input() drivers: any = [];
  @Input() classTypes: any = [];

  @ViewChild("orderTable", { static: true }) orderTable: ElementRef;
  searchText: string;
  selectedSites: any = [];
  selectedDispatchers: any = [];
  selectedServices: any = [];
  searchTimeOut: any = null;
  socket: any = null;
  ordersData: any = [];
  filteredOrders: any = [];
  totalOrders: number = null;
  offset: number = 0;
  limit: number;
  rowClicked: number = -1;
  loadedOrders: number = null;

  isCollapsed: boolean[] = [];

  updatedOrderId: number = null;
  currentUser: any = null;

  fromDate: any = null;
  endDate: any = null;

  showPagination: boolean = false;

  orderTypeChangeSubscription: any;
  jobTypeText: string = "";
  showJobExpiring: boolean = false;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private OrdersService: OrdersService,
    private SocketService: SocketService,
    private InvoicesService: InvoicesService,
    private LoginService: LoginService,
    private PaymentService: PaymentService
  ) {
    this.InvoicesService.invoiceUpdated.subscribe(() => {
      this.loadOrders(this.type, this.limit, this.offset);
    });
  }

  stopParentEvent(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  ngOnInit() {
    this.showPagination = [
      "draft",
      "completed",
      "cancelled",
      "scheduled",
    ].includes(this.type);

    this.currentUser = this.LoginService.getCurrentUser();

    this.limit = this.showPagination ? 100 : null;

    //apply filters on orders listing
    this.OrdersService.dispatcherSitesFilterSubject.subscribe((data) => {
      let result = JSON.parse(JSON.stringify(data));
      this.selectedSites = result.sites;
      this.selectedDispatchers = result.dispatchers;
      this.selectedServices = result.service;
      this.filteredOrdersData();
    });

    this.orderTypeChangeSubscription =
      this.OrdersService.loadOrderEvent.subscribe((result: any) => {
        switch (result.orderType) {
          case "waiting":
            this.jobTypeText = "Pending";
            break;
          case "active":
            this.jobTypeText = "In-Progress";
            break;
          case "completed":
            this.jobTypeText = "Completed";
            break;
          case "scheduled":
            this.jobTypeText = "Scheduled";
            break;
          case "cancelled":
            this.jobTypeText = "Canceled";
            break;
          case "draft":
            this.jobTypeText = "Draft";
            break;
        }
        this.offset = 0;
        this.fromDate = null;
        this.endDate = null;
        this.showPagination = [
          "draft",
          "completed",
          "cancelled",
          "scheduled",
        ].includes(result.orderType);

        this.limit = this.showPagination ? 100 : null;

        this.ordersData = [];
        this.filteredOrders = [];
        this.loadOrders(result.orderType, this.limit, this.offset);
      });

    this.loadOrders(this.type, this.limit, this.offset);

    this.socket = this.SocketService.getSocket();

    if (this.socket) {
      this.socket.on("newJob", () => {
        this.loadOrders(this.type, this.limit, this.offset);
      });

      this.socket.on("orderStatus", (result: any) => {
        if (result.data.cancelledFromCust) {
          this.loadOrders(this.type, this.limit, this.offset);
        }
        else {
          setTimeout(() => this.updateOrder(result.data.orderId), 500);
        }
      });

      this.socket.on("detailJobUpdated", (result: any) => {
        console.log(result, "detailJobUpdated socket worked");
        setTimeout(() => this.updateOrder(result.data.orderId), 500);
      });

      this.socket.on("paymentCompleted", (result: any) => {
        setTimeout(() => this.loadOrders(this.type, this.limit, this.offset), 500);
      });

      this.socket.on("jobUpdated", (result: any) => {
        console.log("jobUpdated socket worked");
        if (result.data.isDispatchedOrPending || result.data.statusChanged || result.data.isPermantDelete) {
          this.loadOrders(this.type, this.limit, this.offset);
        }
        else {
          setTimeout(() => this.updateOrder(result.data.orderId), 500);
        }
      });

      this.socket.on("appOrderUpdated", (data) => {
        let result = JSON.parse(JSON.stringify(data));
        this.updateAppOrder(result.data);
      });

      this.socket.on("paymentMethod", (result: any) => {
        this.loadOrders(this.type, this.limit, this.offset, null, false);
      });

      this.socket.on("cardAdded", (data: any) => {
        this.loadOrders(this.type, this.limit, this.offset, null, false);
      });

      if (this.currentUser.role == 3) {

        this.socket.on("jobUnAssigned", (result: any) => {
          this.loadOrders(this.type, this.limit, this.offset, null, false);
        });

        this.socket.on("jobAssigned", (result: any) => {
          this.loadOrders(this.type, this.limit, this.offset, null, false);
        });

      }
    }

    this.OrdersService.update$.subscribe((data) => {
      if (data && data.type === 'order') {
        this.loadOrders(this.type, this.limit, this.offset);
      }
    });

  }

  ngOnDestroy() {
    this.orderTypeChangeSubscription.unsubscribe();
  }

  // updatePayment(updatedOrderId: number){
  //   let indeX = this.filteredOrders.findIndex(order => order.id == updatedOrderId);
  //   console.log(indeX);
  //   if(indeX>-1){
  //     console.log("this.filteredOrders[indeX]",this.filteredOrders[indeX])
  //     this.filteredOrders[indeX].invoiceBalance = 0;
  //   }
  // }

  updateOrder(updatedOrderId: number) {
    this.filteredOrders.forEach(date => {
      let reqOrderIdx = date.orders.findIndex(order => order.id == updatedOrderId);
      if (reqOrderIdx != -1) {
        let reqOrder = date.orders[reqOrderIdx];

        this.OrdersService.getOrders(this.type, 1, this.offset, null, reqOrder.id)
          .then((result: any) => {
            setTimeout(() => {
              let gotOrder = result.data[0];
              console.log(gotOrder, "gotOrder");

              gotOrder.isCollapsed = reqOrder.isCollapsed;
              if (gotOrder.action == "rejected") {
                date.orders.splice(reqOrderIdx, 1)
              }
              else {
                date.orders.splice(reqOrderIdx, 1, this.prepIndividualOrder(gotOrder))
              }
            }, 500);
          })
          .catch((err) => {
            console.log("Error in job update socket");
            console.log(err);
          });

        return;
      }
    });
  }

  prepIndividualOrder(x) {
    if (x.driverInfo) {
      if (x.driverInfo.name == " ") x.driverInfo.name = "Admin";
    }
    if (x.orderType == "app") x.accountInfo = { name: "APP Account" };
    // x.completedAt=x.createdAt;
    if (!x.completedTime) x.completedAt = moment("01/01/22").format("MM/DD/YY hh:mm A");
    if (x.completedTime) x.completedAt = x.completedTime;
    if (x.cancelledTime) x.completedAt = x.cancelledTime;
    x.orderedAt = moment(x.createdAt).format("MM/DD/YY hh:mm A");
    if (x.history)
      x.history.forEach((element) => {
        element.date = moment(element.date).format("MM/DD/YY hh:mm A");
      });

    x.mapDefault = { lat: 37.09024, lng: -95.712891 };

    if (x.pickUpLat && x.pickUpLon)
      x.mapDefault = {
        lat: parseFloat(x.pickUpLat),
        lng: parseFloat(x.pickUpLon),
      };
    else if (x.dropOffLat && x.dropOffLon)
      x.mapDefault = {
        lat: parseFloat(x.dropOffLat),
        lng: parseFloat(x.dropOffLon),
      };

    if (x.pickUpLat && x.pickUpLon && x.dropOffLat && x.dropOffLon) {
      x.origin = {
        lat: parseFloat(x.pickUpLat),
        lng: parseFloat(x.pickUpLon),
      };
      x.destination = {
        lat: parseFloat(x.dropOffLat),
        lng: parseFloat(x.dropOffLon),
      };
    }
    if ((this.type == "waiting") && (x.orderType == "app" || !x.action)) {
      this.showJobExpiring = true;
      console.log(x.history[0])

      let seconds = this.getTimer(x.createdAt);

      if (x.history && x.history[0].title == "Draft") {
        x.history.forEach((hist) => {
          if (hist.title == "Created") seconds = this.getTimer(hist.date);
        });
      }

      // if(x.isRejectedToAssign){
      //   x.history.forEach((hist)=>{
      //     if(hist.title=="RejectedReassign") seconds = this.getTimer(hist.date);
      //   });
      // }

      x.config = { leftTime: seconds, format: "mm:ss" };
    }

    if ((this.type != "cancelled" && this.type != "completed") && x.serviceTime == "asap") {
      if (x.etaMins !== null) {
        // let countDownOnce = true;
        x.isCountDown = false;
        setInterval(() => {
          const now = moment(new Date());
          const addEtaTime = moment(x.createdAt).add(x.etaMins, "minutes");
          const duration = moment.duration(addEtaTime.diff(now));
          const diffInDays = Math.floor(duration.asDays());
          const diffInHours = Math.floor(duration.asHours());
          const diffInMinutes = Math.floor(duration.asMinutes());

          if (diffInMinutes == 0 && x.isCountDown == false) {
            x.isCountDown = true;
            x.seconds = Math.floor(duration.asSeconds());
            // countDownOnce = false;
          } else if (diffInMinutes) {
            // if (diffInMinutes == 1 && x.isCountDown == false && countDownOnce) {
            //   x.isCountDown = true;
            //   countDownOnce = false;
            // } else {
            // const text = diffInMinutes < -1 || diffInMinutes > 1 ? " Mins" : " Min";
            // if (diffInMinutes == 1) {
            //   x.etaMinRealTime = 0;
            //   // x.text = "0 Min";
            // } else {
            x.etaMinRealTime = diffInMinutes;
            x.text = diffInMinutes + " min";
            // }
            // }
          }

          if (diffInHours && (diffInMinutes >= 60 || diffInMinutes <= -60)) {
            // let text = diffInHours < -1 || diffInHours > 1 ? " Hours" : " Hour";

            // let remainingMin = 0;
            // if(diffInMinutes > 60) {
            // diffInHours = Math.floor((diffInMinutes/ 60) * 10) / 10;

            x.etaMinRealTime = diffInHours;
            x.text = "";

            if (diffInMinutes <= -60) x.text += "-";

            if (diffInMinutes % 60 == 0) {
              x.text += (Math.abs(diffInHours) != 1 || Math.abs(diffInHours) != -1) ? "1 hr" : Math.abs(diffInHours) + " hr";

              if (diffInHours == -48) x.text = "-2 days";
            } else
              x.text += Math.abs(diffInHours < 0 ? diffInHours + 1 : diffInHours) + " hr " +
                moment.utc(moment.duration(Math.abs(diffInMinutes), "minutes").asMilliseconds()).format("m [min]");
            // if (diffInMinutes >= 30) remainingMin = Math.abs(diffInMinutes - (60 * --diffInHours));
            // else remainingMin = Math.abs(diffInMinutes - (60 * diffInHours));
            // } else if(diffInMinutes < -60) {
            // diffInHours = Math.floor((diffInMinutes/ 60) * 10) / 10;

            // if (diffInMinutes <= -30) remainingMin = Math.abs(diffInMinutes - (60 * --diffInHours));
            // else remainingMin = Math.abs(diffInMinutes - (60 * diffInHours));
            // }

            // x.text = diffInHours + " " + " hr ";

            // if(remainingMin > 1 || remainingMin < -1) x.text += remainingMin + " min";
          }

          if ((diffInDays > 1 || diffInDays < -1) && (diffInHours >= 48 || diffInHours < -48)) {
            // const text = diffInDays < -1 || diffInDays > 1 ? " days" : " day";
            // x.etaMinRealTime = diffInDays;
            const daysCount = Math.floor(diffInHours / 24)
            x.text = daysCount + " days";
            // x.text = diffInDays + " days";
            if (diffInDays < -1) x.text = (daysCount + 1) + " days";
            // if (diffInDays < -1) x.text = (diffInDays + 1) + " days";
            // if (diffInHours < -24) x.text += "-";

            // if (diffInMinutes <= -60) x.text += "-";

            // if (diffInMinutes % 60 === 0) x.text += Math.abs(diffInHours) + " hr";
            // else x.text += Math.abs(diffInHours) + " hr" + moment.utc(moment.duration(Math.abs(diffInMinutes), "minutes").asMilliseconds()).format(" m [min]");
          }
        }, 1000);
      }
    }

    return x;
  }

  loadOrders(type, limit, offset, text = null, showLoader = true) {
    if (type == 'waiting') { this.showJobExpiring = true; }
    else this.showJobExpiring = false;
    if (showLoader) this.WaitService.start();
    this.OrdersService.getOrders(
      type,
      limit,
      offset,
      text,
      null,
      this.fromDate,
      this.endDate
    )
      .then((orders) => {
        this.WaitService.stop();
        this.ordersData = [];
        this.filteredOrders = [];
        let result = JSON.parse(JSON.stringify(orders));
        // console.log(result)
        this.totalOrders = result.total;
        this.ordersData = result.data;

        this.updateTotalJobs.emit(this.ordersData.length);

        this.ordersData.forEach((x) => this.prepIndividualOrder(x));

        if (type != 'active' && type != 'waiting') this.ordersData = this.ordersData.sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime());
        this.groupOrders(type, this.ordersData);
      })
      .catch((err) => {
        console.log(err);

        this.WaitService.stop();
        // this.toastr.error(err.error.error);
      });
  }

  handleEtaEvent(e: CountdownEvent, order: any) {
    console.log(e.action, order);
    if (e.action == "done") {
      order.isCountDown = false;
      console.log("countDown End");
    }
  }

  handleCountDown(e, order) {
    // console.log(e,'event');
    // order.isCountDown=false;
    if (e.action == "done") {
      console.log("time up");

      order.isCountDown = false;
    }
  }

  editOrder(order) {
    this.rowClicked = order.id;
    if (order.orderType == "custom" && !order.isCreatedBySuperAdmin) {
      const modal = this.modalService.open(CreateJobComponent, {
        backdropClass: "light-blue-backdrop",
        size: "xl",
        backdrop: "static",
        keyboard: false,
      });
      modal.componentInstance.orderId = order.id;
      modal.componentInstance.isDraft = order.isDraft;
      modal.result.then((data) => {
        this.rowClicked = order.id;
      })
    }
    if (order.orderType == "custom" && order.isCreatedBySuperAdmin) {
      this.toastr.error("You Are Not Allowed To Perform This Action");
    }
    //Cancel order APP order and Create new order for the customer
    if (order.orderType == "app") {
      const modal = this.modalService.open(ConfirmModalComponent, {
        backdropClass: "light-blue-backdrop",
        backdrop: "static",
        keyboard: false,
      });
      modal.componentInstance.title = "Warning";
      modal.componentInstance.message =
        "If you edit this job, it will be converted into a new job";

      modal.componentInstance.reason.subscribe((result) => {
        if (result != "success") return;

        const jobModal = this.modalService.open(CreateJobComponent, {
          backdropClass: "light-blue-backdrop",
          size: "xl",
          backdrop: "static",
          keyboard: false,
        });

        jobModal.componentInstance.canclledOrderId = order.id;

        delete order.appServiceId;
        delete order.vehicleId;
        delete order.id;
        jobModal.componentInstance.orderData = order;
        jobModal.result.then((data) => {
          this.rowClicked = order.id;
        })
      });
    }
  }

  openEmailModal(order) {
    this.rowClicked = order.id;
    const modal = this.modalService.open(EmailJobComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.order = order;
    modal.result.then((data) => {
      this.rowClicked = order.id;
    })
  }

  dublicateJob(orderId: number) {
    this.WaitService.start();
    this.OrdersService.duplicateOrder(orderId)
      .then((order) => {
        this.WaitService.stop();
        this.toastr.success("Duplicate Order Created");
      })
      .catch((err) => {
        this.WaitService.stop();
        if (err.status == 500) {
          let errorMessage = JSON.parse(err.error.error);
          if (errorMessage.error) this.toastr.error(errorMessage.error);
          else this.toastr.error(errorMessage.error);
        } else this.toastr.error(err.error.error);
      });
  }
  undoCancel(order) {
    this.rowClicked = order.id;
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = "Undo Cancel Job";
    modal.componentInstance.message =
      "Do you really want to undo cancel this job?";
    modal.componentInstance.reason.subscribe((result) => {
      this.rowClicked = order.id;
      if (result != "success") return;
      order.driverId = null;
      order.truckId = null;
      this.WaitService.start();
      this.OrdersService.editOrder(order)
        .then(() => {
          this.WaitService.stop();
          this.changeStatus(order, "pending");
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
      this.rowClicked = order.id;
    });
  }

  createDraftJob(order) {
    console.log(order, "order");
    if (!order.pickUpAddress) {
      this.toastr.error("Please Enter Valid Service Location");
      return
    }
    if (!order.callerPhone || order.callerPhone == "") {
      this.toastr.error("Please Enter Valid Phone Number");
      return
    }
    this.rowClicked = order.id;
    if (
      order.driverId == null &&
      order.serviceTime != "scheduled"
    ) {
      order.status = "pending";
    }
    if (
      order.serviceTime != "scheduled" &&
      order.status == "pending" &&
      order.driverId
    ) {
      order.status = "dispatched";
    }
    this.OrdersService.editOrder(order)
      .then((res) => {
        this.rowClicked = order.id;
        this.toastr.success("Job Updated");
      })
      .catch((err) => { });
  }

  changeToSTorage(order) {
    this.rowClicked = order.id;
    this.WaitService.start();
    order.isUndoJob = false;
    order.isTowedOut = false;
    order.autoStoredCountDays = true;
    order.isStorageToDashboard = false;
    this.OrdersService.editOrder(order)
      .then((res) => {
        this.rowClicked = order.id;
        this.toastr.success("Order Added To Storage");
      })
      .catch((err) => { });
  }

  changeStatus(order, status) {
    this.WaitService.start();

    this.OrdersService.updatedStatus(order.id, status)
      .then((result) => {
        this.WaitService.stop();
        if (order.includeStorage && status == "completed")
          this.toastr.success("Order Added To Storage");
        if (status == "cancelled") this.toastr.success("Job Is Canceled");
        else this.toastr.success("Status Changed");
      })
      .catch((err) => {
        this.WaitService.stop();

        if (err.status == 500) {
          let errorMessage = JSON.parse(err.error.error);
          if (errorMessage.error) this.toastr.error(errorMessage.error);
          else this.toastr.error(errorMessage.error);
        } else this.toastr.error(err.error.error);
      });
  }

  cancelJob(order, status) {
    this.rowClicked = order.id;
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = "Cancel Job";
    modal.componentInstance.message = "Do you really want to delete this job?";
    modal.componentInstance.reason.subscribe((result) => {
      this.rowClicked = order.id;
      if (result != "success") return;
      this.changeStatus(order, status);
    });
  }

  onAction(order) {
    this.rowClicked = order.id;
  }

  actionOnJob(order, action) {
    this.WaitService.start();
    this.OrdersService.updatedAction(order.id, action)
      .then((res: any) => {
        this.WaitService.stop();
        // this.toastr.success("Changes Saved");
        this.toastr.success(res.message);
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  deleteJob(order) {
    this.rowClicked = order.id;
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = order.id + ": Delete Job";
    modal.componentInstance.message =
      "Are you sure you want to permanently delete job ID " +
      order.id +
      "? All data for this job will be lost, including the invoice.";

    modal.componentInstance.reason.subscribe((result) => {
      this.rowClicked = order.id;
      if (result != "success") return;
      this.WaitService.start();
      this.OrdersService.deleteOrder(order.id)
        .then(() => {
          this.rowClicked = order.id;
          this.WaitService.stop();
          this.toastr.success("Job Deleted");
          this.loadOrders(this.type, 50, 0);
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    });
  }

  filteredOrdersData() {
    if (this.searchTimeOut) clearTimeout(this.searchTimeOut);

    this.searchTimeOut = setTimeout(() => {
      if (!this.showPagination) {
        // let txt = this.searchText ? this.searchText.toLowerCase() : null;
        //
        // if (txt) {
        //   let temp = this.ordersData.filter(
        //     (x) =>
        //       (x.id && x.id.toString().includes(txt)) ||
        //       x.accountInfo.name.toLowerCase().includes(txt) ||
        //       (x.appService &&
        //         x.appService.label.toLowerCase().includes(txt)) ||
        //       (x.service && x.service.label.toLowerCase().includes(txt)) ||
        //       (x.truckInfo && x.truckInfo.name.toLowerCase().includes(txt)) ||
        //       (x.driverInfo && x.driverInfo.name.toLowerCase().includes(txt)) ||
        //       (x.pickUpAddress &&
        //         x.pickUpAddress.toLowerCase().includes(txt)) ||
        //       (x.dropOffAddress &&
        //         x.dropOffAddress.toLowerCase().includes(txt)) ||
        //       (x.vehicle &&
        //         ((x.vehicle.make &&
        //           x.vehicle.make.toLowerCase().includes(txt)) ||
        //           (x.vehicle.vehicleModel &&
        //             x.vehicle.vehicleModel.toLowerCase().includes(txt)) ||
        //           (x.vehicle.year &&
        //             x.vehicle.year.toString().toLowerCase().includes(txt)) ||
        //           (x.vehicle.color &&
        //             x.vehicle.color.toLowerCase().includes(txt)))) ||
        //       (x.po && x.po.toLowerCase().includes(txt))
        //   );
        //
        //   this.groupOrders(temp);
        //   this.updateTotalJobs.emit(temp.length);
        // } else {
        //   this.groupOrders(this.ordersData);
        //   this.updateTotalJobs.emit(this.ordersData.length);
        // }
        this.loadOrders(this.type, this.limit, this.offset, this.searchText);
      }
      if (this.showPagination) {
        this.loadOrders(this.type, this.limit, 0, this.searchText);
      }
    }, 1000);
  }

  paginate(offset) {
    if (offset > this.totalOrders) offset = this.totalOrders;
    if (offset <= 0) offset = 0;
    this.offset = offset;
    this.loadOrders(this.type, this.limit, offset);
    this.orderTable.nativeElement.scrollTo(0, 0);
  }

  openDriversModal(orderData) {
    const modal = this.modalService.open(AssignDriverComponent, {
      backdropClass: "light-blue-backdrop",
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.orderData = orderData;
    modal.componentInstance.driverData = this.drivers;
  }

  getTimer(date) {
    let expiredAt = moment(date).add(3, "m");
    let diff = moment.duration(expiredAt.diff(moment(new Date()))).asSeconds();
    return diff;
  }

  updateAppOrder(data) {
    let index = this.filteredOrders.findIndex((x) => x.id == data.id);

    if (index == -1) return;

    this.filteredOrders[index].callerName = data.callerName;
    this.filteredOrders[index].callerPhone = data.callerPhone;
    this.filteredOrders[index].dropOffAddress = data.dropOffAddress;
    this.filteredOrders[index].dropOffLat = data.dropOffLat;
    this.filteredOrders[index].dropOffLon = data.dropOffLon;
  }

  parseStatus(status) {
    if (status == "enroute") return "en-route";
    if (status == "onsite") return "on-site";
    if (status == "towdestination") return "tow destination";
    return status;
  }

  groupOrders(type, ordersData) {
    let dataIndex = 0,
      data = [];
    ordersData.map((x, index) => {
      if (index == 0) {
        data.push({
          date: moment(x.completedAt).format("MM/DD/YYYY"),
          day: moment(x.completedAt).format("dddd"),
          orders: [x],
        });
      } else {
        if (
          moment(x.completedAt).format("MM/DD/YYYY") ==
          moment(ordersData[index - 1].completedAt).format("MM/DD/YYYY")
        ) {
          data[dataIndex].orders.push(x);
        } else {
          dataIndex++;
          data.push({
            date: moment(x.completedAt).format("MM/DD/YYYY"),
            day: moment(x.completedAt).format("dddd"),
            orders: [x],
          });
        }
      }
    });

    this.filteredOrders = data;
    this.loadedOrders = ordersData.length;
    // if (type === 'waiting') {
    //   this.filteredOrders.forEach((e, index, obj) => {
    //     const newOrder = e.orders.filter((ele) => {
    //       if ((!ele.status || ele.status === 'pending') && ele.serviceTime !== "scheduled") {
    //         return ele
    //       }
    //     })
    //     if (newOrder.length)
    //       e.orders = newOrder
    //     else {
    //       obj.splice(index, 1)
    //     }
    //   })
    // }

    if (!this.filteredOrders || !this.filteredOrders.length)
      setTimeout(() => document.getElementById("invisible").style.visibility = "visible", 1000);
  }

  // View Order invoice
  redirectToInvoice(uid: string, download: boolean = false) {
    this.OrdersService.redirectToInvoice(uid, download);
  }

  chargeCard(orderData: any, index: number) {
    this.WaitService.start();
    this.PaymentService.getAddedGateWay()
      .then((response) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));
        let checkForGateway;
        let expDateCheck;
        result.data.forEach((x) => {
          if (x.isActive) {
            checkForGateway = x.gateWayType;
            expDateCheck = x.expiresAt;
          }
        });

        if (!checkForGateway && !orderData.isCreatedBySuperAdmin)
          this.toastr.error(
            "Looks Like You Have Not Enabled Any Payment Gateway, Please Enable It First"
          );
        // if (checkForGateway && checkForGateway != "square" && checkForGateway != "clover")
        //   this.chargeSpreedleCard(orderData, index);
        if ((checkForGateway && checkForGateway == "square") || (orderData.isCreatedBySuperAdmin) || (orderData.orderType == "app")) {
          var today = momentbasic();
          var expiry = momentbasic(expDateCheck);
          var difference = expiry.diff(today, "seconds");
          console.log(difference);
          if (difference <= 0) {
            return this.toastr.error("Authentication Expired");
          }
          this.chargeSquareCard(orderData, index)
        }
        if (checkForGateway && checkForGateway == "clover") {
          this.chargeCloverCard(orderData, index);
        }
        this.InvoicesService.updatePaidTime(orderData.id);
        this.rowClicked = orderData.id;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  chargeCloverCard(orderData: any, index: any) {
    const modalPseudo = this.modalService.open(CloverFormComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalPseudo.componentInstance.orderData = orderData;
    let self = this;
    modalPseudo.componentInstance.returnData.subscribe((res) => {
      if (res) self.ordersData[index].invoiceBalance = 0;
    });
  }

  chargeSquareCard(orderData: any, index: number) {
    // const modal = this.modalService.open(CollectDataComponent, {
    //   backdropClass: "light-blue-backdrop",
    //   backdrop: "static",
    //   keyboard: false,
    // });
    // modal.componentInstance.customerData.subscribe((res) => {
    //   console.log(">>>>>>..",res)
    //   if(!res.success) return;
    const modalPseudo = this.modalService.open(SqFormComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalPseudo.componentInstance.orderData = orderData;
    // modalPseudo.componentInstance.address = res;
    let self = this;
    modalPseudo.componentInstance.returnData.subscribe((res) => {
      console.log("hee--------------");
      if (res) self.loadOrders(self.type, self.limit, self.offset);
    });
    // });
  }

  chargeSpreedleCard(orderData: any, index: number) {
    const modal = this.modalService.open(CollectDataComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.customerData.subscribe((res) => {
      if (!res.success) return;

      this.WaitService.start();
      let serviceName =
        orderData.orderType == "app"
          ? orderData.appService.label
          : orderData.service.label;
      SpreedlyExpress.init(environment.SPREEDLY_ENV_KEY, {
        amount: "$" + orderData.invoiceBalance,
        company_name:
          this.currentUser &&
            this.currentUser.companInfo &&
            this.currentUser.companInfo.companyName
            ? this.currentUser.companInfo.companyName
            : "STUK",
        sidebar_top_description: "Service: " + serviceName,
        sidebar_bottom_description: "Your order total",
        full_name: "",
      });
      setTimeout(() => {
        this.WaitService.stop();
        SpreedlyExpress.openView();
      }, 1500);

      SpreedlyExpress.onPaymentMethod((token, paymentMethod) => {
        let data = {
          orderId: orderData.id,
          token: token,
          amount: orderData.invoiceBalance,
          customerData: res,
        };
        this.WaitService.start();
        this.PaymentService.chargeCard(data)
          .then(() => {
            SpreedlyExpress.unload();
            this.WaitService.stop();
            this.toastr.success("Card Has Been Charged");
            this.ordersData[index].invoiceBalance = 0;
            this.loadOrders(this.type, this.limit, this.offset);
          })
          .catch((err) => {
            SpreedlyExpress.unload();
            this.toastr.error("Something Went Wrong");
            this.WaitService.stop();
          });
      });
    });
  }

  payCash(order, index: number) {
    this.rowClicked = order.id;
    const modal = this.modalService.open(PayCashComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.orderData = order;
    modal.componentInstance.payCash.subscribe((res) => {
      if (!res.success) return;

      this.OrdersService.getJobById(order.id)
        .then((result: any) => {
          if (result.data && result.data.length)
            this.ordersData[index].invoiceBalance =
              result.data[0].invoiceBalance;

          this.loadOrders(this.type, this.limit, this.offset);
          this.InvoicesService.updatePaidTime(order.id);
          this.rowClicked = order.id;
        })
        .catch((err) => {
          this.toastr.error(err.error.error);
        });
    });
    modal.result.then(() => {
      this.rowClicked = order.id;
    })
  }

  openEditEmailModal(data) {
    const modal = this.modalService.open(EmailInvoiceComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.data = data;
    modal.componentInstance.showSaveSendButon = false;
    modal.result.then(() => {
      this.rowClicked = data.id;
    })
  }

  //  Send Invoie
  sendInvoice(data) {
    this.rowClicked = data.id;
    this.openEditEmailModal(data.invoiceInfo);
    return;
    // data.invoiceId = data.invoiceInfo.id;
    // data.email_to = data.invoiceInfo.to;
    // data.email_cc = data.invoiceInfo.cc;
    // data.email_bcc = data.invoiceInfo.bcc;
    // this.WaitService.start();
    // this.InvoicesService.emailInvoice(data.invoiceInfo)
    //   .then(() => {
    //     this.WaitService.stop();
    //     this.toastr.success("Invoice Sent");
    //   })
    //   .catch((err) => {
    //     this.WaitService.stop();
    //     this.toastr.error(err.error.error);
    //   });
  }

  markAsPaid(id: number, isPaid: boolean) {
    this.WaitService.start();
    this.InvoicesService.markAsPaid(id, isPaid)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Changes Saved");
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  moveToNew(data) {
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = "Move Back To New";

    if (data.paid) {
      modal.componentInstance.message =
        "This invoice was paid on site. Are you sure you want to mark unpaid and move it back to the New tab? ";
      modal.componentInstance.message2 =
        "It can be edited and approved again once moved back.";
    } else if (data.sent) {
      modal.componentInstance.message =
        "This invoice has already been sent. Are you sure you want to move it back to the New tab?";
      modal.componentInstance.message2 =
        "It can be edited and sent again once moved back.";
    } else {
      modal.componentInstance.message =
        "Are you sure you want to move it back to the New tab?";
      modal.componentInstance.message2 =
        "It can be edited and sent again once moved back.";
    }

    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.WaitService.start();
      this.InvoicesService.moveToNew(data.id)
        .then((data) => {
          this.WaitService.stop();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    });
  }

  onDateChange(e, dateType: string) {
    if (dateType == "fromDate") this.fromDate = e;
    else this.endDate = e;
    this.loadOrders(this.type, this.limit, 0, this.searchText, true);
  }

  getText(e) {
    this.searchText = e;
    this.filteredOrdersData();
  }
  changeRow(idx: any) {
    // if (this.rowClicked === idx) this.rowClicked = idx;
    // else this.rowClicked = -1;
    // console.log(idx, this.rowClicked);
    this.rowClicked = idx;
  }

  handleEvent(e: CountdownEvent, order: any) {
    if (e.action == "done") {
      this.actionOnJob(order, "rejected");
    }
  }

  dropDownClick(orderId: Number) {
    setTimeout(() => {
      let reqSibling = document.getElementById(`dropdownBasic_${orderId}`).nextElementSibling;
      let reqChild = reqSibling.lastElementChild;
      let customTable = <HTMLElement>document.getElementsByClassName("card_inner_tbl")[0];
      let tableBottomPosition = customTable.getBoundingClientRect().bottom;
      let siblingBottomPosition = reqSibling.getBoundingClientRect().bottom;

      if (siblingBottomPosition && (siblingBottomPosition >= tableBottomPosition || siblingBottomPosition >= window.innerHeight))
        reqChild.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 50);
  }
}
