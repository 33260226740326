import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OrdersService } from "src/app/services/orders/orders.service";
import { WaitService } from "src/app/services/wait/wait.service";

@Component({
  selector: "app-edit-notes",
  templateUrl: "./edit-notes.component.html",
  styleUrls: ["./edit-notes.component.scss"],
})
export class EditNotesComponent implements OnInit {
  @Input() order: any = null;
  constructor(private modal: NgbActiveModal,private OrdersService: OrdersService,private WaitService: WaitService) {}

  ngOnInit() {
    if(this.order.additionalInfo && !this.order.notes){
      this.order.notes=this.order.additionalInfo;
    }
  }

  save(){
    this.WaitService.start();
    if(!this.order.additionalInfo && this.order.notes){
      this.order.additionalInfo=this.order.notes;
    }
    let editQuery;
    if (this.order.isDraft) this.order.dontcreate = true;
    if(!this.order.isEditFromSuperAdmin) editQuery = this.OrdersService.editOrder(this.order);
    if(this.order.isEditFromSuperAdmin) editQuery = this.OrdersService.editOrderSuperadmin(this.order);

    editQuery
      .then((res) => {
        this.WaitService.stop();
        this.modal.close();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  close() {
    this.modal.close();
  }
}
