import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { GoogleApiService } from "../../../services/googleApi/google-api.service";
import { PlacesService } from "../../../services/places/places.service";
import * as moment from "moment";

@Component({
  selector: "app-create-place",
  templateUrl: "./create-place.component.html",
  styleUrls: ["./create-place.component.scss"],
})
export class CreatePlaceComponent implements OnInit {
  @Input() providerId: string = null;
  @Input() placeData: any = {
    providerId: null,
    name: null,
    locationTypeId: null,
    phone: null,
    siteCode: null,
    contactName: null,
    addressText: null,
    lat: null,
    lng: null,
  };
  locationTypes: any = [];
  addressSuggestions = [];
  suggesstionTimeOut: any;
  showSuggesstion: boolean = false;
  lat = 0;
  lng = 0;
  showMap: boolean = false;
  validationErrors: any = {};
  currentUser: any = null;
  updatedAt: any = null;

  constructor(
    private modal: NgbActiveModal,
    private WaitService: WaitService,
    private toastr: ToastrService,
    private GoogleApiService: GoogleApiService,
    private PlacesService: PlacesService
  ) {}

  ngOnInit() {
    this.getLocationTypes();
    if (!this.placeData.providerId) this.placeData.providerId = this.providerId;
    if (this.placeData.id) {
      this.lat = parseFloat(this.placeData.lat);
      this.lng = parseFloat(this.placeData.lng);
      this.updatedAt = moment(this.placeData.updatedAt).format(
        "ddd MMM DD YYYY hh:mm:ss A ZZ"
      );
    }
  }

  getLocationTypes() {
    this.WaitService.start();
    this.PlacesService.getLocationTypes()
      .then((response) => {
        let result = JSON.parse(JSON.stringify(response));
        this.locationTypes = result.data;
        this.WaitService.stop();
      })
      .catch((err) => {
        this.WaitService.stop();
        console.log(err);
      });
  }
  close() {
    this.modal.close();
  }

  hideSuggesstion() {
    setTimeout(() => {
      this.showSuggesstion = false;
      console.log(this.addressSuggestions && this.addressSuggestions.length);

      if (this.addressSuggestions && this.addressSuggestions.length) {
        let matchedAddress = this.addressSuggestions.filter(
          (x) => x.description == this.placeData.addressText
        );

        if (!matchedAddress.length)
          this.placeData.addressText = this.addressSuggestions[0].description;
      }
    }, 1000);
  }

  searchAddress(text) {
    clearTimeout(this.suggesstionTimeOut);
    this.suggesstionTimeOut = setTimeout(() => {
      this.lat = null;
      this.lng = null;
      this.placeData.lat = null;
      this.placeData.lng = null;
      this.GoogleApiService.autoCompleteAddress(text, null, null, true)
        .then((res) => {
          let result = JSON.parse(JSON.stringify(res));
          this.addressSuggestions = result.data.predictions;
        })
        .catch((err) => {
          console.log(err);
        });
    }, 300);
  }

  convertToAddress(e) {
    this.lat = e.coords.lat;
    this.lng = e.coords.lng;
    this.placeData.lat = this.lat;
    this.placeData.lng = this.lng;
    this.GoogleApiService.getAddress(e.coords.lat, e.coords.lng)
      .then((response) => {
        this.showMap = false;
        const result = JSON.parse(JSON.stringify(response));
        let places = result.data.results;
        if (places && places.length) {
          this.placeData.addressText = places[0].formatted_address;
        }
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }

  savePlace() {
    this.WaitService.start();
    let action;

    if (this.placeData.id)
      action = this.PlacesService.editPlace(this.placeData);
    else action = this.PlacesService.createPlace(this.placeData);

    action
      .then((result) => {
        this.WaitService.stop();
        this.close();
        if (this.placeData.id) this.toastr.success("Changes Saved");
        else this.toastr.success("Place Added");
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  selectPlace(data) {
    this.placeData.addressText = data.description;
    this.GoogleApiService.getPlceDetailById(data.place_id).then((response) => {
      let result = JSON.parse(JSON.stringify(response));
      result = result.data.result;

      let bounds = result.geometry.location;
      this.lat = bounds.lat;
      this.lng = bounds.lng;
      this.placeData.lat = this.lat;
      this.placeData.lng = this.lng;
    });

    this.addressSuggestions = [];
  }
}
