import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { UserService } from "../../../services/user/user.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  @Input() showCloseButton: boolean = true;
  data: any = { newPassword: null, confirmPassword: null };

  constructor(
    private modal: NgbActiveModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private UserService: UserService
  ) {}

  ngOnInit() {}

  close() {
    this.modal.close();
  }

  saveData() {
    if (this.data.newPassword !== this.data.confirmPassword) {
      this.toastr.error("Password Not Matched");
      return;
    }

    this.WaitService.start();
    this.UserService.changePassword(this.data.newPassword)
      .then(() => {
        this.WaitService.stop();
        this.close();
        this.toastr.success("Changes Saved");
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }
}
