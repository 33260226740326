import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProvidersService {
  constructor(private httpClient: HttpClient) {}

  getProviderInfo(isSuperAdmin=false, id:any = 0) {
    let params = new HttpParams();

    if (isSuperAdmin) {
      params = params.set("providerId", id);
    }

    let apiUrl = environment.baseURL + "/api/providers";
    if(isSuperAdmin) apiUrl = environment.baseURL + "/api/super_admin/provider/"+id;
    return new Promise((resolve, reject) => {
      this.httpClient.get(apiUrl, {params}).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  saveProviderInfo(data: object) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/providers", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteImage(data: object, id: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(
          environment.baseURL + "/api/providers/deleteProviderImage/" + id,
          data
        )
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getTax(id=null, isEditFromSuperAdmin=false) {
    let params = new HttpParams();

    if (isEditFromSuperAdmin) {
      params = params.set("providerId", id);
    }

    let reqURL = environment.baseURL + "/api/providers/tax";
    if(isEditFromSuperAdmin)  reqURL = environment.baseURL + "/api/super_admin/provider/tax";

    return new Promise((resolve, reject) => {
      this.httpClient.get(reqURL, {params}).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  saveTax(data: object) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/providers/tax", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  uploadImage(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/providers/image", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getOnBoarded() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/providers/onboard")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  setOnBoarded() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/providers/onboard", {})
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
