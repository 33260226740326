import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { OrdersService } from "../../../services/orders/orders.service";
import * as moment from "moment";


@Component({
  selector: "app-email-job",
  templateUrl: "./email-job.component.html",
  styleUrls: ["./email-job.component.scss"],
})
export class EmailJobComponent implements OnInit {
  @Input() order: any;
  @ViewChild("f", { static: false }) form: NgForm;
  emails: any = { to: null, cc: null, bcc: null };
  errors: any = { to: false, cc: false, bcc: false };

  constructor(
    private modal: NgbActiveModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private OrdersService: OrdersService
  ) {}

  ngOnInit() {}

  close() {
    this.modal.close();
  }

  send() {
    var regEx = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let { to, cc, bcc } = this.emails;
    let data = {
      to: [],
      cc: [],
      bcc: [],
      orderId: this.order.id,
    };

    this.errors = { to: false, cc: false, bcc: false };

    if (to && to.length) {
      to.split(/[\s,;]+/).forEach((x) => {
        if (!regEx.test(x)) this.errors.to = true;
        data.to.push(x);
      });
    }

    if (cc && cc.length) {
      cc.split(/[\s,;]+/).forEach((x) => {
        if (!regEx.test(x)) this.errors.cc = true;
        data.cc.push(x);
      });
    }

    if (bcc && bcc.length) {
      bcc.split(/[\s,;]+/).forEach((x) => {
        if (!regEx.test(x)) this.errors.bcc = true;
        data.bcc.push(x);
      });
    }

    if (!to) {
      this.errors.to=true;
      return;
    }
    if (this.errors.to || this.errors.cc || this.errors.bcc) return;

    this.WaitService.start();
    let editbySAdmin = false;
    if(this.order.isEditFromSuperAdmin) editbySAdmin = true;
    this.OrdersService.emailDetails(this.order.id, data, editbySAdmin)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Email Sent");
        this.close();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }
}
