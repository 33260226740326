import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { UserService } from "../../services/user/user.service";
import { WaitService } from "../../services/wait/wait.service";
import { SocketService } from "../../services/socket/socket.service";
import { ValidateService } from "src/app/services/phone-validate/validate.service";
import { EditUserComponent } from "../../views/modals/edit-user/edit-user.component";

@Component({
  selector: "app-drivers",
  templateUrl: "./drivers.component.html",
  styleUrls: ["./drivers.component.scss"],
})
export class DriversComponent implements OnInit {
  p: number = 1;
  drivers: any = [];
  searchText: string = "";
  filteredUsers: any = [];
  loadedData: number = 0;
  totalUsers:number=0;
  filters: any = {
    text: null,
    limit: 10,
    offset: 0,
  };
  constructor(
    private modalService: NgbModal,
    private ToastrService: ToastrService,
    private UserService: UserService,
    private WaitService: WaitService,
    private SocketService: SocketService,
    private ValidateService: ValidateService
  ) {}

  openModal(id: number = null) {
    const modal = this.modalService.open(EditUserComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    if (id) modal.componentInstance.userId = id;
    modal.componentInstance.userType = "driver";
    modal.result.then((data) => {
      this.getDrivers();
    });
  }

  openDeleteModal(id) {
    const modal = this.modalService.open(EditUserComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.deleteUser = id;
    modal.componentInstance.userType = "driver";
    modal.result.then((data) => {
      this.getDrivers();
      this.p = 1;
    });
  }

  getDrivers() {
    this.drivers = [];
    this.WaitService.start();
    this.UserService.getDrivers(this.filters)
      .then((res: any) => {
        this.WaitService.stop();
        this.totalUsers=parseInt(res.total.count);
        this.drivers = res.data.map((user) => {
          let name;
          if (user.phone) {
            user.phone = this.ValidateService.onEdit(user.phone);
            }
          if (user.name) {
            user.name = user.name.split("  ").join(" ");
            name = user.name.split(" ");
          } else name = ["", ""];
          user.fName = name[0] ? name[0] : "";
          user.lName = name[1] ? name[1] : "";
          user.selectDrop = false;

          return user;
        });

        this.filteredUsers = this.drivers;
        this.loadedData=this.drivers.length;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.ToastrService.error("Something Went Wrong");
      });
  }

  ngOnInit() {
    this.getDrivers();

    const socket = this.SocketService.getSocket();

    if (socket) {
      socket.on("driverActive", (result: any) => {
        const index = this.drivers.findIndex((x) => x.id == result.data.userId);
        if (index != -1) this.drivers[index].isOnline = result.data.isOnline;
      });
    }
  }

  filterUsers() {
    this.p = 1;
    const text = this.searchText.toLowerCase();
    this.filteredUsers = this.drivers.filter(
      (x) =>
        (x.fname && x.fname.toLowerCase().includes(text)) ||
        (x.lName && x.lName.toLowerCase().includes(text)) ||
        (x.phone && x.phone.toLowerCase().includes(text)) ||
        (x.email && x.email.toLowerCase().includes(text)) ||
        (x.username && x.username.toLowerCase().includes(text)) ||
        (x.Role && x.Role.name && x.Role.name.toLowerCase().includes(text))
    );
  }

  getText(e) {
    this.searchText = e;
    this.filters.text= e;
    //this.filterUsers();
    this.getDrivers();
  }

  paginate(offset: number) {
    if (offset > this.totalUsers) offset = this.totalUsers;
    if (offset <= 0) offset = 0;
    this.filters.offset = offset;
    this.getDrivers();
    //this.userTable.nativeElement.scrollTo(0, 0);
  }
}
