import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { LoginService } from "../../services/login/login.service";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  currentUser: any = null;
  isOpen:boolean =false
  constructor(public router: Router, private LoginService: LoginService) {}

  ngOnInit() {
    this.currentUser = this.LoginService.getCurrentUser();
  }
}
