import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private httpClient: HttpClient) {}

  getAllPaymentGateWays() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/paymentSetup/all_gatways")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  addPaymentGateWay(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.baseURL + "/api/settings/paymentSetup/add_gatway",
          data
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  squarePaymentGateWayAdd(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.baseURL + "/api/settings/paymentSetup/add_gatways_square",
          data
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  cloverPaymentGateWayAdd(data){
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.baseURL + "/api/settings/paymentSetup/add_gatways_clover",
          data
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAddedGateWay() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/paymentSetup/my_gateways")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  removeGateWay(id: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(
          environment.baseURL + "/api/settings/paymentSetup/my_gateways/" + id
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatePaymentGateWay(id: number, data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(
          environment.baseURL + "/api/settings/paymentSetup/edit_gatway/" + id,
          data
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  activateGateWay(id: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/settings/paymentSetup/activate", {
          id: id,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCloverSDKKey(id: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/paymentSetup/clover/webKey/"+id)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  chargeCard(data: any, isEditFromSuperAdmin = false) {
    let reqURL = environment.baseURL + "/api/payments/pay";
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/payments/pay";
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(reqURL, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  refundCharege(data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/payments/refund", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  payCash(data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/payments/cash", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
