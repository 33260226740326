import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment-timezone";
import { CountdownEvent } from "ngx-countdown";

@Component({
  selector: "app-scheduled-est-time",
  templateUrl: "./scheduled-est-time.component.html",
  styleUrls: ["./scheduled-est-time.component.scss"],
})
export class ScheduledEstTimeComponent implements OnInit {
  @Input() order: any;
  // timeDiff: any;
  id: any;
  text: any;
  isCountDown:boolean = false;
  config:any=null;
  seconds:any=null;
  constructor() {}

  ngOnInit() {
    this.calculateEST();
  }
  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  calculateEST() {
    this.id = setInterval(() => {
      let timezone = null;

      switch (this.order.scheduledTimeZone) {
        case "AKST":
          timezone = "America/Anchorage";
          break;
        case "CST":
          timezone = "America/Chicago";
          break;
        case "EST":
          timezone = "America/New_York";
          break;
        case "HST":
          timezone = "America/Adak";
          break;
        case "MST":
          timezone = "America/Phoenix";
          break;
        case "PST":
          timezone = "America/Los_Angeles";
          break;
      }

      const scheduledDate = moment(this.order.scheduledFor).format("YYYY-MM-DD");
      const scheduledTime = moment.tz(scheduledDate + " " + this.order.scheduledTime, timezone).local();
      const now = moment(new Date());
      console.log(">>>>>>>>>>>>>1",moment.tz(scheduledDate + " " + this.order.scheduledTime, timezone).format());
      
      const timeDiff = moment.duration(scheduledTime.diff(now));
      const diffInDays = Math.floor(timeDiff.asDays());
      console.log(">>>>>>>>>>>>>2",diffInDays);
      const diffInHours = Math.floor(timeDiff.asHours());
      console.log(">>>>>>>>>>>>>3",diffInHours);
      const diffInMinutes = parseFloat(timeDiff.asMinutes().toFixed(0));
      const minutes = parseFloat(timeDiff.asMinutes());
      const diffInSeconds= Math.floor(timeDiff.asSeconds());
      //   const text = diffInMinutes < -1 || diffInMinutes > 1 ? " Mins" : " Min";
      //   this.timeDiff = diffInMinutes;
      //   this.text = text;
      // }
      // if (diffInHours > 0) {
      //   if (diffInHours < 24) {
      //     const text = diffInHours < -1 || diffInHours > 1 ? " Hours" : " Hour";
      //     this.timeDiff = diffInHours;
      //     this.text = text;
      //   } else if (diffInDays > 0) {
      //     const text = diffInDays < -1 || diffInDays > 1 ? " Days" : " Day";
      //     this.timeDiff = diffInDays;
      //     this.text = text;
      //   }
      // }
      if(diffInMinutes >= 0 && this.isCountDown == false && diffInSeconds <= 60) {
        this.isCountDown = true;
        this.config = { leftTime: diffInSeconds};
      }
      else if (diffInMinutes > 0) {
        // this.timeDiff = diffInMinutes;
        this.text = diffInMinutes + " min";
      }

      if (diffInHours > 0 && diffInMinutes > 60) {
        // console.log("here", diffInHours, diffInMinutes, diffInSeconds);
        // console.log("exp =", diffInMinutes * 60, diffInSeconds % 60);

        let min = moment.utc(moment.duration(Math.abs(minutes), "minutes").asMilliseconds()).format(" m [min]");
        let arr = min.split(' ');
        this.text = diffInHours + " hr" + (arr[1] == '0' ? " 1 min" : min);
        // if (diffInMinutes == 61) this.text = diffInHours + " hr 1 min";
      }

      if (diffInDays > 0 && diffInHours >= 48) {
        // this.text = diffInHours + " hr" + moment.utc(moment.duration(Math.abs(diffInMinutes), "minutes").asMilliseconds()).format(" m [min]").replace("0 min","");
        const daysCount = Math.floor(diffInHours / 24);
        // this.text = diffInDays + " days";
        this.text = daysCount + " days";
      }
    }, 1000);
  }

  handleEtaEvent(e: CountdownEvent) {
    console.log(e.action);
    if (e.action == "done") {
      // this.isCountDown = false;
      console.log("countDown End");
    }
  }
}
