import * as io from "socket.io-client";
import { Injectable } from "@angular/core";
import { LoginService } from "../login/login.service";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  private url = "/";
  private socket;
  private connected: boolean = false;
  private currentUser: any = null;

  constructor(private loginserve: LoginService) {
    this.url =
      window.location.hostname == "localhost"
        ? "http://localhost:8080"
        : this.url;
    
    let urlSplit = document.URL.split('/');
    if(urlSplit[3]=="admin") this.currentUser = this.loginserve.getSuperAdmin();
    else this.currentUser = this.loginserve.getCurrentUser();
    this.socket = io(this.url, {
      reconnection: true,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 2000,
    });

    this.socket.on("connect", () => {
      console.log("connected");
      this.connected = true;

      if (!this.currentUser) return;

      this.login(this.currentUser.userId);
    });

    this.socket.on("pong", () => {
      console.log("received pong");
      this.connected = true;
    });

    this.socket.on("login", () => {
      console.log("Socket Logged in");
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected");
      this.connected = false;
    });
  }

  login(uid) {
    if (!this.connected) return;
    this.socket.emit("login", { uid: uid });
  }

  logout(uid) {
    if (!this.connected) return;
    this.socket.emit("logout", { uid: uid });
  }

  getSocket() {
    return this.socket;
  }

  on(ev, callback) {
    if (!this.currentUser) return;
    this.socket.on(ev, callback);
  }

  off(ev, callback) {
    if (!this.currentUser) return;
    this.socket.off(ev, callback);
  }

  disconnect() {
    this.socket.io.disconnect();
  }
}
