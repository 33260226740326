import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { InvoicesService } from "../../../services/invoices/invoices.service";
import { PaymentService } from "../../../services/payment/payment.service";

import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-refund",
  templateUrl: "./refund.component.html",
  styleUrls: ["./refund.component.scss"],
})
export class RefundComponent implements OnInit {
  @Input() invoiceId: number = null;
  @Input() orderId: number = null;
  @Input() memo: string;
  @Output() reason: EventEmitter<any> = new EventEmitter();

  payments: any = [];
  transactionToken: string = "";
  maxAmount: number = 0;
  amount: number = 0;
  isDataLoaded: boolean = false;

  constructor(
    private modal: NgbActiveModal,
    private WaitService: WaitService,
    private InvoicesService: InvoicesService,
    private PaymentService: PaymentService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getTransactions();
  }

  close(success: boolean) {
    this.modal.close();
    this.reason.emit({ success: success });
  }

  getTransactions() {
    this.WaitService.start();
    this.InvoicesService.getInvoicePayments(this.invoiceId)
      .then((res) => {
        this.WaitService.stop();
        this.payments = JSON.parse(JSON.stringify(res)).data;
        this.isDataLoaded = true;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
        this.isDataLoaded = true;
      });
  }

  setMaxAmount(amount: number) {
    this.amount = amount;
    this.maxAmount = amount;
  }

  save() {
    if (isNaN(this.amount) || this.amount <= 0) {
      this.toastr.error("Please Enter A Valid Amount");
      return;
    }
    this.WaitService.start();
    this.PaymentService.refundCharege({
      token: this.transactionToken,
      amount: this.amount,
      memo: this.memo,
      orderId: this.orderId,
    })
      .then((res) => {
        this.WaitService.stop();
        this.close(true);
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }
}
