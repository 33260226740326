// import the required animation functions from the angular animations module
import {
  trigger,
  animate,
  transition,
  style,
  query,
  animateChild,
  group,
} from "@angular/animations";

export const fadeInAnimation = trigger("fadeInAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("2s ease-out", style({ opacity: 1 })),
  ]),
]);

export const fadeOutAnimation = trigger("fadeOutAnimation", [
  transition(":leave", [
    style({ opacity: 1 }),
    animate("1s ease-out", style({ opacity: 0 })),
  ]),
]);
