import { Component } from "@angular/core";
import { AppService } from "../app.service";

import { Router } from "@angular/router";

import { LoginService } from "../services/login/login.service";
import { WaitService } from "../services/wait/wait.service";

import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  isExpanded: boolean = false;
  currentYear = null;

  signupData: any = {
    firstName: null,
    lastName: null,
    address: null,
    companyName: null,
    companyEmail: null,
    phoneNumber: null,
    message: null,
    isSubmitted: false,
  };

  contactData: any = {
    phone: null,
    message: null,
    isSubmitted: false,
  };

  constructor(
    private appService: AppService,
    private LoginService: LoginService,
    private WaitService: WaitService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.appService.pageTitle = "Home";
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    if (this.LoginService.isLoggedIn()) this.router.navigateByUrl("/login");
  }

  scroll(el) {
    document
      .querySelector(el)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }

  signup() {
    this.WaitService.start();
    this.LoginService.signupRequest(this.signupData)
      .then((result) => {
        this.WaitService.stop();
        this.signupData.isSubmitted = true;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error);
      });
  }

  contact() {
    this.WaitService.start();
    this.LoginService.contactRequest(this.contactData)
      .then((result) => {
        this.WaitService.stop();
        this.contactData.isSubmitted = true;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error);
      });
  }
}
