import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { OrdersService } from "../../../services/orders/orders.service";
import { WaitService } from "../../../services/wait/wait.service";

@Component({
  selector: "app-confirm-tow",
  templateUrl: "./confirm-tow.component.html",
  styleUrls: ["./confirm-tow.component.scss"],
})
export class ConfirmTowComponent implements OnInit {
  @Output() reason: EventEmitter<any> = new EventEmitter();
  @Input() orderData: any;

  constructor(
    private modal: NgbActiveModal,
    private OrdersService: OrdersService,
    private WaitService: WaitService
  ) {}

  ngOnInit() {}

  close(reason: string) {
    this.modal.close();
    this.reason.emit(reason);
  }
}
