import { Component, OnInit } from "@angular/core";

import { ForgotService } from "../../services/forgot/forgot.service";

import { fadeInAnimation } from "../../animations";

@Component({
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" },
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  errorMessage: string = null;
  email: string = "";
  view: string = "form";
  isLoading: boolean = false;

  constructor(private ForgotService: ForgotService) {}
  ngOnInit() {}

  sendRestLink() {
    this.isLoading = true;
    this.errorMessage = "";
    this.ForgotService.sendRestLink(this.email)
      .then((res) => {
        console.log(res);
        this.view = "success";
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.errorMessage = err.error.error;
      });
  }
}
