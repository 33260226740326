import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduledEstTimeComponent } from './scheduled-est-time.component';
import { CountdownModule} from "ngx-countdown";


@NgModule({
  declarations: [ScheduledEstTimeComponent],
  imports: [
    CommonModule,
    CountdownModule
  ],
  exports:[ScheduledEstTimeComponent]
})
export class ScheduledEstTimeModule { }
