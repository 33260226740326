import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";

import { UiSwitchModule } from "ngx-ui-switch";
import { StukDatePickerModule } from "../../../components/stuk-date-picker/stuk-date-picker.module";
import { OrderPaymentsModule } from "../../../components/order-payments/order-payments.module";

import { EditInvoiceComponent } from "./edit-invoice.component";

import { DecimalNumberModule } from "../../../pipes/decimalNumber/decimal-number.module";

@NgModule({
  declarations: [EditInvoiceComponent],
  imports: [
    CommonModule,
    FormsModule,
    UiSwitchModule,
    StukDatePickerModule,
    NgbPopoverModule,
    OrderPaymentsModule,
    DecimalNumberModule,
  ],
  exports: [EditInvoiceComponent],
  entryComponents: [EditInvoiceComponent],
})
export class EditInvoiceModule {}
