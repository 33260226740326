import { Injectable } from "@angular/core";
import { HttpClient,HttpParams  } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserSettingsService {
  constructor(private httpClient: HttpClient) {}

  getMyNotifications() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/notification")
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  setMyNotifications(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/config/notification", data)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getWaiver() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/waivers")
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getWaiverWithoutLogin(params) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/configUnGaurd/waivers", { params: params })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getWaiverByType(type: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/waivers/" + type)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  setWaiver(type: string, text: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/config/waivers", {
          text: text,
          type: type,
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getFilters() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/dashboard/filters")
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  setFilters(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/config/dashboard/filters", data)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getTabs() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/dashboard/tabs")
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  setTabs(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/config/dashboard/tabs", data)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  findOrCreateBillingProfile() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(
          environment.baseURL + "/api/config/billing_method/create_profile",
          null
        )
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }
  getBillingMethod() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/billing_method")
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  setBillingMethod(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/config/billing_method", data)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  removeBillingMethod() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(
          environment.baseURL + "/api/config/billing_method/remove_method"
        )
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getBillingList(limit, offset, from?, to?) {
    let params = new HttpParams();
    if (offset) params = params.set("offset", offset);
    if (limit) params = params.set("limit", limit);
    if (to) params = params.set("to", to);
    if (from) params = params.set("from", from);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/billing_method/billed_log", {
          params
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getEarningList(limit, offset, from?, to?) {
    let params = new HttpParams();
    if (offset) params = params.set("offset", offset);
    if (limit) params = params.set("limit", limit);
    if (to) params = params.set("to", to);
    if (from) params = params.set("from", from);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/billing_method/earning", {
          params
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getOperationTimings() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/config/timings")
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  setOperationTimings(data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/config/timings", data)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getBillingInvoice(data, invoiceId) {
    let params = new HttpParams();
    if (data) params = params.set("pid", data);
    if (invoiceId>0) params = params.set("invoiceId", invoiceId);
    
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/billing/billed_invoice",{params})
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getEarningInvoice(data, invoiceId) {
    let params = new HttpParams();
    if (data) params = params.set("pid", data);
    if (invoiceId>0) params = params.set("earningId", invoiceId);
    
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/earningDetail/earned_invoice",{params})
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

}
