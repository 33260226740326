import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

//services
import { ProvidersService } from "../../../services/providers/providers.service";
import { GoogleApiService } from "../../../services/googleApi/google-api.service";
import { WaitService } from "../../../services/wait/wait.service";

@Component({
  selector: "app-provider-location",
  templateUrl: "./provider-location.component.html",
  styleUrls: ["./provider-location.component.scss"],
})
export class ProviderLocationComponent implements OnInit {
  @Input() formattedAddress: string;
  @Input() lat: number;
  @Input() lng: number;
  @Input() address: any = {};

  @Output() newLocation: EventEmitter<any> = new EventEmitter();
  zoom: number = 12;
  matchedPlaces = [];
  searchTimeOut: any;
  errorMessage: string = null;
  showMapMarker: boolean = true;
  showUpdatedAddress: boolean = false;
  hideOptionValidation: boolean = false;
  invalidAddress: boolean = false;

  currentDeviceLocation = {
    lat: null,
    lng: null,
  };

  constructor(
    private modal: NgbActiveModal,
    private GoogleApiService: GoogleApiService,
    private ProvidersService: ProvidersService,
    private WaitService: WaitService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this.lat && this.lng) {
      this.showMapMarker = true;
      this.convertToAddress();
    }
    else {
      this.showMapMarker = false;
      this.getMyLocation();
    }
  }

  close(save = false) {
    this.modal.close();
    if (save)
      this.newLocation.emit(this.address);
  }

  setMarker(e) {
    this.lat = e.coords.lat;
    this.lng = e.coords.lng;
    this.showMapMarker = true;
    this.showUpdatedAddress = true;
    this.invalidAddress = false;
    this.convertToAddress();
  }

  getMyLocation(btnClick?) {
    try {
      if (navigator.geolocation.getCurrentPosition) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            if (btnClick) {
              this.showMapMarker = true;
              this.showUpdatedAddress = true;
            }
            this.convertToAddress();
          },
          (error) => {
            this.toastr.error("Please enable your location")
          }
          
        );
      }
      else {
        this.toastr.error("Please enable your location")
      }
    }
    catch (error) {
      this.toastr.error("Please enable your location")
    }
  }

  convertToAddress() {
    this.zoom = 18;
    this.GoogleApiService.getAddress(this.lat, this.lng)
      .then((result: any) => {
        const places = result.data.results;
        if (places && places.length) {
          if (this.showUpdatedAddress == true) {
            this.formattedAddress = places[0].formatted_address;
            //remove country name
            var lastIndex = this.formattedAddress.lastIndexOf(",");
            this.formattedAddress = this.formattedAddress.substring(0, lastIndex);
          }

          this.address = this.GoogleApiService.parseAddress(
            this.address,
            places[0].address_components
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  hideSuggesstion() {
    this.hideOptionValidation = true;
    setTimeout(() => {
      if (this.matchedPlaces && this.matchedPlaces.length)
        this.selectPlace(this.matchedPlaces[0]);
      this.hideOptionValidation = false;
    }, 300);
  }

  searchPlace() {
    if (this.searchTimeOut) clearTimeout(this.searchTimeOut);

    this.searchTimeOut = setTimeout(() => {
      this.matchedPlaces = [];
      this.GoogleApiService.autoCompleteAddress(
        this.formattedAddress,
        this.lat,
        this.lng,
        true
      )
        .then((response) => {
          let result = JSON.parse(JSON.stringify(response));
          this.matchedPlaces = result.data.predictions;
          this.invalidAddress = false;

        })
        .catch((err) => {
          console.log("Error", err);
          this.invalidAddress = true;
        });
    }, 250);
  }

  selectPlace(data) {
    this.formattedAddress = data.description;
    this.GoogleApiService.getPlceDetailById(data.place_id).then((response) => {
      this.showUpdatedAddress = false;
      this.showMapMarker = true;
      let result = JSON.parse(JSON.stringify(response));
      result = result.data.result;

      let bounds = result.geometry.location;
      this.lat = bounds.lat;
      this.lng = bounds.lng;

      this.convertToAddress();
    });

    this.matchedPlaces = [];
  }

  save() {
    this.errorMessage = "";

    if (!this.lat || !this.lng) {
      this.errorMessage = "Unable to get your location";
      return;
    }
    if (
      !this.formattedAddress
      // !this.address.zip ||
      // !this.address.city ||
      // !this.address.state ||
      // !this.address.country
    ) {
      this.errorMessage = "Please enter your full address";
      return;
    }

    if (this.invalidAddress == true) {
      this.errorMessage = "Invalid Address";
      return;
    }

    if (this.hideOptionValidation == true) {
      this.hideOptionValidation = false;
      return
    }


    let data = {
      lat: this.lat,
      lng: this.lng,
      zipCode: this.address.zip,
      country: this.address.country,
      state: this.address.state,
      city: this.address.city,
      addressLineOne: this.formattedAddress,
    };

    this.WaitService.start();
    this.ProvidersService.saveProviderInfo(data)
      .then((response) => {
        this.WaitService.stop();
        this.toastr.success("Your Address Is Saved");
        this.close(true);
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error("Your Address Not Saved");
      });
  }
}
