import { Component, Input, OnInit } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import { WaitService } from "../../services/wait/wait.service";
import { PriceService } from "../../services/price/price.service";

@Component({
  selector: "app-app-services",
  templateUrl: "./app-services.component.html",
  styleUrls: ["./app-services.component.scss"],
})
export class AppServicesComponent implements OnInit {
  @Input() providerId = null;
  appServices: any = [];
  serviceData: any = [];
  selectAll: boolean = false;
  pageDescription: string = "";

  constructor(
    private toastr: ToastrService,
    private WaitService: WaitService,
    private PriceService: PriceService
  ) {}

  ngOnInit() {
    this.loadService();
    this.loadContent();
  }

  loadContent() {
    this.WaitService.start();

    this.PriceService.getAppServicePageContent()
      .then((result: any) => {
        this.WaitService.stop();
        if (result.data && result.data.content)
          this.pageDescription = result.data.content;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  loadService() {
    this.WaitService.start();
    this.PriceService.getApplicationServices()
      .then((response) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));
        this.appServices = result.data.map((x) => {
          x.price = parseFloat(x.price);
          x.pricePerMile = parseFloat(x.pricePerMile);
          x.serviceCharge = parseFloat(x.serviceCharge);
          x.servicePerMileCharge = parseFloat(x.servicePerMileCharge);

          if (x.ProviderAppServices && x.ProviderAppServices.length) {
            x.isActive = x.ProviderAppServices[0].isActive;
            let data = {
              serviceId: x.serviceId,
              isActive: x.isActive,
            };
            if (x.ProviderAppServices[0].id)
              Object.assign(data, { id: x.ProviderAppServices[0].id });
            this.serviceData.push(data);
          } else {
            x.isActive = false;
            this.serviceData.push({ serviceId: x.serviceId, isActive: false });
          }

          return x;
        });
        this.isAllServiceSelected();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  updateServiceList(e, service) {
    let index = this.serviceData.findIndex(
      (x) => x.serviceId == service.serviceId
    );

    if (index != null && index > -1)
      this.serviceData[index].isActive = e.target.checked;

    this.isAllServiceSelected();
  }

  isAllServiceSelected() {
    const selectedServices = this.appServices.filter((x) => x.isActive).length;
    this.selectAll = selectedServices == this.appServices.length;
  }

  save() {
    this.WaitService.start();
    let data = { serviceData: this.serviceData };
    this.PriceService.setApplicationServices(data)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Changes Saved");
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.success(err.error.error);
      });
  }

  selectAllToggle() {
    this.appServices.forEach((x) => {
      x.isActive = this.selectAll;
    });
    this.serviceData.forEach((x) => {
      x.isActive = this.selectAll;
    });
  }
}
