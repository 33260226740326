import { Component, OnInit, Input } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { UserService } from "../../../services/user/user.service";
import { VehicalService } from "../../../services/vehical/vehical.service";
import { OrdersService } from "../../../services/orders/orders.service";
import { MapBoxService } from "../../../services/map-box/map-box.service";
import { SocketService } from "../../../services/socket/socket.service";
// import { LoginService } from "../../../services/login/login.service";
import { ProvidersService } from "../../../services/providers/providers.service";
import { ClassTypesService } from "src/app/services/classTypes/class-types.service";

import { JobDetailsComponent } from "../../../views/modals/job-details/job-details.component";
// import { CreateJobComponent } from "../../../views/modals/create-job/create-job.component";
// import { ConfirmModalComponent } from "../../../views/modals/confirm-modal/confirm-modal.component";

import * as moment from "moment";
import * as mapboxgl from "mapbox-gl";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-assign-driver",
  templateUrl: "./assign-driver.component.html",
  styleUrls: ["./assign-driver.component.scss"],
})
export class AssignDriverComponent implements OnInit {
  @Input() orderData: any;
  @Input() driverData: any = [];
  map: mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  dropoffs: any;
  pointAMarker: any;
  pointBMarker: any;
  companyMarker: any;
  popup: any;
  popupSec: any;
  // End Map Box

  truckUrl: string = "./assets/flat-icons/truck.png";
  pointAIcon: string = "./assets/flat-icons/map-pin-red.png";
  pointBIcon: string = "./assets/flat-icons/map-pin-green.png";

  socket: any = null;
  data: any = [];
  filteredData: any = [];
  zoom: number = 10;
  filters: any = {
    showOffDutyDriver: "onDuty",
    trucksWithoutDriver: false,
    text: null,
  };
  selectedRouteData: any;
  mapDefault = { lat: 0, lng: 0 };
  selectedJobId: number = null;
  mapType: string = "outdoor";
  intendedIndex: any = -1;

  drivers = [];
  trucks = [];
  classTypes = [];
  jobId: number = null;
  selectedDriver = null;
  selectedTruck = null;
  selectedClass = null;
  showJobDetails: any = false;
  searchData: any = [];
  isSetWidth: boolean = false;
  adminNow: any;
  allDrivers: any = [];
  allPointers: any = [];
  imgUrl: any = "assets/images/service-web.png";
  AddtionalItemsValue: any = "";
  showrouteOrder: any;
  locationUpd: any = 0;
  providerInfo = { lat: 0, lng: 0 };

  constructor(
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private UserService: UserService,
    private VehicalService: VehicalService,
    private OrdersService: OrdersService,
    private MapBoxService: MapBoxService,
    private SocketService: SocketService,
    // private LoginService: LoginService,
    private ProvidersService: ProvidersService,
    private ClassTypesService: ClassTypesService
  ) { }

  ngOnInit() {
    this.changeServiceImg(this.orderData, "job");
    this.ClassTypesService.getClassTypes()
      .then((res: any) => {
        this.classTypes = res.data;
        this.classTypes = this.classTypes.filter((data) => {
          if (data.providerClassType.isActive) {
            return data
          }
        })
      })
      .catch((err) => {
        console.log("Error classes");
        console.log(err);
      });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.buildMap(); }, 100);
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      accessToken: environment.MAPBOX_TOKEN,
      container: "map_assign_driver",
      style: this.style,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      maxWidth: '340',
      offset: 15
    });

    this.popupSec = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      maxWidth: '340',
      offset: 15
    });

    let ele1 = document.createElement("img");
    ele1.src = this.pointAIcon;

    this.pointAMarker = new mapboxgl.Marker({
      element: ele1,
    });
    let ele2 = document.createElement("img");
    ele2.src = this.pointBIcon;

    this.pointBMarker = new mapboxgl.Marker({
      element: ele2,
    });

    let ele3 = document.createElement("img");
    ele3.src = "./assets/flat-icons/build.png";

    this.companyMarker = new mapboxgl.Marker({
      element: ele3,
    });

    this.getCompanyLocation();
    this.getUsers();
    this.getVehicalTruck();
    // this.getTruck();

    console.log(this.orderData)
    this.socket = this.SocketService.getSocket();

    this.socket.on("jobUpdated", (data) => {
      if (
        this.selectedRouteData &&
        this.selectedRouteData.data.id == data.data.id &&
        (data.data.status == "pending" || data.data.status == "completed" || data.data.status == "cancelled")
      ) {
        this.reloadComponent();
      }
      this.getTruck();
    });

    this.socket.on("newJob", () => { this.getTruck(); })

    this.socket.on("driverActive", (data) => {
      this.getTruck();
    });

    this.socket.on("detailJobUpdated", (data) => {
      let updatedOrder = data.data;
      let reqDriver = this.filteredData.findIndex(driver => driver.id === updatedOrder.driverId);

      if (reqDriver !== -1) {
        let reqOrder = this.filteredData[reqDriver].orders.findIndex(order => order.id === updatedOrder.id);

        if (reqOrder !== -1)
          this.OrdersService.getJobById(updatedOrder.id)
            .then((res: any) => {
              updatedOrder = res.data[0];

              this.filteredData[reqDriver].orders[reqOrder].truckInfo = { id: updatedOrder.truckInfo.id, name: updatedOrder.truckInfo.name };
              this.filteredData[reqDriver].orders[reqOrder].classTypeInfo = updatedOrder.classTypeInfo;
            })
            .catch((err) => {
              console.log(err);
            });
      }
    });

    this.socket.on("trackDriver", (data) => {
      this.updateDriverPosition(data);
    });
  }

  selectDriver(driver) {
    this.selectedDriver = driver.id;
    this.selectedTruck = driver.orderTruckId.id;
    this.selectedClass = driver.classTypeInfo.id;
    if (this.intendedIndex == 0) {
      this.showRoutes(this.showrouteOrder, { id: 0 }, this.showrouteOrder.status, true)
    }
  }

  dispatch() {
    if (!this.orderData.isDraft && this.orderData.serviceTime != "scheduled") {
      this.orderData.status = "dispatched";
    }

    this.orderData.driverId = this.selectedDriver;
    this.orderData.truckId = this.selectedTruck;
    this.orderData.classTypeId = this.selectedClass;
    if (this.orderData.isDraft) this.orderData.dontcreate = true;
    if (this.orderData.driverId) {
      this.orderData.statusChanged = true;
    }
    this.WaitService.start();
    this.OrdersService.editOrder(this.orderData)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Changes Saved");
        this.close();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  getCompanyLocation() {
    this.ProvidersService.getProviderInfo()
      .then((result: any) => {
        if (result.data && result.data.lat && result.data.lng) {
          this.mapDefault = {
            lng: result.data.lng,
            lat: result.data.lat,
          };
          this.providerInfo = {
            lng: result.data.lng,
            lat: result.data.lat,
          };
          this.flyTo(this.mapDefault);
        }
      })
      .catch((err) => {
        console.log("Error");
        console.log(err);
      });
  }

  reloadComponent(notRemove = false) {
    this.removeRoute();
    if (this.pointAMarker) this.pointAMarker.remove();
    if (this.pointBMarker) this.pointBMarker.remove();
    if (this.popup) this.popup.remove();
    if (this.popupSec) this.popupSec.remove();
    this.selectedJobId = null;
    let index = this.filteredData.findIndex(
      (x) => x && x.id == this.intendedIndex
    );
    if (index > -1 && !notRemove) {
      this.filteredData[index].marker.remove();
      this.filteredData[index].pointer.remove();
    }
    if (this.intendedIndex != 0) this.intendedIndex = -1;
  }

  getDriverFilter(val) {
    this.filters.showOffDutyDriver = val;
    this.reloadComponent();
    this.getUsers();
    // this.getTruck();
  }
  getTruck() {
    console.log("hereioioio");
    this.WaitService.start();
    // console.log("LLLL", this.filters);
    // this.filteredData.map((x) => {
    //   if (x.marker) x.marker.remove();
    //   if (x.pointer) x.pointer.remove();
    // });
    // console.log(this.filters,'filters');
    this.VehicalService.trackTruck(this.filters)
      .then((response) => {
        this.WaitService.stop();
        this.data = JSON.parse(JSON.stringify(response)).data;
        console.log(this.data, "tracktruck data");

        this.data.forEach((x) => {
          if (x.orders)
            x.orders.forEach((order) => {
              if (order.orderType == "app")
                order.accountInfo = { name: "APP Account" };
              order.updatedAt = moment(order.updatedAt).format(
                "MM/DD/YYYY h:mm A"
              );
              order.showClass = order.status;
              switch (order.showClass) {
                case "dispatched":
                  order.background = "#07de8f";
                  break;
                case "onsite":
                  order.background = "#ef18ef";
                  break;
                case "enroute":
                  order.background = "#ff2626";
                  break;
                case "towdestination":
                  order.background = "#ffa217";
                  break;
                case "towing":
                  order.background = "#0454ce";
                  break;
                default:
                  order.background = "#fff";
                  break;
              }
              order.showClassBorder = order.status + "-border";
              if (order.status == "enroute") order.status = "en route";
              if (order.status == "onsite") order.status = "on site";
              if (order.status == "towdestination")
                order.status = "tow destination";

              order.pseudoStatus = order.status;
              if (order.pseudoStatus == "en route") order.pseudoStatus = "en-route";
              if (order.pseudoStatus == "on site") order.pseudoStatus = "on-site";
              order.AddtionalItemsValue = "";
              if (order.orderAdditionalItems) {
                order.orderAdditionalItems.forEach((x, i) => {
                  order.AddtionalItemsValue = order.AddtionalItemsValue + (i == 0 ? '' : ", ") + x.label;
                })
              }
            });
        });
        this.filteredData = this.data;
        this.filteredData.forEach((x) => {
          x.jobId = x.orders[0].id;
          x.orderTruckId = x.orders[0].truckInfo;
          x.classTypeInfo = x.orders[0].classTypeInfo
          // x.classTypeId = x.orders[0].classTypeID || 2;
          x.orders.forEach((z) => {
            //Dynamically add Service img's
            z.imgUrl = "assets/images/service-web.png";
            this.changeServiceImg(z, "order");
          });
        });

        // if(!this.filters.text){
        this.driverData.forEach((x) => {
          if (x.RoleId == 3 || x.RoleId == 2) {
            let nonExtingIndex = this.filteredData.findIndex(
              (driver) => driver && driver.id == x.id
            );
            if (nonExtingIndex == -1) {
              // console.log(x.isOnline)
              if (
                (this.filters.showOffDutyDriver == "offDuty" && !x.isOnline) ||
                (this.filters.showOffDutyDriver == "onDuty" && x.isOnline)
              ) {
                x.notShowOrder = true;
                if (x.orders.length) {
                  x.orders[0].showClass = "default";
                  x.orders[0].showClassBorder = "default-border";
                  x.orders[0].background = "#fff";
                } else {
                  x.orders.push({
                    showClass: "default",
                    showClassBorder: "default-border",
                    background: "#fff",
                  });
                  // x.classTypeId = null;
                }
                x.orderTruckId = { id: null, name: "" };
                x.classTypeInfo = { id: null, name: "" };
                if (x.lng) x.lng = parseFloat(x.lng);
                if (x.lat) x.lat = parseFloat(x.lat);
                this.filteredData.unshift(x);
              }
            }
          }
        });
        // }
        console.log("ggggg", this.filteredData);
        // this.searchData = JSON.parse(JSON.stringify(this.filteredData));
        let index = this.filteredData.findIndex(
          (x) => x && x.id == this.intendedIndex
        );

        this.allDrivers.forEach((x) => {
          x.remove();
        });
        this.allPointers.forEach((x) => {
          x.remove();
        });
        this.showTruck();

        if (this.selectedJobId && this.intendedIndex != -1) {
          let jobData = this.filteredData[index].orders.filter(
            (x) => x.id == this.selectedJobId
          );
          if (jobData && jobData.length)
            this.showRoutes(
              jobData[0],
              this.filteredData[index],
              jobData[0].status
            );
        }
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  getText(e) {
    // this.searchText = e;
    this.filters.text = e;
    this.filterText();
  }
  setWidth(data) {
    this.isSetWidth = data;
  }
  getVehicalTruck() {
    this.WaitService.start();
    return this.VehicalService.getVehicleTruck()
      .then((res) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        this.trucks = result.data;
      })
      .catch((err) => {
        this.WaitService.stop();
        console.log(err);
      });
  }
  getUsers() {
    this.WaitService.start();
    return this.UserService.getAllUsers()
      .then((res) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        if (result.users)
          this.drivers = result.users.filter(
            (x) => (x.roleId && x.roleId == 2) || x.roleId == 3
          );

        this.driverData = this.drivers;
        this.drivers.forEach((x) => {
          if (x.id == this.orderData.providerId)
            this.adminNow = x;
        });

        setTimeout(() => { this.getTruck(); }, 10);
        setTimeout(() => {
          let getGeoJson = this.MapBoxService.getGeoJson(
            parseFloat(this.adminNow.lat),
            parseFloat(this.adminNow.lng),
            this.orderData.pickUpLat,
            this.orderData.pickUpLon,
            this.orderData.dropOffLat,
            this.orderData.dropOffLon
          );
          getGeoJson.then((result: any) => {
            var estTime2 = "";
            let convertedTime2 = this.MapBoxService.timeConvert(
              result.data.routes[0].legs[0].duration
            ).split(" ");
            let checkMin2 = convertedTime2[0].split(":");
            if (checkMin2[1]) {
              estTime2 =
                Number(checkMin2[0]).toString() +
                "h" +
                ":" +
                Number(checkMin2[1]).toString() +
                "m";
            }
            if (!checkMin2[1]) {
              estTime2 = Number(checkMin2[0]).toString() + "m";
            }
            this.orderData.etaVal = estTime2;
          });
        }, 1000);
      })
      .catch((err) => {
        this.WaitService.stop();
        console.log(err);
      });
  }

  assigneTruck(driver, truckId) {
    driver.truckId = truckId;
    this.selectedDriver = driver.id;
    this.selectedTruck = driver.truckId;

    if (this.trucks && this.trucks.length && truckId && truckId !== "null") {
      this.trucks.forEach((x) => {
        if (truckId && x.id && x.id == truckId) {
          this.classTypes.forEach((y) => {
            if (y.name === x.truckType.name) {
              driver.classTypeId = y.id;
              driver.classTypeInfo.id = y.id;
              this.orderData.classTypeId = y.id;
            }
          })
        }
      });
    } else {
      driver.classTypeInfo.id = null;
    }
    // this.WaitService.start();
    // this.UserService.assigneTruck(userId, truckId)
    //   .then(() => {
    //     this.WaitService.stop();
    //     this.toastr.success("Changes Saved");
    //     this.getUsers();
    //   })
    //   .catch((err) => {
    //     this.WaitService.stop();
    //     this.toastr.error(err.error.error);
    //   });
  }

  filterText() {
    let text = this.filters.text;
    if (text && text.length > 0) {
      text = text.toLowerCase();
      this.filters.text = text;

      this.filteredData = JSON.parse(JSON.stringify(this.searchData));
      let conjoinList = [];
      this.filteredData = this.drivers.map((x) => {
        if (
          (x.name &&
            x.name.toLowerCase().includes(text))
        ) {
          conjoinList.push(x);
        }
      });
      console.log(">>>>>>>>", conjoinList);
      this.driverData = conjoinList;
      this.getTruck();
    } else {
      this.filters.text = null;
      this.driverData = this.drivers;
      this.getTruck();
      // this.filteredData = JSON.parse(JSON.stringify(this.searchData));
    }
  }

  changeMapFocus(data) {
    this.mapDefault = { lat: data.lat, lng: data.lng };
    this.flyTo(this.mapDefault);
    this.selectDriver(data);
    if (this.intendedIndex != this.selectedDriver) this.reloadComponent(true);
  }

  truckIndividual(driver, index) {
    let show = this;
    let ele = document.createElement("img");
    ele.src = this.getTruckImgUrl(driver.orders[index].showClass);;
    ele.style.height = "30px";
    ele.style.width = "14px";
    ele.setAttribute("id", "driver_" + driver.id);
    if (driver && driver.marker) driver.marker.remove();
    let marker = new mapboxgl.Marker({
      element: ele,
      rotation: driver.bearing,
    }).setLngLat([driver.lng, driver.lat]);

    if (driver && driver.pointer) driver.pointer.remove();
    driver.truckName =
      driver.orderTruckId && driver.orderTruckId.name ? driver.orderTruckId.name : "";
    let background = driver.orders[index].background;
    let textShowColor = background == "#fff" ? "#000" : "#fff";
    let symbol = driver.truckName == "" ? "" : " - ";
    let bubble = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      offset: { bottom: [0, -17] }
    })
      .setLngLat([driver.lng, driver.lat])
      .setHTML(
        "<div style='text-align: center;padding:2px;background:" +
        background +
        ";color:" + textShowColor + "'>" +
        driver.name +
        symbol +
        driver.truckName +
        "</div>"
      );

    marker.addTo(this.map);
    bubble.addTo(this.map);
    if (driver) driver.marker = marker;
    if (driver) driver.pointer = bubble;
    this.allDrivers.push(marker);
    this.allPointers.push(bubble);
    marker.getElement().addEventListener("click", (e) => {
      show.showRoutes(
        driver.orders[index],
        driver,
        driver.orders[index].status,
        false
      );
    });
  }

  showRoutes(data, driver, onSite, fitBounds = true, viewRoute = false) {
    // driver.jobId = data.id;
    this.showrouteOrder = data;
    driver.orderTruckId = data.truckInfo ? data.truckInfo : { id: null, name: '' };
    let emgToBe = this.getTruckImgUrl(data.showClass);

    if ((driver.id != 0) && (this.selectedJobId != data.id)) {
      let jobDataIndex = driver.orders.findIndex((x) => x.id == data.id);
      // driver.marker.remove();
      if (jobDataIndex > -1) this.truckIndividual(driver, jobDataIndex);
    }
    driver.jobId = data.id;
    if (driver.id != 0) this.selectedDriver = driver.id;
    this.intendedIndex = driver.id;
    this.pointAMarker.remove();
    this.pointBMarker.remove();
    this.companyMarker.remove();
    this.selectedRouteData = {
      data: data,
      driver: driver,
      onSite: onSite,
      fitBounds: fitBounds,
    };

    console.log("2", data);
    console.log("3", driver);
    setTimeout(() => {
      this.selectedJobId = data.id;
      driver.jobId = data.id;

      let getGeoJson = null;

      if (driver.lat && driver.lng && data.pickUpLat && data.pickUpLon) {
        if (
          ["pending", "en route", "dispatched", "on site"].includes(data.status)
        ) {
          console.log("Condition 1");
          if (onSite == "on site") {
            getGeoJson = this.MapBoxService.getGeoJson(
              fitBounds ? driver.lat : data.truck.lat,
              fitBounds ? driver.lng : data.truck.lng,
              data.dropOffLat,
              data.dropOffLon
            );
          } else {
            getGeoJson = this.MapBoxService.getGeoJson(
              fitBounds ? driver.lat : data.truck.lat,
              fitBounds ? driver.lng : data.truck.lng,
              data.pickUpLat,
              data.pickUpLon,
              data.dropOffLat,
              data.dropOffLon
            );
          }
          if (data.pickUpLat && data.pickUpLon) {
            this.pointAMarker.setLngLat([data.pickUpLon, data.pickUpLat]);
            this.pointAMarker.addTo(this.map);
          }

          if (data.dropOffLat && data.dropOffLon) {
            this.pointBMarker.setLngLat([data.dropOffLon, data.dropOffLat]);
            this.pointBMarker.addTo(this.map);
          }
        } else if (
          ["towing", "tow destination"].includes(data.status) &&
          data.dropOffLat &&
          data.dropOffLon
        ) {
          this.pointBMarker.setLngLat([data.dropOffLon, data.dropOffLat]);
          this.pointBMarker.addTo(this.map);
          this.pointAMarker.remove();

          console.log("Condition 2");

          getGeoJson = this.MapBoxService.getGeoJson(
            driver.lat,
            driver.lng,
            data.dropOffLat,
            data.dropOffLon
          );
        }
      } else if (
        data.pickUpLat &&
        data.pickUpLon &&
        data.dropOffLat &&
        data.dropOffLon
      ) {
        console.log("Condition 3", this.adminNow);

        getGeoJson = this.MapBoxService.getGeoJson(
          this.providerInfo.lat,
          this.providerInfo.lng,
          data.pickUpLat,
          data.pickUpLon,
          data.dropOffLat,
          data.dropOffLon
        );
        // else
        //   getGeoJson = this.MapBoxService.getGeoJson(
        //     data.pickUpLat,
        //     data.pickUpLon,
        //     data.dropOffLat,
        //     data.dropOffLon
        //   );

        this.pointAMarker.setLngLat([data.pickUpLon, data.pickUpLat]);
        this.pointAMarker.addTo(this.map);

        this.pointBMarker.setLngLat([data.dropOffLon, data.dropOffLat]);
        this.pointBMarker.addTo(this.map);

        if (viewRoute && driver.id == 0) {
          this.companyMarker.setLngLat([this.providerInfo.lng, this.providerInfo.lat]);
          this.companyMarker.addTo(this.map);
        }
      } else {
        if (data.pickUpLat && data.pickUpLon) {
          this.pointAMarker.setLngLat([data.pickUpLon, data.pickUpLat]);
          this.pointAMarker.addTo(this.map);
          this.pointBMarker.remove();
        }

        if (data.dropOffLat && data.dropOffLon) {
          this.pointBMarker.setLngLat([data.dropOffLon, data.dropOffLat]);
          this.pointBMarker.addTo(this.map);
          this.pointAMarker.remove();
        }

        if (data.pickUpLat && data.pickUpLon && !data.dropOffLat && !data.dropOffLon) {
          getGeoJson = this.MapBoxService.getGeoJson(
            this.providerInfo.lat,
            this.providerInfo.lng,
            data.pickUpLat,
            data.pickUpLon,
          );
          if (viewRoute && driver.id == 0) {
            this.companyMarker.setLngLat([this.providerInfo.lng, this.providerInfo.lat]);
            this.companyMarker.addTo(this.map);
          }
          // this.map.setCenter({ lng: data.pickUpLon, lat: data.pickUpLat });
        }
      }
      if (!viewRoute && this.selectedDriver && driver.id == 0) {
        let index = this.filteredData.findIndex((x) => x && x.id == this.selectedDriver);
        if (data.pickUpLat, data.pickUpLon, data.dropOffLat, data.dropOffLon) {
          getGeoJson = this.MapBoxService.getGeoJson(
            this.filteredData[index].lat,
            this.filteredData[index].lng,
            data.pickUpLat,
            data.pickUpLon,
            data.dropOffLat,
            data.dropOffLon
          );
        }
        if (data.pickUpLat, data.pickUpLon, !data.dropOffLat, !data.dropOffLon) {
          getGeoJson = this.MapBoxService.getGeoJson(
            this.filteredData[index].lat,
            this.filteredData[index].lng,
            data.pickUpLat,
            data.pickUpLon
          );
        }
      }
      // if(driver.id==0&&data.pickUpLat&&data.pickUpLon&&data.dropOffLat&&data.dropOffLon){
      //   getGeoJson = this.MapBoxService.getGeoJson(
      //     data.pickUpLat,
      //     data.pickUpLon,
      //     data.dropOffLat,
      //     data.dropOffLon
      //   );
      // }

      if (getGeoJson)
        getGeoJson
          .then((result: any) => {
            if (result.data.routes && result.data.routes.length) {
              let coords = [];
              let waypoints = result.data.routes[0].legs[0].steps;

              if (result.data.routes[0].legs.length > 1)
                waypoints = waypoints.concat(
                  result.data.routes[0].legs[1].steps
                );

              waypoints.map((x) => {
                x.intersections.map((i) => {
                  coords.push(i.location);
                });
              });

              this.addRoute(coords);

              const bounds = new mapboxgl.LngLatBounds(
                coords[0],
                coords[0]
              );

              for (const coord of coords) {
                bounds.extend(coord);
              }

              if (result.data.routes[0].duration <= 60) {
                this.map.fitBounds([coords[0], coords[coords.length - 1]], {
                  padding: { top: 70, bottom: 50, left: 170, right: 260 },
                  zoom: 18
                });
              }
              else if (result.data.routes[0].duration > 60 && result.data.routes[0].duration <= 150) {
                this.map.fitBounds([coords[0], coords[coords.length - 1]], {
                  padding: { top: 70, bottom: 50, left: 170, right: 260 },
                  zoom: 16
                });
              } else {
                this.map.fitBounds([coords[0], coords[coords.length - 1]], {
                  padding: { top: 70, bottom: 50, left: 170, right: 260 },
                });
              }

              // if (["towing", "tow destination"].includes(data.status))
              //   this.map.fitBounds([coords[0], coords[coords.length - 1]], {
              //     padding: 40,
              //   });
              // else if (
              //   data.pickUpLat &&
              //   data.pickUpLon &&
              //   data.dropOffLat &&
              //   data.dropOffLon
              // )
              //   this.map.fitBounds(
              //     [
              //       [data.pickUpLon, data.pickUpLat],
              //       [data.dropOffLon, data.dropOffLat],
              //     ],
              //     {
              //       padding: 40,
              //     }
              //   );

              var estTime = "";
              let convertedTime = this.MapBoxService.timeConvert(
                result.data.routes[0].duration
              ).split(" ");

              let checkMin = convertedTime[0].split(":");
              if (checkMin[1]) {
                estTime =
                  Number(checkMin[0]).toString() +
                  " hr" +
                  " " +
                  Number(checkMin[1]).toString() +
                  " min";
              }
              if (!checkMin[1]) {
                estTime = Number(checkMin[0]).toString() + " min";
              }

              // console.log(">>>>>>>>>>>", result.data.routes);
              if (data.dropOffAddress) {
                data.dropOffAddressShow = data.dropOffAddress.replace(
                  /USA/g,
                  ""
                );
                this.popup
                  .setLngLat(coords[coords.length - 1])
                  .setHTML(
                    `
                    <div style="font-size: 10px;text-align: left;height: 100%;font-family: system-ui;display: flex;">
                    <div style="display: flex; align-items: center; justify-content: center;width: auto;padding: 4px 5px;float: left;background: #000;color: #fff; text-align: center; line-height:15px;">
                        ` +
                    `${estTime}` +
                    `</div>
                        <div style="margin:0;margin: 0px 0px;float: right;padding: 2px 9px;">` +
                    data.dropOffAddressShow +
                    `</div></div>
                    `
                  )
                  .addTo(this.map);
              }
              if (!data.dropOffAddress && this.popup) this.popup.remove();
              // console.log(">>>>>>>>>>>", data);
              if (["en route", "dispatched"].includes(data.status) || (driver.id == 0)) {
                var estTime2 = "";
                let convertedTime2 = this.MapBoxService.timeConvert(
                  result.data.routes[0].legs[0].duration
                ).split(" ");
                let checkMin2 = convertedTime2[0].split(":");
                if (checkMin2[1]) {
                  estTime2 =
                    Number(checkMin2[0]).toString() +
                    " hr" +
                    " " +
                    Number(checkMin2[1]).toString() +
                    " min";
                }
                if (!checkMin2[1]) {
                  estTime2 = Number(checkMin2[0]).toString() + " min";
                }

                data.pickUpAddressShow = data.pickUpAddress.replace(
                  /USA/g,
                  ""
                );
                this.popupSec
                  .setLngLat([data.pickUpLon, data.pickUpLat])
                  .setHTML(
                    `
                      <div style="font-size: 10px;text-align: left;height: 100%;font-family: system-ui;display: flex;">
                      <div style="display: flex; align-items: center; justify-content: center;width: auto;padding: 4px 5px;float: left;background: #000;color: #fff; text-align: center; line-height:15px;">
                          ` +
                    `${estTime2}` +
                    `</div>
                          <div style="margin:0;margin: 0px 0px;float: right;padding: 2px 9px;">` +
                    data.pickUpAddressShow +
                    `</div></div>
                      `
                  )
                  .addTo(this.map);
              } else {
                this.popupSec.remove();
              }
            }
          })
          .catch((err) => {
            console.log("Error on GEO");
            console.log(err);
          });
    }, 0);
  }

  // Set dirver statust online/offline
  changeOnlineStatus(userId, status) {
    this.WaitService.start();
    if (userId == this.selectedDriver) this.selectedDriver = null;
    this.selectedTruck = null;
    this.UserService.changeOnlineStatus(userId, status)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Changes Saved");
        this.getTruck();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  // update driver position on the map
  updateDriverPosition(data) {
    console.log("1", data);
    let driverId = data.data.driverId;
    let index = this.filteredData.findIndex((x) => x && x.id == driverId);

    if (index == -1) return;
    this.filteredData[index].lat = parseFloat(data.data.lat);
    this.filteredData[index].lng = parseFloat(data.data.lng);
    this.filteredData[index].bearing = parseFloat(data.data.bearing);

    if (this.locationUpd == 0 || this.locationUpd == 16) {
      const marker = this.filteredData[index].marker;
      marker.setLngLat([data.data.lng, data.data.lat]);
      // marker.setRotation(data.data.bearing);
      marker.addTo(this.map);
      this.locationUpd = 0;

      if (this.selectedJobId) {
        let jobData = this.filteredData[index].orders.filter(
          (x) => x.id == this.selectedJobId
        );
        console.log(jobData);
        jobData[0].truck = { lng: data.data.lng, lat: data.data.lat };
        if (jobData && jobData.length)
          this.showRoutes(
            jobData[0],
            this.filteredData[index],
            jobData[0].status,
            false
          );
      }
    }
    this.locationUpd += 1;
  }

  openJobDetailsModal(orderId) {
    const modal = this.modalService.open(JobDetailsComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "xl",
    });
    modal.componentInstance.orderId = orderId;
    modal.componentInstance.drivers = this.drivers;
    modal.componentInstance.trucks = this.trucks;
  }

  flyTo(coords) {
    this.map.setZoom(14);
    this.map.setCenter({ lng: coords.lng, lat: coords.lat });
  }

  getTruckImgUrl(status) {
    let img;
    switch (status) {
      case "dispatched":
        img = "./assets/flat-icons/dispatched.png";
        break;
      case "onsite":
        img = "./assets/flat-icons/on-site.png";
        break;
      case "enroute":
        img = "./assets/flat-icons/en-route.png";
        break;
      case "towdestination":
        img = "./assets/flat-icons/tow-destination.png";
        break;
      case "towing":
        img = "./assets/flat-icons/towing.png";
        break;
      default:
        img = "./assets/flat-icons/truck.png";
        break;
    }
    return img;
  }

  changeStatus(order, status) {
    this.WaitService.start();

    this.OrdersService.updatedStatus(order.id, status)
      .then((result) => {
        this.WaitService.stop();
        if (order.includeStorage && status == "completed")
          this.toastr.success("Order Added To Storage");
        else this.toastr.success("Status Changed");
      })
      .catch((err) => {
        this.WaitService.stop();

        if (err.status == 500) {
          let errorMessage = JSON.parse(err.error.error);
          if (errorMessage.error) this.toastr.error(errorMessage.error);
          else this.toastr.error(errorMessage.error);
        } else this.toastr.error(err.error.error);
      });
  }

  showTruck() {
    let show = this;
    this.filteredData.map((x) => {
      if (x.lng && x.lat) {
        let ele = document.createElement("img");
        ele.src = this.getTruckImgUrl(x.orders[0].showClass);
        ele.style.height = "30px";
        ele.style.width = "14px";
        ele.setAttribute("id", "driver_" + x.id);

        let marker = new mapboxgl.Marker({
          element: ele,
          rotation: x.bearing,
        }).setLngLat([x.lng, x.lat]);

        x.truckName = x.orderTruckId && x.orderTruckId.name ? x.orderTruckId.name : "";
        let symbol = x.truckName == "" ? "" : " - ";
        let vMake = x.name + symbol + x.truckName;
        let vColor = x.orders[0].background == "#fff" ? "#000" : "#fff";
        let bubble = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          anchor: "bottom",
          offset: { bottom: [0, -17] }
        })
          .setLngLat([x.lng, x.lat])
          .setHTML(
            "<div style='text-align: center;padding:2px;color:" +
            vColor +
            ";background:" +
            x.orders[0].background +
            "'>" +
            vMake +
            "</div>"
          );


        x.marker = marker;
        x.pointer = bubble;
        marker.addTo(this.map);
        x.pointer.addTo(this.map);
        this.allDrivers.push(marker);
        this.allPointers.push(bubble);

        marker.getElement().addEventListener("click", (e) => {
          show.showRoutes(x.orders[0], x, x.orders[0].status);
        });
      }
    });
  }

  // adds the route as a layer on the map
  addRoute(coords) {
    this.removeRoute();
    this.map.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coords,
        },
      },
    });
    this.map.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#26B4FF",
        "line-width": 8,
      },
    });
  }

  // remove the layer if it exists
  removeRoute() {
    if (this.map.getSource("route")) {
      this.map.removeLayer("route");
      this.map.removeSource("route");
    } else {
      return;
    }
  }

  toggleMapView() {
    if (this.mapType != "street") {
      this.map.setStyle("mapbox://styles/mapbox/satellite-streets-v11");
      this.mapType = "street";
    } else {
      this.map.setStyle("mapbox://styles/mapbox/outdoors-v11");
      this.mapType = "outdoor";
    }
  }

  showTraffic() {
    this.map.setStyle("mapbox://styles/mapbox/traffic-day-v2");
    this.mapType = "outdoor";
  }

  close() {
    this.modal.close();
  }

  changeServiceImg(data: any, value: any) {
    if (data.orderType == "custom" && data.service) {
      if (data.service.label == "Electric Charge") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-electricCharge.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-electricCharge.png";
        }
      }
      if (data.service.label == "Fuel Delivery") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-fuelDelivery.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-fuelDelivery.png";
        }
      }
      if (data.service.label == "Jump Start") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-jumpStart.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-jumpStart.png";
        }
      }
      if (data.service.label == "Lock Out") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-Lockout.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-Lockout.png";
        }
      }
      if (data.service.label == "Tire Change") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-tireChange.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-tireChange.png";
        }
      }
      if (data.service.label == "Tow") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-towingSerive.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-towingSerive.png";
        }
      }
    }
    if (data.orderType == "app" && data.appService) {
      if (data.appService.label == "Electric Charge") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-electricCharge.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-electricCharge.png";
        }
      }
      if (data.appService.label == "Fuel Delivery") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-fuelDelivery.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-fuelDelivery.png";
        }
      }
      if (data.appService.label == "Jump Start") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-jumpStart.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-jumpStart.png";
        }
      }
      if (data.appService.label == "Lock Out") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-Lockout.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-Lockout.png";
        }
      }
      if (data.appService.label == "Tire Change") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-tireChange.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-tireChange.png";
        }
      }
      if (data.appService.label == "Tow") {
        if (value == "order") {
          data.imgUrl = "assets/images/service-towingSerive.png";
        }
        if (value == "job") {
          this.imgUrl = "assets/images/service-towingSerive.png";
        }
      }
    }
  }
}
