import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements OnInit {
  @Input() videoAlbum = [];
  @Input() videoIdx: number = null;

  videoSrc: string = "";

  constructor(private modal: NgbActiveModal) {}

  ngOnInit() {
    this.videoSrc = this.videoAlbum[this.videoIdx].url;
  }

  next(index) {
    this.videoIdx = index
    this.videoSrc = this.videoAlbum[index].url;
  }

  prev(index) {
    this.videoIdx = index
    this.videoSrc = this.videoAlbum[index].url;
  }

  close() {
    this.modal.close();
  }
}
