import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlacesService {
  constructor(private httpClient: HttpClient) {}

  getLocationTypes(id=null, isEditFromSuperAdmin=false) {
    let params = new HttpParams();

    if (isEditFromSuperAdmin) {
      params = params.set("providerId", id);
    }

    let reqURL = environment.baseURL + "/api/settings/locations/";
    if(isEditFromSuperAdmin)  reqURL = environment.baseURL + "/api/super_admin/settings/locations/";

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(reqURL,{ params })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getPlaces() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/places")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createPlace(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/places", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  editPlace(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/settings/places", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deletePlace(placeId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(environment.baseURL + "/api/settings/places/" + placeId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
