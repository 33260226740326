import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LoginService } from "../login/login.service";

@Injectable({
  providedIn: 'root'
})
export class StukLiteGuardService {

  constructor(private LoginService: LoginService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.LoginService.getCurrentUser();

    console.log(currentUser,"stukGuard");

    if (!currentUser) {
      this.router.navigate(["/login"]);
      return false;
    }

    if (currentUser.stukLite) {
      this.router.navigate(["/unauthorize"]);
      return false;
    }
    return true;
  }
}
