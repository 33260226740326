// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  squarePROD: true,
  baseURL: "https://dashboard.stuksoftware.com",
  baseFolderURL: "/home/ubuntu/stuck/Stuk-Web",
  GOOGLE_API_KEY: "AIzaSyAv4DUv_fex0q3kcL8bFou5znOOC6tTzUY",
  SPREEDLY_ENV_KEY: "PCb4RcAeWnmjBQaNhcp1gE2e1vd",
  MAPBOX_TOKEN:
    "pk.eyJ1IjoidGptYXJpY29uaSIsImEiOiJjbGx6MXl1dzIybDZzM2pwdmFpOXAwMzc0In0.zzrtYkL8A9HnIasi6Slu8Q",
  SQUARE_OAUTH_LINK_SB:'https://connect.squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb-k8LRbyhfzUeKgaYMltklAQ&scope=CUSTOMERS_WRITE+CUSTOMERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE_IN_PERSON&session=false&state=82201dd8d83d23cc8a48caf52b',
  SQUARE_ACCESS_TOKEN: 'EAAAELtSIic8kq7RymjrodNBTx4Ok--RAOddpevNSiPeCHAYuskEE5ccYjK0FRZq',
  SQUARE_SANBOX_APP_KEY:"sandbox-sq0idb-k8LRbyhfzUeKgaYMltklAQ",
  SQUARE_SANBOX_LOCATION_KEY:"LYB75NT70ENRV",
  SQUARE_OAUTH_LINK_PROD:'https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-z0l3DZmCcHAJGS27WX6-3g&scope=CUSTOMERS_WRITE+CUSTOMERS_READ+MERCHANT_PROFILE_READ+PAYMENTS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE_IN_PERSON&session=false&state=82201dd8d83d23cc8a48caf52b',
  SQUARE_SANBOX_APP_KEY_PROD:'sq0idp-z0l3DZmCcHAJGS27WX6-3g',
  SQUARE_SANBOX_LOCATION_KEY_PROD:'LTRA8WD001QJZ'
};
