import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-collect-data",
  templateUrl: "./collect-data.component.html",
  styleUrls: ["./collect-data.component.scss"],
})
export class CollectDataComponent implements OnInit {
  @Output() customerData: EventEmitter<any> = new EventEmitter();
  data: any = { country: "US" };

  constructor(private modal: NgbActiveModal) {}

  ngOnInit() {}
  close(success: boolean) {
    this.modal.close();
    this.data.success = success;
    this.customerData.emit(this.data);
  }

  save() {
    this.close(true);
  }
}
