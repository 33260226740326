import { Component, OnInit, Input } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { UserService } from "../../../services/user/user.service";
import { WaitService } from "../../../services/wait/wait.service";
import { ProvidersService } from "../../../services/providers/providers.service";

import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";
import { CropImageComponent } from "../crop-image/crop-image.component";

import * as moment from "moment";
import { ValidateService } from "src/app/services/phone-validate/validate.service";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
  @Input() public userId;
  @Input() public deleteUser;
  @Input() userType: string = null;
  userData: any = {
    roleType: [],
  };
  roleType: any[] = [
    {
      value: 2,
      name: "Admin",
      data: false,
      imgBlue: "../../../../assets/images/permission-admin-blue.png",
      imgBlack: "../../../../assets/images/permisson-admin-black.png",
    },
    {
      value: 5,
      name: "Dispatcher",
      data: false,
      imgBlue: "../../../../assets/images/permission-dispatcher-blue.png",
      imgBlack: "../../../../assets/images/permissoin-dispatcher-black.png",
    },
    {
      value: 3,
      name: "Driver",
      data: false,
      imgBlue: "../../../../assets/images/permission-driver-blue.png",
      imgBlack: "../../../../assets/images/permission-driver-black.png",
    },
    // { value: 6, name: "Answering Service", data: false },
  ];
  notification: any[] = [
    {
      value: "none",
      show: false,
      text: "New job assigned to",
      alias: "notif1",
    },
    {
      value: "none",
      show: false,
      text: "ETA submission accepted",
      alias: "notif2",
    },
    {
      value: "none",
      show: false,
      text: "Job dispatched to this user",
      alias: "notif3",
    },
  ];
  emailSettings: any[] = [
    { name: "Job Created or Scheduled", value: false, alias: "jobCreated" },
    { name: "Towing", value: false, alias: "towing" },
    { name: "ETA Provided", value: false, alias: "ETAProvided" },
    // { name: "Tow Destination", value: false, alias: "towDestination" },
    { name: "ETA Extended", value: false, alias: "ETAExtended" },
    { name: "En Route", value: false, alias: "enRouted" },
    { name: "Job Completed", value: false, alias: "jobCompleted" },
    { name: "On Site", value: false, alias: "onSite" },
    { name: "Job Canceled", value: false, alias: "jobCanceled" },
  ];
  showNotifications: boolean = false;
  mail: any = "allMail";
  passwordType: any = "password";
  user: any = {
    error: {},
  };

  submit: boolean = false;
  imageError: string = null;
  userLastLoginAt: string = null;

  constructor(
    private modalService: NgbModal,
    private modal: NgbActiveModal,
    private ToastrService: ToastrService,
    private UserService: UserService,
    private WaitService: WaitService,
    private ProvidersService: ProvidersService,
    // private el: ElementRef,
    private ValidateService: ValidateService
  ) {
  }

  close() {
    this.modal.close();
  }

  changeListener(event) {
    this.imageError = null;
    // let me = this;
    let file = event.target.files[0];
    var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

    if (parseFloat(sizeInMB) > 5) {
      this.imageError = "Image uploaded is greater than 5mb.";
      return;
    }
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function () {
    //   me.user.image = reader.result;
    // };
    // reader.onerror = function (error) {
    //   console.log("Error: ", error);
    // };

    const modal = this.modalService.open(CropImageComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.imgSrc = event;
    modal.result.then((res) => {
      if (res)
        this.user.image = res;
    });
  }

  makeRole() {
    this.notification[0].show = false;
    this.notification[1].show = false;
    this.notification[2].show = false;

    if (this.user.roleId == 2 || this.user.roleId == 5) {
      this.notification[0].show = true;
      this.notification[1].show = true;
    }

    if (this.user.roleId == 3) {
      this.notification[2].show = true;
    }

    this.showNotifications = [2, 3, 5].includes(this.user.roleId);
  }

  save() {
    this.user.watchOtherDrivers = this.user.accessTracking;
    this.user.error = {};
    this.submit = true;

    this.user.canCreateJob =
      this.user.roleId == 3 ? this.user.canCreateJob : true;

    this.user.accessTracking =
      this.user.roleId == 3 ? this.user.accessTracking : true;

    this.user.watchOtherDrivers =
      this.user.roleId == 3 ? this.user.watchOtherDrivers : true;

    this.user.canAssignDriver =
      this.user.roleId == 3 ? this.user.canAssignDriver : true;

    // if (
    //   !this.user.fName ||
    //   !this.user.lName ||
    //   !this.user.username ||
    //   (!this.user.password && !this.userId)
    // ) {
    //   return;
    // }

    if (!this.user.roleId) {
      this.user.error.roleType = true;
      // return;
    }

    if (this.user.fName) this.user.fName = this.user.fName.trim();
    if (this.user.lName) this.user.lName = this.user.lName.trim();
    if (this.user.username) this.user.username = this.user.username.trim();

    if (!/^[a-zA-Z]*$/g.test(this.user.fName)) {
      this.user.error.fName = true;
      // return;
    }

    if (!/^[a-zA-Z]*$/g.test(this.user.lName)) {
      this.user.error.lName = true;
      // return;
    }

    // if (!this.user.email) return;

    if (this.user.email) {
      let email =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email.test(this.user.email.toLowerCase())) {
        this.user.error.email = true;
        // return;
      }
    }

    if (this.user.username) {
      this.user.username = this.user.username.toLowerCase();
      const usernameReg = /^[a-zA-Z0-9\-_.]+$/;
      if (!usernameReg.test(this.user.username))
        this.user.error.username = true;
    }

    if ((this.user.password && this.user.password.length < 8) && !this.userId)
      this.user.error.password = true;

    if (this.user.phone) {
      this.user.error.phone = this.ValidateService.isValid(this.user.phone);
      if (this.user.error.phone) {
        if (this.user.secondNumber) {
          this.user.secondNumber = this.ValidateService.onEdit(
            this.user.secondNumber
          );
        }
        // return
      } else this.user.phone = this.ValidateService.format(this.user.phone);
    }

    if (this.user.secondNumber) {
      this.user.error.secondPhone = this.ValidateService.isValid(
        this.user.secondNumber
      );
      if (this.user.error.secondPhone) {
        if (this.user.phone) {
          this.user.phone = this.ValidateService.onEdit(this.user.phone);
        }
        // return
      } else this.user.secondNumber = this.ValidateService.format(
        this.user.secondNumber
      );
    }

    if (
      !this.user.fName || !this.user.lName ||
      !this.user.username || (!this.user.password && !this.userId) ||
      this.user.error.roleType ||
      this.user.error.fName || this.user.error.lName ||
      this.user.error.phone || this.user.error.secondPhone ||
      this.user.error.email ||
      this.user.error.username || this.user.error.password
    ) {
      setTimeout(() => {
        this.focusOnInvalid();
        this.user.phone = this.ValidateService.onEdit(this.user.phone);
        this.user.secondNumber = this.ValidateService.onEdit(this.user.secondNumber);
      }, 100);
      return;
    }

    delete this.user.error;

    this.WaitService.start();
    this.submit = false;
    var userToSend = this.user;
    userToSend.name = userToSend.fName + "_" + userToSend.lName;
    switch (this.mail) {
      case "noMail":
        userToSend.noMail = true;
        userToSend.userAsDispatcher = false;
        userToSend.allMail = false;
        break;
      case "userAsDispatcher":
        userToSend.noMail = false;
        userToSend.userAsDispatcher = true;
        userToSend.allMail = false;
        break;
      case "allMail":
        userToSend.noMail = false;
        userToSend.userAsDispatcher = false;
        userToSend.allMail = true;
        break;
    }

    let notif = [
      { value: this.notification[0].value, alias: "newJob" },
      { value: this.notification[1].value, alias: "Eta" },
      { value: this.notification[2].value, alias: "jobDispatched" },
    ];
    userToSend.notification = JSON.stringify(notif);
    userToSend.roleArray = JSON.stringify(this.userData.roleType);
    if (userToSend.noMail) {
      this.emailSettings.forEach((x) => {
        userToSend[x.alias] = false;
      });
    } else {
      this.emailSettings.forEach((x) => {
        userToSend[x.alias] = x.value;
      });
    }

    if (this.userId) {
      this.UserService.editUserAddition(userToSend, this.userId)
        .then((res) => {
          let data = JSON.parse(JSON.stringify(res));
          this.ToastrService.success("User Updated");
          this.WaitService.stop();
          this.modal.close();
        })
        .catch((err) => {
          if (this.user.phone)
            this.user.phone = this.ValidateService.onMobileInput(
              this.user.phone
            );
          this.WaitService.stop();
          this.ToastrService.error(err.error);
        });
    } else {
      this.UserService.newUserAddition(userToSend)
        .then((res) => {
          let data = JSON.parse(JSON.stringify(res));
          this.ToastrService.success("User Added");
          this.WaitService.stop();
          this.modal.close();
        })
        .catch((err) => {
          if (this.user.phone)
            this.user.phone = this.ValidateService.onMobileInput(
              this.user.phone
            );
          this.WaitService.stop();
          this.ToastrService.error(err.error);
        });
    }
  }

  deleteUserById() {
    this.UserService.deleteUserById(this.deleteUser)
      .then((res) => {
        // let data = JSON.parse(JSON.stringify(res));

        this.ToastrService.success("User Removed");
        this.modal.close();
      })
      .catch((err) => {
        this.ToastrService.error("User Not Found");
      });
  }

  deleteImage(type) {
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
    });
    modal.componentInstance.title = "Delete Image";
    modal.componentInstance.message = "Are you sure you want to delete image?";
    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;

      this.WaitService.start();

      if (type) {
        let img = this.user.imageUrl.split("/");
        this.UserService.deleteImage(
          { imageUrl: img[img.length - 1] },
          this.userId
        )
        .then((res) => {
          // let data = JSON.parse(JSON.stringify(res));
          this.user.imageUrl = undefined;
          this.restoreUserImg();

          this.WaitService.stop();
          this.ToastrService.success("Image Removed");
        })
        .catch((err) => {
          this.WaitService.stop();
          this.ToastrService.error(err.error);
        });
      } else {
        this.user.image = null;
        this.restoreUserImg();

        let file_input = <HTMLInputElement>document.querySelector("input[type='file']");
        file_input.value = "";

        this.WaitService.stop();
      }
    });
  }

  makeFillUpsForUser(data) {
    let self = this;
    let name;
    if (data.name) name = data.name.split("_");
    else name = ["", ""];

    this.user = {
      fName: name[0] ? name[0].trim() : null,
      lName: name[1] ? name[1].trim() : null,
      phone: data.phone,
      secondNumber: data.secondNumber,
      email: data.email,
      username: data.username,
      imageUrl: data.imageUrl,
      roleId: data.roleId,
      canCreateJob: data.canCreateJob,
      driverLicense: data.driverLicense,
      accessTracking: data.accessTracking,
      watchOtherDrivers: data.watchOtherDrivers,
      canAssignDriver: data.canAssignDriver
    };

    this.makeRole();

    if (this.user.phone) {
      this.user.phone = this.ValidateService.onEdit(this.user.phone);
    }
    if (this.user.secondNumber) {
      this.user.secondNumber = this.ValidateService.onEdit(
        this.user.secondNumber
      );
    }

    this.emailSettings.forEach((mail) => {
      mail.value = data[mail.alias];
    });

    let notifTemp = JSON.parse(data.notification);
    if (notifTemp && notifTemp.length) {
      this.notification[0].value = notifTemp[0].value;
      this.notification[1].value = notifTemp[1].value;
      this.notification[2].value = notifTemp[2].value;
    }
    if (data.allMail) self.mail = "allMail";
    else if (data.noMail) self.mail = "noMail";
  }

  ngOnInit() {
    this.WaitService.start();
    this.ProvidersService.getProviderInfo().then((response) => {
      this.WaitService.stop();
      let result = JSON.parse(JSON.stringify(response));
      if (result.data && result.data.companyName)
        this.notification[0].text = "New job assigned to " + result.data.companyName;
    });

    if (this.userId) {
      this.UserService.getUserById(this.userId)
        .then((data: any) => {
          if (data.loginAt)
            this.userLastLoginAt = moment(data.loginAt).format("ddd MMM DD YYYY hh:mm A");

          if (data.imageUrl)
            this.user.imageUrl = data.imageUrl;
          else
            this.restoreUserImg();

          this.makeFillUpsForUser(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (this.userType && this.userType == "driver") {
      this.user.roleId = 3;
      this.makeRole();
    } else
      this.restoreUserImg();
    // this.user.roleId = 3;
    setTimeout(() => document.getElementById("invisible").style.visibility = "visible", 100);

    this.makeRole();
  }

  focusOnInvalid() {
    // const invalidControl = this.el.nativeElement.querySelector(
    //   "input.ng-invalid,select.ng-invalid,.ng-select.ng-invalid,is-invalid"
    // );
    const invalidControl = document.querySelector(".modal-body .text-danger.ng-star-inserted");

    if (invalidControl)
      invalidControl.scrollIntoView({ behavior: "smooth", block: "center" });

    return true;
  }

  onPhoneInput(value?) {
    if (value == 1) {
      this.user.phone = this.ValidateService.onMobileInput(this.user.phone);
    } else {
      this.user.secondNumber = this.ValidateService.onMobileInput(
        this.user.secondNumber
      );
    }
  }

  restoreUserImg() {
    setTimeout(() => {
      let file_input_img = <HTMLImageElement>document.querySelector(".profile-image img");
      file_input_img.src = "https://stuklive.s3.us-east-2.amazonaws.com/f_1602852394231.png";
    }, 100);
  }
}
