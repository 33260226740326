import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Subject } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class InvoicesService {
  invoiceUpdated: any = new Subject();

  constructor(private httpClient: HttpClient) {}

  getInvoices(filters) {
    let params = new HttpParams();
    if (filters) {
      if (filters.status) params = params.set("status", filters.status);
      if (filters.account) params = params.set("account", filters.account);
      if (filters.to) params = params.set("to", filters.to);
      if (filters.from) params = params.set("from", filters.from);
      if (filters.text && filters.text.trim().length)
        params = params.set("text", filters.text.trim());
      if (filters.offset) params = params.set("offset", filters.offset);
      if (filters.limit) params = params.set("limit", filters.limit);
      if (filters.id) params = params.set("id", filters.id);
    }
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/invoices", { params })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getInvoiceByUid(uid: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/invoices/" + uid)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getInvoiceById(id: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/invoices/ab/" + id)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getInvoicePayments(id: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/invoices/payments/" + id)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  approveInvoice(id: number, approve: boolean) {
    let data = {
      id: id,
      approve: approve,
    };
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/invoices/approve/" + id, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  updatePaidTime(id: number) {
    let data = {
      id: id,
      date: new Date(),
    };
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/invoices/paidTime/" + id, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  updateSentTime(id: number) {
    let data = {
      id: id,
      date: new Date(),
    };
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/invoices/sentTime/" + id, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  markAsPaid(id: number, paid: boolean) {
    let data = {
      id: id,
      paid: paid,
    };
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/invoices/paid/" + id, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  markAsSent(id: number, isSent: boolean) {
    let data = {
      id: id,
      isSent: isSent,
    };
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/invoices/sent/" + id, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  moveToNew(id: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/invoices/move_back/", { id: id })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  save(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/invoices/", data)
        .subscribe(
          (response) => {
            this.invoiceUpdated.next();
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  emailInvoice(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/invoices/email_invoice", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  convertImage(url) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/convert-image/" + url, null)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
