import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DecimalNumberPipe } from "./decimal-number.pipe";

@NgModule({
  declarations: [DecimalNumberPipe],
  imports: [CommonModule],
  exports: [DecimalNumberPipe],
})
export class DecimalNumberModule {}
