import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrdersService } from "../../../services/orders/orders.service";
import { WaitService } from "../../../services/wait/wait.service";
import { GoogleApiService } from "../../../services/googleApi/google-api.service";

import * as moment from "moment";
import { ProvidersService } from "src/app/services/providers/providers.service";
import { CreateJobComponent } from "../create-job/create-job.component";
import { ValidateService } from "src/app/services/phone-validate/validate.service";

@Component({
  selector: "app-release-vehicle",
  templateUrl: "./release-vehicle.component.html",
  styleUrls: ["./release-vehicle.component.scss"],
})
export class ReleaseVehicleComponent implements OnInit {
  @Input() orderData: any;
  suggesstionTimeOut: any = null;
  addressSuggestions: any = [];
  showSuggestion: boolean = false;
  providerInfo: any = null;
  validate: any = {
    error: {},
  };
  lat = 0;
  lng = 0;
  showMap: boolean = false;
  invalidControl: any = null;

  constructor(
    private modal: NgbActiveModal,
    private OrdersService: OrdersService,
    private WaitService: WaitService,
    private toastr: ToastrService,
    private GoogleApiService: GoogleApiService,
    private ProvidersService: ProvidersService,
    private modalService: NgbModal,
    private ValidateService: ValidateService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.getProviderData();
    if (this.orderData.history && this.orderData.history.length) {
      this.orderData.history.forEach((x) => {
        x.formattedDate = x.date;
        let time = moment(x.date, "MM/DD/YYYY hh:mm A").format("hh:mm");
        x.time = time;
      });
    }
    if (this.orderData.releaseToPhone) {
      this.orderData.releaseToPhone = this.ValidateService.onEdit(
        this.orderData.releaseToPhone
      );
    }
  }

  close() {
    this.modal.close();
  }

  onDateChange(e, index) {
    this.orderData.history[index].date = e;
    this.orderData.history[index].formattedDate = e;
  }

  saveJob() {
    // if (!this.orderData.releaseToEmail) return;

    // if (this.orderData.releaseToEmail) {
    //   let email =
    //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   if (!email.test(this.orderData.releaseToEmail.toLowerCase())) {
    //     this.validate.error.email = true;
    //     let customerphone = document.getElementById("customvalidation");
    //     if (customerphone)
    //       customerphone.scrollIntoView({ behavior: "smooth", block: "center" });

    //     return;
    //   }
    // }

    if (this.orderData.releaseToPhone) {
      this.validate.error.phone = this.ValidateService.isValid(
        this.orderData.releaseToPhone
      );
      if (this.validate.error.phone) {
        let customValidate = document.getElementById("customPhone");
        if (customValidate)
          customValidate.scrollIntoView({ behavior: "smooth", block: "center" });

        // return;
      }
      this.orderData.releaseToPhone = this.ValidateService.format(
        this.orderData.releaseToPhone
      );
    }

    if (this.orderData.releaseToAddressText && (!this.orderData.releaseToLat || !this.orderData.releaseToLon)) this.validate.error.address = true;

    if (this.validate.error.address || this.validate.error.phone) {
      this.orderData.releaseToPhone = this.ValidateService.onEdit(
        this.orderData.releaseToPhone
      );
      return;
    }

    const modal = this.modalService.open(CreateJobComponent, {
      backdropClass: "light-blue-backdrop",
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.type = "impound";
    modal.componentInstance.orderReleasedData = this.orderData;
    modal.componentInstance.orderId = this.orderData.id;
    modal.componentInstance.orderReleased = true;
    modal.result.then((data) => {
      this.close();
      // this.orderData.status = "released";
      // this.WaitService.start();
      // this.OrdersService.editOrder(this.orderData)
      //   .then(() => {
      //     this.WaitService.stop();
      //     this.toastr.success("Vehicle Released");
      //     this.close();
      //   })
      //   .catch((err) => {
      //     this.WaitService.stop();
      //     this.toastr.error(err.error.error);
      //   });
    });
  }

  searchAddress(text) {
    clearTimeout(this.suggesstionTimeOut);

    this.orderData.releaseToLat = null;
    this.orderData.releaseToLon = null;

    this.suggesstionTimeOut = setTimeout(() => {
      let lat = null,
        lng = null;
      if (this.providerInfo && this.providerInfo.lat && this.providerInfo.lng) {
        lat = parseFloat(this.providerInfo.lat);
        lng = parseFloat(this.providerInfo.lng);
      }
      this.GoogleApiService.autoCompleteAddress(text, lat, lng, true)
        .then((res) => {
          let result = JSON.parse(JSON.stringify(res));
          this.addressSuggestions = result.data.predictions;
        })
        .catch((err) => {
          console.log(err);
        });
    }, 300);
  }

  hideSuggesstion() {
    setTimeout(() => {
      if (this.addressSuggestions && this.addressSuggestions.length) {
        this.setReleaseToAddress(this.addressSuggestions[0]);
      } else if (!this.orderData.releaseToAddressText) {
        this.orderData.releaseToLat = null;
        this.orderData.releaseToLon = null;
        this.lat = parseFloat(this.providerInfo.lat);
        this.lng = parseFloat(this.providerInfo.lng);
      }

      this.showSuggestion = false;
    }, 300);
  }

  setReleaseToAddress(address) {
    this.orderData.releaseToAddressText = address.description;
    this.addressSuggestions = [];
    this.WaitService.start();
    this.GoogleApiService.getPlceDetailById(address.place_id)
      .then((result: any) => {
        this.WaitService.stop();
        // let result = JSON.parse(JSON.stringify(response));
        // result = result.data.result;

        let bounds = result.data.result.geometry.location;
        this.orderData.releaseToLat = bounds.lat;
        this.orderData.releaseToLon = bounds.lng;
        this.lat = bounds.lat;
        this.lng = bounds.lng;
        this.validate.error.address = false;
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  getProviderData() {
    this.WaitService.start();
    this.ProvidersService.getProviderInfo()
      .then((res: any) => {
        this.WaitService.stop();
        this.providerInfo = res.data;
        // console.log(">>>>",this.providerInfo);
        this.lat = parseFloat(this.providerInfo.lat);
        this.lng = parseFloat(this.providerInfo.lng);
        // console.log(">>>>>>>>>>>>>",this.orderData.releaseToLat);
        // console.log(">>>>>>>>>>>>>",this.orderData.releaseToLon);
        if(this.orderData.releaseToLon && this.orderData.releaseToLat) {
          this.lat = parseFloat(this.orderData.releaseToLat);
          this.lng = parseFloat(this.orderData.releaseToLon);
        }
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }
  onPhoneInput() {
    this.validate.error.phone = false;
    this.orderData.releaseToPhone = this.ValidateService.onMobileInput(
      this.orderData.releaseToPhone
    );
  }

  convertToAddress(e) {
    this.lat = e.coords.lat;
    this.lng = e.coords.lng;
    this.orderData.lat = this.lat;
    this.orderData.lng = this.lng;
    this.GoogleApiService.getAddress(e.coords.lat, e.coords.lng)
      .then((response) => {
        const result = JSON.parse(JSON.stringify(response));
        let places = result.data.results;
        if (places && places.length) {
          let bounds = places[0].geometry.location;
          this.orderData.releaseToAddressText = places[0].formatted_address;
          this.orderData.releaseToAddressText =
            this.orderData.releaseToAddressText.replace(", USA", "");
          this.orderData.releaseToLat = bounds.lat;
          this.orderData.releaseToLon = bounds.lng;
        }
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }

  focusOnInvalid() {
    this.invalidControl = this.el.nativeElement.querySelector(
      "input.ng-invalid,select.ng-invalid,.ng-select.ng-invalid"
    );

    if (this.invalidControl) {
      this.invalidControl.scrollIntoView({ behavior: "smooth", block: "center", });
      setTimeout(() => this.invalidControl.focus(), 500);
      return false;
    }
    if (this.orderData.pickUpAddress && !this.orderData.pickUpLat && !this.orderData.pickUpLon) {
      var elem = document.getElementById("pickupId");
      elem.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    return true;
  }
}
