import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MapBoxService {
  constructor(private httpClient: HttpClient) {}

  getGeoJson(lat1, lng1, lat2, lng2, lat3 = null, lng3 = null) {
    return new Promise((resolve, reject) => {
      let url =
        environment.baseURL +
        "/map-box/directions/v5/mapbox/driving/" +
        lng1 +
        "," +
        lat1 +
        ";" +
        lng2 +
        "," +
        lat2;

      if (lat3 && lng3) url = url + ";" + lng3 + "," + lat3;

      this.httpClient
        .get(url, {
          params: {
            geometries: "polyline6",
            steps: "true",
            overview: "full",
            access_token: environment.MAPBOX_TOKEN,
          },
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  timeConvert(n) {
    var num = n / 60;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.ceil(minutes);
    // var minutes = (hours - rhours) * 60;
    // var rminutes = Math.round(minutes);

    const minutesText = rminutes < 10 ? "0" + rminutes : rminutes;
    const hoursText = rhours < 10 ? "0" + rhours : rhours;

    if (hoursText <= 0) return minutesText + " mins";

    return hoursText + ":" + minutesText + " hrs";
  }

  fullTime(t1, t2){
    // var num1 = n / 60;
    var hours1 = t1 / 3600;
    var rhours1 = Math.floor(hours1);
    var minutes1 = (hours1 - rhours1) * 60;
    var rminutes1 = Math.ceil(minutes1);

    var hours2 = t2 / 3600;
    var rhours2 = Math.floor(hours2);
    var minutes2 = (hours2 - rhours2) * 60;
    var rminutes2 = Math.ceil(minutes2);

    var fullHour = rhours1+rhours2;
    var fullMin = rminutes1+rminutes2;
    if(fullMin >60){
      var hourpsudo =  Math.floor(fullMin/60);
      var fullHour = fullHour + Math.floor(fullMin/60);
      var minpsudo = ((fullMin/60)-hourpsudo)*60;
      var fullMin = Math.ceil(minpsudo);
    }

    const minutesText = fullMin < 10 ? "0" + fullMin : fullMin;
    const hoursText = fullHour < 10 ? "0" + fullHour : fullHour;

    if (hoursText <= 0) return minutesText + " mins";

    return hoursText + ":" + minutesText + " hrs";

  }
}
