import { Injectable } from "@angular/core";
import { HttpClient,HttpParams } from "@angular/common/http";
import { LoginService } from "../login/login.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class VehicalService {
  constructor(
    private loginservice: LoginService,
    private httpClient: HttpClient
  ) {}

  addTruck(data: object) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.baseURL + "/api/trucks", data).subscribe(
        (result) => {
          resolve(result);
        },
        (err) => {
          reject(err.error);
        }
      );
    });
  }

  editTruck(data: object) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/trucks/editUserViaPan", data)
        .subscribe(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err.error);
          }
        );
    });
  }

  getTrucksTypes() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/trucks/trucksTypes")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getVehicleTruck(filters?:any, id=null, isEditFromSuperAdmin=false) {
    let params = new HttpParams();
    if (filters) {
      if (filters.offset) params = params.set("offset", filters.offset);
      if (filters.limit) params = params.set("limit", filters.limit);
      if (filters.text) params =params.set("text",filters.text)
    }

    if (isEditFromSuperAdmin) {
      params = params.set("providerId", id);
    }

    let reqURL = environment.baseURL + "/api/trucks";
    if(isEditFromSuperAdmin)  reqURL = environment.baseURL + "/api/super_admin/trucks";

    return new Promise((resolve, reject) => {
      this.httpClient.get(reqURL,{params}).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteById(id: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(environment.baseURL + "/api/trucks/deleteById/" + id)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getVehicleMake(year, isEditFromSuperAdmin=false) {
    let reqURL = environment.baseURL + "/api/cars/dt/" + year + "/make";
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/cars/dt/" + year + "/make";
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(reqURL)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getVehicleModels(year, make, isEditFromSuperAdmin=false) {
    let reqURL = environment.baseURL + "/api/cars/dt/";
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/cars/dt/";
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          reqURL +
            year +
            " /make/" +
            make +
            "/model"
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getVehicleYear(isEditFromSuperAdmin=false) {
    let reqURL = environment.baseURL + "/api/cars/dt/years";
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/cars/dt/years";
    return new Promise((resolve, reject) => {
      this.httpClient.get(reqURL).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getVehicleColor(make, model, year, isEditFromSuperAdmin=false) {
    let reqURL = environment.baseURL + "/api/cars/make/";
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/cars/make/"
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          reqURL +
            make +
            "/model/" +
            model +
            "/year/" +
            year
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  decodeVin(vin: string, isEditFromSuperAdmin=false) {
    let reqURL = environment.baseURL + "/api/cars/decode_vin/";
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/cars/decode_vin/";
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(reqURL + vin)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  trackTruck(filters = {}) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/trackDriver", filters)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getTruckDriverId(truckId: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/trucks/check/" + truckId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
