import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent } from "./not-found/not-found.component";
import { NotAuthorizedComponent } from "./not-authorized/not-authorized.component";

// *******************************************************************************
// Routes

const routes: Routes = [
  {
    path: "admin",
    loadChildren: () =>
      import("./super-admin/super-admin.module").then(
        (m) => m.SuperAdminModule
      ),
  },
  {
    path: "unauthorize",
    component: NotAuthorizedComponent,
  },
  // 404 Not Found page
  { path: "**", component: NotFoundComponent },
];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
