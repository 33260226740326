import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "app-stuk-date-picker",
  templateUrl: "./stuk-date-picker.component.html",
  styleUrls: ["./stuk-date-picker.component.scss"],
})
export class StukDatePickerComponent implements OnInit {
  @Input() date;
  @Input() disablePastDate: boolean;
  @Input() placeHolder: string = "mm/dd/yyyy";
  @Output() onDateChange = new EventEmitter();
  model: NgbDateStruct;
  newDate = null;
  minDate = null;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.date.currentValue) {
      this.clearDate()
    }
  }

  ngOnInit() {
    // Disable previous dates
    if (this.disablePastDate) {
      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate(),
      };
    }

    if (!this.date) return;

    // Select date in the date picker
    const date = moment(this.date);
    this.newDate = date.format("MM/DD/YYYY");
    this.model = {
      month: date.month() + 1,
      day: date.date(),
      year: date.year(),
    };
  }

  clearDate() {
    const date = moment(new Date());
    this.model = {
      month: date.month() + 1,
      day: date.date(),
      year: date.year(),
    };
    this.newDate = null;
    this.onDateChange.emit(null);
  }

  onDateSelection(e) {
    let newDate = moment(e.month + "/" + e.day + "/" + e.year);
    this.newDate = newDate.format("MM/DD/YYYY");
    this.onDateChange.emit(newDate.format("YYYY-MM-DD"));
  }

  setToday() {
    const date = moment(new Date());

    // const year = date.year(),
    //   month = date.month() + 1,
    //   day = date.date();

    this.newDate = date.format("MM/DD/YYYY");

    this.onDateChange.emit(date.format("YYYY-MM-DD"));
  }
}
