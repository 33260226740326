import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { StukDatePickerModule } from "../stuk-date-picker/stuk-date-picker.module";
import { RefundModule } from "../../views/modals/refund/refund.module";
import { DecimalNumberModule } from "../../pipes/decimalNumber/decimal-number.module";

import { OrderPaymentsComponent } from "./order-payments.component";

@NgModule({
  declarations: [OrderPaymentsComponent],
  imports: [
    CommonModule,
    FormsModule,
    StukDatePickerModule,
    RefundModule,
    DecimalNumberModule,
  ],
  exports: [OrderPaymentsComponent],
})
export class OrderPaymentsModule {}
