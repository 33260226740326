import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { PaymentService } from "../../../services/payment/payment.service";

@Component({
  selector: "app-payment-setup",
  templateUrl: "./payment-setup.component.html",
  styleUrls: ["./payment-setup.component.scss"],
})
export class PaymentSetupComponent implements OnInit {
  @Input() gateWayData: any = null;
  allGatewaysArr:any =[];
  paymentGateWays: any = [];
  gatewayError: string = null;
  selectedPaymentGateWay: any = null;
  gateWayAuthMode: any = null;
  alreadyAdded:boolean = false;
  constructor(
    private modal: NgbActiveModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private PaymentService: PaymentService
  ) {}

  ngOnInit() {
    this.gateWayData.forEach((x) => {
      this.allGatewaysArr.push(x.gateWayName)
    });
    this.getAllPaymentGateWays();
  }

  close() {
    this.modal.close();
  }

  getAllPaymentGateWays() {
    this.WaitService.start();
    this.PaymentService.getAllPaymentGateWays()
      .then((response) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));
        if (result.data && result.data.gateways)
        console.log(result.data.gateways)
        let tempArr = [];
        result.data.gateways.forEach((x)=>{
          if(this.allGatewaysArr.indexOf(x.name)==-1){
            tempArr.push(x)
          }
        })
          this.paymentGateWays = tempArr.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });

        if (this.gateWayData && this.gateWayData[0]) {
          this.selectedPaymentGateWay = this.paymentGateWays.filter(
            (x) => x.gateway_type == this.gateWayData[0].gateWayType
          )[0];
          this.onPaymentGateWayChange();
        }
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  onPaymentGateWayChange() {
    if (!this.selectedPaymentGateWay) this.gateWayAuthMode = null;
    if (this.selectedPaymentGateWay && this.selectedPaymentGateWay.auth_modes) {
      let temp = this.selectedPaymentGateWay.auth_modes.filter(
        (x) => x.auth_mode_type == "default"
      );
      if (temp && temp.length) this.gateWayAuthMode = temp[0].credentials;
    }
  }

  save() {
    let data = {};
    let hasError = false;
    if (!this.selectedPaymentGateWay) {
      this.gatewayError = "Required";
      hasError = true;
    }

    this.gateWayData.forEach((x) => {
      if(x.gateWayType==this.selectedPaymentGateWay.gateway_type){
        this.alreadyAdded = true;
        hasError = true;
        this.toastr.error("Gateway Already Added");
      }
    });

    if (this.gateWayAuthMode) {
      data["gateway_type"] = this.selectedPaymentGateWay.gateway_type;
      this.gateWayAuthMode.forEach((x) => {
        x.error = !x.value || !x.value.length;
        if (x.error) hasError = true;
        data[x.name] = x.value;
      });
    }

    if (hasError) return;

    this.WaitService.start();
    let saveData = null;
    if (this.gateWayData && this.gateWayData.id)
      saveData = this.PaymentService.updatePaymentGateWay(
        this.gateWayData.id,
        data
      );
    else saveData = this.PaymentService.addPaymentGateWay(data);

    saveData
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Payment Has Been Setup Successfully");
        this.close();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error("Unable To Setup Payments");
      });
  }
}
