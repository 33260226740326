import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CommissionService {
  constructor(private httpClient: HttpClient) {}

  checkCommissionInput(e) {
    const key = e.which;
    const keychar = String.fromCharCode(key);
    const allowExtra = "$%";
    let isValid = false;

    if (
      key == null ||
      key == 0 ||
      key == 8 ||
      key == 9 ||
      key == 13 ||
      key == 27 ||
      key == 46 ||
      key == 190 ||
      key == 110 ||
      (key >= 96 && key <= 105)
    )
      isValid = true;
    else if ("0123456789".includes(keychar)) isValid = true;
    else if (allowExtra != null && allowExtra.indexOf(keychar) > -1)
      isValid = true;
    else isValid = false;

    return isValid;
  }

  parseCommissionValue(commsion) {
    const value = commsion || "0";
    let commissionType = null,
      commissionValue = null,
      temp = null;

    if (value && value.length && value.startsWith("$")) {
      temp = value.replaceAll("$", "").replaceAll("%", "");
      commissionType = "flat";
      commissionValue = parseFloat(temp || 0).toFixed(2);
      temp = "$" + commissionValue;
    } else {
      temp = value.replaceAll("$", "").replaceAll("%", "");
      commissionType = "percentage";
      commissionValue = parseFloat(temp || 0).toFixed(2);
      temp = commissionValue + "%";
    }

    console.log("commissionValue : ", commissionValue);

    return {
      commissionType: commissionType,
      commissionValue: commissionValue,
      parsedValue: temp,
    };
  }

  getDefaultCommission(userId, serviceId, serviceType) {
    let params = new HttpParams()
      .set("userId", userId)
      .set("serviceId", serviceId)
      .set("serviceType", serviceType);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/commissions/default", {
          params: params,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  setDefaultCommission(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/commissions/default", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getServices(userId, serviceType) {
    let params = new HttpParams()
      .set("userId", userId)
      .set("serviceType", serviceType);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/commissions/services", {
          params: params,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  setServices(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/commissions/services", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // get commission for Individual service user and rate type class type combination
  getCommission(userId, serviceId, serviceType, rateTypeId, classTypeId) {
    let params = new HttpParams()
      .set("userId", userId)
      .set("serviceId", serviceId)
      .set("serviceType", serviceType)
      .set("rateTypeId", rateTypeId)
      .set("classTypeId", classTypeId);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/commissions", {
          params: params,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // get commission for Individual service user and rate type class type combination
  setCommission(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/commissions", {
          data,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  setAllCommission(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/commissions/setAllToDefault", {
          data,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  calculateCommission(data: any, isEditFromSuperAdmin = false) {
    let reqURL = environment.baseURL + "/api/settings/commissions/calculate";
    if(isEditFromSuperAdmin) reqURL = environment.baseURL + "/api/super_admin/settings/commissions/calculate";
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(reqURL, data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
