import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Subject } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SettingService {
  onSoundSettingChange = new Subject();

  constructor(private httpClient: HttpClient) {}

  getSoundNotificationSetting() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/settings/notification_sound")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  editSoundNotificationSetting(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/notification_sound", data)
        .subscribe(
          (response) => {
            this.onSoundSettingChange.next();
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
