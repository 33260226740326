import { Component, OnInit } from "@angular/core";

import { fadeInAnimation } from "../../animations";

@Component({
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" },
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
