import { Injectable } from "@angular/core";
import { HttpClient,HttpParams  } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SitesService {
  constructor(private httpClient: HttpClient) {}

  loadSites(filters?:any, id=null, isEditFromSuperAdmin=false) {

    let params = new HttpParams();
    if (filters) {
      if (filters.offset) params = params.set("offset", filters.offset);
      if (filters.limit) params = params.set("limit", filters.limit);
      if (filters.text) params =params.set("text",filters.text)
    }

    if (isEditFromSuperAdmin) {
      params = params.set("providerId", id);
    }

    let reqURL = environment.baseURL + "/api/settings/sites";
    if(isEditFromSuperAdmin)  reqURL = environment.baseURL + "/api/super_admin/settings/sites";
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(reqURL,{ params })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  editSite(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/settings/sites", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createSite(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/sites", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteSite(id) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(environment.baseURL + "/api/settings/sites/" + id)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
