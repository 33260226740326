import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { WaitService } from "../../services/wait/wait.service";
import { LoginService } from "../../services/login/login.service";
import { SocketService } from "../../services/socket/socket.service";

@Component({
  selector: "app-web-view",
  template: "<p><p>",
})
export class WebViewComponent implements OnInit {
  orderId: string = null;
  invoiceId: string = null;
  socket: any = null;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private WaitService: WaitService,
    private LoginService: LoginService,
    private SocketService: SocketService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (
        !params.authToken ||
        !params.view ||
        ![
          "accounts",
          "impounds",
          "trucks",
          "users",
          "order",
          "createOrder",
          "editOrder",
          "editInvoice",
          "drivers",
          "tracking",
          "invoices",
          "terms"
        ].includes(params.view)
      )
        this.router.navigateByUrl("/404");

      let token = params.authToken;
      this.route.queryParams.subscribe((params) => {
        this.orderId = params["orderId"];
        this.invoiceId = params["invoiceId"];
      });

      this.WaitService.start();
      this.LoginService.loginWithToken(token)
        .then((response) => {
          let data = JSON.parse(JSON.stringify(response));
          this.LoginService.setUser(data.data);
          setTimeout(() => {
            this.socket = this.SocketService.getSocket();
            this.SocketService.login(data.data.userId);

            this.socket.on("login", (data) => {
              console.log("socket login");
            });
            this.WaitService.stop();
            this.redirectUser(params.view);
          }, 300);
        })
        .catch((err) => {
          this.WaitService.stop();
        });
    });
  }

  redirectUser(view) {
    let url = null;
    switch (view) {
      case "accounts":
        url = "/dashboard/accounts";
        break;
      case "impounds":
        url = "/dashboard/impound";
        break;
      case "trucks":
        url = "/dashboard/settings/trucks";
        break;
      case "tracking":
        url = "/dashboard/tracking";
        break;
      case "users":
        url = "/dashboard/settings/user";
        break;
      case "drivers":
        url = "/dashboard/settings/drivers";
        break;
      case "order":
        url = "/dashboard/order/" + this.orderId;
        break;
      case "createOrder":
        url = "/dashboard/order-ac";
        break;
      case "editOrder":
        url = "/dashboard/order-ac/" + this.orderId;
        break;
      case "editInvoice":
        url = "/dashboard/editInvoice/" + this.invoiceId;
        break;
      case "invoices":
        url = "/dashboard/invoices";
        break;
      case "terms":
        url = "/pages/terms";
        break;
    }
    url = url + "?view=app";
    this.router.navigateByUrl(url);
  }
}
