import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { DetailViewModule } from "../components/detail-view/detail-view.module";

// *******************************************************************************
// Layouts

import { Layout2Component } from "./layout-2/layout-2.component";

// *******************************************************************************
// Layouts Components

import { LayoutNavbarComponent } from "./layout-navbar/layout-navbar.component";
import { LayoutSidenavComponent } from "./layout-sidenav/layout-sidenav.component";
import { LayoutFooterComponent } from "./layout-footer/layout-footer.component";

// *******************************************************************************
// Libs

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SidenavModule } from "../../vendor/libs/sidenav/sidenav.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { AgmSnazzyInfoWindowModule } from "@agm/snazzy-info-window";
import { AgmDirectionModule } from "agm-direction";
import { NgxPaginationModule } from "ngx-pagination";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { CountdownModule, CountdownGlobalConfig } from "ngx-countdown";

import { StukSearchBarModule } from "../components/stuk-search-bar/stuk-search-bar.module";
import { StukDatePickerModule } from "../components/stuk-date-picker/stuk-date-picker.module";
import { StukTimePickerModule } from "../components/stuk-time-picker/stuk-time-picker.module";
import { OrderPaymentsModule } from "../components/order-payments/order-payments.module";
// *******************************************************************************
// Services

import { LayoutService } from "./layout.service";
import { LayoutHeaderComponent } from "./layout-header/layout-header.component";

//Components
import { JobsComponent } from "../components/jobs/jobs.component";
import { OrdersListComponent } from "../components/orders-list/orders-list.component";
import { ImpoundsComponent } from "../components/impounds/impounds.component";

//MODALS
import { ProviderLocationComponent } from "../views/modals/provider-location/provider-location.component";
import { EditRatesComponent } from "../views/modals/edit-rates/edit-rates.component";

import { ConfirmModalComponent } from "../views/modals/confirm-modal/confirm-modal.component";
import { CreateSiteComponent } from "../views/modals/create-site/create-site.component";
import { CreatePlaceComponent } from "../views/modals/create-place/create-place.component";
import { CreateJobComponent } from "../views/modals/create-job/create-job.component";
import { AssignDriverComponent } from "../views/modals/assign-driver/assign-driver.component";
import { JobDetailsComponent } from "../views/modals/job-details/job-details.component";
import { EmailJobComponent } from "../views/modals/email-job/email-job.component";
import { ConfirmTowComponent } from "../views/modals/confirm-tow/confirm-tow.component";
import { ReleaseVehicleComponent } from "../views/modals/release-vehicle/release-vehicle.component";
import { EmailInvoiceComponent } from "../views/modals/email-invoice/email-invoice.component";
import { ChangePasswordComponent } from "../views/modals/change-password/change-password.component";
import { SqFormComponent } from "../views/modals/sq-form/sq-form.component";
import { CloverFormComponent } from "../views/modals/clover-form/clover-form.component";

//customer pipes
import { TimeDiffPipe } from "../pipes/timeDiff/time-diff.pipe";
import { DecimalNumberModule } from "../pipes/decimalNumber/decimal-number.module";

import { environment } from "../../environments/environment";
import { ScheduledEstTimeModule } from "../components/scheduled-est-time/scheduled-est-time.module";
import { LayoutTrackComponent } from './layout-track/layout-track.component';
import { BsDatepickerConfig, BsDatepickerModule } from "ngx-bootstrap/datepicker";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    SidenavModule,
    DetailViewModule,
    BsDatepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
    }),
    AgmSnazzyInfoWindowModule,
    AgmDirectionModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-center",
      preventDuplicates: true,
      countDuplicates: true,
      easeTime: 800,
      progressBar: true,
      progressAnimation: "decreasing",
    }),
    NgxPaginationModule,
    NgSelectModule,
    UiSwitchModule,
    AngularMultiSelectModule,
    StukDatePickerModule,
    StukTimePickerModule,
    StukSearchBarModule,
    CountdownModule,
    OrderPaymentsModule,
    ScheduledEstTimeModule,
    DecimalNumberModule,
  ],

  exports: [
    LayoutFooterComponent,
    LayoutHeaderComponent,
    ProviderLocationComponent,
    EditRatesComponent,
    ConfirmModalComponent,
    CreateSiteComponent,
    CreatePlaceComponent,
    CreateJobComponent,
    AssignDriverComponent,
    JobDetailsComponent,
    EmailJobComponent,
    ConfirmTowComponent,
    ReleaseVehicleComponent,
    EmailInvoiceComponent,
    ChangePasswordComponent,
    SqFormComponent,
    CloverFormComponent
  ],
  declarations: [
    Layout2Component,
    LayoutNavbarComponent,
    LayoutSidenavComponent,
    LayoutFooterComponent,
    LayoutHeaderComponent,
    ProviderLocationComponent,
    EditRatesComponent,
    ConfirmModalComponent,
    JobsComponent,
    OrdersListComponent,
    ImpoundsComponent,
    CreateSiteComponent,
    CreatePlaceComponent,
    CreateJobComponent,
    AssignDriverComponent,
    JobDetailsComponent,
    EmailJobComponent,
    ConfirmTowComponent,
    ReleaseVehicleComponent,
    EmailInvoiceComponent,
    ChangePasswordComponent,
    SqFormComponent,
    CloverFormComponent,
    //pipe
    TimeDiffPipe,
    LayoutTrackComponent,
  ],
  providers: [LayoutService, { provide: CountdownGlobalConfig }, BsDatepickerConfig],
  entryComponents: [
    ProviderLocationComponent,
    EditRatesComponent,
    ConfirmModalComponent,
    CreateSiteComponent,
    CreatePlaceComponent,
    CreateJobComponent,
    AssignDriverComponent,
    JobDetailsComponent,
    EmailJobComponent,
    ConfirmTowComponent,
    ReleaseVehicleComponent,
    EmailInvoiceComponent,
    ChangePasswordComponent,
    SqFormComponent,
    CloverFormComponent
  ],
})
export class LayoutModule {}
