import { Component, Input, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../app.service";
import { LayoutService } from "../../layout/layout.service";
import { LoginService } from "../../services/login/login.service";
import { OrdersService } from "../../services/orders/orders.service";

@Component({
  selector: "app-layout-navbar",
  templateUrl: "./layout-navbar.component.html",
  styleUrls: ["./layout-navbar.component.scss"],
})
export class LayoutNavbarComponent {
  isExpanded = false;
  isRTL: boolean;
  currentUser: any;
  lastLink;
  lastLinkValues: any;
  userRoleName: string = "";
  blinkPending: boolean = false;

  @Input() sidenavToggle = true;

  @HostBinding("class.layout-navbar") hostClassMain = true;

  constructor(
    private appService: AppService,
    private layoutService: LayoutService,
    private loginservice: LoginService,
    public router: Router,
    private OrdersService: OrdersService
  ) {
    this.isRTL = appService.isRTL;
    this.currentUser = loginservice.getCurrentUser();
    this.userRoleName = this.loginservice.getUserRoleName();
    this.loginservice.updatedUser.subscribe((value) => {
      this.currentUser = value;
    });
    this.lastLink = this.appService.lastLink.subscribe((value) => {
      this.lastLinkValues = value;
    });
  }

  ngOnInit() {
    this.OrdersService.newJobsSubject.subscribe((data: any) => {
      this.blinkPending =
        data && data.length && this.router.url != "/dashboard";
    });
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  stopBlink() {
    this.blinkPending = false;
  }
}
