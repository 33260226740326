import { Component, AfterViewInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { LayoutService } from "../layout.service";
import { LoginService } from "../../services/login/login.service";
import { ProvidersService } from "../../services/providers/providers.service";

import { ChangePasswordComponent } from "../../views/modals/change-password/change-password.component";

@Component({
  selector: 'app-layout-track',
  templateUrl: './layout-track.component.html',
  styles: [
    ":host { display: block; }",
    ":host ::ng-deep .layout-loading .sidenav-link { transition: none !important; }",
  ]
})
export class LayoutTrackComponent implements AfterViewInit, OnDestroy {
  // Prevent "blink" effect
  public initialized = false;
  showHeader: boolean = true;
  currentYear: number = null;
  currentUser: any = null;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private LoginService: LoginService,
    private modal: NgbModal,
    private ProvidersService: ProvidersService
  ) {
    this.currentYear = new Date().getFullYear();
    this.currentUser = this.LoginService.getCurrentUser();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialized = true;

      this.layoutService.init();
      this.layoutService.update();
      this.layoutService.setAutoUpdate(true);
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params && params.view == "app") this.showHeader = false;
    });

    if (!this.currentUser.superAdmin)
      this.LoginService.refreshToken()
        .then((result) => {
          let data = JSON.parse(JSON.stringify(result));
          this.LoginService.setUser(data.data);

          if (data.data.isAdmin) {
            if (!data.data.defaultPasswordChanged) {
              const modal = this.modal.open(ChangePasswordComponent, {
                backdropClass: "light-blue-backdrop",
                backdrop: "static",
                keyboard: false,
              });

              modal.componentInstance.showCloseButton = false;
            }

            this.ProvidersService.getOnBoarded()
              .then((result: any) => {
                if (
                  result.data &&
                  result.data.userInfo &&
                  !result.data.userInfo.isOnBoarded
                )
                  this.router.navigateByUrl("/onboard");
              })
              .catch((err) => {
                console.log("Onboard erro");
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.layoutService.destroy();
    });
  }

  closeSidenav() {
    setTimeout(() => {
      this.layoutService.setCollapsed(true);
    });
  }
}
