import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  @Output() updatedUser: EventEmitter<any> = new EventEmitter();
  constructor(private httpClient: HttpClient) {}

  verifyEmail(token: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/auth/verify/email", {
          token: token,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => reject(err)
        );
    });
  }

  login(data: object) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/auth/login", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (res) => {
            reject(res.error);
          }
        );
    });
  }

  loginWithToken(token: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/auth/login_link", {
          authToken: token,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (res) => {
            reject(res.error);
          }
        );
    });
  }

  logout() {
    sessionStorage.removeItem("stuk:auth:token");
    sessionStorage.removeItem("stuk:loggedInUser");
    sessionStorage.removeItem("stuk:token:expires_at");
  }

  logoutAll() {
    sessionStorage.removeItem("stuk:auth:token");
    sessionStorage.removeItem("stuk:loggedInUser");
    sessionStorage.removeItem("stuk:token:expires_at");

    sessionStorage.removeItem("stuk:superadmin:token");
    sessionStorage.removeItem("stuk:superadmin");
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  public isLoggedIn() {
    return (
      sessionStorage.getItem("stuk:auth:token") ||
      sessionStorage.getItem("stuk:superadmin:token")
    );
  }

  getCurrentUser() {
    if (this.isLoggedOut()) return null;
    let data = sessionStorage.getItem("stuk:loggedInUser");
    return data ? JSON.parse(data) : null;
  }

  getUserRoleName() {
    const user = this.getCurrentUser();
    if (!user) return;

    if (user.isAdmin) return "Admin";
    if (user.isDispatcher) return "Dispatcher";
    if (user.isDriver) return "Driver";
  }

  getSuperAdmin() {
    let data = sessionStorage.getItem("stuk:superadmin");
    return data ? JSON.parse(data) : null;
  }

  setUser(user) {
    if (user.superAdmin) {
      sessionStorage.setItem("stuk:superadmin", JSON.stringify(user));
      sessionStorage.setItem("stuk:superadmin:token", user.token);
    } else {
      sessionStorage.setItem("stuk:loggedInUser", JSON.stringify(user));
      sessionStorage.setItem("stuk:auth:token", user.token);
    }
    this.updatedUser.emit(user);
  }

  setSQToken(code) {
    localStorage.setItem("stuk:square:token", code);
  }

  getSQToken() {
    return localStorage.getItem("stuk:square:token");
  }

  setCloverToken(code) {
    localStorage.setItem("stuk:clover:token", code);
  }

  getCloverToken() {
    return localStorage.getItem("stuk:clover:token");
  }

  deleteSQToken() {
    localStorage.removeItem("stuk:square:token");
  }

  deleteCloverToken(){
    localStorage.removeItem("stuk:clover:token");
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/user/refresh_token", {})
        .subscribe(
          (response) => {
            resolve(response);
          },
          (res) => {
            reject(res.error);
          }
        );
    });
  }

  signupRequest(data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/contact/signup", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (res) => {
            reject(res.error);
          }
        );
    });
  }

  contactRequest(data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/contact/query", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (res) => {
            reject(res.error);
          }
        );
    });
  }
}
