import { Component, OnInit } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../services/wait/wait.service";
import { PriceService } from "../../services/price/price.service";
import { AccountService } from "../../services/account/account.service";

import { EditRatesComponent } from "../../views/modals/edit-rates/edit-rates.component";
import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";

@Component({
  selector: "app-service-price",
  templateUrl: "./service-price.component.html",
  styleUrls: ["./service-price.component.scss"],
})
export class ServicePriceComponent implements OnInit {
  view: string = "main";
  mainServices: any = [];
  additonalServices: any = [];
  userAccounts = [];
  selectedAccount: number;
  selectAllMain: boolean = false;
  selectAllAdditional: boolean = false;
  customServiceName: string = null;
  customServiceError: string = null;

  constructor(
    private PriceService: PriceService,
    private AccountService: AccountService,
    private WaitService: WaitService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getAccounts();
  }

  getAccounts() {
    this.WaitService.start();
    this.AccountService.getUserAccounts()
      .then((accounts) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(accounts));
        this.userAccounts = result.data;
        if (result.data && result.data.length)
          this.getServices(result.data[0].id);
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  getServices(accountId) {
    this.customServiceError = null;
    this.customServiceName = null;
    this.selectedAccount = accountId;
    this.WaitService.start();
    this.PriceService.getServices(accountId)
      .then((response) => {
        this.mainServices = [];
        this.additonalServices = [];
        this.WaitService.stop();

        let result = JSON.parse(JSON.stringify(response));
        if (result.data && result.data.length)
          result.data.forEach((element) => {
            element.isActive = element.provider[0].isActive;
            if (
              element.serviceType == "main" ||
              element.serviceType == "storage"
            )
              this.mainServices.push(element);
            if (element.serviceType == "additional")
              this.additonalServices.push(element);
          });
        this.isAllServiceSelected();
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  changeSelect() {
    if (this.view == "main") {
      this.mainServices.forEach((x) => {
        x.isActive = this.selectAllMain;
      });
    }
    if (this.view == "additional") {
      this.additonalServices.forEach((x) => {
        x.isActive = this.selectAllAdditional;
      });
    }
  }

  isAllServiceSelected() {
    const selectedServices = this.mainServices.filter((x) => x.isActive).length;
    const selectedAddSercice = this.additonalServices.filter((x) => x.isActive)
      .length;

    this.selectAllAdditional =
      this.additonalServices.length == selectedAddSercice;
    this.selectAllMain = this.mainServices.length == selectedServices;
  }

  save() {
    this.WaitService.start();
    let data = [];
    if (this.view == "main") {
      this.mainServices.forEach((x) => {
        data.push({ id: x.id, isActive: x.isActive });
      });
    }
    if (this.view == "additional") {
      this.additonalServices.forEach((x) => {
        data.push({ id: x.id, isActive: x.isActive });
      });
    }

    this.PriceService.changeStatus(this.selectedAccount, data)
      .then((data) => {
        this.WaitService.stop();
        this.toastr.success("Changes Saved", "");
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  covertFirstLetterCapital(str){
    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(" ");
  }

  addCustomService() {
    this.customServiceError = null;
    let regEx = /^(?=.*[\w\d]).+/g;
    if (!this.customServiceName) {
      this.customServiceError = "Service name is required";
      return;
    } else if (!regEx.test(this.customServiceName)) {
      this.customServiceError = "Service name is not valid";
      return;
    }
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.title = "Create Service";
    modal.componentInstance.message =
      "Do you want to create " + this.customServiceName + " service?";
    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
     this.customServiceName=this.covertFirstLetterCapital(this.customServiceName)
      let data = {
        accountId: this.selectedAccount,
        serviceName: this.customServiceName,
        serviceType: this.view,
        isActive: true,
      };
      this.WaitService.start();
      this.PriceService.createCustomService(data)
        .then((response) => {
          this.WaitService.stop();
          this.customServiceName = null;
          let result = JSON.parse(JSON.stringify(response));
          this.toastr.success("Service Created");
          this.getServices(this.selectedAccount);

          this.editService({
            id: result.data.id,
            isActive: result.data.isActive,
            label: result.data.label,
          });
        })
        .catch((err) => {
          this.toastr.error(err.error.error);
          this.WaitService.stop();
        });
    });
  }

  editService(data) {
    const modal = this.modalService.open(EditRatesComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.accountId = this.selectedAccount;
    modal.componentInstance.serviceId = data.id;
    modal.componentInstance.isActive = data.isActive;
    modal.componentInstance.serviceName = data.label;
    modal.componentInstance.serviceType = data.serviceType;
    modal.componentInstance.isMainService = this.view == "main";
  }

  deleteService(data) {
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = "Delete Service";
    modal.componentInstance.message =
      "Do you really want to delete " + data.label + " service?";
    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.WaitService.start();
      this.PriceService.deleteService(this.selectedAccount, data.id)
        .then((response) => {
          this.WaitService.stop();
          this.toastr.success("Service Deleted");
          this.getServices(this.selectedAccount);
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    });
  }

  changeView(view) {
    this.view = view;
    this.customServiceError = null;
    this.customServiceName = null;
  }
}
