import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { RefundComponent } from "./refund.component";

@NgModule({
  declarations: [RefundComponent],
  imports: [CommonModule, FormsModule],
  exports: [RefundComponent],
  entryComponents: [RefundComponent],
})
export class RefundModule {}
