import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ForgotService } from "../../services/forgot/forgot.service";

import { fadeInAnimation } from "../../animations";

@Component({
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" },
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  view: string = "form";
  errorMessage: string = null;
  token: string = null;
  password: string = null;
  confirmPassword: string = null;
  errors: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ForgotService: ForgotService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (!params["token"]) this.router.navigate(["/404"]);
      if (params["token"]) {
        this.token = params["token"];
        this.ForgotService.verifyResetLink(params["token"])
          .then((res) => {
            console.log(res);
            this.view = "form";
          })
          .catch((err) => {
            this.view = "error";
            console.log(err.error.error);
            this.errorMessage = err.error.error;
          });
      }
    });
  }

  changePassword() {
    if (!this.validatePassword()) return;
    let data = {
      password: this.password,
      token: this.token,
    };
    this.ForgotService.changePassword(data)
      .then((res) => {
        console.log(res);
        this.view = "success";
      })
      .catch((err) => {
        console.log(err);
        this.view = "error";
        this.errorMessage = err.error.error;
      });
  }

  validatePassword() {
    this.errors = {};
    if (!this.password || this.password.length < 6)
      this.errors.password = "Password should have more then 6 character";
    if (this.password.length >= 6 && this.password != this.confirmPassword)
      this.errors.confirmPassword = "Password not matched";

    return !Object.keys(this.errors).length;
  }
}
