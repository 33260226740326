import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ClassTypesService {
  constructor(private httpClient: HttpClient) {}


  getClassTypes(isEditFromSuperAdmin=false, id= null) {
    let baseURL = environment.baseURL + "/api/settings/class_types";
    if(isEditFromSuperAdmin) baseURL = environment.baseURL + "/api/super_admin/settings/class_types";
    
    let params = new HttpParams();

    if (isEditFromSuperAdmin) {
      params = params.set("providerId", id);
    }
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(baseURL, { params })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateStatus(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/settings/class_types", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createClassType(classTypeName: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/settings/class_types/create", {
          classTypeName: classTypeName,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteClassType(classTypeId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(
          environment.baseURL +
            "/api/settings/class_types/delete/" +
            classTypeId
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
