import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleApiService {

  removeCountries = ['usa','us','united states'];

  constructor(private httpClient: HttpClient) {}

  searchPlace(address: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          environment.baseURL +
            "/map/place/textsearch/json?query=" +
            address +
            "&language=en&key=" +
            environment.GOOGLE_API_KEY
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAddress(lat: number, lng: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          environment.baseURL +
            "/map/geocode/json?latlng=" +
            lat +
            "," +
            lng +
            "&key=" +
            environment.GOOGLE_API_KEY
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCoordinates(address: string) {
    let text = encodeURI(address);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          environment.baseURL +
            "/map/geocode/json?address=" +
            text +
            "&key=" +
            environment.GOOGLE_API_KEY
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  autoCompleteAddress(text, lat = null, lng = null, removeCountry = false, bounds: any = null) {
    // let url =
    //   "/map/place/autocomplete/json?input=" +
    //   encodeURI(text) +
    //   "&key=" +
    //   environment.GOOGLE_API_KEY;

    // if (lat && lng) url += "&location=" + lat + "," + lng + "&radius=32186";

    // if (lat && lng) url += "&location=" + lat + "," + lng + "&radius=10000&origin=" + lat + "," + lng;

    // let query = { input: text, bounds: new google.maps.LatLngBounds({ lat: lat - 0.05, lng: lng - 0.05}, { lat: lat + 0.05, lng: lng + 0.05}) }
    // if (!lat || !lng) delete query.bounds;

    let query = { input: text, bounds: null };
    if (bounds) query.bounds = new google.maps.LatLngBounds(bounds.sw, bounds.ne);
    else if (lat && lng) query.bounds = new google.maps.LatLngBounds({ lat: lat - 0.05, lng: lng - 0.05}, { lat: lat + 0.05, lng: lng + 0.05});
    else delete query.bounds;

    return new Promise((resolve, reject) => {
      new google.maps.places.AutocompleteService().getPlacePredictions(
        query,
        (predictions, status) => {
          if (status === "OK") resolve({ data: { predictions: removeCountry ? this.formattAddress(predictions) : predictions }});
          else reject("Google Place API Error");
        }
      );
    });

    // if (lat != null && lng != null)
    //   url += "&location=" + lat + "," + lng + "&strictbounds&radius=32186";
    // else url += "&location=28.175718,-83.578720&strictbounds&radius=468679.70";
    // const formattedResponse = [];
    // return new Promise((resolve, reject) => {
    //   this.httpClient.get(environment.baseURL + url).subscribe(
    //     (response) => {
    //       if(removeCountry) {
    //         let result = JSON.parse(JSON.stringify(response));
    //         result.data.predictions = this.formattAddress(result.data.predictions);
    //         result.data.predictions.sort((a,b) => a.distance_meters - b.distance_meters);
    //         resolve(result);
    //       } else {
    //         resolve(response);
    //       }
    //     },
    //     (err) => {
    //       reject(err);
    //     }
    //   );
    // });
  }

  formattAddress(predictions) {
    const removeCountries = this.removeCountries;
    predictions.forEach(function(prediction, index) {
      let lastIndex = prediction.terms.length - 1;
      let lastTerm = prediction.terms[lastIndex];
      if(lastTerm.value.toLowerCase() && removeCountries.includes(lastTerm.value.toLowerCase())) {
        // predictions[index].terms.pop();
        // predictions[index].description = predictions[index].terms.map(term => term.value).join(', ');
        let description = predictions[index].description.split(',');
        description.pop();
        predictions[index].description = description.join();
      }
    });

    return predictions;
  }

  getNearByPlaces(text, lat, lng) {
    let url = "/map/place/nearbysearch/json";

    url += "?location=" + lat + "," + lng;

    url += "&radius=32186.9";
    url += "&type=street_number";
    url += "&key=" + environment.GOOGLE_API_KEY;

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.baseURL + url).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getPlceDetailById(palceId: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          environment.baseURL +
            "/map/place/details/json?placeid=" +
            palceId +
            "&key=" +
            environment.GOOGLE_API_KEY
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  parseAddress(currentVariable, addressObj, name = null) {
    let temp = addressObj.reverse();

    let zip = temp.filter((x) => x.types.includes("postal_code"));
    currentVariable.zip = zip && zip.length ? zip[0].long_name : null;

    let country = temp.filter((x) => x.types.includes("country"));
    currentVariable.country =
      country && country.length ? country[0].long_name : null;

    let state = temp.filter((x) =>
      x.types.includes("administrative_area_level_1")
    );
    currentVariable.state = state && state.length ? state[0].long_name : null;

    let city = temp.filter((x) => x.types.includes("locality") || x.types.includes("administrative_area_level_2"));
    currentVariable.city = city && city.length ? city[0].long_name : null;

    let premise = null,
      locality = null,
      route = null;

    premise = temp.filter((x) => x.types.includes("premise"));
    premise = premise && premise.length ? premise[0].long_name + " " : "";

    locality = temp.filter(
      (x) =>
        x.types.includes("street_number") ||
        x.types.includes('"sublocality"') ||
        x.types.includes("sublocality_level_1")
    );
    locality =
      locality && locality.length
        ? locality.map((x) => x.long_name).join() + " "
        : "";

    route = temp.filter((x) => x.types.includes("route"));
    route = route && route.length ? route[0].long_name : "";

    let addressLineOne = "";
    addressLineOne += premise + locality + route;
    if (!addressLineOne && name) addressLineOne = name;
    currentVariable.addressLineOne = addressLineOne;

    return currentVariable;
  }

  getDistance(lat1, lng1, lat2, lng2) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          environment.baseURL +
            "/map/distancematrix/json?origins=" +
            lat1 +
            "," +
            lng1 +
            "&destinations=" +
            lat2 +
            "," +
            lng2 +
            "&key=" +
            environment.GOOGLE_API_KEY
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
