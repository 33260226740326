import { Component, OnInit, HostListener, ViewChild, ElementRef  } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { EditUserComponent } from "../../views/modals/edit-user/edit-user.component";
import { UserService } from "../../services/user/user.service";
import { WaitService } from "../../services/wait/wait.service";
import { ValidateService } from "src/app/services/phone-validate/validate.service";
import { SocketService } from "src/app/services/socket/socket.service";

@Component({
  selector: "app-user-dispaly",
  templateUrl: "./user-dispaly.component.html",
  styleUrls: ["./user-dispaly.component.scss"],
})
export class UserDispalyComponent implements OnInit {
  @ViewChild("userTable", { static: true }) userTable: ElementRef;
  p: number = 1;
  allusers: any = [];
  roleType: any[] = [
    { value: 5, name: "Admin" },
    { value: 6, name: "Dispatcher" },
    { value: 3, name: "Driver" },
    { value: 7, name: "AnsweringService" },
  ];
  totalUsers:number=0;
  loadedData: number = 0;
  filters: any = {
    text:null,
    limit: 0,
    offset: 0,
  };
  searchText: string = "";
  filteredUsers: any = [];
  dispatchers:any =[];
  drivers:any=[];
  admins:any=[];
  socket: any = null;
  rowClicked: number = -1;

  constructor(
    private modalService: NgbModal,
    private ToastrService: ToastrService,
    private UserService: UserService,
    private WaitService: WaitService,
    private ValidateService: ValidateService,
    private SocketService: SocketService,
  ) {}

  openModal() {
    const modal = this.modalService.open(EditUserComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modal.result.then((data) => {
      this.filters.text=null;
      this.getUsers();
    });
  }

  openDeleteModal(id) {
    const modal = this.modalService.open(EditUserComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.deleteUser = id;
    modal.result.then((data) => {
      this.listAllUsers();
      this.p = 1;
    });
  }

  openEditModal(id) {
    const modal = this.modalService.open(EditUserComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.userId = id;
    modal.result
      .then((data) => {
        this.filters.text=null;
        this.getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getUsers(){
    this.UserService.getAllUsers(this.filters)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res));
        this.allusers = data.users;
        this.totalUsers=parseInt(data.total);

        this.allusers.forEach((user) => {
          let name;
          if (user.name) {
            user.name = user.name.split("  ").join(" ");
            name = user.name.split(" ");
          } else name = ["", ""];
          user.fName = name[0] ? name[0] : "";
          user.lName = name[1] ? name[1] : "";
          user.selectDrop = false;
          let roleArray = user.roleArray ? JSON.parse(user.roleArray) : [];
          this.roleType.forEach((role) => {
            user[role.name] = !(roleArray.indexOf(role.value) == -1);
          });
          // user.phone=user && user.phone?this.ValidateService.format(user.phone):"";
        });

        this.filteredUsers = this.allusers;
        this.filteredUsers.forEach((x)=>{
        if (x.phone) {
          x.phone = this.ValidateService.onEdit(x.phone);
          }
        })
        this.filterUsersByRole(this.filteredUsers);
        this.loadedData=this.allusers.length
        this.WaitService.stop();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.ToastrService.error("Something Went Wrong");
      });
  }

  listAllUsers() {
    this.WaitService.start();
    this.allusers = [];
    this.socket = this.SocketService.getSocket();
    this.socket.on("driverActive", (data) => {
      console.log("socket-driverActive");
      this.getUsers();
    });
    this.getUsers();
  }

  ngOnInit() {
    this.listAllUsers();
  }

  filterUsers() {
    this.p = 1;
    const text = this.searchText.toLowerCase();
    this.filteredUsers = this.allusers.filter(
      (x) =>
        (x.fname && x.fname.toLowerCase().includes(text)) ||
        (x.lName && x.lName.toLowerCase().includes(text)) ||
        (x.phone && x.phone.toLowerCase().includes(text)) ||
        (x.email && x.email.toLowerCase().includes(text)) ||
        (x.username && x.username.toLowerCase().includes(text)) ||
        (x.Role && x.Role.name && x.Role.name.toLowerCase().includes(text))
    );
  }

  getText(e) {
    this.searchText = e;
    this.filters.text= e;
    this.getUsers();
  }

  paginate(offset: number) {
    if (offset > this.totalUsers) offset = this.totalUsers;
    if (offset <= 0) offset = 0;
    this.filters.offset = offset;
    this.getUsers();
    this.userTable.nativeElement.scrollTo(0, 0);
  }

  filterUsersByRole(users){
    this.admins=users.filter(x=>x.RoleId==2).sort(value => {
      return value.isOnline ? -1 : 1
    });;
    this.dispatchers=users.filter(x=>x.RoleId==5).sort(value => {
      return value.isOnline ? -1 : 1
    });;
    this.drivers=users.filter(x=>x.RoleId==3).sort(value => {
      return value.isOnline ? -1 : 1
    });;
  }
  selectedRow(idx: any) {
    this.rowClicked = idx;
  }
}
