import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from "agm-direction";

import { FeaturesComponent } from "./features.component";
import { FeaturesRoutingModule } from "./features-routing.module";

import { LoginComponent } from "./login/login.component";

import { ConfirmEmailComponent } from "./confirm-email/confirm-email.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LogoutComponent } from "./logout/logout.component";
import { FaqComponent } from "./faq/faq.component";
import { NavBarComponent } from "../components/nav-bar/nav-bar.component";

// PAGES
import { TermsComponent } from "../pages/terms/terms.component";
//Guest order tracking components
import { TrackingComponent } from "./tracking/tracking.component";
import { RequestLocationComponent } from "../components/request-location/request-location.component";
import { OrderTrackingComponent } from "../components/order-tracking/order-tracking.component";

import { environment } from "../../environments/environment";
import { WebViewComponent } from "./web-view/web-view.component";

@NgModule({
  declarations: [
    NavBarComponent,
    FeaturesComponent,
    LoginComponent,
    ConfirmEmailComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    FaqComponent,
    TermsComponent,
    TrackingComponent,
    RequestLocationComponent,
    OrderTrackingComponent,
    WebViewComponent,
  ],
  imports: [
    CommonModule,
    FeaturesRoutingModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
    }),
    AgmDirectionModule,
  ],
})
export class FeaturesModule {}
