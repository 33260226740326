import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";

import { LoginService } from "../../services/login/login.service";
import { WaitService } from "../../services/wait/wait.service";
import { PaymentService } from "../../services/payment/payment.service";

@Component({
  selector: 'app-auth-direct',
  templateUrl: './auth-direct.component.html',
  styleUrls: ['./auth-direct.component.scss']
})
export class AuthDirectComponent implements OnInit {
  public code: string;
  public merchanId: string;
  public employeeId: string;
  public clientId: string;
  public currentUser: any = null;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private WaitService: WaitService,
    private PaymentService: PaymentService,
    private LoginService: LoginService) { }

  ngOnInit() {
    let url = new URL(window.location.href);
    this.code = '';
    if(url.searchParams.get("code")){
      this.code = url.searchParams.get("code");
    }
    if(url.searchParams.get("merchant_id")){
      this.merchanId = url.searchParams.get("merchant_id");
    }
    if(url.searchParams.get("employee_id")){
      this.employeeId = url.searchParams.get("employee_id");
    }
    if(url.searchParams.get("client_id")){
      this.clientId = url.searchParams.get("client_id");
    }
    

    this.currentUser = this.LoginService.getCurrentUser();
    if(this.code && this.merchanId && this.employeeId){
      // this.getUserPayment();
      let data = {
        code:this.code,
        merchanId:this.merchanId,
        employeeId:this.employeeId,
        clientId:this.clientId
      };

      this.addCloverPaymentToken(JSON.stringify(data));
    }
    if(this.code && !this.merchanId && !this.employeeId){
      this.getUserPayment();
    }
  }

  getUserPayment() {
    this.WaitService.start();
    this.PaymentService.getAddedGateWay()
      .then((response) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));
        let data = result.data;
        let sqGateways = data.map((x) => {
          x.gateWayName=='Square';
          return x;
        });
        this.addPaymentGateWayToken(sqGateways);
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  addPaymentGateWayToken(gateway){
    this.LoginService.setSQToken(this.code);
    this.router.navigate(['/dashboard/settings/payment']);
  }

  addCloverPaymentToken(cloverData){
    this.LoginService.setCloverToken(cloverData);
    this.router.navigate(['/dashboard/settings/payment']);
  }

}
