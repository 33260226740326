import { Injectable, EventEmitter, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";
import * as UAParser from "ua-parser-js";

@Injectable()
export class AppService {
  @Output() lastLink: EventEmitter<any> = new EventEmitter();
  public tour: boolean = false;
  constructor(private titleService: Title) {}

  // Set page title
  set pageTitle(value: string) {
    this.titleService.setTitle(`${value} - STUK`);
  }

  get isMobileDevice() {
    let instance = new UAParser();
    let deviceDetails = instance.getDevice();
    return (
      deviceDetails &&
      (deviceDetails.type == "mobile" || deviceDetails.type == "tablet")
    );
  }

  // Check for RTL layout
  get isRTL() {
    return (
      document.documentElement.getAttribute("dir") === "rtl" ||
      document.body.getAttribute("dir") === "rtl"
    );
  }

  set backLink(value: any) {
    this.lastLink.emit(value);
  }

  // Check if IE10
  get isIE10() {
    return (
      typeof document["documentMode"] === "number" &&
      document["documentMode"] === 10
    );
  }

  // Layout navbar color
  get layoutNavbarBg() {
    return "navbar-theme";
  }

  // Layout sidenav color
  get layoutSidenavBg() {
    return "sidenav-theme";
  }

  // Layout footer color
  get layoutFooterBg() {
    return "footer-theme";
  }

  // Animate scrollTop
  scrollTop(
    to: number,
    duration: number,
    element = document.scrollingElement || document.documentElement
  ) {
    if (element.scrollTop === to) {
      return;
    }
    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    // t = current time; b = start value; c = change in value; d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) {
        return (c / 2) * t * t + b;
      }
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(
        easeInOutQuad(currentTime, start, change, duration),
        10
      );
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  }
}
