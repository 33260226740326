import { Component, OnInit } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../services/wait/wait.service";
import { ClassTypesService } from "../../services/classTypes/class-types.service";

import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";

@Component({
  selector: "app-class-types",
  templateUrl: "./class-types.component.html",
  styleUrls: ["./class-types.component.scss"],
})
export class ClassTypesComponent implements OnInit {
  allClassTypes: any = [];
  newClassTypeName: string = null;
  errorMessage: string = null;
  selectAll: boolean = false;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private ClassTypesService: ClassTypesService
  ) {}

  ngOnInit() {
    this.getClassTypes();
  }

  getClassTypes() {
    this.WaitService.start();
    this.ClassTypesService.getClassTypes()
      .then((res) => {
        this.newClassTypeName = null;

        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        result = result.data;
        this.allClassTypes = result.map((x) => {
          x.isActive =
            x.providerClassType && x.providerClassType.isActive ? true : false;
          if (x.providerClassType && x.providerClassType.id)
            x.providerClassTypeId = x.providerClassType.id;
          return x;
        });
        this.isAllSelected();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  isAllSelected() {
    const selectedItems = this.allClassTypes.filter((x) => x.isActive).length;
    this.selectAll = selectedItems == this.allClassTypes.length;
  }

  save() {
    let classTypes = this.allClassTypes.map((x) => {
      return {
        id: x.id,
        isActive: x.isActive,
        providerClassTypeId: x.providerClassTypeId,
      };
    });

    let data = {
      classTypes: classTypes,
    };

    this.WaitService.start();
    this.ClassTypesService.updateStatus(data)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Changes Saved");
        this.getClassTypes();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  validateCustomClassType() {
    let classTypeIsValid = this.allClassTypes.filter(
      (x) =>
        x.name.trim().toLowerCase() ==
        this.newClassTypeName.trim().toLowerCase()
    ).length;

    return !classTypeIsValid;
  }

  covertFirstLetterCapital(str){
    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(" ");
  }

  addClassType() {
    this.errorMessage = null;

    if (!this.newClassTypeName || !this.newClassTypeName.trim().length) {
      this.errorMessage = "Class Type name is required";
      return;
    }

    if (!this.validateCustomClassType()) {
      this.errorMessage = "Class Type already exists";
      return;
    }

    this.newClassTypeName=this.covertFirstLetterCapital(this.newClassTypeName)
    console.log(this.newClassTypeName);

    this.WaitService.start();
    this.ClassTypesService.createClassType(this.newClassTypeName)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Class Type Created");
        this.getClassTypes();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  deleteClassType(classTypeId: number, name: string) {
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = "Delete Class Type";
    modal.componentInstance.message =
      "Do you really want to delete " + name + " class type?";
    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.WaitService.start();
      this.ClassTypesService.deleteClassType(classTypeId)
        .then(() => {
          this.WaitService.stop();
          this.toastr.success("Class type removed");
          this.getClassTypes();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    });
  }

  changeSelect() {
    this.allClassTypes.forEach((x) => {
      x.isActive = this.selectAll;
    });
  }
}
