import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { UserSettingsService } from "src/app/services/user-settings/user-settings.service";
import { WaitService } from "src/app/services/wait/wait.service";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
})
export class TermsComponent implements OnInit {
  data: any = null;
  dataWaiver:any=null;
  constructor(
    private toastr: ToastrService,
    private WaitService: WaitService,
    private UserSettingsService: UserSettingsService
  ) {}

  ngOnInit() {
    this.getWaiver();
  }

  getWaiver() {
    this.WaitService.start();
    this.UserSettingsService.getWaiver()
      .then((result: any) => {
        this.WaitService.stop();
        if (result && result.data) this.dataWaiver = result.data;
        if(!this.dataWaiver || this.dataWaiver.pickUp=="" || !this.dataWaiver.pickUp ){
          this.dataWaiver={
            pickUp:"During transport vehicles and vehicle equipment may cease to operate properly through no fault of the transporter. The transporter will be responsible for damage directly caused by the driver. The Transporter WILL NOT be responsible for damage NOT caused by the driver. I agree with the Driver's assessment of the condition of this vehicle. I have read and understand the terms and conditions above. I agree to be bound by all of these terms and conditions."
          }
        } 
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }
}
