import { Component, OnInit, Input } from "@angular/core";

import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { ValidateService } from "src/app/services/phone-validate/validate.service";
import { GoogleApiService } from "../../../services/googleApi/google-api.service";
import { SitesService } from "../../../services/sites/sites.service";
import { PlacesService } from "../../../services/places/places.service";
import { ProvidersService } from "../../../services/providers/providers.service";
import { ReportingService } from "../../../services/reporting/reporting.service";

@Component({
  selector: "app-create-site",
  templateUrl: "./create-site.component.html",
  styleUrls: ["./create-site.component.scss"],
})
export class CreateSiteComponent implements OnInit {
  @Input() siteData: any = {
    locationTypeId: null,
  };
  addressSuggestions = [];
  suggesstionTimeOut: any;
  showSuggesstion: boolean = false;
  lat = 0;
  lng = 0;
  showMap: boolean = false;
  validationErrors: any = {};

  locationTypes: any = [];
  providerInfo: any = null;
  phoneNumber: any;

  validate: any = {
    error: {},
  };

  constructor(
    private modal: NgbActiveModal,
    private WaitService: WaitService,
    private toastr: ToastrService,
    private GoogleApiService: GoogleApiService,
    private SitesService: SitesService,
    private PlacesService: PlacesService,
    private ProvidersService: ProvidersService,
    private ReportingService: ReportingService,
    private ValidateService: ValidateService
  ) { }

  ngOnInit() {
    this.getData();
    if (this.siteData.phone) {
      this.siteData.phone = this.ValidateService.onEdit(this.siteData.phone);
      this.phoneNumber = this.siteData.phone
    }
    if (this.siteData.lat && this.siteData.lng) {
      this.lat = parseFloat(this.siteData.lat);
      this.lng = parseFloat(this.siteData.lng);
    }

    if (!this.siteData || !this.siteData.openHours)
      this.siteData.openHours = "any";
    if (!this.siteData || !this.siteData.timings) {
      this.siteData.timings = {
        weekdays: { isActive: false, open: "09:00", close: "05:00" },
        saturday: { isActive: false, open: "09:00", close: "05:00" },
        sunday: { isActive: false, open: "09:00", close: "05:00" },
      };
    }

    // this.getMyLocation();
  }

  close() {
    this.modal.close();
  }

  // getMyLocation() {
  //   if (navigator.geolocation && !this.siteData.id) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       if (position) {
  //         this.lat = position.coords.latitude;
  //         this.lng = position.coords.longitude;
  //       }
  //     });
  //   }

  //   if (this.siteData.id) {
  //     this.lat = parseFloat(this.siteData.lat);
  //     this.lng = parseFloat(this.siteData.lng);
  //   }
  // }

  searchAddress(text) {
    clearTimeout(this.suggesstionTimeOut);
    this.suggesstionTimeOut = setTimeout(() => {
      this.lat = null;
      this.lng = null;
      this.siteData.lat = null;
      this.siteData.lng = null;

      let lat = null,
        lng = null;
      if (this.providerInfo && this.providerInfo.lat && this.providerInfo.lng) {
        lat = parseFloat(this.providerInfo.lat);
        lng = parseFloat(this.providerInfo.lng);
      }
      this.GoogleApiService.autoCompleteAddress(text, lat, lng, true)
        .then((res) => {
          let result = JSON.parse(JSON.stringify(res));
          this.addressSuggestions = result.data.predictions;
          // this.addressSuggestions.map((x)=>{
          //   var lastIndex = x.description.lastIndexOf(",");
          //   x.description = x.description.substring(0, lastIndex);
          // })
        })
        .catch((err) => {
          console.log(err);
        });
    }, 300);
  }

  hideSuggesstion() {
    setTimeout(() => {
      this.showSuggesstion = false;
      if (this.addressSuggestions && this.addressSuggestions.length) {
        // let matchedAddress = this.addressSuggestions.filter(
        //   (x) => x.description == this.siteData.address
        // );

        // if (!matchedAddress.length)
        this.selectPlace(this.addressSuggestions[0])
        // this.siteData.address = this.addressSuggestions[0].description;
      } else if (!this.siteData.address) {
        this.siteData.lat = null;
        this.siteData.lng = null;
        this.lat = parseFloat(this.providerInfo.lat);
        this.lng = parseFloat(this.providerInfo.lng);
      }
    }, 500);
  }

  convertToAddress(e) {
    this.lat = e.coords.lat;
    this.lng = e.coords.lng;
    this.siteData.lat = this.lat;
    this.siteData.lng = this.lng;
    this.GoogleApiService.getAddress(e.coords.lat, e.coords.lng)
      .then((response) => {
        const result = JSON.parse(JSON.stringify(response));
        let places = result.data.results;
        if (places && places.length) {
          this.siteData.address = places[0].formatted_address;
          this.siteData.address = this.siteData.address;
          // .replace(
          //   ", USA",
          //   ""
          // );
        }
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }

  save() {
    if (this.phoneNumber) {
      this.validate.error.phone = this.ValidateService.isValid(
        this.phoneNumber
      );
      if (this.validate.error.phone) return;
      this.siteData.phone = this.ValidateService.format(this.phoneNumber);
    }
    if (this.validateData()) {
      if (this.siteData.locationTypeId == 18)
        this.siteData.customLocationType = this.ReportingService.capitalize(this.siteData.customLocationType)

      let save = this.siteData.id
        ? this.SitesService.editSite(this.siteData)
        : this.SitesService.createSite(this.siteData);
      this.WaitService.start();
      save
        .then((response) => {
          this.WaitService.stop();
          this.close();
          if (this.siteData.id) this.toastr.success("Changes Saved");
          else this.toastr.success("Location Added");
        })
        .catch((err) => {
          this.WaitService.stop();
          if (this.phoneNumber)
            this.phoneNumber = this.ValidateService.onMobileInput(
              this.phoneNumber
            );
          // if (this.siteData.phone)
          //   this.siteData.phone = this.ValidateService.onMobileInput(
          //     this.siteData.phone
          //   );
          this.toastr.error(err.error.error);
        });
    }
    else {
      if (this.phoneNumber)
        this.phoneNumber = this.ValidateService.onMobileInput(
          this.phoneNumber
        );
      // if (this.siteData.phone)
      //   this.siteData.phone = this.ValidateService.onMobileInput(
      //     this.siteData.phone
      //   );
    }
  }

  validateData() {
    this.validationErrors = {};
    let data = this.siteData;

    if (!data.name || !data.name.trim().length)
      this.validationErrors.name = "Required";

    if (data.locationTypeId == 18 && !data.customLocationType)
      this.validationErrors.customLocationType = "Required";
    else if (data.locationTypeId == 18 && data.customLocationType && !data.customLocationType.match(/^[A-Za-z\d\-_\s]+$/))
      this.validationErrors.customLocationType = "Invalid Location Type Name";
    else if (data.locationTypeId != 18 && data.customLocationType)
      delete this.siteData.customLocationType;

    // if (!data.phone) this.validationErrors.phone = "Phone number is required";
    if (data.phone && (data.phone.length < 12 || !data.phone.match(/\+\d+/)))
      this.validationErrors.phone = "Invalid Number";

    if (!data.address) this.validationErrors.address = "Required";

    if (
      this.siteData.timings.weekdays.isActive &&
      (this.siteData.timings.weekdays.open == "00:00" ||
        this.siteData.timings.weekdays.close == "00:00")
    ) {
      this.validationErrors.timings = "Invalid Time";
    }
    if (
      this.siteData.timings.sunday.isActive &&
      (this.siteData.timings.sunday.open == "00:00" ||
        this.siteData.timings.sunday.close == "00:00")
    ) {
      this.validationErrors.timings = "Invalid Time";
    }

    if (
      this.siteData.timings.saturday.isActive &&
      (this.siteData.timings.saturday.open == "00:00" ||
        this.siteData.timings.saturday.close == "00:00")
    ) {
      this.validationErrors.timings = "Invalid Time";
    }

    return Object.keys(this.validationErrors).length < 1;
  }

  selectPlace(data) {
    this.siteData.address = data.description;
    // .replace(
    //   /USA/g,
    //   ""
    // );
    this.GoogleApiService.getPlceDetailById(data.place_id).then((result: any) => {
      // let result = JSON.parse(JSON.stringify(response));
      result = result.data.result;

      let bounds = result.geometry.location;
      this.lat = bounds.lat;
      this.lng = bounds.lng;
      this.siteData.lat = this.lat;
      this.siteData.lng = this.lng;
    });

    this.addressSuggestions = [];
  }

  getData() {
    this.WaitService.start();
    const getLoactionType = this.PlacesService.getLocationTypes();
    const getProviderInfo = this.ProvidersService.getProviderInfo();

    return Promise.all([getLoactionType, getProviderInfo])
      .then((result: any) => {
        console.log(result)
        this.locationTypes = result[0].data;
        this.providerInfo = result[1].data;
        this.WaitService.stop();
        if (this.providerInfo && !this.siteData.lat && !this.siteData.lng) {
          this.lat = parseFloat(this.providerInfo.lat);
          this.lng = parseFloat(this.providerInfo.lng);
        }
        // if (this.providerInfo.phone) {
        //   this.providerInfo.phone = this.ValidateService.onEdit(this.providerInfo.phone);
        // }
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  onPhoneInput() {
    // this.siteData.phone = this.ValidateService.onMobileInput(
    //   this.siteData.phone
    // );
    this.phoneNumber = this.ValidateService.onMobileInput(
      this.phoneNumber
    );
  }

  onLocationChange() {
    if (this.siteData.locationTypeId == 18)
      this.siteData.customLocationType = "";
  }
}
