import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { WaitService } from "../../../services/wait/wait.service";
import { VehicalService } from "../../../services/vehical/vehical.service";
import { ClassTypesService } from "src/app/services/classTypes/class-types.service";

@Component({
  selector: "app-edit-trucks",
  templateUrl: "./edit-trucks.component.html",
  styleUrls: ["./edit-trucks.component.scss"],
})
export class EditTrucksComponent implements OnInit {
  @Input() public truckEdit;
  truck: any = {
    error: {},
    name: null,
    make: null,
    model: null,
    year: null,
    type: null,
    vinNumber: null,
  };
  types: any;

  constructor(
    private modal: NgbActiveModal,
    private ToastrService: ToastrService,
    private WaitService: WaitService,
    private VehicalService: VehicalService,
    private ClassTypesService: ClassTypesService
  ) { }

  close() {
    this.modal.close();
  }

  ngOnInit() {
    if (this.truckEdit) this.truck = this.truckEdit;
    if (this.truck.make == "N/A") this.truck.make = "";
    if (this.truck.vehicleModel == "N/A") this.truck.vehicleModel = "";

    this.ClassTypesService.getClassTypes()
      .then((res: any) => {
        this.types = res.data;
        console.log(this.types, 'types');
        this.types = this.types.filter((x) => {
          if (!this.truck.id) {
            if (x.providerClassType.isActive) {
              return x
            }
          } else {
            return x.name != 'All'
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Get car details by VIN number
  decodeVin() {
    if (this.truck.vinNumber && this.truck.vinNumber.length == 17) {
      this.WaitService.start();
      this.VehicalService.decodeVin(this.truck.vinNumber)
        .then((result: any) => {
          this.WaitService.stop();
          if (result && result.data) {
            this.truck.make = result.data.make;
            this.truck.model = result.data.model;
            this.truck.year = result.data.year;
          }
        })
        .catch((err) => {
          this.WaitService.stop();
        });
    }
  }

  save() {
    if (
      this.truck.year &&
      (this.truck.year < 1990 || this.truck.year > new Date().getFullYear())
    ) {
      this.ToastrService.error("Year Is Out Of Range");
      return;
    }

    this.WaitService.start();

    if (!this.truck.id) {
      this.VehicalService.addTruck(this.truck)
        .then((res: any) => {
          this.ToastrService.success("Truck Added");
          this.WaitService.stop();
          this.modal.close();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.ToastrService.error("Something Went Wrong");
        });
    }

    if (this.truck.id) {
      this.truck.vehicleId = this.truck.id;
      this.VehicalService.editTruck(this.truck)
        .then((res) => {
          this.ToastrService.success("Changes Saved");
          this.WaitService.stop();
          this.modal.close();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.ToastrService.error("Something Went Wrong");
        });
    }
  }
}
