import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./login/login.component";
// import { SignupComponent } from "./signup/signup.component";
import { ConfirmEmailComponent } from "./confirm-email/confirm-email.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LogoutComponent } from "./logout/logout.component";
import { FaqComponent } from "./faq/faq.component";
import { WebViewComponent } from "./web-view/web-view.component";
import { HomeComponent } from "../home/home.component";
// PAGES
import { TermsComponent } from "../pages/terms/terms.component";
// *******************************************************************************
// Layouts

import { Layout2Component } from "../layout/layout-2/layout-2.component";
import { LayoutTrackComponent } from '../layout/layout-track/layout-track.component';

// DASHBOARD COMPONENTES

import { JobsComponent } from "../components/jobs/jobs.component";
import { ImpoundsComponent } from "../components/impounds/impounds.component";

//Guest order tracking components
import { TrackingComponent } from "./tracking/tracking.component";
import { RequestLocationComponent } from "../components/request-location/request-location.component";
import { OrderTrackingComponent } from "../components/order-tracking/order-tracking.component";

//AUTH ROTUER GUARD
import { DispatcherGuardService } from "../services/dispatcherGuard/dispatcher-guard.service";
import { DriverGuardService } from "../services/driverGuard/driver-guard.service";
import { AdminGuardService } from "../services/adminGuard/admin-guard.service";
import {StukLiteGuardService} from "../services/stukLiteGuard/stuk-lite-guard.service"
const routes: Routes = [
  { path:"", redirectTo:'/login', pathMatch: 'full'},
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  // { path: "register", component: SignupComponent },
  { path: "confirm", component: ConfirmEmailComponent },
  { path: "reset", component: ResetPasswordComponent },
  { path: "forgot", component: ForgotPasswordComponent },
  {
    path: "pages",
    component: FaqComponent,
    children: [
      {
        path: "terms",
        component: TermsComponent,
      },
    ],
  },
  {
    path: "invoice/:uid",
    loadChildren: () =>
      import("./invoice-pdf/invoice-pdf.module").then(
        (m) => m.InvoicePdfModule
      ),
  },
  {
    path: "invoice/docs/:uid",
    loadChildren: () =>
      import("./docs-pdf/docs-pdf.module").then(
        (m) => m.DocsPdfModule
      ),
  },
  {
    path: "billing/:pid",
    loadChildren: () =>
      import("./billing-pdf/billing-pdf.module").then(
        (m) => m.BillingPdfModule
      ),
  },
  {
    path: "billing/:pid/:iid",
    loadChildren: () =>
      import("./billing-pdf/billing-pdf.module").then(
        (m) => m.BillingPdfModule
      ),
  },
  {
    path: "earningDetail/:pid/:iid",
    loadChildren: () =>
      import("./earning-pdf/earning-pdf.module").then(
        (m) => m.EarningPdfModule
      ),
  },
  {
    path: "dashboard/tracking",
    component: Layout2Component,
    canActivate: [DriverGuardService,StukLiteGuardService],
    children: [{
      path: "",
        loadChildren: () =>
          import("./driver-tracking/driver-tracking.module").then(
            (m) => m.DriverTrackingModule
          ),
    }]
  },
  {
    path: "dashboard",
    component: Layout2Component,
    canActivate: [DriverGuardService],
    children: [
      { path: "", component: JobsComponent },
      {
        path: "impound",
        component: ImpoundsComponent,
      },
      {
        canActivate: [DriverGuardService],
        path: "accounts",
        loadChildren: () =>
          import("./accounts/accounts.module").then((m) => m.AccountsModule),
      },
      {
        canActivate: [DriverGuardService],
        path: "invoices",
        loadChildren: () =>
          import("./invoices/invoices.module").then((m) => m.InvoicesModule),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("../provider-settings/provider-settings.module").then(
            (m) => m.ProviderSettingsModule
          ),
      },
      // {
      //   path: "tracking",
      //   loadChildren: () =>
      //     import("./driver-tracking/driver-tracking.module").then(
      //       (m) => m.DriverTrackingModule
      //     ),
      // },
      {
        canActivate: [DispatcherGuardService],
        path: "reporting",
        loadChildren: () =>
          import("./report/report.module").then((m) => m.ReportModule),
      },
      //For APP Web view only
      {
        canActivate: [DriverGuardService],
        path: "order",
        loadChildren: () =>
          import("./order-details/order-details.module").then(
            (m) => m.OrderDetailsModule
          ),
      },
      {
        canActivate: [DriverGuardService],
        path: "order-ac",
        loadChildren: () =>
          import("./job-modal-container/job-modal-container.module").then(
            (m) => m.JobModalContainerModule
          ),
      },
      {
        canActivate: [DriverGuardService],
        path: "editInvoice",
        loadChildren: () =>
          import(
            "./invoice-modal-container/invoice-modal-container.module"
          ).then((m) => m.InvoiceModalContainerModule),
      },
      // End here
    ],
  },
  {
    canActivate: [AdminGuardService],
    path: "onboard",
    loadChildren: () =>
      import("./onboard-provider/onboard-provider.module").then(
        (m) => m.OnboardProviderModule
      ),
  },
  {
    path: "order",
    component: TrackingComponent,
    children: [
      { path: "request/:requestId", component: RequestLocationComponent },
      { path: "track/:orderId", component: OrderTrackingComponent },
    ],
  },
  {
    path: "app/:view/:authToken",
    component: WebViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {}
