import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ToastrService } from "ngx-toastr";

import { OrdersService } from "../../services/orders/orders.service";
import { SocketService } from "../../services/socket/socket.service";
import { GoogleApiService } from "../../services/googleApi/google-api.service";

import { MapBoxService } from "../../services/map-box/map-box.service";

import * as mapboxgl from "mapbox-gl";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-order-tracking",
  templateUrl: "./order-tracking.component.html",
  styleUrls: ["./order-tracking.component.scss"],
})
export class OrderTrackingComponent implements OnInit {
  socket: any = null;
  orderId: string = null;
  orderData: any = null;
  orderStatus: string = null;

  mapDefault = { lat: 0, lng: 0 };

  driver = { lat: null, lng: null, bearing: 0 };

  origin: any = null;
  destination: any = null;

  // Map Box
  map: mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  dropoffs: any;
  pointAMarker: any;
  pointBMarker: any;
  providerMarker: any;
  driverMarker: any;
  driverPopUP: any;
  popup: any;
  popupSec: any;
  // End Map Box

  constructor(
    private SocketService: SocketService,
    private OrdersService: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private GoogleApiService: GoogleApiService,
    private MapBoxService: MapBoxService
  ) { }

  ngOnInit() {
    this.orderId = this.route.snapshot.paramMap.get("orderId");
    this.getOrderDetails();

    this.socket = this.SocketService.getSocket();
    this.socket.on("connect", () => {
      this.SocketService.login("st_" + this.orderId);
    });

    //On order status change
    this.socket.on("jobUpdated", () => {
      this.getOrderDetails();
    });

    // on driver location change
    this.socket.on("trackDriver", (data) => {
      this.updateDriverPosition(data);
    });

    this.buildMap();
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      accessToken: environment.MAPBOX_TOKEN,
      container: "customer-map",
      style: this.style,
    });

    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      maxWidth: "340",
      offset: 15,
    });

    this.popupSec = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      maxWidth: "340",
      offset: 15,
    });

    let ele1 = document.createElement("img");
    ele1.src = "./assets/flat-icons/map-pin-red.png";
    // ele1.style.height = "40px";
    // ele1.style.width = "34px";

    this.pointAMarker = new mapboxgl.Marker({
      element: ele1,
    });

    let ele2 = document.createElement("img");
    ele2.src = "./assets/flat-icons/map-pin-green.png";
    // ele2.style.height = "40px";
    // ele2.style.width = "34px";

    this.pointBMarker = new mapboxgl.Marker({
      element: ele2,
    });

    let ele3 = document.createElement("img");
    ele3.src = "./assets/flat-icons/build.png";

    this.providerMarker = new mapboxgl.Marker({
      element: ele3,
    });
  }

  // update driver position on the map
  updateDriverPosition(data) {
    this.driver.lat = parseFloat(data.data.lat);
    this.driver.lng = parseFloat(data.data.lng);
    this.driver.bearing = parseFloat(data.data.bearing);

    const marker = this.driverMarker;
    marker.setLngLat([data.data.lng, data.data.lat]);
    marker.setRotation(data.data.bearing);

    const popup = this.driverPopUP;
    popup.setLngLat([data.data.lng, data.data.lat]);

    this.showRoutes(false);
  }


  getOrderDetails() {
    this.OrdersService.getOrderDetailsById(this.orderId)
      .then((data) => {
        let result = JSON.parse(JSON.stringify(data));

        if (!result.data) this.router.navigateByUrl("/not-found");
        this.orderData = result.data;

        if (this.orderData) {
          this.map.setZoom(14);
          if (this.orderData.etaMins)
            this.orderData.etaMins = this.orderData.etaMins + " mins";
          if (this.orderData.pickUpLat && this.orderData.pickUpLon) {
            this.mapDefault = {
              lat: parseFloat(this.orderData.pickUpLat),
              lng: parseFloat(this.orderData.pickUpLon),
            };

            this.pointAMarker
              .setLngLat([this.orderData.pickUpLon, this.orderData.pickUpLat])
              .addTo(this.map);
          } else if (this.orderData.dropOffLat && this.orderData.dropOffLon) {
            this.mapDefault = {
              lat: parseFloat(this.orderData.dropOffLat),
              lng: parseFloat(this.orderData.dropOffLon),
            };
          } else this.getPosition();

          if (this.orderData.dropOffLat && this.orderData.dropOffLon)
            this.pointBMarker
              .setLngLat([this.orderData.dropOffLon, this.orderData.dropOffLat])
              .addTo(this.map);

          if (this.orderData.action && this.orderData.action == "rejected")
            this.orderStatus = "REJECTED";
          else if (!this.orderData.status || this.orderData.status == "pending")
            this.orderStatus = "PENDING";
          else if (this.orderData.status == "enroute")
            this.orderStatus = "EN-ROUTE";
          else if (this.orderData.status == "onsite")
            this.orderStatus = "ON-SITE";
          else if (this.orderData.status == "towdestination")
            this.orderStatus = "TOW DESTINATION";
          else if (this.orderData.status == "cancelled")
            this.orderStatus = "CANCELED";
          else this.orderStatus = this.orderData.status.toUpperCase();


          switch (this.orderData.status) {
            case "dispatched":
              this.orderData.background = "#07de8f";
              this.orderData.testCol = "#fff";
              break;
            case "onsite":
              this.orderData.background = "#ef18ef";
              this.orderData.testCol = "#fff";
              break;
            case "enroute":
              this.orderData.background = "#ff2626";
              this.orderData.testCol = "#fff";
              break;
            case "towdestination":
              this.orderData.background = "#ffa217";
              this.orderData.testCol = "#fff";
              break;
            case "towing":
              this.orderData.background = "#0454ce";
              this.orderData.testCol = "#fff";
              break;
            default:
              this.orderData.testCol = "#000";
              break;
          }

          if (this.mapDefault.lat && this.mapDefault.lng)
            this.map.setCenter([this.mapDefault.lng, this.mapDefault.lat]);
          //fetch driver location after order data load
          this.getDriverLocation();
          this.calculateETA();
          this.showRoutes();
        }
      })
      .catch((err) => {
        this.router.navigateByUrl("/not-found");
      });
  }

  getDriverLocation() {
    this.OrdersService.getDriverLocationByOrderId(this.orderId)
      .then((data) => {
        let result = JSON.parse(JSON.stringify(data));
        this.driver = result.data;
        this.mapDefault = this.driver;

        let driverImage = document.createElement("img");
        driverImage.src = this.getTruckImgUrl(this.orderData.status);
        driverImage.style.height = "30px";
        driverImage.style.width = "14px";

        if (this.driverMarker) this.driverMarker.remove();
        if (this.driverPopUP) this.driverPopUP.remove();
        this.driverMarker = new mapboxgl.Marker({
          element: driverImage,
        });
        this.driverPopUP = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          anchor: "top",
          offset: { top: [0, 15] }
        });

        if (this.driver.lng && this.driver.lat) {
          this.driverMarker.setLngLat([this.driver.lng, this.driver.lat]);
          this.driverMarker.setRotation(this.driver.bearing);
          this.driverMarker.addTo(this.map);
          this.driverPopUP.setLngLat([this.driver.lng, this.driver.lat])
            .setHTML(
              "<div style='text-align: center;padding:2px;background:" +
              this.orderData.background +
              ";color:" + this.orderData.testCol + "'>" +
              this.orderData.driverInfo.name +
              " " +
              this.orderData.truckInfo.name +
              "</div>"
            ).addTo(this.map);
          this.showRoutes();
        }

        this.calculateETA();
      })
      .catch((err) => {
        console.log(err)
        //test - commet
        // this.toastr.info(err.error.error);
      });
  }

  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          this.mapDefault = {
            lng: resp.coords.longitude,
            lat: resp.coords.latitude,
          };
        },
        (err) => {
          this.toastr.error("Your Browser Is Not Supported");
          reject(err);
        }
      );
    });
  }

  calculateETA() {
    if (
      this.orderData &&
      (this.orderData.status != "completed")
    ) {
      if (
        this.orderData.pickUpLat &&
        this.orderData.pickUpLon &&
        this.driver.lat &&
        this.driver.lng
      ) {
        let distance = this.MapBoxService.getGeoJson(
          this.orderData.dropOffLat ? this.orderData.dropOffLat : this.orderData.pickUpLat,
          this.orderData.dropOffLon ? this.orderData.dropOffLon : this.orderData.pickUpLon,
          this.driver.lat,
          this.driver.lng
        )

        if (this.orderData.dropOffLat && this.orderData.dropOffLon &&
          (["pending", "enroute", "dispatched"].includes(this.orderData.status))) {
          distance = this.MapBoxService.getGeoJson(
            this.driver.lat,
            this.driver.lng,
            this.orderData.pickUpLat,
            this.orderData.pickUpLon,
            this.orderData.dropOffLat,
            this.orderData.dropOffLon
          )
        }

        distance
          .then((response) => {
            let result = JSON.parse(JSON.stringify(response));
            console.log('>>>', result);
            let data = result.data;
            if (
              data &&
              data.routes &&
              data.routes[0].duration
            ) {
              this.orderData.etaMins = data.routes[0].duration;
              var estTime = "";
              let convertedTime =
                this.MapBoxService.timeConvert(this.orderData.etaMins).split(" ");

              let checkMin = convertedTime[0].split(":");
              if (checkMin[1]) {
                estTime =
                  Number(checkMin[0]).toString() +
                  " hr " +
                  Number(checkMin[1]).toString() +
                  " min ";
              }
              if (!checkMin[1]) {
                estTime = Number(checkMin[0]).toString() + " min";
              }

              this.orderData.etaMins = estTime;
            }
          })
          .catch((err) => {
            console.log("Error in ETA Calculate");
            console.log(err.error.error);
          });
      }
    }
    // if (
    //   this.orderData &&
    //   (this.orderData.status == "completed")
    // ) {
    //   let locationlat = this.orderData.dropOffLat?this.orderData.dropOffLat:this.orderData.pickUpLat;
    //   let locationlon = this.orderData.dropOffLon?this.orderData.dropOffLon:this.orderData.pickUpLon;
    //   if (
    //     this.orderData.Provider.lat &&
    //     this.orderData.Provider.lng &&
    //     locationlat &&
    //     locationlon
    //   ) {
    //     this.GoogleApiService.getDistance(
    //       this.orderData.Provider.lat,
    //       this.orderData.Provider.lng,
    //       locationlat,
    //       locationlon
    //     )
    //       .then((response) => {
    //         let result = JSON.parse(JSON.stringify(response));
    //         let data = result.data.rows;
    //         if (
    //           data &&
    //           data.length &&
    //           data[0].elements &&
    //           data[0].elements[0].duration
    //         ) {
    //           this.orderData.etaMins = data[0].elements[0].duration.text;
    //         }
    //       })
    //       .catch((err) => {
    //         this.toastr.error(err.error.error);
    //       });
    //     }
    // }
  }

  getTruckImgUrl(status) {
    let img;
    switch (status) {
      case "dispatched":
        img = "./assets/flat-icons/dispatched.png";
        break;
      case "onsite":
        img = "./assets/flat-icons/on-site.png";
        break;
      case "enroute":
        img = "./assets/flat-icons/en-route.png";
        break;
      case "towdestination":
        img = "./assets/flat-icons/tow-destination.png";
        break;
      case "towing":
        img = "./assets/flat-icons/towing.png";
        break;
      default:
        img = "./assets/flat-icons/truck.png";
        break;
    }
    return img;
  }


  showRoutes(fitBounds = true) {
    console.log("Show routes");

    const driver = this.driver;
    console.log("driver", driver);
    const data = this.orderData;
    console.log("data", data);

    this.pointAMarker.remove();
    this.pointBMarker.remove();

    console.log("2", data);
    console.log("3", driver);
    setTimeout(() => {
      let getGeoJson = null;

      if (driver.lat && driver.lng && data.pickUpLat && data.pickUpLon) {
        if (
          ["pending", "enroute", "dispatched", "onsite"].includes(data.status)
        ) {
          console.log("Condition 1");
          if (data.status == "onsite") {
            if (data.dropOffLat && data.dropOffLon) {
              getGeoJson = this.MapBoxService.getGeoJson(
                driver ? driver.lat : data.truck.lat,
                driver ? driver.lng : data.truck.lng,
                data.dropOffLat,
                data.dropOffLon
              );
            }
            else {
              this.map.setZoom(14);
              this.map.setCenter({ lng: driver.lng, lat: driver.lat });
            }
          } else {
            getGeoJson = this.MapBoxService.getGeoJson(
              driver ? driver.lat : data.truck.lat,
              driver ? driver.lng : data.truck.lng,
              data.pickUpLat,
              data.pickUpLon,
              data.dropOffLat,
              data.dropOffLon
            );
          }
          if (data.pickUpLat && data.pickUpLon) {
            this.pointAMarker.setLngLat([data.pickUpLon, data.pickUpLat]);
            this.pointAMarker.addTo(this.map);
          }

          if (data.dropOffLat && data.dropOffLon) {
            this.pointBMarker.setLngLat([data.dropOffLon, data.dropOffLat]);
            this.pointBMarker.addTo(this.map);
          }
          // if((onSite == "on site")&&this.pointAMarker)this.pointAMarker.remove();
        } else if (
          ["towing", "towdestination"].includes(data.status) &&
          data.dropOffLat &&
          data.dropOffLon
        ) {
          this.pointBMarker.setLngLat([data.dropOffLon, data.dropOffLat]);
          this.pointBMarker.addTo(this.map);
          if (this.pointAMarker) this.pointAMarker.remove();

          console.log("Condition 2");

          getGeoJson = this.MapBoxService.getGeoJson(
            driver.lat,
            driver.lng,
            data.dropOffLat,
            data.dropOffLon
          );
        } else if (
          ["towing", "towdestination"].includes(data.status) &&
          !data.dropOffLat &&
          !data.dropOffLon
        ) {
          this.map.setZoom(14);
          this.map.setCenter({ lng: driver.lng, lat: driver.lat });
        }
      } else if (
        data.pickUpLat &&
        data.pickUpLon &&
        data.dropOffLat &&
        data.dropOffLon &&
        !driver.lat &&
        !driver.lng
      ) {
        console.log("Condition 3");
        getGeoJson = this.MapBoxService.getGeoJson(
          data.pickUpLat,
          data.pickUpLon,
          data.dropOffLat,
          data.dropOffLon
        );

        if (data.status == 'completed') {
          getGeoJson = this.MapBoxService.getGeoJson(
            data.Provider.lat,
            data.Provider.lng,
            data.pickUpLat,
            data.pickUpLon,
            data.dropOffLat,
            data.dropOffLon
          );
          this.providerMarker.setLngLat([data.Provider.lng, data.Provider.lat]);
          this.providerMarker.addTo(this.map);
        }

        this.pointAMarker.setLngLat([data.pickUpLon, data.pickUpLat]);
        this.pointAMarker.addTo(this.map);

        this.pointBMarker.setLngLat([data.dropOffLon, data.dropOffLat]);
        this.pointBMarker.addTo(this.map);
      } else if (
        data.pickUpLat &&
        data.pickUpLon &&
        data.status == 'completed' &&
        !data.dropOffLat &&
        !data.dropOffLon &&
        !driver.lat &&
        !driver.lng
      ) {
        getGeoJson = this.MapBoxService.getGeoJson(
          data.Provider.lat,
          data.Provider.lng,
          data.pickUpLat,
          data.pickUpLon,
        );
        this.pointAMarker.setLngLat([data.pickUpLon, data.pickUpLat]);
        this.pointAMarker.addTo(this.map);
        this.providerMarker.setLngLat([data.Provider.lng, data.Provider.lat]);
        this.providerMarker.addTo(this.map);
      } else {
        if (data.pickUpLat && data.pickUpLon) {
          this.pointAMarker.setLngLat([data.pickUpLon, data.pickUpLat]);
          this.pointAMarker.addTo(this.map);
          this.pointBMarker.remove();
        }

        if (data.dropOffLat && data.dropOffLon) {
          this.pointBMarker.setLngLat([data.dropOffLon, data.dropOffLat]);
          this.pointBMarker.addTo(this.map);
          this.pointAMarker.remove();
        }
      }

      if (getGeoJson)
        getGeoJson
          .then((result: any) => {
            if (result.data.routes && result.data.routes.length) {
              let coords = [];
              let waypoints = result.data.routes[0].legs[0].steps;

              if (result.data.routes[0].legs.length > 1)
                waypoints = waypoints.concat(
                  result.data.routes[0].legs[1].steps
                );

              waypoints.map((x) => {
                x.intersections.map((i) => {
                  coords.push(i.location);
                });
              });

              this.addRoute(coords);
              const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);

              if (fitBounds) {
                for (const coord of coords) {
                  bounds.extend(coord);
                }
                this.map.fitBounds(bounds, {
                  padding: { top: 20, bottom: 20, left: 70, right: 70 },
                });
              }
              // this.map.fitBounds(
              //   [
              //     [this.orderData.pickUpLon, this.orderData.pickUpLat],
              //     [this.orderData.dropOffLon, this.orderData.dropOffLat],
              //   ],
              //   {
              //     padding: 40,
              //   }
              // );

              let durationAtB = result.data.routes[0].legs[1]
                ? result.data.routes[0].legs[1].duration
                : result.data.routes[0].legs[0].duration;
              var estTime = "";
              let convertedTime =
                this.MapBoxService.timeConvert(durationAtB).split(" ");

              let checkMin = convertedTime[0].split(":");
              if (checkMin[1]) {
                estTime =
                  Number(checkMin[0]).toString() +
                  " hr " +
                  Number(checkMin[1]).toString() +
                  " min";
              }
              if (!checkMin[1]) {
                estTime = Number(checkMin[0]).toString() + " min";
              }

              if (data.dropOffAddress) {
                console.log(">>>>>>>>>>>", result.data.routes);
                data.dropOffAddressShow = data.dropOffAddress.replace(
                  /USA/g,
                  ""
                );

                this.popup
                  .setLngLat(coords[coords.length - 1])
                  .setHTML(
                    `
                    <div style="font-size: 10px;text-align: left;height: 100%;font-family: system-ui; display: flex;">
                        <div style="display: flex; align-items: center; justify-content: center;width: auto;padding: 4px 1px;float: left;background: #000;color: #fff; text-align: center; line-height:15px;">
                        ` +
                    `${estTime}` +
                    `</div>
                        <div style="margin:0;margin: 0px 0px;float: right;padding: 2px 9px;">` +
                    data.dropOffAddressShow +
                    `</div></div>
                    `
                  )
                  .addTo(this.map);
              }
              if (!data.dropOffAddress && this.popup) this.popup.remove();
              if (["enroute", "dispatched", "completed"].includes(data.status)) {
                var estTime2 = "";
                let convertedTime2 = this.MapBoxService.timeConvert(
                  result.data.routes[0].legs[0].duration
                ).split(" ");
                let checkMin2 = convertedTime2[0].split(":");
                if (checkMin2[1]) {
                  estTime2 =
                    Number(checkMin2[0]).toString() +
                    " hr " +
                    Number(checkMin2[1]).toString() +
                    " min";
                }
                if (!checkMin2[1]) {
                  estTime2 = Number(checkMin2[0]).toString() + " min";
                }
                if (data.pickUpAddress) {
                  data.pickUpAddressShow = data.pickUpAddress.replace(
                    /USA/g,
                    ""
                  );
                  this.popupSec
                    .setLngLat([data.pickUpLon, data.pickUpLat])
                    .setHTML(
                      `
                            <div style="font-size: 10px;text-align: left;height: 100%;font-family: system-ui; display: flex;">
                                <div style="display: flex; align-items: center; justify-content: center; width: auto;padding: 4px 1px;float: left;background: #000;color: #fff; text-align: center; line-height:15px;">
                                ` +
                      `${estTime2}` +
                      `</div>
                                <div style="margin:0;margin: 0px 0px;float: right;padding: 2px 9px;">` +
                      data.pickUpAddressShow +
                      `</div></div>
                            `
                    )
                    .addTo(this.map);
                }

                if (data.status == "completed") {
                  let convertedTimeTotal = this.MapBoxService.timeConvert(
                    result.data.routes[0].duration
                  ).split(" ");
                  let checkMinTotal = convertedTimeTotal[0].split(":");
                  if (checkMinTotal[1]) {
                    this.orderData.etaMins =
                      Number(checkMinTotal[0]).toString() +
                      " h " +
                      Number(checkMinTotal[1]).toString() +
                      " mins";
                  }
                  if (!checkMinTotal[1]) {
                    this.orderData.etaMins = Number(checkMinTotal[0]).toString() + " mins";
                  }
                }
                // if (isFlyto) {
                //   this.map.setZoom(12);
                //   const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);
                //
                //   for (const coord of coords) {
                //     bounds.extend(coord);
                //   }
                //   var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
                //   // if(isChrome){
                //   //   var mXzoom = 12;
                //   //   if(result.data.routes[0].duration<3000) mXzoom=14;
                //   //   this.map.fitBounds(bounds, {
                //   //     padding: { top: 20, bottom: 200, left: 20, right: 20 },
                //   //     maxZoom: mXzoom,
                //   //   });
                //   // }
                //   // else{
                //     // this.map.fitBounds(bounds, {
                //     //   padding: { top: 20, bottom: 20, left: 20, right: 20 },
                //     // });
                //   // }
                // }

              } else {
                this.popupSec.remove();
              }
            }
          })
          .catch((err) => {
            console.log("Error on GEO");
            console.log(err);
          });
    }, 200);
  }

  // adds the route as a layer on the map
  addRoute(coords) {
    this.removeRoute();
    this.map.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coords,
        },
      },
    });
    this.map.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#26B4FF",
        "line-width": 8,
      },
    });
  }

  // remove the layer if it exists
  removeRoute() {
    if (this.map.getSource("route")) {
      this.map.removeLayer("route");
      this.map.removeSource("route");
    } else {
      return;
    }
  }
}
