import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ValidateService } from "src/app/services/phone-validate/validate.service";

import { SitesService } from "../../services/sites/sites.service";
import { WaitService } from "../../services/wait/wait.service";

import { CreateSiteComponent } from "../../views/modals/create-site/create-site.component";
import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";

@Component({
  selector: "app-sites",
  templateUrl: "./sites.component.html",
  styleUrls: ["./sites.component.scss"],
})
export class SitesComponent implements OnInit {
  p: number = 1;
  sites: any = [];
  companyName: string = null;
  totalLocations: number = 0;
  loadedData: number = 0;
  filters: any = {
    text: null,
    limit: 100,
    offset: 0,
  };
  rowClicked: number = -1;
  constructor(
    private modalService: NgbModal,
    private SitesService: SitesService,
    private WaitService: WaitService,
    private toastr: ToastrService,
    private ValidateService: ValidateService
  ) {}

  ngOnInit() {
    this.getSites();
  }

  getSites() {
    this.WaitService.start();
    this.SitesService.loadSites(this.filters)
      .then((response: any) => {
        let result = response.data;
        this.sites = result.sites;
        this.loadedData = this.sites.length;
        this.totalLocations = parseInt(result.total.count);
        this.sites.forEach((site) => {
          if (site.phone) {
            site.phone = this.ValidateService.onEdit(site.phone);
          }
          site.tempAddress =
            site.address && site.address.length > 24
              ? site.address.substr(0, 23) + ".."
              : site.address;
          site.tempName =
            site.name && site.address.name > 18
              ? site.name.substr(0, 16) + ".."
              : site.name;
          site.timings = JSON.parse(site.timings);
        });

        if (result && result.company) this.companyName = result.company.name;

        if (!this.loadedData) setTimeout(() => document.getElementById("invisible").removeAttribute("id"), 500);
        this.WaitService.stop();
      })
      .catch((err) => {
        this.WaitService.stop();
        console.log(err);
      });
  }

  openEditModal(data?) {
    const modal = this.modalService.open(CreateSiteComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    if (data) modal.componentInstance.siteData = data;
    if (this.companyName)
      modal.componentInstance.companyName = this.companyName;
    modal.result.then(() => {
      this.getSites();
    });
  }

  deleteSite(data) {
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.title = "Delete Location";
    modal.componentInstance.message =
      "Are you sure you want to delete this location?";

    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.WaitService.start();
      this.SitesService.deleteSite(data.id)
        .then((response) => {
          this.WaitService.stop();
          this.toastr.success("Location Deleted");
          this.p = 1;
          this.getSites();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    });
  }

  getText(e) {
    // this.searchText = e;
    this.filters.text = e;
    this.getSites();
  }

  paginate(offset: number) {
    if (offset > this.totalLocations) offset = this.totalLocations;
    if (offset <= 0) offset = 0;
    this.filters.offset = offset;
    this.getSites();
    //this.userTable.nativeElement.scrollTo(0, 0);
  }
  selectedRow(idx: any) {
    this.rowClicked = idx;
  }
}
