import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { StukTimePickerComponent } from "./stuk-time-picker.component";

@NgModule({
  declarations: [StukTimePickerComponent],
  imports: [CommonModule, FormsModule, NgbModule],
  exports: [StukTimePickerComponent],
  entryComponents: [StukTimePickerComponent],
})
export class StukTimePickerModule {}
