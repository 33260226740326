import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
//bootstrap
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ValidateService } from "src/app/services/phone-validate/validate.service";

//services
import { ProvidersService } from "../../services/providers/providers.service";
import { GoogleApiService } from "../../services/googleApi/google-api.service";
import { WaitService } from "../../services/wait/wait.service";
import { LoginService } from "../../services/login/login.service";
import { UserSettingsService } from "../../services/user-settings/user-settings.service";

//components
import { ProviderLocationComponent } from "../../views/modals/provider-location/provider-location.component";
import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";

import * as momentTz from "moment-timezone";
import { CropImageComponent } from "src/app/views/modals/crop-image/crop-image.component";

@Component({
  selector: "app-provider-setting",
  templateUrl: "./provider-setting.component.html",
  styleUrls: ["./provider-setting.component.scss"],
})
export class ProviderSettingComponent implements OnInit {
  @Input() initSetup = false;
  @Output() afterSubmit = new EventEmitter();

  btnHovered: boolean = false;
  providersData: any = {};
  errors: any = {};

  fileData: File = null;
  previewUrl: any = null;
  imageError: string = null;
  currentUser: any = null;
  phoneNumber: any;
  toOP: any = {
    openHours: "any",
    openOnWeekDays: false,
    openAtWeekDays: null,
    closeAtWeekDays: null,
    openOnSat: false,
    openAtSat: null,
    closeAtSat: null,
    openOnSun: false,
    openAtSun: null,
    closeAtSun: null,
  };
  validate: any = {
    error: {},
  };
  matchedPlaces: any =[];
  // providerAddressSelect:any = "none";
  constructor(
    private modalService: NgbModal,
    private ProvidersService: ProvidersService,
    private GoogleApiService: GoogleApiService,
    private WaitService: WaitService,
    private toastr: ToastrService,
    private LoginService: LoginService,
    private UserSettingsService: UserSettingsService,
    private ValidateService: ValidateService,
    private router: Router
  ) {
    this.currentUser = this.LoginService.getCurrentUser();
    if (this.currentUser.role != 2)
      this.router.navigateByUrl("/dashboard/settings/sites");
  }

  ngOnInit() {
    this.getData();
    this.getTimings();
  }

  changeImageList(event) {
    this.imageError = null;
    // let me = this;
    let file = event.target.files[0];

    var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

    if (parseFloat(sizeInMB) > 5) {
      this.imageError = "Image uploaded is greater than 5mb.";
      this.toastr.error(this.imageError);
      return;
    }
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function () {
    //   me.providersData.imageBase64 = reader.result;
    // };

    const modal = this.modalService.open(CropImageComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.imgSrc = event;
    modal.result.then((res) => {
      if (res) {
        this.providersData.imageBase64 = res;

        setTimeout(() => {
          this.WaitService.start();
          this.ProvidersService.uploadImage({ image: this.providersData.imageBase64 })
            .then(() => {
              this.WaitService.stop();
              this.toastr.success("Image Uploaded");
              this.getData();
            })
            .catch((err) => {
              this.toastr.error(err.error.error);
              this.WaitService.stop();
            });
        }, 1000);
      }
    });
  }

  deleteImage() {
    // let self = this;
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.message =
      "Are you sure you want to delete this image?";

    modal.componentInstance.title = "Delete Image";

    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.WaitService.start();
      let img = this.providersData.image.split("/");
      this.ProvidersService.deleteImage(
        { imageUrl: img[img.length - 1] },
        this.providersData.id
      )
        .then((res) => {
          this.providersData.image = undefined;
          this.WaitService.stop();
          this.toastr.success("Image Removed");
          this.getData();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error("Something Went Wrong");
        });
    });
  }

  getData() {
    this.WaitService.start();
    this.ProvidersService.getProviderInfo()
      .then((response) => {
        let result = JSON.parse(JSON.stringify(response));
        if (result.data) this.providersData = result.data;
        if (this.providersData.phone) {
          this.providersData.phone = this.ValidateService.onEdit(this.providersData.phone);
          this.phoneNumber = this.providersData.phone;
        }

        this.updateIsBothSwitch();

        let imgElem = document.querySelector(".profile-image img") as HTMLImageElement;
        if (this.providersData.image) imgElem.src = this.providersData.image;
        else if (this.providersData.imageBase64) imgElem.src = this.providersData.imageBase64;
        else imgElem.src = 'https://stuklive.s3.us-east-2.amazonaws.com/f_1602852394231.png';

        this.WaitService.stop();
        document.getElementById("invisible").style.visibility = "visible";
        // imgElem.alt = "Your Image";
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  openMapModal() {
    const modal = this.modalService.open(ProviderLocationComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.lat = this.providersData.lat
      ? parseFloat(this.providersData.lat)
      : null;
    modal.componentInstance.lng = this.providersData.lng
      ? parseFloat(this.providersData.lng)
      : null;

    modal.componentInstance.formattedAddress = this.providersData.addressLineOne;

    modal.componentInstance.newLocation.subscribe((data) => {
      if(!data) return;
      this.providersData.zipCode = data.zip;
      this.providersData.addressLineOne = data.addressLineOne;
      this.providersData.city = data.city;
      this.providersData.state = data.state;
      this.providersData.country = data.country;
      this.getData();
    });
  }

  fileUpload(event) {
    this.fileData = <File>event.target.files[0];
    this.preview();
  }

  hideSuggesstion() {
    setTimeout(() => {
      if (this.matchedPlaces && this.matchedPlaces.length){        
        this.selectCompanyAdress(this.matchedPlaces[0]);}
    }, 1000);
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  getTimings() {
    this.WaitService.start();
    this.UserSettingsService.getOperationTimings()
      .then((res) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        if (result && result.data) this.toOP = result.data;
      })
      .catch(() => {
        this.WaitService.stop();
      });
  }

  save() {
    // if(this.providerAddressSelect == 'started') return;
    if (this.providersData.addressLineOne && (!this.providersData.lat || !this.providersData.lng)) {
      this.errors.address = true;
      return;
    }

    // if (this.providersData.phone) {
    //   this.validate.error.phone = this.ValidateService.isValid(
    //     this.providersData.phone
    //   );
    //   if (this.validate.error.phone) return;
    //   this.providersData.phone = this.ValidateService.format(this.providersData.phone);
    // }

    if (this.phoneNumber) {
      this.validate.error.phone = this.ValidateService.isValid(
        this.phoneNumber
      );
      if (this.validate.error.phone) return;
      this.providersData.phone = this.ValidateService.format(this.phoneNumber);
    }

    this.WaitService.start();
    // let address =
    //   this.providersData.addressLineOne +
    //   " " +
    //   this.providersData.state +
    //   " " +
    //   this.providersData.country +
    //   " " +
    //   this.providersData.zipCode;
    //
    // let tempAddress = address.split(" ").join("+");
    // this.GoogleApiService.getCoordinates(tempAddress)
    //   .then((result) => {
    //     let data = JSON.parse(JSON.stringify(result));
    //     let location = data.data.results[0].geometry.location;
    //
    //     this.providersData.lat = location.lat;
    //     this.providersData.lng = location.lng;
    //
    //     this.providersData.timezone = momentTz.tz.guess();
    //     return this.ProvidersService.saveProviderInfo(this.providersData);
    //   })
      this.providersData.timezone = momentTz.tz.guess();
      return this.ProvidersService.saveProviderInfo(this.providersData)
      .then((response) => {
        setTimeout(() => this.getData(), 10);
        this.WaitService.stop();
        this.WaitService.start();
        return this.UserSettingsService.setOperationTimings(this.toOP);
      })
      .then((res: any) => {
        this.WaitService.stop();
        this.toastr.success(res.message);
      })
      .then(() => {
        if (this.initSetup) this.afterSubmit.emit(true);
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error("Your Address Is Not Correct");
      });
  }

  searchPlace(){
    // this.providerAddressSelect = 'none';
    this.providersData.lat = null;
    this.providersData.lng = null;
    this.matchedPlaces = [];

    setTimeout(() => {
      this.GoogleApiService.autoCompleteAddress(
        this.providersData.addressLineOne,
        null,
        null,
        true
      )
        .then((response) => {
          let result = JSON.parse(JSON.stringify(response));
          // this.providerAddressSelect = 'started';
          this.matchedPlaces = result.data.predictions;
        })
        .catch((err) => {
          console.log(err);
        });
      },500);
  }

  searchfocusOut() {
    setTimeout(() => {
      if (this.matchedPlaces && this.matchedPlaces.length) {
        this.selectCompanyAdress(this.matchedPlaces[0]);
        // this.matchedPlaces = [];
      } else if (!this.providersData.addressLineOne) {
        this.providersData.lat = null;
        this.providersData.lat = null;
      }
    }, 300);
  }

  selectCompanyAdress(place){
    this.providersData.addressLineOne = place.description;
    this.matchedPlaces = [];

    this.GoogleApiService.getPlceDetailById(place.place_id)
    .then((response) => {
      let result = JSON.parse(JSON.stringify(response));
      result = result.data.result;
      // this.providerAddressSelect = 'selected';
      let bounds = result.geometry.location;
      this.providersData.lat = bounds.lat;
      this.providersData.lng = bounds.lng;
      this.errors.address = false;
    });
  }

  onToggle(e) {
    this.providersData.isImpoundLot = e;
    this.providersData.isRepairShop = e;
  }

  updateIsBothSwitch() {
    this.providersData.isBoth =
      this.providersData.isImpoundLot && this.providersData.isRepairShop;
  }

  onPhoneInput() {
    // this.providersData.phone = this.ValidateService.onMobileInput(
    //   this.providersData.phone
    // );

    this.phoneNumber = this.ValidateService.onMobileInput(
      this.phoneNumber
    );
  }
}
