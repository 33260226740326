import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProviderService {
  constructor(private httpClient: HttpClient) { }

  createProvider(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/super_admin/provider", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateProvider(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/super_admin/provider", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getProviders(offset, limit, text, sortBy = null) {
    let param = {
      offset: offset ? offset : "",
      limit: limit ? limit : "",
      text: text && text.length ? text : "",
    };

    if (sortBy) param["sortBy"] = sortBy;

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/super_admin/provider", {
          params: param,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAllProviders() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(environment.baseURL + "/api/super_admin/provider/getAllProviders")
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  logInAsProvider(id) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/super_admin/provider/loginAs", {
          id: id,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getProviderBillingMethod(providerId: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          environment.baseURL +
          "/api/super_admin/provider/billing_method/" +
          providerId
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getProviderLatestBilling(providerId: any, limit, offset, from, to) {
    let params = new HttpParams();
    if (providerId) params = params.set("id", providerId);
    if (offset) params = params.set("offset", offset);
    if (limit) params = params.set("limit", limit);
    if (to) params = params.set("to", to);
    if (from) params = params.set("from", from);

    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          environment.baseURL +
          "/api/super_admin/provider/latest_billing/" + providerId + "/" + limit + "/" + offset +  "/" + to + "/" + from)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getProviderLatestEarning(providerId: number, limit, offset, from?, to?) {
    let params = new HttpParams();
    if (offset) params = params.set("offset", offset);
    if (limit) params = params.set("limit", limit);
    if (to) params = params.set("to", to);
    if (from) params = params.set("from", from);
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          environment.baseURL +
          "/api/super_admin/provider/latest_earning/" +
          providerId, { params }
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateProviderEarningPaymentStatus(data) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(environment.baseURL + "/api/super_admin/provider/update_payment", data)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  sendBillingInvoice(providerId: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(environment.baseURL + "/api/super_admin/provider/send_invoice", {
          providerId: providerId,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteProvider(providerId: number) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(environment.baseURL + "/api/super_admin/provider/" + providerId)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
