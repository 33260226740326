import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { LoginService } from "../../services/login/login.service";

import { fadeInAnimation } from "../../animations";

@Component({
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" },
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.scss"],
})
export class ConfirmEmailComponent implements OnInit {
  isLoading: boolean = true;
  isConfirmed: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private LoginService: LoginService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (!params["token"]) this.router.navigate(["/404"]);
      if (params["token"]) {
        this.LoginService.verifyEmail(params["token"])
          .then((res) => {
            this.isLoading = false;
            this.isConfirmed = true;
          })
          .catch((err) => {
            this.isLoading = false;
          });
      }
    });
  }
}
