import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LightboxModule } from "ngx-lightbox";

import { VideoPlayerModule } from "../../views/modals/video-player/video-player.module";
import { EditInvoiceModule } from "../../views/modals/edit-invoice/edit-invoice.module";

import { DetailViewComponent } from "./detail-view.component";
import { CollectDataComponent } from "../../views/modals/collect-data/collect-data.component";

import { PayCashComponent } from "../../views/modals/pay-cash/pay-cash.component";
import { EditNotesComponent } from "src/app/views/modals/edit-notes/edit-notes.component";

@NgModule({
  declarations: [DetailViewComponent, CollectDataComponent, PayCashComponent,EditNotesComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    LightboxModule,
    VideoPlayerModule,
    EditInvoiceModule,
  ],
  exports: [DetailViewComponent, CollectDataComponent, PayCashComponent,EditNotesComponent],
  entryComponents: [
    DetailViewComponent,
    CollectDataComponent,
    PayCashComponent,
    EditNotesComponent
  ],
})
export class DetailViewModule {}
