import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ProviderSettingsComponent } from "./provider-settings.component";
import { ProviderSettingComponent } from "../components/provider-setting/provider-setting.component";
import { UserDispalyComponent } from "../components/user-dispaly/user-dispaly.component";
import { ServicePriceComponent } from "../components/service-price/service-price.component";
import { AppServicesComponent } from "../components/app-services/app-services.component";

import { SitesComponent } from "./sites/sites.component";
import { TrucksComponent } from "../components/trucks/trucks.component";
import { SalesTaxComponent } from "./sales-tax/sales-tax.component";
import { LinkBankComponent } from "./link-bank/link-bank.component";
import { DriversComponent } from "./drivers/drivers.component";
import { AuthDirectComponent } from './auth-direct/auth-direct.component';
//roles gaurds
import { AdminGuardService } from "../services/adminGuard/admin-guard.service";
import { DispatcherGuardService } from "../services/dispatcherGuard/dispatcher-guard.service";
import { DriverGuardService } from "../services/driverGuard/driver-guard.service";

const routes: Routes = [
  {
    path: "",
    component: ProviderSettingsComponent,
    children: [
      {
        canActivate: [AdminGuardService],
        path: "company",
        component: ProviderSettingComponent,
      },
      {
        canActivate: [DispatcherGuardService],
        path: "services",
        component: ServicePriceComponent,
      },
      {
        canActivate: [AdminGuardService],
        path: "appservices",
        component: AppServicesComponent,
      },
      {
        canActivate: [DispatcherGuardService],
        path: "user",
        component: UserDispalyComponent,
      },
      {
        path: "drivers",
        component: DriversComponent,
      },
      {
        canActivate: [DispatcherGuardService],
        path: "location",
        component: SitesComponent,
      },
      {
        canActivate: [DriverGuardService],
        path: "trucks",
        component: TrucksComponent,
      },

      {
        canActivate: [AdminGuardService],
        path: "tax",
        component: SalesTaxComponent,
      },
      {
        canActivate: [AdminGuardService],
        path: "payment",
        component: LinkBankComponent,
      },
      {
        canActivate: [AdminGuardService],
        path: "autodirect",
        component: AuthDirectComponent,
      },
      {
        canActivate: [DriverGuardService],
        path: "my-notification",
        loadChildren: () =>
          import("./my-notification/my-notification.module").then(
            (m) => m.MyNotificationModule
          ),
      },
      {
        canActivate: [AdminGuardService],
        path: "my-waivers",
        loadChildren: () =>
          import("./waivers/waivers.module").then((m) => m.WaiversModule),
      },
      {
        canActivate: [AdminGuardService],
        path: "dashboard-setup",
        loadChildren: () =>
          import("./dashboard-setup/dashboard-setup.module").then(
            (m) => m.DashboardSetupModule
          ),
      },
      {
        canActivate: [AdminGuardService],
        path: "billing-setup",
        loadChildren: () =>
          import("./billing-method/billing-method.module").then(
            (m) => m.BillingMethodModule
          ),
      },
      {
        canActivate: [AdminGuardService],
        path: "operate",
        loadChildren: () =>
          import("./operation-timing/operation-timing.module").then(
            (m) => m.OperationTimingModule
          ),
      },
      {
        canActivate: [AdminGuardService],
        path: "commission",
        loadChildren: () =>
          import("./commission/commission.module").then(
            (m) => m.CommissionModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProviderSettingsRoutingModule {}
