import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { WaitService } from "../../services/wait/wait.service";
import { InvoicesService } from "../../services/invoices/invoices.service";
import { PaymentService } from "../../services/payment/payment.service";

import { CollectDataComponent } from "../../views/modals/collect-data/collect-data.component";
import { RefundComponent } from "../../views/modals/refund/refund.component";
import { SqFormComponent } from '../../views/modals/sq-form/sq-form.component';

import * as moment from "moment";

import { environment } from "../../../environments/environment";
import { PayCashComponent } from "../../views/modals/pay-cash/pay-cash.component";
import { OrdersService } from "../../services/orders/orders.service";
declare let SpreedlyExpress: any;

@Component({
  selector: "app-order-payments",
  templateUrl: "./order-payments.component.html",
  styleUrls: ["./order-payments.component.scss"],
})
export class OrderPaymentsComponent implements OnInit {
  @Input() currentUser: any = null;
  @Input() orderPayments: any = [];
  @Input() orderId: any = null;
  @Input() invoiceId: any = null;
  @Input() invoiceBalance: any = null;
  @Input() actualTotal: number = 0;
  @Input() type: any = '';

  @Output() onPayments = new EventEmitter();

  newPayments: any = [];
  selectedPaymentType: any = null;
  initialPayment = 0;
  maxRefund = 0;

  constructor(
    private toastr: ToastrService,
    private WaitService: WaitService,
    private InvoicesService: InvoicesService,
    private OrdersService: OrdersService,
    private PaymentService: PaymentService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit() {
    this.formateDate();
    this.initialPayment = this.invoiceBalance;
    this.maxRefund = this.actualTotal - this.invoiceBalance;
    this.type = this.router.url.split('/').filter(part => part !== '')[1];
  }

  removePaymentItem(index) {
    this.newPayments.splice(index, 1);
    this.updatePayments();
  }

  setDate(date, index) {
    this.newPayments[index].date = date;
    this.updatePayments();
  }

  payCash(amount: string = "1", index, memo = null) {
    if (amount != null) {
      let orderDataToSend = { id: this.orderId, invoiceBalance: amount, memo }
      const modal = this.modalService.open(PayCashComponent, {
        backdropClass: "light-blue-backdrop",
        backdrop: "static",
        keyboard: false,
      });
      modal.componentInstance.orderData = orderDataToSend;
      let self = this;
      modal.componentInstance.payCash.subscribe((res) => {
        if (!res.success) return;
        self.updateInvoicePayments();
      });
    }
  }

  chargeAmount(e, paymentData, index) {
    e.preventDefault()
    if (paymentData.paymentMethod == 'Cash' && paymentData.paymentType == 'Payment') {
      this.payCash(paymentData.amount, index, paymentData.memo)
    } else if (paymentData.paymentMethod == 'Credit/Debit card' && paymentData.paymentType == 'Payment') {
      this.chargecard(paymentData.amount, index, paymentData.memo)
    } else {
      this.refundPayment(index, paymentData.memo)
    }
  }

  chargecard(amount: string = "1", index, memo = null) {
    if (amount != null) {
      this.WaitService.start();
      this.PaymentService.getAddedGateWay()
        .then((response) => {
          this.WaitService.stop();
          let result = JSON.parse(JSON.stringify(response));
          let checkForGateway;
          let expDateCheck;
          result.data.forEach((x) => {
            if (x.isActive) {
              checkForGateway = x.gateWayType;
              expDateCheck = x.expiresAt;
            }
          });
          if (!checkForGateway) this.toastr.error("Looks Like You Have Not Enabled Any Payment Gateway, Please Enable It First")
          if (checkForGateway && (checkForGateway != 'square')) this.chargeSpreedleCard(amount, index, memo = null);
          if (checkForGateway && (checkForGateway == 'square')) {
            let orderDataToSend = { id: this.orderId, invoiceBalance: amount }
            var today = moment();
            var expiry = moment(expDateCheck);
            var difference = expiry.diff(today, 'seconds');
            if (difference <= 0) { return this.toastr.error("Authentication Expired") }
            this.chargeSquareCard(orderDataToSend);
          }
          this.InvoicesService.updatePaidTime(this.orderId);
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    }
  }

  chargeSquareCard(orderData: any) {
    const modalPseudo = this.modalService.open(SqFormComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalPseudo.componentInstance.orderData = orderData;
    let self = this;
    modalPseudo.componentInstance.returnData.subscribe((res) => {
      if (res) {
        self.toastr.success("Card Has Been Charged");
        self.updateInvoicePayments();
      }
    });
  }

  chargeSpreedleCard(amount: string = "1", index, memo = null) {
    const chargeAmount = parseFloat(amount);
    if (chargeAmount <= 0) {
      this.toastr.error(
        "Please Enter A Valid Amount To Charge Credit/Debit Card"
      );
      return;
    }
    const modal = this.modalService.open(CollectDataComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.customerData.subscribe((res) => {
      if (!res.success) return;

      this.WaitService.start();
      let serviceName = "Payment";

      SpreedlyExpress.init(environment.SPREEDLY_ENV_KEY, {
        amount: "$" + chargeAmount,
        company_name:
          this.currentUser &&
            this.currentUser.companInfo &&
            this.currentUser.companInfo.companyName
            ? this.currentUser.companInfo.companyName
            : "STUK",
        sidebar_top_description: "Service: " + serviceName,
        sidebar_bottom_description: "Your order total",
        full_name: "",
      });
      setTimeout(() => {
        this.WaitService.stop();
        SpreedlyExpress.openView();
      }, 1500);

      SpreedlyExpress.onPaymentMethod((token, paymentMethod) => {
        let data = {
          orderId: this.orderId,
          token: token,
          amount: chargeAmount,
          customerData: res,
          memo: memo,
        };
        this.WaitService.start();
        this.PaymentService.chargeCard(data)
          .then(() => {
            SpreedlyExpress.unload();
            this.WaitService.stop();
            this.toastr.success("Card Has Been Charged");
            this.newPayments.splice(index, 1);
            this.updateInvoicePayments();
          })
          .catch((err) => {
            SpreedlyExpress.unload();
            this.toastr.error("Something Went Wrong");
            this.WaitService.stop();
          });
      });
    });
  }

  refundPayment(index, memo = null) {
    const modal = this.modalService.open(RefundComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.invoiceId = this.invoiceId;
    modal.componentInstance.orderId = this.orderId;
    modal.componentInstance.memo = memo;
    modal.componentInstance.reason.subscribe((res) => {
      let result = JSON.parse(JSON.stringify(res));

      if (!result.success) return;
      if (result.success) {
        this.toastr.success("Refund Has Been Initiated");
        this.newPayments.splice(index, 1);
        this.updateInvoicePayments();
      }
    });
  }

  addPayment() {
    if (!this.selectedPaymentType) return;

    this.newPayments.push({
      paymentType: this.selectedPaymentType,
      paymentMethod: null,
      memo: null,
      date: moment().format("MM/DD/YYYY"),
      amount: 0.00
      // this.selectedPaymentType == "Payment" && this.invoiceBalance > 0
      //   ? this.invoiceBalance
      //   : 0.00,
    });

    this.updatePayments();
    setTimeout(() => {
      this.selectedPaymentType = null;
    }, 100);
  }

  updateInvoicePayments() {
    this.WaitService.start();
    this.InvoicesService.getInvoiceById(this.invoiceId)
      .then((res) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        if (result.data) {
          this.orderPayments = result.data.payments;
          this.formateDate();
          this.updatePayments();
          this.removePaymentItem(0)
          this.OrdersService.triggerUpdate({orderid: this.orderId, type: this.type ? this.type : "order"});
        }
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  updatePayments() {
    const temp = {
      confirmedPayments: this.orderPayments,
      newPayments: this.newPayments,
    };
    this.onPayments.emit(temp);
    if (temp.newPayments.length)
      temp.newPayments.forEach(x => {
        if (x.amount == 0) {
          x.amount = null;
        }
      })
  }

  formateDate() {
    if (this.orderPayments && this.orderPayments.length)
      this.orderPayments = this.orderPayments.map((x) => {
        x.date = moment(x.date).format("MM/DD/YYYY");
        return x;
      });
  }
}
