import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModalModule } from "ngx-bootstrap/modal";

import { CarouselModule } from "ngx-owl-carousel-o";
// *******************************************************************************
// NgBootstrap

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// *******************************************************************************
// App

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AppService } from "./app.service";
import { LayoutModule } from "./layout/layout.module";
import { FeaturesModule } from "./features/features.module";
// *******************************************************************************
// Pages

import { HomeComponent } from "./home/home.component";

// *******************************************************************************
//
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptorService } from "./services/auth-interceptor/auth-interceptor.service";
import { SocketService } from "./services/socket/socket.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { ProviderSettingsModule } from "./provider-settings/provider-settings.module";
import { NotAuthorizedComponent } from "./not-authorized/not-authorized.component";
import { SqFormComponent } from './views/modals/sq-form/sq-form.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,

    // Pages
    HomeComponent,

    NotAuthorizedComponent,
  ],

  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    // App
    FeaturesModule,
    ProviderSettingsModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    CarouselModule,
  ],
  providers: [
    Title,
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    SocketService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
