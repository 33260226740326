import { Component, OnInit } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { WaitService } from "../../services/wait/wait.service";
import { ProvidersService } from "../../services/providers/providers.service";

import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";

@Component({
  selector: "app-sales-tax",
  templateUrl: "./sales-tax.component.html",
  styleUrls: ["./sales-tax.component.scss"],
})
export class SalesTaxComponent implements OnInit {
  taxData: any = {};
  view: string = "show";
  newTax: any = {};

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private ProvidersService: ProvidersService
  ) {}

  ngOnInit() {
    this.getInfo();
  }

  getInfo() {
    this.WaitService.start();
    this.ProvidersService.getTax()
      .then((response) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));
        if (result.data) this.taxData = result.data;
        this.taxData.taxType = "single";
        this.taxData.tax = result.data.tax ? result.data.tax : 0;
        if(this.taxData.tax==0)this.taxData.tax=null;
        if(this.taxData.storageTax==0)this.taxData.storageTax=null;
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  save() {
    if(!this.taxData.storageTax) this.taxData.storageTax=0
    if(!this.taxData.tax) this.taxData.tax=0;
    if (this.taxData.taxType == "single") this.taxData.multipleTax = [];
    if (this.taxData.taxType == "multiple") this.taxData.tax = null;
    if  ((this.taxData.taxType == "multiple")) this.taxData.storageTax=null;

    console.log(this.taxData,"taxData");

    this.WaitService.start();
    this.ProvidersService.saveTax(this.taxData)
      .then((result) => {
        this.WaitService.stop();
        this.view = "show";
        this.getInfo();
        this.toastr.success("Changes Saved");
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  saveTaxItem() {
    if (this.newTax.index != null) {
      this.taxData.multipleTax[this.newTax.index] = {
        taxName: this.newTax.taxName,
        value: this.newTax.value,
      };
    } else {
      this.taxData.multipleTax.push({
        taxName: this.newTax.taxName,
        value: this.newTax.value,
      });
    }
    this.save();
  }

  editTaxItem(index) {
    if (index != null) {
      this.newTax = this.taxData.multipleTax[index];
      this.newTax.index = index;
    } else {
      this.newTax = { taxName: "", value: null, index: null };
    }
    this.view = "edit";
  }

  focusTax(taxData){
    // if(taxData.tax==0)taxData.tax=null;
    // if(taxData.storageTax==0)taxData.storageTax=null;
  }

  deleteTaxItem() {
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = "Delete Tax Rate";
    modal.componentInstance.message =
      "Are you sure you want to delete this Tax Rate?";
    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.taxData.multipleTax.splice(this.newTax.index, 1);
      this.save();
    });
  }
}
