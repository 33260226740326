import { Component, OnInit, Input } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Lightbox } from "ngx-lightbox";

import { WaitService } from "../../services/wait/wait.service";
import { OrdersService } from "../../services/orders/orders.service";
import { LoginService } from "../../services/login/login.service";
import { PaymentService } from "../../services/payment/payment.service";
import { UserService } from "../../services/user/user.service";
import { ProvidersService } from "../../services/providers/providers.service";
import { MapBoxService } from "../../services/map-box/map-box.service";
import { SocketService } from "../../services/socket/socket.service";
import { InvoicesService } from "src/app/services/invoices/invoices.service";
import { PriceService } from "src/app/services/price/price.service";
import { ClassTypesService } from "src/app/services/classTypes/class-types.service";
import { ValidateService } from "src/app/services/phone-validate/validate.service";

import { AssignDriverComponent } from "../../views/modals/assign-driver/assign-driver.component";
import { CollectDataComponent } from "../../views/modals/collect-data/collect-data.component";
import { VideoPlayerComponent } from "../../views/modals/video-player/video-player.component";
// import { EditInvoiceComponent } from "../../views/modals/edit-invoice/edit-invoice.component";
import { CreateJobComponent } from "../../views/modals/create-job/create-job.component";
import { PayCashComponent } from "../../views/modals/pay-cash/pay-cash.component";
import { SqFormComponent } from "../../views/modals/sq-form/sq-form.component";
import { CloverFormComponent } from "../../views/modals/clover-form/clover-form.component";

import { EditNotesComponent } from "src/app/views/modals/edit-notes/edit-notes.component";

import { environment } from "../../../environments/environment";

import * as mapboxgl from "mapbox-gl";
import * as moment from "moment";
import * as momentz from "moment-timezone";
import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";

declare let SpreedlyExpress: any;

@Component({
  selector: "app-detail-view",
  templateUrl: "./detail-view.component.html",
  styleUrls: ["./detail-view.component.scss"],
})
export class DetailViewComponent implements OnInit {
  @Input() data: any;
  @Input() trucks: any = [];
  @Input() drivers: any = [];
  @Input() classTypes: any = [];
  @Input() issuperadmin: any;

  isLoading: boolean = false;
  // Map Box
  map: mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  pointAMarker: any;
  pointBMarker: any;
  carMarker: any;
  popup: any;
  popupSec: any;
  popupAdmin: any;
  // End Map Box
  AddtionalItemsValue: any = "";
  currentUser: any = null;
  album: any = [];
  eSignAlbum: any = [];
  isNotesIcon: boolean = false;
  mapType: string = "outdoor";
  EtaTime: any = "";
  ServiceTime: any = "";
  estimatedTime: string = "";
  providerInfo: any;
  truckName: any = "";
  isDriverChanged: boolean = false;
  driverName: any = null;
  driverID: any = null;
  truckType: any = null;
  showChargeCard: boolean = false;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private OrdersService: OrdersService,
    private LoginService: LoginService,
    private PaymentService: PaymentService,
    private LightBox: Lightbox,
    private UserService: UserService,
    private ProvidersService: ProvidersService,
    private MapBoxService: MapBoxService,
    private SocketService: SocketService,
    private ValidateService: ValidateService,
    private InvoicesService: InvoicesService,
    private PriceService: PriceService,
    private ClassTypesService: ClassTypesService
  ) { }

  ngOnInit() {
    this.data.showHistory = false;
    this.driverID = this.data.driverId;
    if (this.data.truckInfo && this.data.truckInfo.truckType && this.data.truckInfo.truckType.name && this.data.truckInfo.truckType.name)
      this.truckType = this.data.truckInfo.truckType.name;
    this.driverName = this.data.driverInfo ? Object.assign(this.data.driverInfo.name) : null;

    if (this.data.callerPhone) {
      this.data.callerPhone = this.ValidateService.onEdit(
        this.data.callerPhone
      );
    }
    //Showing driver status
    if (this.data.orderType == "app")
      this.data.accountInfo = { name: "APP Account" };
    this.drivers.map((x) => {
      if (x.isOnline) {
        x.show = "online";
      } else {
        x.show = "offline";
      }
    });

    this.drivers = this.drivers.sort((a, b) => {
      return b.isOnline - a.isOnline;
    });

    this.data.history = JSON.parse(JSON.stringify(this.data.history))

    this.data.history && this.data.history.length && Array.prototype.map.call(this.data.history, (x) => {
      if (x.title == "Scheduled" || !x.title) {
        let timezone = null;

        switch (this.data.scheduledTimeZoneHistory) {
          case "AKST":
            timezone = "America/Anchorage";
            break;
          case "CST":
            timezone = "America/Chicago";
            break;
          case "EST":
            timezone = "America/New_York";
            break;
          case "HST":
            timezone = "America/Adak";
            break;
          case "MST":
            timezone = "America/Phoenix";
            break;
          case "PST":
            timezone = "America/Los_Angeles";
            break;
        }

        const scheduledDate = moment(this.data.scheduledHistory).format("YYYY-MM-DD");

        const scheduledTime = momentz.tz(scheduledDate + " " + this.data.scheduledTimeHistory, timezone).format("YYYY-MM-DD hh:mm A");
        if (scheduledTime && this.data.scheduledTimeZoneHistory) x.title = "Job Scheduled For " + scheduledTime + " " + this.data.scheduledTimeZoneHistory;
      }
    })
    this.isDriverChanged = this.data.driverId ? true : false;

    this.SocketService.on("jobUpdated", (data) => {
      this.drivers.forEach((driver) => {
        if (driver.id == data.data.driverId) {
          this.data.driverInfo = driver;
        }
      });
    });

    this.SocketService.on("paymentCompleted", (result: any) => {
      setTimeout(() => this.updatePayment(result.data.id), 100);
    });

    /**
    Drivers' live status update via socket
    **/
    this.SocketService.on("driverActive", (result: any) => {
      // const index = this.drivers.findIndex((x) => x.id == result.data.userId);
      // if (index != -1) {
      //   this.drivers[index].isOnline = result.data.isOnline;
      //   if (this.drivers[index].isOnline) {
      //     this.drivers[index].show = "online";
      //   } else {
      //     this.drivers[index].show = "offline";
      //   }
      // }
      // this.drivers = this.drivers.sort((a, b) => {
      //   return b.isOnline - a.isOnline;
      // });
      this.UserService.getAllUsers()
        .then((result) => {
          this.drivers = [];
          let data = JSON.parse(JSON.stringify(result));
          if (data && data.users) {
            data.users = data.users.map((x) => {
              x.roleName =
                x.roleId == "2"
                  ? "Admin"
                  : x.roleId == "3"
                    ? "Driver"
                    : x.roleId == "5"
                      ? "Dispatcher"
                      : "";
              if (x.isOnline) {
                x.show = "online";
              } else {
                x.show = "offline";
              }
              if (x.roleId == "3" || x.roleId == "2") this.drivers.push(x);
            });

            this.drivers = this.drivers.sort((a, b) => {
              return b.isOnline - a.isOnline;
            });
          }
        })
        .catch();
    });
    /** **/

    this.currentUser = this.data.isEditFromSuperAdmin ? this.LoginService.getSuperAdmin() : this.LoginService.getCurrentUser();
    this.showChargeCard = (this.data.isCreatedBySuperAdmin && this.currentUser.role == 1) || (!this.data.isCreatedBySuperAdmin && this.currentUser.role != 1);
    if (this.data.orderImages && this.data.orderImages.length) {
      this.album = this.data.orderImages.map((x) => {
        return {
          src: x.url,
          caption: null,
          thumb: x.url,
          timestamp: x.timestamp,
          imgType: "app",
        };
      });
    }

    // if (this.data.documentUrl && this.data.documentUrl.length) {
    //   let docArray = this.data.documentUrl.map((x) => {
    //     return {
    //       src: x.url,
    //       caption: null,
    //       thumb: x.url,
    //       timestamp: x.timestamp,
    //       imgType: "web",
    //     };
    //   });
    //   this.album = this.album.concat(docArray);
    // }

    if (this.data.orderAdditionalItems) {
      this.data.orderAdditionalItems.forEach((x, i) => {
        this.AddtionalItemsValue =
          this.AddtionalItemsValue + (i == 0 ? "" : ", ") + x.label;
      });
    }

    if (this.data.eSignature && this.data.eSignature.url)
      this.eSignAlbum.push({
        src: this.data.eSignature.url,
        caption: null,
        thumb: this.data.eSignature.url,
        timestamp: this.data.eSignature.timestamp,
      });

    if (this.data.invoiceBalance)
      this.data.invoiceBalance = parseFloat(this.data.invoiceBalance).toFixed(2);
    if (this.data.milesBalance)
      this.data.milesBalance = parseFloat(this.data.milesBalance).toFixed(2);

    if (this.trucks && this.trucks.length && this.data.truckId) {
      this.trucks.forEach((x) => {
        if (x.id == this.data.truckId) {
          this.truckName = x.name;
        }
      });
    }

    // if(this.issuperadmin){
    //   this.getClassTypes(this.data);
    // }
  }

  ngAfterViewInit() {
    let isSAdmin = this.data.isEditFromSuperAdmin ? true : false;
    this.ProvidersService.getProviderInfo(isSAdmin, this.data.providerId)
      .then((result: any) => {
        this.providerInfo = result.data;
        console.log(this.providerInfo);
        this.buildMap();
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
      });
  }

  updatePayment(updatedOrderId: number) {
    if (this.data.id == updatedOrderId) {
      console.log("here");
      this.data.invoiceBalance = 0;
    }
  }

  getClassTypes(data) {
    this.WaitService.start();
    this.ClassTypesService.getClassTypes(true, data.providerId)
      .then((classTypes) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(classTypes));
        this.classTypes = result.data;
        this.classTypes = this.classTypes.filter((data) => {
          if (data.providerClassType.isActive) {
            return data
          }
        })
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  showDetailView() {
    this.data.showHistory = false;
    setTimeout(() => {
      this.buildMap();
    }, 100);
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      accessToken: environment.MAPBOX_TOKEN,
      container: "map_details_" + this.data.id,
      style: this.style,
    });

    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      offset: 15,
    });

    this.popupSec = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      offset: 15,
    });

    this.popupAdmin = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "right",
    });

    let ele1 = document.createElement("img");
    ele1.src = "./assets/flat-icons/map-pin-red.png";
    // ele1.style.height = "40px";
    // ele1.style.width = "34px";

    this.pointAMarker = new mapboxgl.Marker({
      element: ele1,
    });
    let ele2 = document.createElement("img");
    ele2.src = "./assets/flat-icons/map-pin-green.png";
    // ele2.style.height = "40px";
    // ele2.style.width = "34px";

    this.pointBMarker = new mapboxgl.Marker({
      element: ele2,
    });

    let ele3 = document.createElement("img");
    ele3.src = "./assets/flat-icons/build.png";

    let ele4 = document.createElement("img");
    ele4.src = "./assets/flat-icons/truck.png";
    ele4.style.height = "30px";
    ele4.style.width = "14px";

    this.popupAdmin = new mapboxgl.Marker({
      element: ele3,
    });

    this.carMarker = new mapboxgl.Marker({
      element: ele4,
    });

    this.map.setZoom(8);

    if (this.data.pickUpLat && this.data.pickUpLon) {
      this.pointAMarker.setLngLat([this.data.pickUpLon, this.data.pickUpLat]);
      this.pointAMarker.addTo(this.map);
    }

    if (this.data.dropOffLat && this.data.dropOffLon) {
      this.pointBMarker.setLngLat([this.data.dropOffLon, this.data.dropOffLat]);
      this.pointBMarker.addTo(this.map);
    }

    if (this.providerInfo && this.providerInfo.lat && this.providerInfo.lng) {
      this.popupAdmin.setLngLat([this.providerInfo.lng, this.providerInfo.lat]);
      this.popupAdmin.addTo(this.map);
    }

    if (this.data.startLat && this.data.startLon) {
      this.carMarker.setLngLat([this.data.startLon, this.data.startLat]);
      this.carMarker.addTo(this.map);
    }

    if (this.data.pickUpLat && this.data.pickUpLon)
      this.map.setCenter({
        lng: this.data.pickUpLon,
        lat: this.data.pickUpLat,
      });
    else if (this.data.dropOffLat && this.data.dropOffLon)
      this.map.setCenter({
        lng: this.data.dropOffLon,
        lat: this.data.dropOffLat,
      });

    // console.log(">>>>>>here", this.data);
    this.drawPolyline();
  }

  drawPolyline() {
    setTimeout(() => {
      let geojsonHit;
      if (
        this.data.pickUpLat &&
        this.data.pickUpLon &&
        this.data.dropOffLat &&
        this.data.dropOffLon
      ) {
        this.isLoading = true;
        if (
          this.data.startLat &&
          this.data.startLon
        ) {
          geojsonHit = this.MapBoxService.getGeoJson(
            parseFloat(this.data.startLat),
            parseFloat(this.data.startLon),
            this.data.pickUpLat,
            this.data.pickUpLon,
            this.data.dropOffLat,
            this.data.dropOffLon
          );
        } else if (
          this.providerInfo &&
          this.providerInfo.lat &&
          this.providerInfo.lng
        ) {
          geojsonHit = this.MapBoxService.getGeoJson(
            this.providerInfo.lat,
            this.providerInfo.lng,
            this.data.pickUpLat,
            this.data.pickUpLon,
            this.data.dropOffLat,
            this.data.dropOffLon
          );
        } else {
          geojsonHit = this.MapBoxService.getGeoJson(
            this.data.pickUpLat,
            this.data.pickUpLon,
            this.data.dropOffLat,
            this.data.dropOffLon
          );
        }
        geojsonHit
          .then((result: any) => {
            console.log(">>>result.data", result.data);
            if (result.data.routes && result.data.routes.length) {
              let coords = [];
              let waypoints = result.data.routes[0].legs[0].steps;

              if (result.data.routes[0].legs.length > 1)
                waypoints = waypoints.concat(
                  result.data.routes[0].legs[1].steps
                );

              waypoints.map((x) => {
                x.intersections.map((i) => {
                  coords.push(i.location);
                });
              });

              this.map.addSource("route", {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: coords,
                  },
                },
              });

              this.map.addLayer({
                id: "route",
                type: "line",
                source: "route",
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": "#26B4FF",
                  "line-width": 8,
                },
              });
              const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);

              for (const coord of coords) {
                bounds.extend(coord);
              }

              if (result.data.routes[0].duration <= 60) {
                this.map.fitBounds(bounds, {
                  padding: { top: 60, bottom: 40, left: 40, right: 40 },
                  zoom: 16
                });
              }
              else if (result.data.routes[0].duration > 60 && result.data.routes[0].duration <= 150) {
                this.map.fitBounds(bounds, {
                  padding: { top: 60, bottom: 40, left: 40, right: 40 },
                  zoom: 14
                });
              } else {
                this.map.fitBounds(bounds, {
                  padding: { top: 60, bottom: 40, left: 40, right: 40 },
                });
              }
              // this.map.fitBounds(
              //   [
              //     [this.data.pickUpLon, this.data.pickUpLat],
              //     [this.data.dropOffLon, this.data.dropOffLat],
              //   ],
              //   {
              //     padding: 40,
              //   }
              // );

              if (
                (this.data.driverInfo &&
                  this.data.driverInfo.lat &&
                  this.data.driverInfo.lng) ||
                (this.providerInfo &&
                  this.providerInfo.lat &&
                  this.providerInfo.lng)
              ) {
                var estTime2 = "";
                let convertedTime2 = this.MapBoxService.timeConvert(
                  result.data.routes[0].legs[0].duration
                ).split(" ");
                let checkMin2 = convertedTime2[0].split(":");
                if (checkMin2[1]) {
                  estTime2 =
                    Number(checkMin2[0]).toString() +
                    " hr " +
                    Number(checkMin2[1]).toString() +
                    " min";
                }
                if (!checkMin2[1]) {
                  estTime2 = Number(checkMin2[0]).toString() + " min";
                }
                this.popupSec
                  .setLngLat([this.data.pickUpLon, this.data.pickUpLat])
                  .setHTML(
                    `<div style='font-size: 16px;
                    text-align: left;
                    height: 100%;
                    font-family: system-ui;'>
                    <p style='margin:0;display:none'><b>Distance: </b>` +
                    (0.000621371192 * result.data.routes[0].distance).toFixed(
                      2
                    ) +
                    ` miles</p>
                    <p style='margin:0; padding: 4px 5px 4px 0px;'><b style="background: #000; color: #fff; padding: 4px 5px; margin-right:5px;">ETA</b> ` +
                    `${estTime2}` +
                    `</p></div>`
                  )
                  .addTo(this.map);
              }

              var estTime = "";
              var durationVal = result.data.routes[0].legs[1]
                ? result.data.routes[0].legs[1].duration
                : result.data.routes[0].duration;
              var convertedTime =
                this.MapBoxService.timeConvert(durationVal).split(" ");

              let checkMin = convertedTime[0].split(":");
              if (checkMin[1]) {
                estTime =
                  Number(checkMin[0]).toString() +
                  " hr " +
                  Number(checkMin[1]).toString() +
                  " min";
                this.EtaTime =
                  Number(checkMin[0]).toString +
                  " hr " +
                  Number(checkMin[1]).toString() +
                  " min";
                this.ServiceTime = moment(new Date())
                  .add(checkMin[0], "h")
                  .add(checkMin[1], "m");
              }
              if (!checkMin[1]) {
                estTime = checkMin[0] + " min";
                this.EtaTime = checkMin[0] + " min";
                this.ServiceTime = moment(new Date()).add(checkMin[0], "m");
              }
              // this.EtaTime = estTime;
              this.popup
                .setLngLat(coords[coords.length - 1])
                .setHTML(
                  `<div style='font-size: 16px;
                text-align: left;
                height: 100%;
                font-family: system-ui;'>
                <p style='margin:0;display:none'><b>Distance: </b>` +
                  (0.000621371192 * result.data.routes[0].distance).toFixed(
                    2
                  ) +
                  ` miles</p>
                <p style='margin:0; padding: 4px 5px 4px 0px;'><b style="background: #000; color: #fff; padding: 4px 5px; margin-right:5px;">ETA</b> ` +
                  `${estTime}` +
                  `</p></div>`
                )
                .addTo(this.map);

              var totalEst = "";
              let totalconvertedTime = this.MapBoxService.fullTime(
                result.data.routes[0].legs[0].duration,
                result.data.routes[0].legs[1]
                  ? result.data.routes[0].legs[1].duration
                  : 0
              ).split(" ");
              let totalcheckMin = totalconvertedTime[0].split(":");
              if (totalcheckMin[1]) {
                totalEst =
                  Number(totalcheckMin[0]).toString() +
                  "h" +
                  ":" +
                  Number(totalcheckMin[1]).toString() +
                  "m";
              }
              if (!totalcheckMin[1]) {
                totalEst = Number(totalcheckMin[0]).toString() + "m";
              }
              console.log(totalEst);
              this.estimatedTime = totalEst;
            }
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          });
      } else if (
        this.data.pickUpLat &&
        this.data.pickUpLon &&
        !this.data.dropOffLat &&
        !this.data.dropOffLon
      ) {
        this.isLoading = true;
        if (
          this.data.startLat &&
          this.data.startLon
        ) {
          geojsonHit = this.MapBoxService.getGeoJson(
            parseFloat(this.data.startLat),
            parseFloat(this.data.startLon),
            this.data.pickUpLat,
            this.data.pickUpLon
          );
        } else if (
          this.providerInfo &&
          this.providerInfo.lng &&
          this.providerInfo.lat
        ) {
          geojsonHit = this.MapBoxService.getGeoJson(
            this.providerInfo.lat,
            this.providerInfo.lng,
            this.data.pickUpLat,
            this.data.pickUpLon
          );
        }
        if (!geojsonHit) return;
        geojsonHit
          .then((result: any) => {
            console.log(">>>result.data", result.data);
            if (result.data.routes && result.data.routes.length) {
              let coords = [];
              let waypoints = result.data.routes[0].legs[0].steps;

              if (result.data.routes[0].legs.length > 1)
                waypoints = waypoints.concat(
                  result.data.routes[0].legs[1].steps
                );

              waypoints.map((x) => {
                x.intersections.map((i) => {
                  coords.push(i.location);
                });
              });

              this.map.addSource("route", {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: coords,
                  },
                },
              });

              this.map.addLayer({
                id: "route",
                type: "line",
                source: "route",
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": "#26B4FF",
                  "line-width": 8,
                },
              });

              const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);

              for (const coord of coords) {
                bounds.extend(coord);
              }

              this.map.fitBounds(bounds, {
                padding: { top: 60, bottom: 40, left: 40, right: 40 },
              });

              var estTime2 = "";
              let convertedTime2 = this.MapBoxService.timeConvert(
                result.data.routes[0].duration
              ).split(" ");
              let checkMin2 = convertedTime2[0].split(":");
              if (checkMin2[1]) {
                estTime2 =
                  Number(checkMin2[0]).toString() +
                  " hr " +
                  Number(checkMin2[1]).toString() +
                  " min";
              }
              if (!checkMin2[1]) {
                estTime2 = Number(checkMin2[0]).toString() + " min";
              }
              this.popupSec
                .setLngLat([this.data.pickUpLon, this.data.pickUpLat])
                .setHTML(
                  `<div style='font-size: 16px;
                    text-align: left;
                    height: 100%;
                    font-family: system-ui;'>
                    <p style='margin:0;display:none'><b>Distance: </b>` +
                  (0.000621371192 * result.data.routes[0].distance).toFixed(
                    2
                  ) +
                  ` miles</p>
                    <p style='margin:0; padding: 4px 5px 4px 0px;'><b style="background: #000; color: #fff; padding: 4px 5px; margin-right:5px;">ETA</b> ` +
                  `${estTime2}` +
                  `</p></div>`
                )
                .addTo(this.map);

              this.estimatedTime = estTime2;
            }
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          });
      }
    }, 2000);
  }

  openDriversModal(orderData) {
    const modal = this.modalService.open(AssignDriverComponent, {
      backdropClass: "light-blue-backdrop",
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.orderData = orderData;
    modal.componentInstance.driverData = this.drivers;
  }

  changeTruck(order, truckId) {
    if (order.isDraft) order.dontcreate = true;
    order.truckId = truckId;
    if (!order.truckId) {
      this.truckName = null;
    }
    if (!truckId) this.truckType = null;

    if (this.trucks && this.trucks.length && truckId) {
      this.trucks.forEach((x) => {
        if (truckId && x.id && x.id == truckId) {
          this.truckName = x.name;
          this.classTypes.forEach((y) => {
            if (y.name == x.truckType.name) {
              order.classTypeId = y.id;
            }
          })
        }
      });
      if (order.orderType != "app") this.changeClassType(order);
    } else {
      order.classTypeId = null;
    }
  }

  changeClassType(order) {
    this.WaitService.start();
    const getClassTypes = this.PriceService.getService(
      order.accountId,
      order.serviceId,
      this.issuperadmin ? true : false,
      this.issuperadmin ? order.providerId : null
    )

    const getClassTypesRateTypes = this.PriceService.getClassTypeRate(
      order.accountId,
      order.serviceId,
      order.rateTypeId,
      order.classTypeId,
      this.issuperadmin ? true : false,
      this.issuperadmin ? order.providerId : null
    )

    Promise.all([getClassTypes, getClassTypesRateTypes])
      .then((response) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));
        let selectedRateType = result[0] && result[0].data && result[0].data.rates.filter(
          (x) => x.rateTypesId == order.rateTypeId
        )[0];
        if (result[1].data && result[1].data.additionalItems && result[1].data.additionalItems.length) {
          order.orderAdditionalItems = result[1].data.additionalItems;
        }
        let mainItems = result[1].data && result[1].data.items ? result[1].data.items : null;
        if (result[1].data) order.orderAdditionalItems = result[1].data.additionalItems
        if (mainItems && mainItems.length) {
          order.orderMainItems = mainItems;
        }

        order.orderMainItems && order.orderMainItems.length && order.orderMainItems.forEach((x) => {
          x.qty = x.type == "quantity" ? parseFloat(x.value) : 1;
          x.price = x.type == "quantity" ? 1 : parseFloat(x.value);
        });

        if (order.orderAdditionalItems) {
          order.orderAdditionalItems.map((x) => {
            x.qty = 1;
            if (!x.price) x.price = x.value
            x.price = x.value;
            x.val = x.value;
          });
        }

      })
      .catch((err) => {
        console.log(err, "error");
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      })
  }

  changeDriver(order, driverId) {
    this.driverID = driverId;

    if (!((this.isDriverChanged && this.driverID) || (!this.isDriverChanged && !this.driverID))) {
      order.isDriverChanged = true;
    }
    if (this.driverID) {
      this.drivers.forEach((driver) => {
        if (driver.id == this.driverID) {
          this.driverName = driver.name;
        }
      });
    }
    if (!this.driverID) {
      this.driverName = null;
    }
  }

  saveDriverTruckInfo(order) {
    order.driverId = this.driverID;
    if (order.status == "pending" && !order.driverId && !order.isDraft) {
      this.toastr.error("Please Select Driver First");
      return
    }
    order.fromJobDetails = true;
    if (
      !order.isDraft &&
      order.driverId == null &&
      order.serviceTime != "scheduled"
    ) {
      order.status = "pending";
    }
    if (
      !order.isDraft &&
      order.serviceTime != "scheduled" &&
      order.status == "pending" &&
      order.driverId
    ) {
      order.status = "dispatched";
    }
    if (order.isDraft) order.dontcreate = true;

    order.driverName = this.driverName;
    order.truckName = this.truckName;
    this.WaitService.start();
    this.OrdersService.editOrder(order)
      .then((res: any) => {
        this.WaitService.stop();
        this.assignTruckToDriver();

        this.OrdersService.getJobById(res.data.id)
          .then((res: any) => {
            let order = res.data[0];

            // if (order.truckId)
            //   this.data.truckInfo.truckType.name = order.truckInfo.truckType.name;
            // else
            //   this.data.truckInfo.truckType.name = null;
          })
          .catch((err) => {
            console.log(err);
          });
        this.toastr.success("Changes Saved");
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  assignTruckToDriver() {
    if (this.data.truckId && this.data.driverId) {
      this.UserService.assigneTruck(
        this.data.driverId,
        this.data.truckId
      ).catch((err) => {
        this.toastr.error(err.error.error);
      });
    }
  }

  uploadDocument(event, orderId) {
    let file = event.target.files[0];
    let documentUrl = null;
    let format = null;

    if (file.type.indexOf("image") > -1) {
      format = "image";
    } else if (file.type.indexOf("video") > -1) {
      format = "video";
    } else {
      format = "document";
    }

    var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

    if (parseFloat(sizeInMB) > 5 && format == "image") {
      this.toastr.error("Max File Size Allowed 5MB");
      return;
    }

    if (format == "image" || format == "video" || format == "document") {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        documentUrl = reader.result;
        console.log(reader, "result");
      };
    }

    // if (format == "video") {
    //   let reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = function () {
    //     documentUrl = reader.result;
    //     console.log(reader, "result");
    //   };
    // }

    // if (format != "document")
    setTimeout(() => {
      this.WaitService.start();
      this.OrdersService.addDocument({
        documentUrl: documentUrl,
        orderId: orderId,
        docName: file.name,
        format: format,
      },
        this.issuperadmin ? true : false,
      )
        .then((response) => {
          let result = JSON.parse(JSON.stringify(response));
          this.WaitService.stop();
          this.toastr.success("Document Added");
          // console.log(">>>>>>>>>>>>>...response",response)
          if (format = "document") this.data.documentUrl = result.data.documentUrl;
          if (format = "image") this.data.orderImages = result.data.orderImages;
          if (format = "video") this.data.videos = result.data.videos;

          // if (this.data.documentUrl && this.data.documentUrl.length) {
          //   this.album = this.data.documentUrl.map((x) => {
          //     return {
          //       src: x.url,
          //       caption: null,
          //       thumb: x.url,
          //       timestamp: x.timestamp,
          //       imgType: "web",
          //     };
          //   });
          // }
          if (this.data.orderImages && this.data.orderImages.length) {
            this.album = this.data.orderImages.map((x) => {
              return {
                src: x.url,
                caption: null,
                thumb: x.url,
                timestamp: x.timestamp,
                imgType: "app",
              };
            });
          }

          let fileInput = <HTMLInputElement>document.getElementsByClassName("input-ctm")[0];
          if (fileInput) fileInput.value = '';
        })
        .catch((err) => {
          console.log(err);
          this.toastr.error(err.error.error);
          this.WaitService.stop();

          let fileInput = <HTMLInputElement>document.getElementsByClassName("input-ctm")[0];
          if (fileInput) fileInput.value = '';
        });
    }, 1200);
  }

  removeDocument(url, index, type) {
    console.log("url", url);
    let dataArr;
    if (type == "image") {
      this.data.orderImages.splice(index, 1);
      this.album.splice(index, 1);
      dataArr = this.data.orderImages;
    }
    if (type == "video") {
      this.data.videos.splice(index, 1);
      dataArr = this.data.videos;
    }

    if (type == "document") {
      this.data.documentUrl.splice(index, 1);
      dataArr = this.data.documentUrl;
    }

    this.WaitService.start();
    this.OrdersService.updateDocument({
      orderId: this.data.id,
      keyUrl: url,
      type: type,
      documentUrl: dataArr,
    },
      this.issuperadmin ? true : false,
    )
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Document Removed");
      })
      .catch((err) => {
        this.toastr.error(err.error.error);
        this.WaitService.stop();
      });
  }

  removeSignature(url) {
    this.WaitService.start();
    this.OrdersService.removeSignature({
      orderId: this.data.id,
      keyUrl: url
    })
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Signature Removed");
        this.data.eSignature = null;
        this.eSignAlbum = [];
      })
      .catch((err) => {
        this.toastr.error(err.error.error);
        this.WaitService.stop();
      });
  }

  chargeCard(orderData: any, index: number) {
    if (this.currentUser.role == 1) {
      this.chargeSquareCard(orderData, true);
    }
    else {
      this.WaitService.start();
      this.PaymentService.getAddedGateWay()
        .then((response) => {
          this.WaitService.stop();
          let result = JSON.parse(JSON.stringify(response));
          let checkForGateway;
          let expDateCheck;
          result.data.forEach((x) => {
            if (x.isActive) {
              checkForGateway = x.gateWayType;
              expDateCheck = x.expiresAt;
            }
          });
          if (!checkForGateway && !orderData.isCreatedBySuperAdmin)
            this.toastr.error(
              "Looks Like You Have Not Enabled Any Payment Gateway, Please Enable It First"
            );

          // if (checkForGateway && checkForGateway != "square" && checkForGateway != "clover")
          //   this.chargeSpreedleCard(orderData);
          if ((checkForGateway && checkForGateway == "square") || (orderData.isCreatedBySuperAdmin) || (orderData.orderType == "app")) {
            var today = moment();
            var expiry = moment(expDateCheck);
            var difference = expiry.diff(today, "seconds");
            if (difference <= 0) {
              return this.toastr.error("Authentication Expired");
            }
            this.chargeSquareCard(orderData);
          }
          if (checkForGateway && checkForGateway == "clover")
            this.chargeCloverCard(orderData);
          this.InvoicesService.updatePaidTime(orderData.id);
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    }
  }

  chargeSquareCard(orderData: any, isEditFromSuperAdmin = false) {
    const modalPseudo = this.modalService.open(SqFormComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    if (isEditFromSuperAdmin) orderData.isEditFromSuperAdmin = true;
    modalPseudo.componentInstance.orderData = orderData;

    modalPseudo.componentInstance.returnData.subscribe((res) => {
      console.log(res);
      if (res) this.data.invoiceBalance = 0.0;
    });
  }

  chargeCloverCard(orderData: any) {
    const modalPseudo = this.modalService.open(CloverFormComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalPseudo.componentInstance.orderData = orderData;
    let self = this;
    modalPseudo.componentInstance.returnData.subscribe((res) => {
      console.log(res);
      if (res) self.data.invoiceBalance = 0.0;
    });
  }

  chargeSpreedleCard(orderData: any) {
    const modal = this.modalService.open(CollectDataComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.customerData.subscribe((res) => {
      if (!res.success) return;

      this.WaitService.start();
      let serviceName =
        orderData.orderType == "app"
          ? orderData.appService.label
          : orderData.service.label;
      SpreedlyExpress.init(environment.SPREEDLY_ENV_KEY, {
        amount: "$" + orderData.invoiceBalance,
        company_name:
          this.currentUser &&
            this.currentUser.companInfo &&
            this.currentUser.companInfo.companyName
            ? this.currentUser.companInfo.companyName
            : "STUK",
        sidebar_top_description: "Service: " + serviceName,
        sidebar_bottom_description: "Your order total",
        full_name: "",
      });
      setTimeout(() => {
        this.WaitService.stop();
        SpreedlyExpress.openView();
      }, 1500);

      SpreedlyExpress.onPaymentMethod((token, paymentMethod) => {
        let data = {
          orderId: orderData.id,
          token: token,
          amount: orderData.invoiceBalance,
          customerData: res,
        };
        this.WaitService.start();
        this.PaymentService.chargeCard(data)
          .then(() => {
            SpreedlyExpress.unload();
            this.WaitService.stop();
            this.toastr.success("Card Has Been Charged");
            this.data.invoiceBalance = 0;
          })
          .catch((err) => {
            SpreedlyExpress.unload();
            this.toastr.error("Something Went Wrong");
            this.WaitService.stop();
          });
      });
    });
  }

  payCash(order) {
    const modal = this.modalService.open(PayCashComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.orderData = order;
    modal.componentInstance.payCash.subscribe((res) => {
      if (!res.success) return;

      this.OrdersService.getJobById(order.id)
        .then((result: any) => {
          if (result.data && result.data.length)
            this.data.invoiceBalance = result.data[0].invoiceBalance;
          this.InvoicesService.updatePaidTime(order.id);
        })
        .catch((err) => {
          this.toastr.error(err.error.error);
        });
    });
  }

  open(album, index: number): void {
    this.LightBox.open(album, index);
  }

  close(): void {
    this.LightBox.close();
  }

  openVideoPlayer(index: number) {
    const modal = this.modalService.open(VideoPlayerComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      centered: true,
      keyboard: false,
    });

    modal.componentInstance.videoAlbum = this.data.videos;
    modal.componentInstance.videoIdx = index;
  }

  openEditInvoiceModal(data) {
    var modal;
    if (data.orderType == "custom" && data.isCreatedBySuperAdmin && this.currentUser.role != 1) {
      return this.toastr.error("You Are Not Allowed To Perform This Action");
    }
    if (data.orderType == "app") {
      modal = this.modalService.open(ConfirmModalComponent, {
        backdropClass: "light-blue-backdrop",
        backdrop: "static",
        keyboard: false,
      });
      modal.componentInstance.title = "Warning";
      modal.componentInstance.message =
        "If you edit this job, it will be converted into a new job";

      modal.componentInstance.reason.subscribe((result) => {
        if (result != "success") return;

        modal = this.modalService.open(CreateJobComponent, {
          backdropClass: "light-blue-backdrop",
          size: "xl",
          backdrop: "static",
          keyboard: false,
        });
        // modal.componentInstance.type = "editWithInvoice";

        if (data.type == "impound") modal.componentInstance.type = "impound";
        modal.componentInstance.orderId = data.id;
        modal.componentInstance.isDraft = data.isDraft;
        if (this.issuperadmin) modal.componentInstance.isEditByUSerRole = "superAdmin";
      });
    } else {
      modal = this.modalService.open(CreateJobComponent, {
        backdropClass: "light-blue-backdrop",
        size: "xl",
        backdrop: "static",
        keyboard: false,
      });
      // modal.componentInstance.type = "editWithInvoice";

      if (data.type == "impound") modal.componentInstance.type = "impound";
      modal.componentInstance.orderId = data.id;
      modal.componentInstance.isDraft = data.isDraft;
      if (this.issuperadmin) modal.componentInstance.isEditByUSerRole = "superAdmin";
    }
  }

  redirectToInvoice(uid: string) {
    this.OrdersService.redirectToInvoice(uid);
  }

  toggleMapView() {
    if (this.mapType != "street") {
      this.map.setStyle("mapbox://styles/mapbox/satellite-streets-v11");
      this.mapType = "street";
    } else {
      this.map.setStyle("mapbox://styles/mapbox/outdoors-v11");
      this.mapType = "outdoor";
    }
    this.drawPolyline();
  }

  convertToTimeStamp(date: any) {
    return (
      moment(date.timestamp).format("MM/DD/YYYY") +
      "<br>" +
      moment(date.timestamp).format("hh:mm A")
    );
  }

  showTraffic() {
    this.map.setStyle("mapbox://styles/mapbox/traffic-day-v2");
    this.mapType = "outdoor";
    this.drawPolyline();
  }

  openNoteModal(order) {
    order.fromJobDetails = true;
    const modal = this.modalService.open(EditNotesComponent, {
      backdropClass: "light-blue-backdrop",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    let notesOrder = JSON.parse(JSON.stringify(order));
    if (this.issuperadmin) order.isEditFromSuperAdmin = true;
    modal.componentInstance.order = notesOrder;
    // modal.result.then((data) => {
    // });
  }
}
