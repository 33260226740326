import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "crop-img",
  templateUrl: "./crop-image.component.html",
  styleUrls: ["./crop-image.component.scss"],
})
export class CropImageComponent implements OnInit {
  @Input() imgSrc = '';

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private modal: NgbActiveModal,
    private ToastrService: ToastrService,
  ) {}

  ngOnInit() {
    this.fileChangeEvent(this.imgSrc);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    console.log("img loaded");
  }
  cropperReady() {
    console.log("img crop ready");
  }
  loadImageFailed() {
    this.ToastrService.error("img load failed");
  }

  save() {
    console.log('saved');
    this.modal.close(this.croppedImage);
  }

  close() {
    this.modal.close();
  }
}
