import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, EventEmitter, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ValidateService } from "src/app/services/phone-validate/validate.service";

import { ToastrService } from "ngx-toastr";
import {
  NgbModal,
  NgbActiveModal,
  // NgbDropdownModule,
} from "@ng-bootstrap/ng-bootstrap";

import { WaitService } from "../../../services/wait/wait.service";
import { GoogleApiService } from "../../../services/googleApi/google-api.service";
import { PlacesService } from "../../../services/places/places.service";
import { AccountService } from "../../../services/account/account.service";
import { SitesService } from "../../../services/sites/sites.service";
import { UserService } from "../../../services/user/user.service";
import { LoginService } from "../../../services/login/login.service";
import { VehicalService } from "../../../services/vehical/vehical.service";
import { PriceService } from "../../../services/price/price.service";
import { ProvidersService } from "../../../services/providers/providers.service";
import { OrdersService } from "../../../services/orders/orders.service";
import { SocketService } from "../../../services/socket/socket.service";
import { MapBoxService } from "../../../services/map-box/map-box.service";
import { CommissionService } from "../../../services/commission/commission.service";
import { ProviderService } from "../../../services/superAdmin/provider/provider.service";
import { ClassTypesService } from "../../../services/classTypes/class-types.service";

import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";
import { Router } from '@angular/router';

import * as moment from "moment";
import * as momentTz from "moment-timezone";
// import * as mapboxgl from "mapbox-gl";
const mapboxgl = require('mapbox-gl');
import { environment } from "../../../../environments/environment";
import { EMPTY, Observable, Subject, Subscription, of, timer } from "rxjs";
import { filter, finalize, mergeMap, takeUntil, takeWhile } from "rxjs/operators";

@Component({
  selector: "app-create-job",
  templateUrl: "./create-job.component.html",
  styleUrls: ["./create-job.component.scss"],
})
export class CreateJobComponent implements OnInit, OnDestroy {
  currentUser: any = null;
  storageSites: any = [];
  isDefaultEdited: boolean = false;
  isDefaultCommissionChanges: boolean = false;
  providerAccounts: any = [];
  providerPlaces: any = [];
  providerSites: any = [];
  providerUsers: any = [];
  locationTypes: any = [];
  trucks: any = [];
  drivers: any = [];
  dispatchers: any = [];
  showColorDropDown: boolean = true;
  tDate = new Date();
  @Output() returnData: EventEmitter<any> = new EventEmitter();
  minDateValidation: any = {
    year: this.tDate.getFullYear(),
    month: this.tDate.getMonth() + 1,
    day: this.tDate.getDate()
  }

  vehicleData: any = {
    makes: [],
    makesLoading: false,
    models: [],
    modelsLoading: false,
    years: [],
    yearsLoading: false,
    colors: [],
    colorLoading: false,
  };
  selectedAccount: any = null;
  providerServices: any = [];
  isOthers: boolean = false;
  pickUpLocation: any = { lat: null, lng: null };
  dropOffLocation = { lat: null, lng: null };

  activePointer = null;

  destination = null;
  origin = null;
  invalidControl: any = null;
  customerAddressSelect: any = "none";
  manualLocationTypeChange: any = { pick: false, drop: false };
  @Input() type: string = "order"; //order or impound
  @Input() orderId: number = null;
  @Input() isDraft: boolean = false;
  @Input() canclledOrderId: number = null;
  @Input() orderItems: any = [];
  @Input() orderTowData: any = null;
  @Input() orderReleasedData: any = null;
  @Input() orderReleased: boolean = false;
  @Input() fromTracking: boolean = false;
  @Input() isEditByUSerRole: any;

  orderData: any = {
    accountId: null,
    callerName: null,
    callerPhone: null,
    customerEmail: null,
    customerAddress: null,
    vehicle: {
      make: "",
      vehicleModel: "",
      year: null,
      color: null,
      driveTrain: null,
      licensePlate: null,
      vinNumber: null,
      odometer: null,
    },
    pickUpLocationType: null,
    pickUpAddress: null,
    pickUpLat: null,
    pickUpLon: null,
    pickUpName: null,
    pickUpNumber: null,
    dropOffLocationType: null,
    dropOffAddress: null,
    dropOffLat: null,
    dropOffLon: null,
    dropOffName: null,
    dropOffNumber: null,
    dirverId: null,
    truckId: null,
    stateLicense: null,
    serviceId: null,
    rateTypeId: null,
    classTypeId: null,
    serviceTime: "asap",
    scheduledFor: null,
    scheduledTime: { hour: null, minute: null },
    scheduledTimeZone: null,
    etaMins: null,
    billingType: null,
    notes: null,
    dispatcher: null,
    dispatchSiteId: null,
    isDraft: false,
    includeStorage: false,
    storageSiteId: null,
    storedPerDayPrice: null,
    storageType: null,
    includeTowOut: false,
    towOutDestinationAddress: null,
    towOutDestinationLat: null,
    towOutDestinationLon: null,
    towOutScheduledFor: null,
    towOutTime: null,
    towOutScheduledForTimeZone: null,
    sendSmsTo: "customer",
    locationSms: false,
    driverEnRouteSms: false,
    jobCompletedSms: false,
    orderMainItems: [],
    orderAdditionalItems: [],
    discountType: "flat",
    discountValue: 0,
    requestLocation: false,
    invoiceNotes: null,
    releaseTo: null,
    releaseToName: null,
    releaseToAddressText: null,
    releaseToLat: null,
    releaseToLon: null,
    releaseToPhone: null,
    releaseToEmail: null,
    replaceInvoiceInfo: false,
    licenseState: null,
    driverLicense: null,
    po: null,
  };

  rateTypes: any = null;
  selectedRateType: any = {};
  classTypes: any = null;
  filteredItems: any = [];
  additionalItems: any = null;
  currentRateTypeId: Number;
  classType: Number;
  filteredSites: any = [];
  isDraftJob: boolean = false;
  isDriverChanged: boolean = false;
  googleAutoComplete: any = [];
  googleAutoCompleteTimeOut: any = null;
  googleAutoCompleteLoading: boolean = false;
  showPickUpPlaces: boolean = false;
  showDropOffPlaces: boolean = false;
  showTowOutSuggesstion: boolean = false;

  discountAmmount: number = 0;
  subTotal: number = 0;
  total: number = 0;
  taxAmount: number = 0;
  storageTaxAmount: number = 0;

  exemptTax: boolean = false;
  storageExemptTax: boolean = false;
  mileageFee: number = 0;

  selectedServiceName: string = null;
  suggesstionTimeOut: any = null;
  addressSuggestions: any = [];
  showSuggestion: boolean = false;
  isNullclassType: boolean = false;
  storageRateMeta: any = null;
  displayDrivers: any = {
    driver: "Select",
  };

  storageServiceId: any = -1;
  storageRateTypeId: number = 7;
  tempEtaValue: any = null;
  highwaySelectDetails: any = {
    pickHighway: false,
    pickAdress: "",
    pickLat: "",
    pickLng: "",
    dropHighway: false,
    dropAdress: "",
    dropLat: "",
    dropLng: "",
  }

  @ViewChild("pickUpAddress", { static: false }) pickUpAddressFiled: ElementRef;
  @ViewChild("dropOffAddress", { static: false })
  dropOffAddressFiled: ElementRef;
  @ViewChild("f", { static: false }) form: NgForm;

  @ViewChild("etaTime", { static: false }) etaField: ElementRef;

  etaOptions: any = [];
  showEtaOptions: boolean = false;
  providerInfo: any = null;

  isDataLoaded: boolean = false;

  basicColors: any = [
    { color_name: "White" },
    { color_name: "Yellow" },
    { color_name: "Blue" },
    { color_name: "Red" },
    { color_name: "Green" },
    { color_name: "Black" },
    { color_name: "Silver" },
    { color_name: "Gray" },
    { color_name: "Orange" },
  ];

  showExemptTax: boolean = false;
  showStorageExemptTax: boolean = false;

  // Map Box
  map: mapboxgl.Map;
  style = "mapbox://styles/mapbox/outdoors-v11";

  mapType: string = "outdoor";
  pointAMarker: any;
  pointBMarker: any;
  popup: any;
  popupSec: any;
  popupAdmin: any;
  // End Map Box

  commissionTimeout: any = null;

  isCustomerCreated: boolean = false;

  newPayments: any = [];

  serviceLocationMiles: number = 0;
  dropOffLocationMiles: number = 0;
  estimatedTime: string = "";
  mapEstimatedTime: any = null;
  mapContentEl$;
  validate: any = {
    error: {},
  };
  truckType: any = {
    id: null,
    name: null,
  };
  lat = 0;
  lng = 0;
  showMap: boolean = false;
  matchedPlaces: any = [];
  showDriverList: boolean = false;
  driverOnHover: boolean = false;
  allClassTypes: any = [];
  socket: any = null;
  isEditFromSuperAdmin: any = false;
  allProviders: any = [];
  selectedProvider: any;
  providerAlter: any = false;
  minDate: Date;
  disabledDates = this.getAllDatesTillYesterday()

  private destroy$ = new Subject<void>();
  private retrySubscription: Subscription;

  constructor(
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private GoogleApiService: GoogleApiService,
    private PlacesService: PlacesService,
    private AccountService: AccountService,
    private SitesService: SitesService,
    private UserService: UserService,
    private LoginService: LoginService,
    private VehicalService: VehicalService,
    private PriceService: PriceService,
    private ProvidersService: ProvidersService,
    private OrdersService: OrdersService,
    private SocketService: SocketService,
    private MapBoxService: MapBoxService,
    private CommissionService: CommissionService,
    private ProviderService: ProviderService,
    private el: ElementRef,
    private ValidateService: ValidateService,
    private ClassTypesService: ClassTypesService,
    private router: Router
  ) {
    this.currentUser = this.LoginService.getCurrentUser();
    this.socket = this.SocketService.getSocket();
  }

  getAllDatesTillYesterday() {
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth();
    var currentYear = currentDate.getFullYear();
    var yesterday = currentDate.getDate() - 1;

    var dates = [];
    for (var i = 1; i <= yesterday; i++) {
      var date = new Date(currentYear, currentMonth, i);
      dates.push(date);
    }
    return dates;
  }

  ngOnInit() {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getMonth() + 1);
    this.isEditFromSuperAdmin = this.isEditByUSerRole == "superAdmin" ? true : false;

    this.mapContentEl$ = this.el.nativeElement;

    this.currentUser = this.isEditFromSuperAdmin ? this.LoginService.getSuperAdmin() : this.LoginService.getCurrentUser();
    this.socket = this.SocketService.getSocket();
    if (this.isEditFromSuperAdmin) {
      this.getProviders();
    }
    // this.mapContentEl$ = this.el.nativeElement;

    // this.currentUser = this.LoginService.getCurrentUser();

    /**
    Drivers' live status update via socket
    **/

    if (this.socket) {

      if (!this.isEditFromSuperAdmin) {
        this.socket.on("driverActive", (result: any) => {
          // const index = this.drivers.findIndex((x) => x.id == result.data.userId);
          // if (index != -1) {
          //   this.drivers[index].isOnline = result.data.isOnline;
          //   if (this.drivers[index].isOnline) {
          //     this.drivers[index].show = "online";
          //   } else {
          //     this.drivers[index].show = "offline";
          //   }
          // }
          // this.drivers = this.drivers.sort((a, b) => {
          //   return b.isOnline - a.isOnline;
          // });

          this.UserService.getAllUsers()
            .then((result) => {
              this.drivers = [];
              let data = JSON.parse(JSON.stringify(result));
              if (data && data.users) {
                data.users = data.users.map((x) => {
                  x.roleName =
                    x.roleId == "2"
                      ? "Admin"
                      : x.roleId == "3"
                        ? "Driver"
                        : x.roleId == "5"
                          ? "Dispatcher"
                          : "";
                  if (x.isOnline) {
                    x.show = "online";
                  } else {
                    x.show = "offline";
                  }
                  if (x.roleId == "2" || x.roleId == "5") this.dispatchers.push(x);
                  if (x.roleId == "3" || x.roleId == "2") this.drivers.push(x);
                });

                this.drivers = this.drivers.sort((a, b) => {
                  return b.isOnline - a.isOnline;
                });
              }
            })
            .catch()
        });
      }

      /**
       * Auto Fill Pickup location when customer pickup location
       * when customer uses's the requst location link
       */
      this.socket.on("customerLocation", (res) => {
        let result = JSON.parse(JSON.stringify(res)).data;
        if (this.orderData && this.orderData.id == result.id) {
          this.orderData.pickUpAddress = result.pickUpAddress;
          this.orderData.pickUpLat = parseFloat(result.pickUpLat);
          this.orderData.pickUpLon = parseFloat(result.pickUpLon);
          this.origin = {
            lat: parseFloat(result.pickUpLat),
            lng: parseFloat(result.pickUpLon),
          };

          this.pointAMarker
            .setLngLat([this.orderData.pickUpLon, this.orderData.pickUpLat])
            .addTo(this.map);

          // Move map to center to the piclup location
          this.map.setCenter([
            this.orderData.pickUpLon,
            this.orderData.pickUpLat,
          ]);

          this.showPath();
        }
      });

    }

    this.getVehicleYear();

    if (this.orderId) { this.getJobDetails(); }
    else {
      if (!this.currentUser.superAdmin) this.createOrder();
    }
    let timeCount = 0;
    let currentTime = moment();
    if (this.orderId && this.orderData && this.orderData.createdAt) {
      currentTime = moment(this.orderData.createdAt).utc();
    }
    for (let index = 0; index < 25; index++) {
      let timeInterval = index <= 12 ? 5 : 10;
      // let value = index * timeInterval;
      if (index != 0) {
        timeCount = timeCount + timeInterval;
      }
      this.etaOptions.push({
        minutes: timeCount,
        time: currentTime.format("h:mm a"),
        text:
          timeCount <= 60
            ? timeCount + " mins"
            : Math.floor((timeCount / 60) * 10) / 10 + " hrs",
      });
      if (index == 12) {
        timeInterval = 10;
        currentTime = currentTime.add(timeInterval, "minutes");
      }
      if (index != 12) {
        currentTime = currentTime.add(timeInterval, "minutes");
      }
    }

    this.type = this.router.url.split('/').filter(part => part !== '')[1];
    if (this.orderTowData && this.orderTowData.isTowService) {
      this.type = 'order';
    }
    this.orderData.requestAgain = false;

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.retrySubscription) {
      this.retrySubscription.unsubscribe();
    }
  }

  // initializeMap() {
  //   setTimeout(() => {
  //     this.map = new mapboxgl.Map({
  //       accessToken: environment.MAPBOX_TOKEN,
  //       container: "map_create_job",
  //       style: this.style,
  //     });

  //     this.map.addControl(new mapboxgl.NavigationControl());

  //     this.popup = new mapboxgl.Popup({
  //       closeButton: false,
  //       closeOnClick: false,
  //       anchor: "bottom",
  //       offset: 15,
  //     });

  //     this.popupSec = new mapboxgl.Popup({
  //       closeButton: false,
  //       closeOnClick: false,
  //       anchor: "bottom",
  //       offset: 15,
  //     });

  //     this.popupAdmin = new mapboxgl.Popup({
  //       closeButton: false,
  //       closeOnClick: false,
  //       anchor: "right",
  //     });

  //     let ele1 = document.createElement("img");
  //     ele1.src = "./assets/flat-icons/map-pin-red.png";

  //     this.pointAMarker = new mapboxgl.Marker({
  //       element: ele1,
  //     });
  //     let ele2 = document.createElement("img");
  //     ele2.src = "./assets/flat-icons/map-pin-green.png";

  //     this.pointBMarker = new mapboxgl.Marker({
  //       element: ele2,
  //     });

  //     let ele3 = document.createElement("img");
  //     ele3.src = "./assets/flat-icons/build.png";

  //     this.popupAdmin = new mapboxgl.Marker({
  //       element: ele3,
  //     });

  //     if (this.orderData.pickUpLat && this.orderData.pickUpLon) {
  //       this.pointAMarker
  //         .setLngLat([this.orderData.pickUpLon, this.orderData.pickUpLat])
  //         .addTo(this.map);

  //       this.map.setZoom(14);
  //       this.map.setCenter([
  //         this.orderData.pickUpLon,
  //         this.orderData.pickUpLat,
  //       ]);
  //     }

  //     if (this.orderData.dropOffLat && this.orderData.dropOffLon)
  //       this.pointBMarker
  //         .setLngLat([this.orderData.dropOffLon, this.orderData.dropOffLat])
  //         .addTo(this.map);

  //     this.showPath();

  //     this.map.on("click", (e) => this.setMarker(e));
  //   }, 1000);
  // }

  initializeMap() {
    this.retrySubscription = timer(0, 1000)
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(() => {
          const mapContainer = document.getElementById('map_create_job');
          if (mapContainer) {
            const mapConfigSubscription = this.mapConfig(mapContainer).pipe(
              takeWhile(() => !document.getElementById('map_create_job'))
            );

            // Use the finalize operator to unsubscribe from the mapConfigSubscription when it is no longer needed
            return mapConfigSubscription.pipe(finalize(() => this.destroy$.next()));
          } else {
            console.error('Container not found.');
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  mapConfig(container: HTMLElement): Observable<any> {
    mapboxgl.accessToken = environment.MAPBOX_TOKEN;
    this.map = new mapboxgl.Map({
      // accessToken: environment.MAPBOX_TOKEN,
      container: container,
      style: this.style,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      offset: 15,
    });

    this.popupSec = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "bottom",
      offset: 15,
    });

    this.popupAdmin = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "right",
    });

    let ele1 = document.createElement("img");
    ele1.src = "./assets/flat-icons/map-pin-red.png";

    this.pointAMarker = new mapboxgl.Marker({
      element: ele1,
    });
    let ele2 = document.createElement("img");
    ele2.src = "./assets/flat-icons/map-pin-green.png";

    this.pointBMarker = new mapboxgl.Marker({
      element: ele2,
    });

    let ele3 = document.createElement("img");
    ele3.src = "./assets/flat-icons/build.png";

    this.popupAdmin = new mapboxgl.Marker({
      element: ele3,
    });

    if (this.orderData.pickUpLat && this.orderData.pickUpLon && this.map) {
      this.pointAMarker
        .setLngLat([this.orderData.pickUpLon, this.orderData.pickUpLat])
        .addTo(this.map);

      this.map.setZoom(14);
      this.map.setCenter([
        this.orderData.pickUpLon,
        this.orderData.pickUpLat,
      ]);
    }

    if (this.orderData.dropOffLat && this.orderData.dropOffLon)
      this.pointBMarker
        .setLngLat([this.orderData.dropOffLon, this.orderData.dropOffLat])
        .addTo(this.map);

    this.showPath();

    this.map.on("click", (e) => this.setMarker(e));
    return of(null);
  }

  isImpound() {
    if (this.type == "impound") this.orderData.includeStorage = true;
    this.orderData.isImpound = this.type == "impound";
  }
  searchfocusOut() {
    setTimeout(() => {
      this.matchedPlaces = [];
    }, 1000);
  }
  onProviderChange(provider) {
    if (this.orderId) {
      this.isDataLoaded = true;
      this.isImpound();

      this.orderData.storedDays = 1;
      this.orderData.accountId = null;
      this.providerAlter = true;
      setTimeout(() => this.loadData(), 200);
    }
    else
      this.createOrder(this.selectedProvider);
  }

  getProviders() {
    this.WaitService.start();
    this.ProviderService.getAllProviders()
      .then((res) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        this.allProviders = result.data.providers;
        // this.allProviders.unshift({companyName: "Select Company", userInfo:{id:0}});
        if (!this.orderId) {
          let lastSelected = 0;

          if (result.data.lastJobAddedFor && result.data.lastJobAddedFor.providerId) {
            lastSelected = this.allProviders.findIndex(provider => provider.userInfo.id == result.data.lastJobAddedFor.providerId);
          }
          let indextoSelect = (lastSelected + 1) == this.allProviders.length ? 0 : lastSelected + 1;
          this.selectedProvider = this.allProviders[indextoSelect].userInfo.id;
          this.createOrder(this.selectedProvider);
        }
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  searchPlace() {
    this.customerAddressSelect = 'none';
    this.matchedPlaces = [];

    new Promise<void>((resolve) => {
      if (this.providerInfo && this.providerInfo.lat && this.providerInfo.lng) {
        resolve();
      } else {
        setTimeout(() => resolve(), 500);
      }
    }).then(() => {
      // const { lat, lng } = this.providerInfo || { lat: null, lng: null };
      let lat = parseFloat(this.providerInfo.lat) || null;
      let lng = parseFloat(this.providerInfo.lng) || null;

      return this.GoogleApiService.autoCompleteAddress(
        this.orderData.customerAddress,
        lat,
        lng,
        true
      );
    }).then((response) => {
      const result = JSON.parse(JSON.stringify(response));
      this.customerAddressSelect = "started";
      this.matchedPlaces = result.data.predictions;

      if (!this.orderData.customerAddress) {
        this.customerAddressSelect = "none";
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  selectCustAdress(place) {
    this.orderData.customerAddress = place.description;
    this.customerAddressSelect = "selected";
    this.matchedPlaces = [];
  }

  setToday() {
    const date = moment(new Date());

    const year = date.year(),
      month = date.month() + 1,
      day = date.date();

    //this.newDate = date.format("MM/DD/YYYY");

    this.orderData.scheduledFor = year + "-" + month + "-" + day;
  }

  createOrder(id: any = null) {
    this.WaitService.start();
    this.OrdersService.createOrderObj(this.isEditFromSuperAdmin, id, this.orderData.id)
      .then((res) => {
        let result = JSON.parse(JSON.stringify(res));
        this.orderData = result.data;
        if (this.type == "impound") {
          this.orderData.serviceCharge = 0;
        }
        this.orderData.vehicle = {
          make: null,
          vehicleModel: null,
          year: null,
          color: null,
          driveTrain: null,
          licensePlate: null,
          vinNumber: null,
          odometer: null,
        };
        this.isDataLoaded = true;
        this.isImpound();
        // this.setToday();
        this.WaitService.stop();
        // this.orderData.scheduledFor = null;

        this.orderData.storedDays = 1;
        if (!this.orderData.isImpound) this.initializeMap();

        if (this.currentUser && this.currentUser.role == 3 && !this.currentUser.canAssignDriver)
          this.orderData.driverId = this.currentUser.userId;

        if (this.orderTowData) {
          if (this.orderTowData.callerPhone) {
            this.orderTowData.callerPhone = this.ValidateService.onEdit(
              this.orderTowData.callerPhone
            );
          }
          this.orderData.isTowServiceEdit = this.orderTowData.isTowService
          this.orderData.accountId = this.orderTowData.accountId;
          this.orderData.callerName = this.orderTowData.callerName;
          this.orderData.callerPhone = this.orderTowData.callerPhone;
          this.orderData.customerEmail = this.orderTowData.customerEmail;
          this.orderData.customerAddress = this.orderTowData.customerAddress;
          this.orderData.vehicle = this.orderTowData.vehicle;
          this.orderData.pickUpAddress = this.orderTowData.pickUpAddress;
          this.orderData.pickUpLat = this.orderTowData.pickUpLat;
          this.orderData.pickUpLon = this.orderTowData.pickUpLon;
          this.orderData.serviceTime = this.orderTowData.serviceTime;
          this.orderData.notes = this.orderTowData.notes;
          this.orderData.dispatcher = this.orderTowData.dispatcher;
          this.orderData.dispatchSiteId = this.orderTowData.dispatchSiteId;
          this.orderData.isDraft = this.orderTowData.isDraft;
          this.orderData.storageOrderId = this.orderTowData.id;
          this.isOthers = this.orderTowData.isOthers;
          this.orderData.po = this.orderTowData.po;
          this.orderData.pickUpLocationType = 4
        }

        setTimeout(() => this.loadData(), 200);
      })
      .catch((err) => {
        console.log(err);

        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  getJobDetails() {
    this.WaitService.start();
    this.OrdersService.getJobById(this.orderId, this.fromTracking, this.isEditFromSuperAdmin, this.selectedProvider)
      .then((result: any) => {
        const responseData = JSON.parse(JSON.stringify(result));

        this.WaitService.stop();
        this.orderData = Object.assign(responseData.data[0]);

        this.selectedProvider = this.orderData.providerId

        if (this.orderData.orderMainItems && this.orderData.orderMainItems.length)
          this.orderData.orderMainItems.forEach(order => (
            order.value = parseFloat(order.price)
          ));
        // if(this.orderData.orderMainItems && this.orderData.orderMainItems[0].price){
        //   this.orderData.orderMainItems[0].value=parseFloat(this.orderData.orderMainItems[0].price)
        // }
        if (!this.orderData.classTypeId) {
          this.isNullclassType = true;
        }
        this.isDraftJob = this.orderData.isDraft;
        this.isDriverChanged = this.orderData.driverId ? true : false;
        if (this.orderData.isUndoJob) {
          this.type = "impound"
        }
        if (this.orderData.callerPhone) {
          this.orderData.callerPhone = this.ValidateService.onEdit(
            this.orderData.callerPhone
          );
        }

        this.orderData.storedDays = this.orderData.autoStoredCountDays
          ? this.orderData.totalStoredDays
          : this.orderData.storedDays;

        if (this.orderData) {
          if (this.orderData.isOthers) this.isOthers = this.orderData.isOthers;
          if (this.fromTracking) this.orderData.fromTracking = true;
          if (this.orderData.exemptTax)
            this.exemptTax = this.orderData.exemptTax;
          if (this.orderData.vehicle.make === "")
            this.orderData.vehicle.make = null;
          if (this.orderData.vehicle.vehicleModel === "")
            this.orderData.vehicle.vehicleModel = null;
          if (this.orderData.towOutScheduledFor)
            this.orderData.towOutScheduledFor = moment(
              this.orderData.towOutScheduledFor
            ).format("YYYY-MM-DD");

          if (this.orderData.scheduledFor) {
            const scheduledFor = new Date(this.orderData.scheduledFor)
            this.orderData.scheduledFor = {
              day: scheduledFor.getDate(),
              month: scheduledFor.getMonth() + 1,
              year: scheduledFor.getFullYear()
            }
          }
          // if (this.orderData.scheduledTime) {
          //   const splitTime = this.orderData.scheduledTime.split(':')
          //   this.orderData.scheduledTime = { hour: parseInt(splitTime[0]), minute: parseInt(splitTime[1]) }
          // }

          if (this.orderData.pickUpLat && this.orderData.pickUpLon)
            this.origin = {
              lat: parseFloat(this.orderData.pickUpLat),
              lng: parseFloat(this.orderData.pickUpLon),
            };

          if (this.orderData.dropOffLat && this.orderData.dropOffLon)
            this.destination = {
              lat: parseFloat(this.orderData.dropOffLat),
              lng: parseFloat(this.orderData.dropOffLon),
            };

          if (this.orderData.invoiceInfo) {
            this.orderData.invoiceId = this.orderData.invoiceInfo.id;
            this.orderData.payments =
              this.orderData.invoiceInfo.payments &&
                this.orderData.invoiceInfo.payments.length
                ? this.orderData.invoiceInfo.payments
                : [];
          }

          if (this.orderData.vehicle.vinNumber) {
            this.decodeVin();
          }

          this.serviceLocationMiles = parseFloat(
            this.orderData.milesProviderToCustomer
          );
          this.dropOffLocationMiles = parseFloat(
            this.orderData.milesCustomerToShop
          );

          this.isImpound();

          if (this.orderReleasedData) {
            this.orderData.releaseTo = this.orderReleasedData.releaseTo;
            this.orderData.releaseToName = this.orderReleasedData.releaseToName;
            this.orderData.releaseToAddressText = this.orderReleasedData.releaseToAddressText;
            this.orderData.releaseToPhone = this.orderReleasedData.releaseToPhone;
            this.orderData.releaseToEmail = this.orderReleasedData.releaseToEmail;
            this.orderData.driverLicense = this.orderReleasedData.driverLicense;
            this.orderData.replaceInvoiceInfo = this.orderReleasedData.replaceInvoiceInfo;
            this.orderData.licenseState = this.orderReleasedData.licenseState;
            this.orderData.autoStoredCountDays = false;
            this.orderData.releaseToLat = this.orderReleasedData.releaseToLat;
            this.orderData.releaseToLon = this.orderReleasedData.releaseToLon;
            this.selectedRateType.additionalItems = this.additionalItems;
          }

          if (this.orderData.etaMins) {
            this.tempEtaValue = this.orderData.etaMins;
          }

          if (this.orderData.releaseToPhone) {
            this.orderData.releaseToPhone = this.ValidateService.onEdit(
              this.orderData.releaseToPhone
            );
          }
          // Do not show map if order is impound
          if (!this.orderData.isImpound) this.initializeMap();

          this.VehicalService.getVehicleTruck(null, this.selectedProvider, this.isEditFromSuperAdmin)
            .then((res) => {
              let result = JSON.parse(JSON.stringify(res));
              this.trucks = result.data;
              if (this.orderData.truckId && this.orderData.classTypeId) {
                this.orderData.initialClassTypeId = true;
                this.loadTruckType();
              }
            })
            .catch((err) => { });
          setTimeout(() => this.loadData(), 200);
        }
        this.isDataLoaded = true;
        this.calculatePrice()
      })
      .catch((err) => {
        this.WaitService.stop();
        console.log("err =", err);
        // this.toastr.error(err.error.error);
      });
  }

  loadData() {
    let accounts = this.AccountService.getUserAccounts(this.selectedProvider, this.isEditFromSuperAdmin);
    let users = this.UserService.getAllUsers(null, this.selectedProvider, this.isEditFromSuperAdmin);
    let sites = this.SitesService.loadSites(null, this.selectedProvider, this.isEditFromSuperAdmin);
    let locations = this.PlacesService.getLocationTypes(this.selectedProvider, this.isEditFromSuperAdmin);
    let trucks = this.VehicalService.getVehicleTruck(null, this.selectedProvider, this.isEditFromSuperAdmin);
    let taxes = this.ProvidersService.getTax(this.selectedProvider, this.isEditFromSuperAdmin);
    let getProviderInfo = this.ProvidersService.getProviderInfo(this.isEditFromSuperAdmin, this.selectedProvider);
    let loadedClassTypes = this.ClassTypesService.getClassTypes(this.isEditFromSuperAdmin, this.selectedProvider);
    this.WaitService.start();
    return Promise.all([
      accounts,
      users,
      sites,
      locations,
      trucks,
      taxes,
      getProviderInfo,
      loadedClassTypes
    ])
      .then((data) => {
        let result = JSON.parse(JSON.stringify(data));
        this.providerAccounts = result[0].data;

        if (result[1] && result[1].users) {
          result[1].users = result[1].users.map((x) => {
            x.roleName =
              x.roleId == "2"
                ? "Admin"
                : x.roleId == "3"
                  ? "Driver"
                  : x.roleId == "5"
                    ? "Dispatcher"
                    : "";
            if (x.isOnline) {
              x.show = "online";
            } else {
              x.show = "offline";
            }
            if (x.roleId == "2" || x.roleId == "5") this.dispatchers.push(x);
            if (x.roleId == "3" || x.roleId == "2") this.drivers.push(x);
          });

          this.drivers = this.drivers.sort((a, b) => {
            return b.isOnline - a.isOnline;
          });

          this.getDriver();

          if (result[2] && result[2].data) {
            this.providerSites = result[2].data.sites;
            this.storageSites = result[2].data.sites.filter(
              (x) =>
                (x.locationType &&
                  (x.locationType.name == "Storage Facility" ||
                    x.locationType.name == "Impound Lot")) ||
                x.siteType == "default"
            );
          }

          if (result[7] && result[7].data) {
            //code added for create job if class type is disabled
            result[7].data = result[7].data.filter((data) => {
              if (data.providerClassType.isActive) {
                return data
              }
            })
            this.allClassTypes = result[7].data;
            this.classTypes = result[7].data;
          }

          this.locationTypes = result[3].data.filter(type => type.id != 18);

          this.trucks = result[4].data;

          if (result[5] && result[5].data) {
            this.showStorageExemptTax = result[5].data.showStorageExemptTax
            this.showExemptTax = result[5].data.showExemptTax;
            // if (this.orderData.deletedAt){
            this.orderData.tax = result[5].data.tax;
            this.orderData.storageTax = result[5].data.storageTax;
            if (this.type == "impound" || this.orderData.includeStorage) {
              this.orderData.taxPercentage = result[5].data.storageTax
            }
            else {
              this.orderData.taxPercentage = result[5].data.tax;
            }
            // }
            if (!this.orderData.stateLicense)
              this.orderData.stateLicense = result[5].data.stateLicense;
          }

          if (result[6] && result[6].data) {
            this.providerInfo = result[6].data;

            this.lat = this.orderData.releaseToLat ? parseFloat(this.orderData.releaseToLat) : parseFloat(this.providerInfo.lat);
            this.lng = this.orderData.releaseToLon ? parseFloat(this.orderData.releaseToLon) : parseFloat(this.providerInfo.lng);

            if (
              this.providerInfo &&
              this.providerInfo.lat &&
              this.providerInfo.lng &&
              !this.orderData.pickUpLat &&
              !this.orderData.pickUpLon &&
              this.map
            ) {
              this.map.setZoom(8);
              this.map.setCenter([
                parseFloat(this.providerInfo.lng),
                parseFloat(this.providerInfo.lat),
              ]);
            }
          }

          // if (this.map)
          // this.storageSites.map((x) => {
          //   let ele = document.createElement("img");
          //   ele.src = "./assets/flat-icons/map-blue-pin.png";
          //   ele.style.height = "40px";
          //   ele.style.width = "34px";

          //   let marker = new mapboxgl.Marker({
          //     element: ele,
          //     rotation: x.bearing,
          //   }).setLngLat([x.lng, x.lat]);
          //   let popup = new mapboxgl.Popup({ offset: 25 }).setText(x.name);

          //   marker.setPopup(popup);
          //   marker.addTo(this.map);
          //   x.marker = marker;
          // });

          if ((this.providerAlter || this.orderData.deletedAt) && this.providerAccounts[0])
            this.orderData.accountId = this.providerAccounts[0].id;
          this.WaitService.stop();
          this.onAccountChange();
          this.showPath();
        }

        if (this.orderData.classTypeId && this.orderData.deletedAt) this.loadRates();
        console.log(this.orderData, "createJobOrders");

      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error);
      });
  }

  getVehicleMake(year) {
    this.vehicleData.makes = [];
    this.vehicleData.models = [];
    this.vehicleData.colors = [];

    this.orderData.vehicle.make = null;
    this.orderData.vehicle.vehicleModel = null;

    this.vehicleData.makesLoading = true;
    this.VehicalService.getVehicleMake(year, this.isEditFromSuperAdmin)
      .then((result: any) => {
        // let result = JSON.parse(JSON.stringify(data));
        this.vehicleData.makes = result.data;
        this.vehicleData.makesLoading = false;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
        this.vehicleData.makesLoading = false;
      });
  }

  addCustomYear = (term: string) => (this.orderData.vehicle.year = term);

  getVehicleModels(year, make) {
    this.vehicleData.models = [];
    this.orderData.vehicle.vehicleModel = null;

    if (!make || !year) return;

    this.vehicleData.modelsLoading = true;
    this.VehicalService.getVehicleModels(year, make, this.isEditFromSuperAdmin)
      .then((result: any) => {
        // let result = JSON.parse(JSON.stringify(data));
        this.vehicleData.models = result.data;
        this.vehicleData.modelsLoading = false;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.vehicleData.modelsLoading = false;
        this.toastr.error(err.error.error);
      });
  }

  addCustomMake = (term: string) => (this.orderData.vehicle.make = term);

  getVehicleYear() {
    this.vehicleData.make = [];
    this.vehicleData.models = [];

    this.vehicleData.yearsLoading = true;
    this.VehicalService.getVehicleYear(this.isEditFromSuperAdmin)
      .then((result: any) => {
        // let result = JSON.parse(JSON.stringify(data));
        this.vehicleData.years = result.data;
        this.vehicleData.yearsLoading = false;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
        this.vehicleData.yearsLoading = false;
      });
  }

  getVehicleColor(make, model, year) {
    this.vehicleData.colors = [];
    this.orderData.vehicle.color = null;

    if (year <= 1990) {
      this.vehicleData.colors = this.basicColors;
      return;
    }

    if (!make || !model) return;
    this.vehicleData.colorLoading = true;
    this.VehicalService.getVehicleColor(make, model, year, this.isEditFromSuperAdmin)
      .then((result: any) => {
        // let result = JSON.parse(JSON.stringify(data));
        this.vehicleData.colors = result.data;
        if (result.data.length)
          this.orderData.vehicle.color = result.data[0].color_name;
        this.vehicleData.colorLoading = false;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
        this.vehicleData.colorLoading = false;
      });
  }

  addCustomModel = (term: string) => (this.orderData.vehicle.vehicleModel = term);

  close(data?) {
    this.returnData.emit({ status: false, type: this.type });
    this.modal.close(data);
  }

  // Update services list for the selected account
  onAccountChange() {
    if (!this.orderData.accountId) {
      this.providerServices = [];
      this.selectedAccount = null;
      return;
    }

    this.selectedAccount = this.providerAccounts.filter(
      (x) => x.id == this.orderData.accountId
    )[0];

    this.getServices(this.orderData.accountId);
  }

  setMarker(e) {
    const { lat, lng } = e.lngLat;

    if (this.activePointer == "pickup") {
      this.orderData.pickUpLat = lat;
      this.orderData.pickUpLon = lng;
      if (this.manualLocationTypeChange.pick == 'auto') this.orderData.pickUpLocationType = null;
      this.origin = { lat: lat, lng: lng };
      this.convertToAddress("pickup", lat, lng);
      this.pointAMarker.setLngLat([lng, lat]).addTo(this.map);
    }

    if (this.activePointer == "dropOff") {
      this.orderData.dropOffLat = lat;
      this.orderData.dropOffLon = lng;
      if (this.manualLocationTypeChange.drop == 'auto') this.orderData.dropOffLocationType = null;
      this.destination = { lat: lat, lng: lng };
      this.convertToAddress("dropOff", lat, lng);
      this.pointBMarker.setLngLat([lng, lat]).addTo(this.map);
    }

    this.showPath();
  }

  convertToAddress(type, lat, lng) {
    this.GoogleApiService.getAddress(lat, lng)
      .then((response) => {
        const result = JSON.parse(JSON.stringify(response));
        let places = result.data.results;

        if (
          (type == "dropOff" && this.orderData.dropOffLocationType == 13) ||
          (type == "pickup" && this.orderData.pickUpLocationType == 13)
        )
          places = result.data.results.filter((x) => x.types.includes("route"));

        if (places && places.length) {
          let address = places[0].formatted_address;
          //remove country name
          var lastIndex = address.lastIndexOf(",");
          address = address.substring(0, lastIndex);
          //

          if (type == "dropOff") this.orderData.dropOffAddress = address;

          if (type == "pickup") this.orderData.pickUpAddress = address;
        }
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  getServices(accountId) {
    this.providerServices = [];
    this.WaitService.start();
    let params = {
      isActive: true,
      providerId: this.isEditFromSuperAdmin ? this.selectedProvider : null,
      // serviceType:
      //   this.type == "impound" ||
      //   (!this.orderData.deletedAt && this.orderData.includeStorage)
      //     ? "storage"
      //     : "main",
      serviceType:
        (this.type == "impound" &&
          this.orderData.serviceId == this.storageServiceId) ||
          (this.type == "impound" &&
            (!this.orderData.serviceId || !this.storageServiceId))
          ? "storage"
          : "main",
    };

    const loadStorageRates = this.PriceService.getStorageRate(
      this.orderData.accountId,
      this.orderData.classTypeId,
      this.isEditFromSuperAdmin
    );

    const loadServices = this.PriceService.getServices(accountId, params, this.isEditFromSuperAdmin);
    const loadAdditionalServices = this.PriceService.getServices(accountId, {
      isActive: true,
      serviceType: "additional",
      providerId: this.isEditFromSuperAdmin ? this.selectedProvider : null,
    }, this.isEditFromSuperAdmin);

    return Promise.all([loadStorageRates, loadServices, loadAdditionalServices])
      .then((res) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(res));
        let storageData = result[0];
        let service = result[1];

        // Assign 'Tow' from storage > tow vehicle
        if (this.orderTowData && this.orderTowData.isTowService) {
          service.data.forEach((x) => {
            if (x.label == "Tow") {
              this.orderData.serviceId = x.id;
            }
          });
        }
        this.storageRateMeta = storageData.data;

        this.providerServices = service.data;

        if (service.data && service.data.length) {
          if (
            (this.type == "impound" &&
              this.orderData.serviceId == this.storageServiceId) ||
            (this.type == "impound" &&
              (!this.orderData.serviceId || !this.storageServiceId))
          ) {
            let storageService = service.data.filter(
              (x) => x.serviceType == "storage"
            );
            if (storageService && storageService.length)
              this.orderData.serviceId = storageService[0].id;
            this.storageServiceId = storageService[0].id;
          } else {
            this.loadStorageService(accountId);
          }
          let selectedService = service.data.filter(
            (x) => x.id == this.orderData.serviceId
          );

          if (selectedService && selectedService.length)
            this.selectedServiceName = selectedService[0].label;
        }

        this.additionalItems = result[2].data.map((x) => {
          x.name = x.label;
          return x;
        });
        this.filteredItems = this.additionalItems;

        this.loadRates();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  loadStorageService(accountId) {
    if (!accountId) return;

    this.WaitService.start();
    this.PriceService.getServices(accountId, {
      isActive: true,
      serviceType: "storage",
      providerId: this.isEditFromSuperAdmin ? this.selectedProvider : null,
    }, this.isEditFromSuperAdmin)
      .then((result: any) => {
        this.WaitService.stop();
        if (result && result.data && result.data[0])
          this.storageServiceId = result.data[0].id;
      })
      .catch((err) => {
        console.log(err);
        this.WaitService.stop();
      });
  }


  resetServicePricing() {
    this.orderData.orderMainItems =
      [{
        chargePerQty: false,
        key: "Flat Rate",
        price: "0",
        qty: 1,
        type: "rate",
        value: 0
      }]
    this.orderData.orderAdditionalItems = null;
    this.selectedRateType.additionalItems = null;

    this.calculatePrice();
  }
  loadRates() {
    // if (this.orderData.serviceId == null) {
    //   this.orderData.rateTypeId = null;
    //   // this.orderData.classTypeId = null;
    // }
    if (!this.orderData.serviceId) {
      this.resetServicePricing();
    }
    this.rateTypes = [];
    if (!this.classTypes) {
      this.classTypes = [];
    }
    this.selectedRateType = null;

    if (!this.orderData.accountId || !this.orderData.serviceId) return;

    this.WaitService.start();
    this.PriceService.getService(
      this.orderData.accountId,
      this.orderData.serviceId,
      this.isEditFromSuperAdmin,
      this.selectedProvider
    )
      .then((result: any) => {
        this.WaitService.stop();
        if (result.data) {
          this.rateTypes = result.data.rates;

          // if (!this.orderData.isImpound)
          if (this.type !== "impound") {
            this.rateTypes = this.rateTypes.filter((x) => x.rateTypesId != 7);
          }
          this.classTypes = result.data.classes;

          // Show alter if the user didn't enable any class type from settings
          if (!this.classTypes || !this.classTypes.length)
            this.toastr.warning("Please Enable Class Types From Settings");

          if (this.orderData.deletedAt || this.isDefaultEdited) {
            // if (!this.orderData.truckId) {
            //   this.orderData.classTypeId = this.classTypes[0].id;
            // }
            // if (this.orderData.truckId==null) {
            //   this.orderData.classTypeId == null;
            // }
            if (this.orderData.truckId && this.truckType.Id) {
              this.orderData.classTypeId = this.truckType.Id;
            }
            if (this.rateTypes.length) this.orderData.rateTypeId = this.rateTypes[0].rateTypesId;
          }

          this.changeRateType();

          if (!this.selectedRateType.additionalItems)
            this.selectedRateType.additionalItems = [];
        }
      })
      .catch((err) => {
        console.log(err);
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  loadTruckType(onEdit?) {
    if (this.orderData.truckId) {
      this.trucks.forEach((x) => {
        if (x.id == this.orderData.truckId) {
          this.allClassTypes.forEach((y) => {
            if (y.name == x.truckType.name) {
              this.classTypes = this.allClassTypes;
              this.orderData.classTypeId = y.id;
            }
          })
          if (this.orderData.initialClassTypeId) {
            this.orderData.initialClassTypeId = false;
          }
          else {
            this.orderData.classTypeId = x.truckType.id;
          }
          this.truckType.Id = x.truckType.id;
          this.truckType.name = x.truckType.name;
        }
      });
    } else {
      this.truckType.Id = null;
      this.truckType.name = null;
    }
  }

  changeTruck() {
    this.loadTruckType();
    this.changeClassType(true);
  }

  changeRateType() {
    if (!this.orderData.rateTypeId) {
      // this.orderData.classTypeId = null;
      this.resetServicePricing();
      return;
    }

    this.selectedRateType = this.rateTypes.filter(
      (x) => x.rateTypesId == this.orderData.rateTypeId
    )[0];

    if (!this.selectedRateType) {
      this.selectedRateType = {}
    }

    if (!this.selectedRateType.additionalItems)
      this.selectedRateType.additionalItems = [];

    //prevent orderMainItems and orderAdditionalItems to updated on load
    if (!this.isDefaultEdited && !this.orderData.deletedAt) {
      this.selectedRateType.additionalItems =
        this.orderData.orderAdditionalItems;
      // this.selectedRateType.additionalItems =
      //   this.selectedRateType.additionalItems.concat(
      //     this.orderData.orderAdditionalItems
      //   );


      this.updateAdditionalItemList();

      this.isDefaultEdited = true;
      this.calculatePrice();
      this.reInitializeServicePrice()
      return;
    }

    this.changeClassType();
  }

  reInitializeServicePrice() {
    this.orderData.orderMainItems && this.orderData.orderMainItems.length && this.orderData.orderMainItems.forEach((x) => {
      if (x.price == 0 || x.value == 0) {
        x.price = x.value = null;
      }
    })
    this.orderData.orderAdditionalItems && this.orderData.orderAdditionalItems.length && this.orderData.orderAdditionalItems.forEach((x) => {
      if (x.price == 0 || x.value == 0) {
        x.price = x.value = null;
      }
    })
    if (this.orderData.storedPerDayPrice == 0) {
      this.orderData.storedPerDayPrice = 0;
    }
  }

  changeClassType(isChangeTruk = false) {
    let tempOrderMainItem = [];
    if (!this.selectedRateType || !this.selectedRateType.rateTypesId)
      return;

    let classTypeID;

    if (!this.orderData.classTypeId) {
      classTypeID = 2;
      if (this.isNullclassType) {
        this.isNullclassType = false;
      }
    }
    else {
      classTypeID = this.orderData.classTypeId
    }

    let selectedClassTypeForStorage = this.storageRateMeta.filter(
      (x) => x.classTypesId == classTypeID
    );

    if (selectedClassTypeForStorage && selectedClassTypeForStorage.length)
      this.orderData.storedPerDayPrice =
        selectedClassTypeForStorage[0].items[0].value;
    else this.orderData.storedPerDayPrice = 0;

    tempOrderMainItem = this.orderData.orderMainItems.map(e => e) 
    if (this.orderData.classTypeId != 2 || (!this.isNullclassType && this.orderData.classTypeId == 2)) {
      this.orderData.orderMainItems = [];
    }

    this.WaitService.start();
    this.PriceService.getClassTypeRate(
      this.orderData.accountId,
      this.orderData.serviceId,
      this.selectedRateType.rateTypesId,
      classTypeID,
      this.isEditFromSuperAdmin,
      this.selectedProvider
    )
      .then((response) => {
        this.orderData.orderAdditionalItems = null;
        this.selectedRateType.additionalItems = null;
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));

        if (!result.data) {

          if (this.isNullclassType && this.orderData.classTypeId == 2) {
            this.selectedRateType.items[0].value = this.selectedRateType.items[0].price = this.orderData.orderMainItems[0].price;
          }

          this.orderData.orderMainItems = this.selectedRateType.items;
          this.orderData.orderMainItems.forEach((x) => {
            if (!x.value) x.value = 0;
          });

        } else {
          this.orderData.orderMainItems = result.data.items;
          this.orderData.orderAdditionalItems = result.data.additionalItems;
          this.selectedRateType.additionalItems = result.data.additionalItems;
          
          if (classTypeID && !this.orderData.classTypeId) {
            this.orderData.orderMainItems =
              [{
                chargePerQty: false,
                key: "Flat Rate",
                price: "0",
                qty: 1,
                type: "rate",
                value: 0
              }]
          };
        }

        this.orderData.orderMainItems.length && this.orderData.orderMainItems.forEach((x) => {
          x.qty = x.type == "quantity" ? parseFloat(x.value) : 1;
          x.price = x.type == "quantity" ? 1 : parseFloat(x.value);
        });

        if (this.selectedRateType && this.selectedRateType.additionalItems) {
          this.selectedRateType.additionalItems.map((x) => {
            x.qty = 1;
            if (!x.price) x.price = x.value
            x.price = x.value;
          });

          this.filteredItems = this.additionalItems.map((x) => {
            let index = this.selectedRateType.additionalItems.findIndex(
              (i) => i.id == x.id
            );
            if (index == -1) return x;
          });
        }

        // Don't show main items For stroage service orders
        if (
          this.orderData.isImpound &&
          this.orderData.orderMainItems &&
          this.orderData.orderMainItems.length
        ) {
          this.orderData.storedPerDayPrice =
            this.orderData.orderMainItems[0].price;
          this.orderData.orderMainItems = [];
        } else {
          this.loadStorageRates();
        }

        if (classTypeID && !this.orderData.classTypeId) {
          this.orderData.orderAdditionalItems = null;
        }
        else {
          if (result.data && result.data.additionalItems) {
            let additionalItems = result.data.additionalItems;
            additionalItems && additionalItems.length && additionalItems.forEach(item => item.price = item.value);
            this.selectedRateType.additionalItems = additionalItems ? additionalItems : [];
            this.orderData.orderAdditionalItems = this.selectedRateType.additionalItems;
          }
        }
        if (!this.orderData.serviceId || !this.orderData.rateTypeId) {
          this.resetServicePricing();
        }

        if (isChangeTruk && this.orderData.orderMainItems.length) {
          this.orderData.orderMainItems = this.orderData.orderMainItems.map((ele) => {
            const getIndex = tempOrderMainItem.findIndex((e) => e.key === ele.key);
            if (getIndex > -1) {
              ele.qty = tempOrderMainItem[getIndex].qty
            }
            return ele
          })
        }

        this.calculatePrice();
        this.updateAdditionalItemPrice();
        this.reInitializeServicePrice();
      })
      .catch((err) => {
        console.log(err, "error");
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
    this.reInitializeServicePrice();
  }

  loadStorageRates() {
    this.PriceService.getClassTypeRate(
      this.orderData.accountId,
      this.storageServiceId,
      this.storageRateTypeId,
      this.orderData.classTypeId,
      this.isEditFromSuperAdmin,
      this.selectedProvider
    )
      .then((response) => {
        let result = JSON.parse(JSON.stringify(response));
        if (result.data) {
          this.orderData.storedPerDayPrice = result.data.items[0].value;
          this.calculatePrice();
        }
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  updateAdditionalItemPrice() {
    const serviceIds =
      this.orderData.orderAdditionalItems &&
        this.orderData.orderAdditionalItems.length
        ? this.orderData.orderAdditionalItems.map((x) => x.id)
        : [];

    if (serviceIds && serviceIds.length) {
      this.WaitService.start();
      let promiseArray = [];

      serviceIds.forEach((x) => {
        promiseArray.push(
          this.PriceService.getClassTypeRate(
            this.orderData.accountId,
            x,
            6,
            this.orderData.classTypeId,
            this.isEditFromSuperAdmin,
            this.selectedProvider
          )
        );
      });

      return Promise.all(promiseArray)
        .then((result: any) => {
          this.WaitService.stop();

          if (result && result.length) {
            result.forEach((x, index) => {
              // this.orderData.orderAdditionalItems[index].price =
              //   x.data && x.data.items && x.data.items.length
              //     ? parseFloat(x.data.items[0].value)
              //     : 1;
              // if(x.data && x.data.items && x.data.items.length){
              //   this.orderData.orderAdditionalItems[index].price=parseFloat(x.data.items[0].value)
              // }
            });
          }
          this.calculatePrice();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error("Something Went Wrong");
        });
    }
  }
  resetLoc(field: string) {
    if (field == "pickup") {
      this.orderData.pickUpLat = null;
      this.orderData.pickUpLon = null;
    }
    if (field == "dropOff") {
      this.orderData.dropOffLat = null;
      this.orderData.dropOffLon = null;
    }
  }
  setFocus(field: string) {
    if (field == "pickup") {
      this.pickUpAddressFiled.nativeElement.focus();
      this.showPickUpPlaces = true;
      this.showDropOffPlaces = false;
      // this.orderData.pickUpLat = null;
      // this.orderData.pickUpLon = null;
      setTimeout(() => {
        if ((!this.orderData.pickUpAddress || !this.orderData.pickUpLat || !this.orderData.pickUpLon) && this.manualLocationTypeChange.pick == 'auto')
          this.orderData.pickUpLocationType = null;
      }, 1500)


      this.filterData(this.orderData.pickUpAddress);
    }
    if (field == "dropOff") {
      this.dropOffAddressFiled.nativeElement.focus();
      this.showPickUpPlaces = false;
      this.showDropOffPlaces = true;
      // this.orderData.dropOffLat = null;
      // this.orderData.dropOffLon = null;
      setTimeout(() => {
        if ((!this.orderData.dropOffAddress || !this.orderData.dropOffLat || !this.orderData.dropOffLon) && this.manualLocationTypeChange.drop == 'auto')
          this.orderData.dropOffLocationType = null;
      }, 1500)
      this.filterData(this.orderData.dropOffAddress);
    }
  }

  selectOnMap(type, lat, lng, address, locationType = null) {
    this.map.setCenter([parseFloat(lng), parseFloat(lat)]);

    if (type == "dropoff") {
      if (this.manualLocationTypeChange.drop == 'auto') this.orderData.dropOffLocationType = null;
      this.orderData.dropOffAddress = address;
      this.orderData.dropOffLat = lat;
      this.orderData.dropOffLon = lng;
      if (locationType) {
        // if(this.orderData.dropOffLocationType!=locationType) this.manualLocationTypeChange.drop = false;
        this.manualLocationTypeChange.drop = 'auto';
        this.orderData.dropOffLocationType = locationType;
      }

      this.destination = { lat: parseFloat(lat), lng: parseFloat(lng) };
      this.pointBMarker
        .setLngLat([parseFloat(lng), parseFloat(lat)])
        .addTo(this.map);
    }

    if (type == "pickup") {
      if (this.manualLocationTypeChange.pick == 'auto') this.orderData.pickUpLocationType = null;
      this.orderData.pickUpAddress = address;
      this.orderData.pickUpLat = lat;
      this.orderData.pickUpLon = lng;

      if (locationType) {
        this.manualLocationTypeChange.pick = 'auto';
        // if(this.orderData.pickUpLocationType!=locationType) this.manualLocationTypeChange.pick = false;
        this.orderData.pickUpLocationType = locationType;
      }

      this.origin = { lat: parseFloat(lat), lng: parseFloat(lng) };
      this.pointAMarker
        .setLngLat([parseFloat(lng), parseFloat(lat)])
        .addTo(this.map);
    }

    this.showPickUpPlaces = false;
    this.showDropOffPlaces = false;
    this.showPath();
  }

  filterData(text) {
    const searchText = text ? text.toLowerCase() : "";

    if (
      !this.orderData.dropOffLat &&
      !this.orderData.dropOffLon &&
      !this.orderData.pickUpLat &&
      !this.orderData.pickUpLon
    ) {
      this.serviceLocationMiles = null;
      this.dropOffLocationMiles = null;
      this.estimatedTime = null;
    }

    if (!searchText || !searchText.length) {
      if (this.activePointer == "pickup") {
        this.orderData.pickUpLat = null;
        this.orderData.pickUpLon = null;

        this.pointAMarker.remove();
      }
      if (this.activePointer == "dropOff") {
        this.orderData.dropOffLat = null;
        this.orderData.dropOffLon = null;
        this.pointBMarker.remove();
      }
      if (
        !(
          this.orderData.pickUpLat == this.pickUpLocation.lat &&
          this.orderData.pickUpLon == this.pickUpLocation.lng &&
          this.orderData.dropOffLat == this.dropOffLocation.lat &&
          this.orderData.dropOffLon == this.dropOffLocation.lng
        )
      )
        this.showPath();
    }

    if (this.activePointer == "pickup") {
      if (
        !(
          this.orderData.pickUpAddress &&
          this.orderData.pickUpAddress.trim().length > 0
        )
      ) {
        this.filteredSites = this.providerSites;
      } else {
        this.filteredSites = null;
      }
    }

    if (this.activePointer == "dropOff") {
      if (
        !(
          this.orderData.dropOffAddress &&
          this.orderData.dropOffAddress.trim().length > 0
        )
      ) {
        this.filteredSites = this.providerSites;
      } else {
        this.filteredSites = null;
      }
    }

    if (text) this.getGoogleAutoComplete(text);
  }

  getGoogleAutoComplete(text) {
    let lat = null,
      lng = null;

    if (this.activePointer == "dropOff") {
      if (this.orderData.pickUpLat && this.orderData.pickUpLon) {
        lat = parseFloat(this.orderData.pickUpLat);
        lng = parseFloat(this.orderData.pickUpLon);
      } else if (this.providerInfo.lat && this.providerInfo.lng) {
        lat = parseFloat(this.providerInfo.lat);
        lng = parseFloat(this.providerInfo.lng);
      }
    }

    if (
      this.activePointer == "pickup" &&
      this.providerInfo &&
      this.providerInfo.lat &&
      this.providerInfo.lng
    ) {
      lat = parseFloat(this.providerInfo.lat);
      lng = parseFloat(this.providerInfo.lng);
    }

    this.googleAutoComplete = [];
    if (!text) return;
    clearTimeout(this.googleAutoCompleteTimeOut);
    this.googleAutoCompleteTimeOut = setTimeout(() => {
      this.googleAutoCompleteLoading = true;
      const bounds = this.map.getBounds();
      this.GoogleApiService.autoCompleteAddress(text, null, null, true, { sw: bounds.getSouthWest(), ne: bounds.getNorthEast() })
        .then((res: any) => {
          this.googleAutoCompleteLoading = false;
          this.googleAutoComplete = res.data.predictions;
          // .map((x) => {
          //   if (x.terms[x.terms.length - 1].value.toLowerCase() == "usa") {
          //     x.description = x.description.substring(
          //       0,
          //       x.description.length - 5
          //     );
          //   }
          //   return x;
          // });
        })
        .catch((err) => {
          this.googleAutoCompleteLoading = false;
        });
    }, 800);
  }

  selectPlace(type, data) {
    this.GoogleApiService.getPlceDetailById(data.place_id).then((response) => {
      let result = JSON.parse(JSON.stringify(response));
      result = result.data.result;

      let bounds = result.geometry.location;

      if (this.map)
        this.map.setCenter([parseFloat(bounds.lng), parseFloat(bounds.lat)]);

      if (type == "dropoff") {
        this.orderData.dropOffAddress = data.description;
        this.orderData.dropOffLat = bounds.lat;
        this.orderData.dropOffLon = bounds.lng;
        if (this.manualLocationTypeChange.drop == 'auto') this.orderData.dropOffLocationType = null;
        this.manualLocationTypeChange.drop = false;
        this.destination = {
          lat: parseFloat(bounds.lat),
          lng: parseFloat(bounds.lng),
        };

        this.pointBMarker
          .setLngLat([parseFloat(bounds.lng), parseFloat(bounds.lat)])
          .addTo(this.map);
      } else if (type == "pickup") {
        this.orderData.pickUpAddress = data.description;
        this.orderData.pickUpLat = bounds.lat;
        this.orderData.pickUpLon = bounds.lng;
        if (this.manualLocationTypeChange.pick == 'auto') this.orderData.pickUpLocationType = null;
        this.manualLocationTypeChange.pick = false;
        this.origin = {
          lat: parseFloat(bounds.lat),
          lng: parseFloat(bounds.lng),
        };

        this.pointAMarker
          .setLngLat([parseFloat(bounds.lng), parseFloat(bounds.lat)])
          .addTo(this.map);
      } else if (type == "towout") {
        this.orderData.towOutDestinationAddress = data.description;
        this.orderData.towOutDestinationLat = bounds.lat;
        this.orderData.towOutDestinationLon = bounds.lng;
      }

      this.showPath();
    });

    this.googleAutoComplete = [];
  }

  hideTowOutSuggesstion() {
    setTimeout(() => {
      this.showTowOutSuggesstion = false;
      if (this.googleAutoComplete && this.googleAutoComplete.length) {
        let matchedAddress = this.googleAutoComplete.filter(
          (x) => x.description == this.orderData.towOutDestinationAddress
        );

        if (!matchedAddress.length)
          this.orderData.towOutDestinationAddress =
            this.googleAutoComplete[0].description;
      }
    }, 1000);
  }

  addAdditionalItem(value) {
    const index = this.additionalItems.findIndex((x) => x.id == value);

    let item = this.additionalItems[index];

    this.WaitService.start();

    this.PriceService.getClassTypeRate(
      this.orderData.accountId,
      value,
      6,
      this.orderData.classTypeId,
      this.isEditFromSuperAdmin,
      this.selectedProvider
    )
      .then((result: any) => {
        this.WaitService.stop();
        if (!item.type) {
          item.type = "flat";
        }
        if (!item.value && !item.price) {
          item.value = item.price = 0;
        }

        if (!this.orderData.classTypeId && (!this.orderData.orderAdditionalItems || this.orderData.orderAdditionalItems && this.orderData.orderAdditionalItems.length)) {
          this.selectedRateType.additionalItems = null;
        }
        if (!this.selectedRateType.additionalItems)
          this.selectedRateType.additionalItems = [];
        this.selectedRateType.additionalItems.push(item);

        item.qty = 1;
        if (result.data && result.data.items && result.data.items.length){
          item.price = parseFloat(result.data.items[0].value);
          item.value = parseFloat(result.data.items[0].value);
        }

        if (this.orderData.orderAdditionalItems && this.orderData.orderAdditionalItems.length && !this.orderData.classTypeId) {
          this.orderData.orderAdditionalItems = this.selectedRateType.additionalItems = this.orderData.orderAdditionalItems.concat(this.selectedRateType.additionalItems).filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
        } else {
          this.orderData.orderAdditionalItems =
            this.selectedRateType.additionalItems;
        }

        this.updateAdditionalItemList();
        this.calculatePrice();
        this.orderData.orderAdditionalItems && this.orderData.orderAdditionalItems.length && this.orderData.orderAdditionalItems.forEach((x) => {
          if (x.value == 0) x.value = x.price = null;
        });
        this.selectedRateType.additionalItems && this.selectedRateType.additionalItems.legnth && this.selectedRateType.additionalItems.forEach((x) => {
          if (x.value == 0) x.value = x.price = null;
        })

      })
      .catch((err) => {
        console.log(err, "error");

        this.WaitService.stop();
        this.toastr.error("Something Went Wrong");
      });
  }

  removeAdditionalItem(item) {
    item.value = item.price = 0;
    const index = this.selectedRateType.additionalItems.findIndex(
      (x) => x.id == item.id
    );
    this.selectedRateType.additionalItems.splice(index, 1);
    this.orderData.orderAdditionalItems = this.selectedRateType.additionalItems;

    this.updateAdditionalItemList();

    this.calculatePrice();
  }

  removeRateTypeItem(item) {
    let index = this.orderData.orderMainItems.findIndex(
      (x) => x.key == item.key
    );
    this.orderData.orderMainItems.splice(index, 1);
    this.calculatePrice();
  }

  calculatePrice() {
    let data = this.PriceService.calculatePrice(
      this.orderData,
      this.selectedRateType,
      this.exemptTax,
      parseFloat(this.orderData.serviceCharge),
      parseFloat(this.orderData.servicePerMileCharge)
    );

    this.subTotal = data.subTotal;
    this.taxAmount = data.taxAmount;
    this.discountAmmount = data.discountAmmount;

    this.mileageFee = data.mileageFee;
    this.total = data.total;

    // this.orderData.invoiceBalance = this.total;
    this.calculateInvoiceBalance(this.total);
    this.calculateDriverCommission();
  }

  calculateAdditionalPrice(item) {
    let price =
      ((parseFloat(this.orderData.serviceCharge) + this.subTotal) / 100) *
      parseFloat(item.price);
    return price;
  }

  requestLocation() {
    let inValidPhone = false;

    if (this.orderData.callerPhone) {
      inValidPhone = this.ValidateService.isValid(this.orderData.callerPhone);
      if (!inValidPhone) {
        this.orderData.callerPhone = this.ValidateService.format(
          this.orderData.callerPhone
        );
      }
    }

    if (inValidPhone || !this.orderData.callerPhone) {
      this.toastr.error("Customer Phone Number Is Not Valid");
      return;
    }

    this.WaitService.start();
    this.OrdersService.requestLocation({
      orderId: this.orderData.id,
      phone: this.orderData.callerPhone,
    },
      this.isEditFromSuperAdmin
    )
      .then(() => {
        this.toastr.info(
          "Request Location Link Has Been Sent To The Customer."
        );
        this.orderData.callerPhone = this.ValidateService.onEdit(
          this.orderData.callerPhone
        );
        this.WaitService.stop();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err);
      });
  }

  setDraft(val) {
    this.orderData.isDraft = val;

    if ((!val && this.orderData.isDraft && (!this.orderData.pickUpAddress) || (!this.orderData.callerPhone))) {
      if (!this.orderData.deletedAt) this.orderData.isDraft = true;
      console.log("working");
    }

    if (val)
      this.orderData.isEditDraft = true;

  }

  //  check if a Truck is assinged to Another Driver for other order
  checkJob() {
    if (this.orderData.driverId && this.orderData.truckId) {
      this.WaitService.start();
      this.VehicalService.getTruckDriverId(this.orderData.truckId)
        .then((res) => {
          this.WaitService.stop();
          let result = JSON.parse(JSON.stringify(res));
          if (!result.data || result.data.id == this.orderData.driverId)
            this.saveData();
          else {
            const modal = this.modalService.open(ConfirmModalComponent, {
              backdropClass: "light-blue-backdrop",
              centered: true,
              backdrop: "static",
              keyboard: false,
            });
            modal.componentInstance.title = "Alert";
            modal.componentInstance.message =
              "This truck is already assinged to another driver";

            modal.componentInstance.reason.subscribe((result) => {
              if (result != "success") return;
              this.saveData();
            });
          }
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    } else {
      this.saveData();
    }
  }

  saveData() {
    this.orderData.isRejectedToAssign = false;

    if (this.providerAlter) {
      this.orderData.providerId = this.selectedProvider;
    }
    if (
      !this.orderData.isImpound &&
      !this.orderData.isDraft &&
      (!this.orderData.pickUpAddress ||
        !this.orderData.pickUpLat ||
        !this.orderData.pickUpLon)
    )
      return;

    if (this.customerAddressSelect == "started") {
      let customerAddress = document.getElementById('customerAddress');
      if (customerAddress) { customerAddress.scrollIntoView({ behavior: "smooth", block: "center" }); }
      return;
    }

    this.orderData.orderMainItems && this.orderData.orderMainItems.length && this.orderData.orderMainItems.forEach((x) => {
      if (!x.price) {
        x.value = x.price = 0;
      }
    })
    if (this.orderData && !this.orderData.storedPerDayPrice && this.orderData.includeStorage && !this.orderData.removeStorageCharges) {
      this.orderData.storedPerDayPrice = 0;
    }
    this.orderData.orderAdditionalItems && this.orderData.orderAdditionalItems.length && this.orderData.orderAdditionalItems.forEach((x) => {
      if (!x.price) {
        x.value = x.price = 0;
      }
    })

    let servicePriceError = false;
    this.orderData.orderMainItems && this.orderData.orderMainItems.length && this.orderData.orderMainItems.forEach((x) => {
      if (x.price) {
        // let value = /^[1-9]\d*(\.\d+)?$/.test(x.price)
        let value = /^(?=.*[1-9])\d*(?:\.\d+)?$/.test(x.price)
        if (!value) servicePriceError = true;
      }
    })
    this.orderData.orderAdditionalItems && this.orderData.orderAdditionalItems.length && this.orderData.orderAdditionalItems.forEach((x) => {
      if (x.price) {
        // let value = /^[1-9]\d*(\.\d+)?$/.test(x.price)
        let value = /^(?=.*[1-9])\d*(?:\.\d+)?$/.test(x.price)
        if (!value) servicePriceError = true;
      }
    })
    if (!this.orderData.deletedAt && this.orderData.storedPerDayPrice && !/^(?=.*[1-9])\d*(?:\.\d+)?$/.test(this.orderData.storedPerDayPrice.toString())) {
      // if (!this.orderData.deletedAt && this.orderData.storedPerDayPrice && !/^(?:0?\.[1-9][0-9]*|[1-9][0-9]*(\.[0-9]+)?)$/.test(this.orderData.storedPerDayPrice)) {
      servicePriceError = true;
    }
    if (servicePriceError) {
      this.toastr.error("Invalid Service Price");
      return
    }

    if (this.orderData.callerPhone) {
      this.validate.error.phone = this.ValidateService.isValid(
        this.orderData.callerPhone
      );
      if (this.validate.error.phone && !this.orderData.isDraft) {
        let customerphone = document.getElementById('customerphone');
        if (customerphone) { customerphone.scrollIntoView({ behavior: "smooth", block: "center" }); }
        if (this.orderData.releaseToPhone) {
          this.orderData.releaseToPhone = this.ValidateService.onEdit(this.orderData.releaseToPhone);
        }
        return;
      };
      this.orderData.callerPhone = this.ValidateService.format(
        this.orderData.callerPhone
      );
    }

    if ((!this.orderData.pickUpAddress || !this.orderData.callerPhone) && this.orderData.isDraft && !this.orderData.isEditDraft) {
      if (!this.orderData.pickUpAddress) {
        let pickup = document.getElementById('pickupId');
        if (pickup) { pickup.scrollIntoView({ behavior: "smooth", block: "center" }); }
      }
      if (!this.orderData.callerPhone) {
        let customerphone = document.getElementById('customerphone');
        if (customerphone) { customerphone.scrollIntoView({ behavior: "smooth", block: "center" }); }
      }
      return
    }

    if (this.orderData.releaseToPhone) {
      this.validate.error.releasePhone = this.ValidateService.isValid(
        this.orderData.releaseToPhone
      );
      if (this.validate.error.releasePhone && this.orderData.callerPhone) {
        this.orderData.callerPhone = this.ValidateService.format(
          this.orderData.callerPhone
        );
      }
      if (this.validate.error.releasePhone) {
        if (this.orderData.callerPhone) {
          this.orderData.callerPhone = this.ValidateService.onEdit(this.orderData.callerPhone);
        }
        return;
      }
      this.orderData.releaseToPhone = this.ValidateService.format(
        this.orderData.releaseToPhone
      );
    }

    if (this.type === "impound" && !this.orderData.isDraft) {
      if (this.orderData.releaseToAddressText && (!this.orderData.releaseToLat || !this.orderData.releaseToLon))
        this.validate.error.releaseToAddress = true;

      if (this.validate.error.releaseToAddress) {
        this.orderData.releaseToPhone = this.ValidateService.onEdit(
          this.orderData.releaseToPhone
        );
        return;
      }
    }

    let temp = null;
    this.orderData.isOthers = this.isOthers;
    if (this.estimatedTime) {
      this.orderData.estimatedTime = this.mapEstimatedTime;
    }
    if (this.isOthers) {
      this.orderData.vehicle.vehicleModel = null;
      this.orderData.vehicle.year = null;
      this.orderData.vehicle.color = null;
      this.orderData.vehicle.driveTrain = null;
      this.orderData.vehicle.licensePlate = null;
      this.orderData.vehicle.vinNumber = null;
      this.orderData.vehicle.odometer = null;
    }
    if (this.orderId) {
      this.orderData.isDraft = this.isDraftJob;
    }
    this.orderData.invoiceTotal = this.total;
    this.orderData.providerTimezone = momentTz.tz.guess();
    // console.log(
    //   "this.orderData.providerTimezone",
    //   this.orderData.providerTimezone
    // );

    if (this.orderData.dropOffAddress && !this.orderData.dropOffLat && !this.orderData.dropOffLon)
      this.orderData.dropOffAddress = null;

    this.orderData.exemptTax = this.exemptTax;
    this.orderData.serviceType = this.type;
    // let tempDate = moment.utc(this.orderData.scheduledFor+ " " + this.orderData.scheduledTime);
    // this.orderData.scheduledFor = tempDate;

    let scheduledDateTime = null;
    if (this.orderData.serviceTime == "scheduled") {
      this.validate.error.scheduledFor = false;
      console.log("input date", this.orderData.scheduledFor);
      console.log("input time", this.orderData.scheduledTime);
      // if (this.orderData.scheduledTime) {
      //   this.orderData.scheduledTime = moment().set(this.orderData.scheduledTime).format("HH:mm");
      // }
      scheduledDateTime = moment(this.orderData.scheduledFor.month + "/" + this.orderData.scheduledFor.day + "/" + this.orderData.scheduledFor.year).format("YYYY-MM-DD") + " " + this.orderData.scheduledTime;
      console.log("formatted datetime", scheduledDateTime);
      let timezone = null;

      switch (this.orderData.scheduledTimeZone) {
        case "AKST":
          timezone = "America/Anchorage";
          break;
        case "CST":
          timezone = "America/Chicago";
          break;
        case "EST":
          timezone = "America/New_York";
          break;
        case "HST":
          timezone = "America/Adak";
          break;
        case "MST":
          timezone = "America/Phoenix";
          break;
        case "PST":
          timezone = "America/Los_Angeles";
          break;
      }
      console.log("formatted datetime zone", momentTz.tz(scheduledDateTime, timezone).utc());

      if (momentTz.tz(scheduledDateTime, timezone).utc() <= moment.utc()) {
        this.validate.error.scheduledFor = true;
        document.querySelector("app-stuk-date-picker").scrollIntoView({ behavior: "smooth", block: "center" });
        if (this.orderData.callerPhone)
          this.orderData.callerPhone = this.ValidateService.onEdit(
            this.orderData.callerPhone
          );

        return;
      }
    }

    if (!this.orderData.deletedAt) {
      if (this.newPayments && this.newPayments.length) {
        let offlinePayments = this.newPayments.filter(
          (x) => x.paymentMethod != "Credit/Debit card"
        );

        let isPaymentError = false, paymentMaxError = false;
        if (this.newPayments && this.newPayments.length) {
          this.newPayments.forEach((x) => {
            if (!x.paymentMethod || !x.amount) {
              isPaymentError = true;
            }
          });

          // if (this.orderData.invoiceBalance < 0 || this.orderData.invoiceBalance > this.orderData.invoiceTotal)
          //   paymentMaxError = true;
        }

        if (isPaymentError || paymentMaxError) {
          // this.toastr.error("Payment Method Is Required");
          this.toastr.error("Invalid Payment Method Or Amount");
          if (this.orderData.callerPhone) {
            this.orderData.callerPhone = this.ValidateService.onEdit(
              this.orderData.callerPhone
            );
          }
          return;
        }

        this.orderData.payments = this.orderData.payments.concat(offlinePayments);
      }
      //change eta to scheduled onEdit
      // this.orderData.status = "dispatched";

      if (this.orderData.serviceTime == "scheduled")
        this.orderData.status = null;

      if (this.orderReleased) {
        this.orderData.status = "released";
        this.orderData.completedTime = new Date();
        this.orderData.isStorageToDashboard = true;
      }

      if (this.orderData.serviceTime == "asap") {
        this.orderData.scheduledTime = null;
        this.orderData.scheduledFor = null;
        this.orderData.scheduledTimeZone = null;
      }

      if (this.tempEtaValue && this.orderData.etaMins && this.tempEtaValue != this.orderData.etaMins)
        this.orderData.isEtaExtended = true;

      if (!this.tempEtaValue && this.orderData.etaMins)
        this.orderData.isEtaProvided = true;

      if (!((this.isDriverChanged && this.orderData.driverId) || (!this.isDriverChanged && !this.orderData.driverId))) {
        this.orderData.isDriverChanged = true;
      }
      if (this.orderData.scheduledFor) {
        console.log('b4', this.orderData.scheduledFor);
        this.orderData.scheduledFor = moment.utc(scheduledDateTime).toISOString();
        console.log('after', this.orderData.scheduledFor);
      }

      console.log("this.orderData.action", this.orderData.action)
      if (this.orderData.action == "rejected") {
        this.orderData.isRejectedToAssign = true;
      }
      console.log("this.orderData", this.orderData);
      this.orderData.providerId = this.selectedProvider;

      temp = this.isEditFromSuperAdmin ? this.OrdersService.editOrderSuperadmin(this.orderData) : this.OrdersService.editOrder(this.orderData);
    } else {
      if (this.orderData.scheduledFor) {
        console.log('b4', this.orderData.scheduledFor);
        this.orderData.scheduledFor = moment.utc(scheduledDateTime).toISOString();
        console.log('after', this.orderData.scheduledFor);
      }
      this.orderData.providerId = this.selectedProvider;
      temp = this.OrdersService.saveOrder(this.orderData, this.isEditFromSuperAdmin);
    }

    this.WaitService.start();
    //Cancel order APP order and Create new order for the customer
    let cencelOrder = this.canclledOrderId
      ? this.OrdersService.updatedStatus(this.canclledOrderId, "cancelled")
      : null;

    console.log("about to post");

    return Promise.all([temp, cencelOrder])
      .then((response) => {
        this.WaitService.stop();
        console.log("about to close");
        this.close();
        if (!this.orderData.deletedAt) this.toastr.success("Job Updated");
        else if (
          this.orderData.serviceTime == "scheduled" &&
          !this.orderData.isDraft
        )
          this.toastr.success("Scheduled Job Created");
        else {
          if (this.orderData.isDraft) {
            console.log("draft toast");
            this.toastr.success("Draft Has Been Created");
          } else {
            if (this.type == "impound" && this.orderData.includeStorage) {
              this.toastr.success("Vehicle Added To Storage");
            } else {
              this.toastr.success("Job Created");
            }
          }
        }
        if (this.orderTowData.isTowService) {
          this.type = 'impound'
        }
        this.returnData.emit({ status: true, type: this.type });
      })
      .catch((err) => {
        console.log("Error:", err);
        this.WaitService.stop();
        if (this.orderData.callerPhone) {
          this.orderData.callerPhone = this.ValidateService.onEdit(
            this.orderData.callerPhone
          );
        }
        if (err.status == 500) {
          let errorMessage = JSON.parse(err.error.error);
          if (errorMessage.error) this.toastr.error(errorMessage.error);
          else this.toastr.error(errorMessage.error);
        } else this.toastr.error(err.error.error);
      });
  }

  percentValChange(e) {
    let t = e.target.value.toString();
    // t =
    //   t.indexOf(".") >= 0
    //     ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
    //     : t;
    // console.log(t,"t2");

    // t = parseFloat(t);
    this.orderData.discountValue = t ? t : null;
    e.target.value = t ? t : null;
    this.calculatePrice();
  }

  changeActivePlace(i) {
    if (this.orderData.storageSiteId && i == "dropOff") return;
    if (this.activePointer == i) {
      this.activePointer = null;
      return;
    }
    this.activePointer = i;
  }

  setStorageSite() {
    if (!this.orderData.storageSiteId) return;

    let selectedSite = this.providerSites.filter((x) => {
      if (x.id == this.orderData.storageSiteId && x.locationType && x.locationType.id) {
        this.orderData.dropOffLocationType = x.locationType.id;
      }
      return x.id == this.orderData.storageSiteId;
    });

    if (selectedSite && selectedSite.length) {
      this.orderData.dropOffAddress = selectedSite[0].address;
      this.orderData.dropOffLat = selectedSite[0].lat;
      this.orderData.dropOffLon = selectedSite[0].lng;
      this.pointBMarker.remove();
      this.pointBMarker
        .setLngLat([this.orderData.dropOffLon, this.orderData.dropOffLat])
        .addTo(this.map);
      this.showPath();
      if (this.activePointer == "dropOff") this.activePointer = null;
    }
  }

  removeStorage() {
    this.orderData.includeStorage = false;
    this.orderData.includeTowOut = false;
    this.orderData.storageSiteId = null;
    this.orderData.taxPercentage = this.orderData.tax;
    this.exemptTax = false;
    this.calculatePrice()
  }

  searchAddress(text) {
    clearTimeout(this.suggesstionTimeOut);

    this.orderData.releaseToLat = null;
    this.orderData.releaseToLon = null;
    this.validate.error.releaseToAddress = false;

    this.suggesstionTimeOut = setTimeout(() => {
      let lat = null;
      let lng = null;
      if (
        this.providerInfo &&
        this.providerInfo.lat &&
        this.providerInfo.lng
      ) {
        lat = parseFloat(this.providerInfo.lat);
        lng = parseFloat(this.providerInfo.lng);
      }
      // console.log("this.providerInfo",this.providerInfo)
      this.GoogleApiService.autoCompleteAddress(text, lat, lng, true)
        .then((result: any) => {
          // let result = JSON.parse(JSON.stringify(res));
          this.addressSuggestions = result.data.predictions;
        })
        .catch((err) => {
          console.log(err);
        });
    }, 300);
  }

  hideSuggesstion() {
    setTimeout(() => {
      if (this.addressSuggestions && this.addressSuggestions.length) {
        this.setReleaseToAddress(this.addressSuggestions[0]);
      } else if (!this.orderData.releaseToAddressText) {
        this.orderData.releaseToLat = null;
        this.orderData.releaseToLon = null;
        this.lat = parseFloat(this.providerInfo.lat);
        this.lng = parseFloat(this.providerInfo.lng);
      }

      this.showSuggestion = false;
    }, 300);
  }

  hideEtaOptions() {
    setTimeout(() => {
      this.showEtaOptions = false;
    }, 300);
  }

  setReleaseToAddress(address) {
    this.orderData.releaseToAddressText = address.description;
    this.addressSuggestions = [];
    this.WaitService.start();
    this.GoogleApiService.getPlceDetailById(address.place_id)
      .then((result: any) => {
        this.WaitService.stop();
        // let result = JSON.parse(JSON.stringify(response));
        // result = result.data.result;

        let bounds = result.data.result.geometry.location;
        // console.log("bounds", bounds);

        this.orderData.releaseToLat = bounds.lat;
        this.orderData.releaseToLon = bounds.lng;
        this.lat = bounds.lat;
        this.lng = bounds.lng
        this.validate.error.releaseToAddress = false;
        // console.log("releseLat", this.orderData.releaseToLat);
        // console.log("releseLon", this.orderData.releaseToLon);
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  onLocationTypeChange(type) {
    let placeQuery = null;
    if (type == "pickup") this.manualLocationTypeChange.pick = 'manual';
    if (type == "dropOff") this.manualLocationTypeChange.drop = 'manual';

    const showHighWays =
      (this.orderData.pickUpLocationType == 13 && type == "pickup") ||
      (type == "dropOff" && this.orderData.dropOffLocationType == 13);

    if (type == "dropOff" && this.orderData.dropOffLocationType == 4)
      this.orderData.includeStorage = true;

    if (this.highwaySelectDetails.dropHighway && !(type == "dropOff" && this.orderData.dropOffLocationType == 13)) {
      this.orderData.dropOffAddress = this.highwaySelectDetails.dropAdress;
      this.orderData.dropOffLat = this.highwaySelectDetails.dropLat;
      this.orderData.dropOffLon = this.highwaySelectDetails.dropLng;
      this.highwaySelectDetails.dropHighway = false;
      this.showPath();
    }
    if (this.highwaySelectDetails.pickHighway && !(this.orderData.pickUpLocationType == 13 && type == "pickup")) {
      this.orderData.pickUpAddress = this.highwaySelectDetails.pickAdress;
      this.orderData.pickUpLat = this.highwaySelectDetails.pickLat;
      this.orderData.pickUpLon = this.highwaySelectDetails.pickLng;
      this.highwaySelectDetails.pickHighway = false;
      this.showPath();
    }

    if (!this.orderData.pickUpLocationType) this.manualLocationTypeChange.pick = false;
    if (!this.orderData.dropOffLocationType) this.manualLocationTypeChange.drop = false;

    if (
      type == "dropOff" &&
      this.orderData.dropOffLat &&
      this.orderData.dropOffLon &&
      showHighWays
    ) {
      placeQuery = this.GoogleApiService.getAddress(
        this.orderData.dropOffLat,
        this.orderData.dropOffLon
      );
      this.highwaySelectDetails.dropHighway = true;
      this.highwaySelectDetails.dropAdress = this.orderData.dropOffAddress;
      this.highwaySelectDetails.dropLat = this.orderData.dropOffLat;
      this.highwaySelectDetails.dropLng = this.orderData.dropOffLon;
    } else if (type == "pickup" && this.orderData.pickUpLat && this.orderData.pickUpLon && showHighWays) {
      placeQuery = this.GoogleApiService.getAddress(
        this.orderData.pickUpLat,
        this.orderData.pickUpLon
      );
      this.highwaySelectDetails.pickHighway = true;
      this.highwaySelectDetails.pickAdress = this.orderData.pickUpAddress;
      this.highwaySelectDetails.pickLat = this.orderData.pickUpLat;
      this.highwaySelectDetails.pickLng = this.orderData.pickUpLon;
    }

    // for the time being stopping this functionto do anything.
    //return;

    if (!placeQuery) return;

    this.WaitService.start();
    return placeQuery
      .then((result: any) => {
        this.WaitService.stop();
        let roads = [];

        console.log("Show Highways: ", showHighWays);

        if (showHighWays)
          roads = result.data.results.filter((x) => x.types.includes("route"));
        else
          roads = result.data.results.filter((x) => !x.types.includes("route"));

        if (roads && roads.length) {
          if (type == "dropOff") {
            this.orderData.dropOffAddress = roads[0].formatted_address;
            this.orderData.dropOffLat = roads[0].geometry.location.lat;
            this.orderData.dropOffLon = roads[0].geometry.location.lng;

            this.pointBMarker
              .setLngLat([this.orderData.dropOffLon, this.orderData.dropOffLat])
              .addTo(this.map);
          } else {
            this.orderData.pickUpAddress = roads[0].formatted_address;
            this.orderData.pickUpLat = roads[0].geometry.location.lat;
            this.orderData.pickUpLon = roads[0].geometry.location.lng;
            this.pointAMarker
              .setLngLat([this.orderData.pickUpLon, this.orderData.pickUpLat])
              .addTo(this.map);
          }
          this.showPath();
        }
      })
      .catch((err) => {
        this.WaitService.stop();
      });
  }

  onScheduledFor(e) {
    this.orderData.scheduledFor = e;
  }

  onScheduledForTime(e) {
    this.orderData.scheduledTime = e.target.value;
  }

  onTowScheduledFor(e) {
    this.orderData.towOutScheduledFor = e;
  }

  countDays(autoStoredCountDays: boolean) {
    if (
      autoStoredCountDays &&
      this.storageRateMeta &&
      this.storageRateMeta.length
    )

      this.orderData.storedDays =
        this.orderData.totalStoredDays && this.orderData.totalStoredDays > 0
          ? this.orderData.totalStoredDays
          : 1;

    this.calculatePrice();
  }

  focusOrderDataFunction(e) {
    let storedDaysQty = e.target.value.toString();
    this.orderData.storedDays = Math.abs(parseFloat(storedDaysQty));
    e.target.value = this.orderData.storedDays;
  }
  focusStoragePrice(e) {
    if (e.target.value && !isNaN(e.target.value)) {
      this.orderData.storedPerDayPrice = Math.abs(
        parseFloat(e.target.value)
      );
    } else {
      this.orderData.storedPerDayPrice = null
    }
    this.calculatePrice();
  }
  focusDiscountValue(orderData) {
    // orderData.discountValue = Math.abs(parseFloat(orderData.discountValue));
  }
  focusOut(field: string) {
    setTimeout(() => {
      if (field == "pickup") {
        this.showPickUpPlaces = false;
      }
      if (field == "dropOff") {
        this.showDropOffPlaces = false;
      }
    }, 300);
  }

  //Get car details by VIN number
  decodeVin() {
    if (
      this.orderData.vehicle.vinNumber &&
      this.orderData.vehicle.vinNumber.length == 17
    ) {
      this.WaitService.start();
      this.VehicalService.decodeVin(this.orderData.vehicle.vinNumber, this.isEditFromSuperAdmin)
        .then((res) => {
          this.WaitService.stop();
          let result = JSON.parse(JSON.stringify(res)).data;
          if (result) {
            this.orderData.vehicle.make = result.make;
            this.orderData.vehicle.vehicleModel = result.model;
            this.orderData.vehicle.year = result.year;

            this.orderData.vehicle.gross_vehicle_weight_range =
              result.gross_vehicle_weight_range;

            this.getVehicleColor(result.make, result.model, result.year);
            // this.orderData.vehicle.color = result.color;
          }
        })
        .catch((err) => {
          this.WaitService.stop();
        });
    }
    if (this.orderData.vehicle.vinNumber.length == 0) {
      this.orderData.vehicle.gross_vehicle_weight_range = "";
    }
  }

  setFocusOnEta() {
    if (this.showEtaOptions) return;
    this.etaField.nativeElement.focus();
    this.showEtaOptions = true;
  }

  onColorChange() {
    if (this.orderData.vehicle.color == "other") {
      this.showColorDropDown = false;
      this.orderData.vehicle.color = "";
    }
  }

  addCustomColor = (term: string) => (this.orderData.vehicle.color = term);

  showPath() {
    this.pickUpLocation.lat = this.orderData.pickUpLat;
    this.pickUpLocation.lng = this.orderData.pickUpLon;
    this.dropOffLocation.lat = this.orderData.dropOffLat;
    this.dropOffLocation.lng = this.orderData.dropOffLon;
    setTimeout(() => {
      if (this.map && this.map.getSource("route")) {
        this.map.removeLayer("route");
        this.map.removeSource("route");
      }

      if (this.popup) this.popup.remove();
      if (this.popupSec) this.popupSec.remove();
      if (this.popupAdmin) this.popupAdmin.remove();

      if (
        this.popupAdmin &&
        this.providerInfo &&
        this.providerInfo.lng &&
        this.providerInfo.lat &&
        (this.dropOffLocation.lat !== this.providerInfo.lat && this.dropOffLocation.lng !== this.providerInfo.lng)
      )
        this.popupAdmin
          .setLngLat([this.providerInfo.lng, this.providerInfo.lat])
          .addTo(this.map);

      if (this.providerInfo && this.providerInfo.lng && this.providerInfo.lat && this.map) {
        this.map.setZoom(14);
        this.map.setCenter([this.providerInfo.lng, this.providerInfo.lat]);
      }

      let providerToPickUp = null,
        pickUpToDropOff = null;

      if (
        this.providerInfo &&
        this.providerInfo.lat &&
        this.providerInfo.lng &&
        this.orderData.pickUpLat &&
        this.orderData.pickUpLon &&
        this.orderData.dropOffLat &&
        this.orderData.dropOffLon
      ) {
        console.log(">>>>>12");
        pickUpToDropOff = this.MapBoxService.getGeoJson(
          this.providerInfo.lat,
          this.providerInfo.lng,
          this.orderData.pickUpLat,
          this.orderData.pickUpLon,
          this.orderData.dropOffLat,
          this.orderData.dropOffLon
        );
      }

      if (
        this.orderData.pickUpLat &&
        this.orderData.pickUpLon &&
        this.providerInfo &&
        this.providerInfo.lat &&
        this.providerInfo.lng &&
        !this.orderData.dropOffLat &&
        !this.orderData.dropOffLon
      ) {
        pickUpToDropOff = this.MapBoxService.getGeoJson(
          this.providerInfo.lat,
          this.providerInfo.lng,
          this.orderData.pickUpLat,
          this.orderData.pickUpLon
        );
        providerToPickUp = this.MapBoxService.getGeoJson(
          this.orderData.pickUpLat,
          this.orderData.pickUpLon,
          this.providerInfo.lat,
          this.providerInfo.lng
        );
      }
      this.WaitService.start();
      return Promise.all([pickUpToDropOff, providerToPickUp])
        .then((result: any) => {
          this.WaitService.stop();
          if (
            result &&
            result[0] &&
            result[0].data.routes &&
            result[0].data.routes.length
          ) {
            let coords = [];
            let waypoints = result[0].data.routes[0].legs[0].steps;

            if (result[0].data.routes[0].legs.length > 1)
              waypoints = waypoints.concat(
                result[0].data.routes[0].legs[1].steps
              );

            waypoints.map((x) => {
              x.intersections.map((i) => {
                coords.push(i.location);
              });
            });

            if (this.map) {
              this.map.addSource("route", {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: coords,
                  },
                },
              });

              this.map.addLayer({
                id: "route",
                type: "line",
                source: "route",
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": "#26B4FF",
                  "line-width": 8,
                },
              });
            }
            const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);

            for (const coord of coords) {
              bounds.extend(coord);
            }

            if (result[0].data.routes[0].duration <= 60) {
              this.map.fitBounds(bounds, {
                padding: 150,
                zoom: 18
              });
            }
            else if (result[0].data.routes[0].duration > 60 && result[0].data.routes[0].duration <= 150) {
              this.map.fitBounds(bounds, {
                padding: 150,
                zoom: 16
              });
            } else {
              if (this.map)
                this.map.fitBounds(bounds, {
                  padding: 150,
                });
            }
            // this.map.fitBounds(
            //   [
            //     [this.orderData.pickUpLon, this.orderData.pickUpLat],
            //     [this.orderData.dropOffLon, this.orderData.dropOffLat],
            //   ],
            //   {
            //     padding: 20,
            //   }
            // );
            if (this.orderData.dropOffLat && this.orderData.dropOffLon)
              this.dropOffLocationMiles =
                0.000621371192 * result[0].data.routes[0].legs[1].distance;
            else this.dropOffLocationMiles = 0;

            var estTime2 = "";
            let convertedTime2 = this.MapBoxService.timeConvert(
              result[0].data.routes[0].legs[0].duration
            ).split(" ");
            let checkMin2 = convertedTime2[0].split(":");
            if (checkMin2[1]) {
              estTime2 =
                Number(checkMin2[0]).toString() +
                " hr" +
                " " +
                Number(checkMin2[1]).toString() +
                " min";
            }
            if (!checkMin2[1]) {
              estTime2 = Number(checkMin2[0]).toString() + " min";
            }

            // this.popupAdmin
            //   .setLngLat([this.providerInfo.lng, this.providerInfo.lat])
            //   .addTo(this.map);
            if (this.popupSec)
              this.popupSec
                .setLngLat([this.orderData.pickUpLon, this.orderData.pickUpLat])
                .setHTML(
                  `<div style='font-size: 16px;
                  text-align: left;
                  height: 100%;
                  font-family: system-ui;'>
                  <p style='margin:0;display:none'><b>Distance: </b>` +
                  this.dropOffLocationMiles.toFixed(2) +
                  ` miles</p>
                  <p style='margin:0; padding: 4px 5px 4px 0px;'>
                      <b style="background: #000; color: #fff; padding: 4px 5px;">ETA</b> ` +
                  `${estTime2}` +
                  `</p>
                </div>`
                )
                .addTo(this.map);

            if (result[0].data.routes[0].legs[1]) {
              var estTime = "";
              var convertedTime = this.MapBoxService.timeConvert(
                result[0].data.routes[0].legs[1].duration
              ).split(" ");

              let checkMin = convertedTime[0].split(":");
              if (checkMin[1]) {
                estTime =
                  Number(checkMin[0]).toString() +
                  " hr" +
                  " " +
                  Number(checkMin[1]).toString() +
                  " min";
              }
              if (!checkMin[1]) {
                estTime = Number(checkMin[0]).toString() + " min";
              }

              this.popup
                .setLngLat(coords[coords.length - 1])
                .setHTML(
                  `<div style='font-size: 16px;
                  text-align: left;
                  height: 100%;
                  font-family: system-ui;'>
                  <p style='margin:0;display:none'><b>Distance: </b>` +
                  this.dropOffLocationMiles.toFixed(2) +
                  ` miles</p>
                  <p style='margin:0; padding: 4px 5px 4px 0px;'>
                      <b style="background: #000; color: #fff; padding: 4px 5px;">ETA</b> ` +
                  `${estTime}` +
                  `</p>
                </div>`
                )
                .addTo(this.map);
            }
          }

          var totalEst = "";
          let totalconvertedTime;
          if (result && result[0] && result[0].data) {
            totalconvertedTime = this.MapBoxService.fullTime(
              result[0].data.routes[0].legs[0].duration,
              result[0].data.routes[0].legs[1]
                ? result[0].data.routes[0].legs[1].duration
                : 0
            ).split(" ");

            let totalcheckMin = totalconvertedTime[0].split(":");
            if (totalcheckMin[1]) {
              totalEst =
                Number(totalcheckMin[0]).toString() +
                " hr" +
                " " +
                Number(totalcheckMin[1]).toString() +
                " min";
              this.mapEstimatedTime = ((parseFloat(totalcheckMin[0]) * 60) + parseFloat(totalcheckMin[1])).toString();
            }
            if (!totalcheckMin[1]) {
              totalEst = Number(totalcheckMin[0]).toString() + " min";
              this.mapEstimatedTime = totalcheckMin[0];
            }
            console.log(totalEst);
            this.estimatedTime = totalEst;
            //this.removeMapETAPopupPadding();

            // if (result[1] && result[1].data.routes && result[1].data.routes.length)
            this.serviceLocationMiles =
              0.000621371192 * result[0].data.routes[0].legs[0].distance;

            // if (estTime !== "") {
            //   this.estimatedTime = estTime;
            // }
          }
        })
        .catch((err) => {
          this.WaitService.stop();
          console.log(err);
        });
    }, 700);
  }

  removeMapETAPopupPadding() {
    const mapContentDivs = this.mapContentEl$.getElementsByClassName(
      "mapboxgl-popup-content"
    );
    if (mapContentDivs && mapContentDivs.length) {
      mapContentDivs[0].style.padding = 0;
    }
  }

  updateAdditionalItemList() {
    let additionalItemsIds = [];
    if (this.selectedRateType && this.selectedRateType.additionalItems)
      additionalItemsIds = this.selectedRateType.additionalItems.map(
        (x) => x.id
      );

    this.filteredItems = this.additionalItems.filter(
      (x) => !additionalItemsIds.includes(x.id)
    );
  }

  calculateDriverCommission() {
    // if (!this.isDefaultCommissionChanges && !this.orderData.deletedAt) {
    //   this.isDefaultCommissionChanges = true;
    //   return;
    // }

    const {
      driverId,
      serviceId,
      rateTypeId,
      classTypeId,
      orderMainItems,
      orderAdditionalItems,
    } = this.orderData;

    if (!driverId || !serviceId || !rateTypeId || !classTypeId) {
      this.orderData.driverCommission = 0;
      return;
    }

    if (this.commissionTimeout) clearTimeout(this.commissionTimeout);
    this.commissionTimeout = setTimeout(() => {
      const data = {
        driverId: driverId,
        serviceId: serviceId,
        rateTypeId: rateTypeId,
        classTypeId: classTypeId,
        orderMainItems: orderMainItems,
        orderAdditionalItems: orderAdditionalItems,
      };

      this.CommissionService.calculateCommission(data, this.isEditFromSuperAdmin)
        .then((result: any) => {
          this.orderData.driverCommission = result.data.commissionAmount ? result.data.commissionAmount : 0;
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    }, 500);
  }

  createUser() {
    const data = {
      phone: this.orderData.callerPhone,
      email: this.orderData.customerEmail,
      name: this.orderData.callerName,
    };

    this.WaitService.start();
    this.UserService.createCustomer(data, this.isEditFromSuperAdmin)
      .then((res: any) => {
        this.WaitService.stop();
        this.toastr.success("Customer Created");
        this.isCustomerCreated = true;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error);
      });
  }

  toggleMapView() {
    if (this.mapType != "street") {
      this.map.setStyle("mapbox://styles/mapbox/satellite-streets-v11");
      this.mapType = "street";
    } else {
      this.map.setStyle("mapbox://styles/mapbox/outdoors-v11");
      this.mapType = "outdoor";
    }
    this.showPath();
  }

  showTraffic() {
    this.map.setStyle("mapbox://styles/mapbox/traffic-day-v2");
    this.mapType = "outdoor";
    this.showPath();
  }

  focusOnInvalid(form: NgForm) {
    console.log(form)
    let customerName = document.getElementById('customername');
    if (customerName) customerName.scrollIntoView({ behavior: "smooth", block: "center" });
    let vinnumber = document.getElementById('vinnumber');
    if (vinnumber) vinnumber.scrollIntoView({ behavior: "smooth", block: "center" });

    this.invalidControl = this.el.nativeElement.querySelector(
      "form .ng-invalid,input.ng-invalid,select.ng-invalid,.ng-select.ng-invalid"
    );

    if (this.invalidControl) {
      this.invalidControl.scrollIntoView({ behavior: "smooth", block: "center" });
      setTimeout(() => this.invalidControl.focus(), 500);
      return false;
    }

    if (this.orderData.pickUpAddress && !this.orderData.pickUpLat && !this.orderData.pickUpLon) {
      var elem = document.getElementById('pickupId');
      elem.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    return true;
  }

  paymentsUpdated(data) {
    this.orderData.payments = data.confirmedPayments;
    this.newPayments = data.newPayments;
    this.calculateInvoiceBalance();
  }

  calculateInvoiceBalance(total?) {
    let totalPrice;
    if (total) {
      totalPrice = total.toFixed(2);
    } else {
      totalPrice = this.total.toFixed(2);
    }
    let paymentTotal = 0;
    if (this.orderData.payments && this.orderData.payments.length)
      this.orderData.payments.forEach((x) => {
        x.amount = parseFloat(x.amount);
        if (Number.isNaN(x.amount)) x.amount = 0;

        if (x.paymentType == "Refund") x.amount = -Math.abs(x.amount);
        else x.amount = parseFloat(x.amount);

        paymentTotal = paymentTotal + parseFloat(x.amount);
      });

    // unsaved Payments
    if (this.newPayments && this.newPayments.length)
      this.newPayments.forEach((x) => {
        x.amount = parseFloat(x.amount);
        if (Number.isNaN(x.amount)) x.amount = 0;

        if (x.paymentType == "Refund") x.amount = -Math.abs(x.amount);
        else x.amount = Math.abs(x.amount);

        paymentTotal = paymentTotal + parseFloat(x.amount);
      });

    paymentTotal = parseFloat(paymentTotal.toFixed(2));

    this.orderData.invoiceBalance = parseFloat(totalPrice) - paymentTotal;
  }

  focusItemsFunction(event, item) {

    // if (!item.price) item.price = item.value = null;
    // else if (item.value == 0) item.value = item.price = null;
    // else item.value = item.price;

    // item.price = item.value;

    if (event.target.value && !isNaN(event.target.value)) {
      item.price = item.value = event.target.value
    } else {
      item.price = item.value = null
    }

    this.calculatePrice();
    // this.reInitializeServicePrice();
  }

  focusItemsAdditonalFunction(e, item) {
    if (e.target.value && !isNaN(e.target.value)) {
      item.price = item.value = e.target.value
    } else {
      item.price = item.value = null
    }
    // if (item.price == 0) item.price = item.value = null;
    // item.price = item.value = item.price;
    this.calculatePrice();
  }

  formatQty(clItem, e) {
    if (e.target.value) {
      let clItemQty = e.target.value.toString();
      clItem.qty = Math.abs(parseFloat(clItemQty))
      e.target.value = clItem.qty
    } else {
      clItem.qty = Math.abs(parseFloat("0"))
      e.target.value = clItem.qty
    }
    // clItem.qty = Math.abs(Number(clItem.qty));
  }

  driverSelect(action) {
    if (action == 'focus') {
      this.showDriverList = !this.showDriverList;
      if (this.orderData.driverId) {
        this.driverOnHover = false;
      }
    } else {
      setTimeout(() => {
        this.showDriverList = false;
      }, 1000);
    }
  }

  focusOnDriver() {
    document.getElementById("driversDropDown").click();
  }

  setDriver(selectedOne) {
    if (selectedOne == "") {
      this.orderData.driverId = null;
      this.displayDrivers.driver = "Select";
      this.driverOnHover = false;
    } else {
      this.orderData.driverId = selectedOne.id;
      this.displayDrivers.driver =
        selectedOne.name + " (" + selectedOne.roleName + ")";
    }
    this.showDriverList = false;
    if (this.orderData.driverId && this.orderData.truckId && this.orderData.serviceId) {
      this.calculateDriverCommission()
    }
    // this.isDriverChanged=!this.isDriverChanged
  }

  getDriver() {
    if (this.orderData.driverId) {
      let index = this.drivers.findIndex(
        (i) => i.id == this.orderData.driverId
      );
      if (index != -1)
        this.displayDrivers.driver =
          this.drivers[index].name + " (" + this.drivers[index].roleName + ")";
    }
  }

  onChangeOthers() {
    this.orderData.isOthers = this.isOthers = !this.isOthers;
    this.orderData.vehicle.make = null;
    this.orderData.vehicle.vehicleModel = null;
    this.orderData.vehicle.year = null;
    this.orderData.vehicle.color = null;
    this.orderData.vehicle.driveTrain = null;
    this.orderData.vehicle.licensePlate = null;
    this.orderData.vehicle.vinNumber = null;
    this.orderData.vehicle.odometer = null;
  }
  onPhoneInput(data?) {
    if (this.validate.error.phone) this.validate.error.phone = false;
    if (this.validate.error.releasePhone) this.validate.error.releasePhone = false;
    if (data) {
      this.orderData.releaseToPhone = this.ValidateService.onMobileInput(
        this.orderData.releaseToPhone
      );
    } else {
      this.orderData.callerPhone = this.ValidateService.onMobileInput(
        this.orderData.callerPhone
      );
    }
  }
  deleteAutoStorageDays() {
    if (this.type != "impound") {
      this.orderData.removeStorageCharges = true;
      this.orderData.includeStorage = false;
    }
  }

  convertToMapAddress(e) {
    this.lat = e.coords.lat;
    this.lng = e.coords.lng;
    this.orderData.lat = this.lat;
    this.orderData.lng = this.lng;
    this.GoogleApiService.getAddress(e.coords.lat, e.coords.lng)
      .then((response) => {
        const result = JSON.parse(JSON.stringify(response));
        let places = result.data.results;
        if (places && places.length) {
          let address = places[0];
          let bounds = address.geometry.location;
          this.orderData.releaseToAddressText = address.formatted_address.replace(", USA", "");
          this.orderData.releaseToLat = bounds.lat;
          this.orderData.releaseToLon = bounds.lng;
        }
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  }
}
