import { Component, OnInit } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

import { WaitService } from "../../services/wait/wait.service";
import { PaymentService } from "../../services/payment/payment.service";
import { LoginService } from "../../services/login/login.service";
import { environment } from "../../../environments/environment";

import { PaymentSetupComponent } from "../../views/modals/payment-setup/payment-setup.component";
import { ConfirmModalComponent } from "../../views/modals/confirm-modal/confirm-modal.component";

import * as moment from "moment";

@Component({
  selector: "app-link-bank",
  templateUrl: "./link-bank.component.html",
  styleUrls: ["./link-bank.component.scss"],
})
export class LinkBankComponent implements OnInit {
  data: any = [];
  square:any;
  clover:any;
  currentUser:any;
  constructor(
    private WaitService: WaitService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private PaymentService: PaymentService,
    private LoginService: LoginService
  ) {}

  ngOnInit() {
    this.currentUser = this.LoginService.getCurrentUser();
    let tokenSQ = this.LoginService.getSQToken();
    let cloverData = this.LoginService.getCloverToken();
    if(tokenSQ){
      this.setSquareAccount(tokenSQ);
    }
    if(cloverData || cloverData!=null){
      this.seCloverAccount(JSON.parse(cloverData));
    }
    this.getUserPayment();

  }

  seCloverAccount(data){
    if(!data.code || data.code=='') return;
    let datatosend = {
      userId:this.currentUser.userId,
      gateway:{
        name:'Clover',
        gateway_type:'clover',
        token:data.code,
        meta:{
          code:data.code,
          merchanId:data.merchanId,
          employeeId:data.employeeId,
          clientId:data.clientId
        }
      },
      isActive:true
    }

    this.WaitService.start();
    this.PaymentService.cloverPaymentGateWayAdd(datatosend)
      .then((result) => {
        console.log(result);
        this.WaitService.stop();
        this.LoginService.deleteCloverToken();
        this.toastr.success("Clover Has Been Setup Successfully");
        this.getUserPayment();
      })
      .catch((err) => {
        console.log(err);
        this.WaitService.stop();
        this.LoginService.deleteCloverToken();
      });

  } 

  setSquareAccount(token){
    if(token=='') {
      return;
      // this.toastr.error("Unable To Setup");
    }
    let data = {
      userId:this.currentUser.userId,
      gateway:{
        name:'Square',
        gateway_type:'square',
        token:token
      },
      isActive:true
    }
    this.PaymentService.squarePaymentGateWayAdd(data)
      .then(() => {
        this.WaitService.stop();
        this.LoginService.deleteSQToken();
        this.toastr.success("Square Has Been Setup Successfully");
        this.getUserPayment();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.LoginService.deleteSQToken();
        // this.toastr.error("Unable To Setup");
      });
  }

  getUserPayment() {
    this.square = {};
    this.clover = {};
    this.WaitService.start();
    this.PaymentService.getAddedGateWay()
      .then((response) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));
        this.data = result.data;
        console.log(this.data);
        let temp = [];
        this.data.forEach((x) => {
          if(x.gateWayType=='square') {
            this.square = x;
            this.square.isConnected = true;
            var today = moment();
            var expiry = moment(x.expiresAt);
            var difference = expiry.diff(today, 'seconds');
            if(difference<=0){
              this.autoremove(x.id);
            }
          }
          if(x.gateWayType=='clover') {
            this.clover = x;
            this.clover.isConnected = true;
          }
          else temp.push(x);
        });
        this.data = temp;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  openPaymentModal() {
    const modal = this.modalService.open(PaymentSetupComponent, {
      backdropClass: "light-blue-backdrop",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    if (this.data) modal.componentInstance.gateWayData = this.data;
    modal.result.then(() => {
      this.getUserPayment();
    });
  }

  autoremove(id: number){
    this.WaitService.start();
    this.PaymentService.removeGateWay(id)
      .then(() => {
        this.WaitService.stop();
        this.getUserPayment();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  removeGateWay(id: number) {
    const modal = this.modalService.open(ConfirmModalComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.title = "Delete";
    modal.componentInstance.message =
      "You will no longer be able to accept payments through this payment method. Are you sure you want to deactivate this payment method?";

    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.WaitService.start();
      this.PaymentService.removeGateWay(id)
        .then(() => {
          this.WaitService.stop();
          this.toastr.success("Payment Gateway Has Been Removed");
          this.getUserPayment();
        })
        .catch((err) => {
          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    });
  }

  activateGateWay(id: number, gateway) {
    if(!id){
      let radios = document.getElementById('square_radio') as HTMLInputElement | null;
      radios.checked = false;
      let radios2 = document.getElementById('clover_radio') as HTMLInputElement | null;
      radios2.checked = false;
      return this.toastr.error(gateway +" Is Not Linked To Your Account");
    }
    this.WaitService.start();
    this.PaymentService.activateGateWay(id)
      .then((res: any) => {
        this.WaitService.stop();
        this.toastr.success("Changes Saved");
        this.getUserPayment();
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  goToUrl() {
    let sqUrl = environment.SQUARE_OAUTH_LINK_SB;
    if(environment.squarePROD){
      sqUrl = environment.SQUARE_OAUTH_LINK_PROD;
    }
    // console.log(">>>>>>>>>>",sqUrl)
    window.location.href = sqUrl;
  }

  disconnectClover(){

  }
  
  connectClover(){
    window.location.href = "https://sandbox.dev.clover.com/oauth/authorize?client_id=NG3QHR07VKDCE";
  }
}
