import { Component, ElementRef, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { ClassTypesService } from "src/app/services/classTypes/class-types.service";
import { environment } from "../../../environments/environment";
import { LoginService } from "../../services/login/login.service";
import { OrdersService } from "../../services/orders/orders.service";
import { PaymentService } from "../../services/payment/payment.service";
import { SitesService } from "../../services/sites/sites.service";
import { SocketService } from "../../services/socket/socket.service";
import { UserService } from "../../services/user/user.service";
import { VehicalService } from "../../services/vehical/vehical.service";
import { WaitService } from "../../services/wait/wait.service";
import { CollectDataComponent } from "../../views/modals/collect-data/collect-data.component";
import { ConfirmTowComponent } from "../../views/modals/confirm-tow/confirm-tow.component";
import { CreateJobComponent } from "../../views/modals/create-job/create-job.component";
import { EditInvoiceComponent } from "../../views/modals/edit-invoice/edit-invoice.component";
import { PayCashComponent } from "../../views/modals/pay-cash/pay-cash.component";
import { ReleaseVehicleComponent } from "../../views/modals/release-vehicle/release-vehicle.component";
import { SqFormComponent } from "../../views/modals/sq-form/sq-form.component";
import { CloverFormComponent } from "../../views/modals/clover-form/clover-form.component";

declare let SpreedlyExpress: any;

@Component({
  selector: "app-impounds",
  templateUrl: "./impounds.component.html",
  styleUrls: ["./impounds.component.scss"],
})
export class ImpoundsComponent implements OnInit, AfterViewInit {
  @ViewChild("impoundTable", { static: true }) impoundTable: ElementRef;

  searchText: string = null;
  selectedSites: any = [];
  searchTimeOut: any = null;
  socket: any = null;
  ordersData: any = [];
  filteredOrders: any = [];
  drivers: any = [];
  trucks: any = [];
  totalOrders: number = null;
  limit: number = 100;
  offset: number = 0;
  sites: any = [];
  fromDate: any = null;
  endDate: any = null;
  isOpen: boolean = false;
  isCollapsed: boolean[] = [];
  rowClicked: number = -1;

  orderItems: any = {
    accountId: null,
    callerName: null,
    callerPhone: null,
    customerEmail: null,
    customerAddress: null,
    vehicle: {
      make: null,
      vehicleModel: null,
      year: null,
      color: null,
      driveTrain: null,
      licensePlate: null,
      vinNumber: null,
      odometer: null,
    },
    pickUpLocationType: null,
    pickUpAddress: null,
    pickUpLat: null,
    pickUpLon: null,
    pickUpName: null,
    pickUpNumber: null,
    dropOffLocationType: null,
    dropOffAddress: null,
    dropOffLat: null,
    dropOffLon: null,
    dropOffName: null,
    dropOffNumber: null,
    dirverId: null,
    truckId: null,
    stateLicense: null,
    serviceId: null,
    rateTypeId: null,
    classTypeId: null,
    serviceTime: "asap",
    scheduledFor: null,
    scheduledTime: null,
    scheduledTimeZone: null,
    etaMins: null,
    billingtype: null,
    notes: null,
    dispatcher: null,
    dispatchSiteId: null,
    isDraft: false,
    includeStorage: false,
    storageSiteId: null,
    storageType: null,
    includeTowOut: false,
    towOutDestinationAddress: null,
    towOutDestinationLat: null,
    towOutDestinationLon: null,
    towOutScheduledFor: null,
    towOutTime: null,
    towOutScheduledForTimeZone: null,
    sendSmsTo: "customer",
    locationSms: false,
    driverEnRouteSms: false,
    jobCompletedSms: false,
    orderMainItems: [],
    orderAdditionalItems: [],
    discountType: "flat",
    discountValue: 0,
    requestLocation: false,
    invoiceNotes: null,
    releaseTo: null,
    releaseToName: null,
    releaseToAddressText: null,
    releaseToLat: null,
    releaseToLon: null,
    releaseToPhone: null,
    releaseToEmail: null,
    replaceInvoiceInfo: false,
    autoStoredCountDays: true,
    storedPerDayPrice: null,
    storedDays: null,
  };
  sitesDropDownSettings: any = {
    singleSelection: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true,
    searchBy: ["name"],
    labelKey: "name",
    badgeShowLimit: 1,
    text: "FILTER BY SITE",
  };
  classTypes: any = [];
  currentUser: any = null;
  loadedOrders: number = 0;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private WaitService: WaitService,
    private UserService: UserService,
    private VehicalService: VehicalService,
    private OrdersService: OrdersService,
    private SocketService: SocketService,
    private SitesService: SitesService,
    private LoginService: LoginService,
    private PaymentService: PaymentService,
    private ClassTypesService: ClassTypesService
  ) {
    this.currentUser = this.LoginService.getCurrentUser();
  }

  ngOnInit() {
    this.loadImpounds();
    this.loadSites();
    this.loadTrucks();
    this.loadDrivers();
    this.getClassTypes();
    this.socket = this.SocketService.getSocket();
    this.OrdersService.update$.subscribe((data) => {
      if (data && data.type === 'impound') {
        this.loadImpounds();
      }
    });

    if (this.socket) {
      this.socket.on("newJob", (data) => {
        let order = JSON.parse(JSON.stringify(data));

        if (
          order.data &&
          order.data.status == "completed" &&
          order.data.includeStorage
        )
          this.loadImpounds();
      });

      this.socket.on("orderStatus", (data) => {
        let order = JSON.parse(JSON.stringify(data));
        if (
          order.data &&
          (order.data.status == "completed" || order.data.status == "released")
        )
          this.loadImpounds();
      });

      this.socket.on("jobUpdated", (data) => {
        let order = JSON.parse(JSON.stringify(data));
        if (
          order.data &&
          (order.data.status == "completed" || order.data.status == "released")
        )
          this.loadImpounds();
      });
    }
  }

  ngAfterViewInit() {
    let search_filter_list = document.getElementsByClassName("list-area")[0];
    search_filter_list.prepend(search_filter_list.querySelector("div.list-filter"));
    search_filter_list.querySelector("div.filter-select-all").remove();
  }

  stopParentEvent(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  loadTrucks() {
    this.WaitService.start();
    this.VehicalService.getVehicleTruck()
      .then((trucks) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(trucks));
        this.trucks = result.data;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  getClassTypes() {
    this.WaitService.start();
    this.ClassTypesService.getClassTypes()
      .then((classTypes) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(classTypes));
        this.classTypes = result.data;
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  loadDrivers() {
    this.WaitService.start();
    this.UserService.getAllUsers()
      .then((users) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(users));
        if (result && result.users) {
          this.drivers = result.users.filter(
            (x) => x.roleId && (x.roleId == 3 || x.roleId == 2)
          );
        }
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  loadSites() {
    this.SitesService.loadSites()
      .then((sites) => {
        let result = JSON.parse(JSON.stringify(sites));
        this.sites = result.data.sites.filter(
          (x) =>
            (x.locationType &&
              (x.locationType.name == "Storage Facility" ||
                x.locationType.name == "Impound Lot")) ||
            x.siteType == "default"
        );
      })
      .catch((err) => {
        this.toastr.error(err.error.error);
      });
  }

  loadImpounds(selectedSites = null) {
    if (this.searchTimeOut) clearTimeout(this.searchTimeOut);

    this.searchTimeOut = setTimeout(() => {
      this.WaitService.start();
      this.OrdersService.getImpounds(
        this.offset,
        this.limit,
        this.searchText,
        selectedSites,
        this.fromDate,
        this.endDate
      )
        .then((result: any) => {
          // let result = JSON.parse(JSON.stringify(data));
          this.totalOrders = result.total;

          this.ordersData = result.data.map((x) => {
            x.days = x.autoStoredCountDays ? x.totalStoredDays : x.storedDays;

            if (x.orderType == "app") x.accountInfo = { name: "APP Account" };

            x.storedDate = x.storedOn;
            x.createdAt = moment(x.createdAt).format("MM/DD/YY hh:mm A");
            x.storedOn = moment(x.storedOn).format("MM/DD/YY hh:mm A");

            if (x.history)
              x.history.forEach((element) => {
                element.date = moment(element.date).format("MM/DD/YY hh:mm A");
              });

            x.mapDefault = { lat: 37.09024, lng: -95.712891 };

            if (x.pickUpLat && x.pickUpLon)
              x.mapDefault = {
                lat: parseFloat(x.pickUpLat),
                lng: parseFloat(x.pickUpLon),
              };
            else if (x.dropOffLat && x.dropOffLon)
              x.mapDefault = {
                lat: parseFloat(x.dropOffLat),
                lng: parseFloat(x.dropOffLon),
              };

            if (x.pickUpLat && x.pickUpLon && x.dropOffLat && x.dropOffLon) {
              x.origin = {
                lat: parseFloat(x.pickUpLat),
                lng: parseFloat(x.pickUpLon),
              };
              x.destination = {
                lat: parseFloat(x.dropOffLat),
                lng: parseFloat(x.dropOffLon),
              };
            }

            return x;
          });

          this.ordersData = this.ordersData.sort((a, b) => new Date(b.storedDate).getTime() - new Date(a.storedDate).getTime());
          this.groupData(this.ordersData);
          this.WaitService.stop();
          setTimeout(() => {
            if (!this.filteredOrders || !this.filteredOrders.length)
              document.getElementById("invisible").style.visibility = "visible";
          }, 100);
        })
        .catch((err) => {
          console.log(err);

          this.WaitService.stop();
          this.toastr.error(err.error.error);
        });
    }, 700);
  }

  groupData(ordersData) {
    let dataIndex = 0,
      data = [];
    ordersData.map((x, index) => {
      if (index == 0) {
        data.push({
          date: moment(x.storedDate).format("MM/DD/YYYY"),
          day: moment(x.storedDate).format("dddd"),
          orders: [x],
        });
      } else {
        if (
          moment(x.storedDate).format("MM/DD/YYYY") ==
          moment(ordersData[index - 1].storedDate).format("MM/DD/YYYY")
        ) {
          data[dataIndex].orders.push(x);
        } else {
          dataIndex++;
          data.push({
            date: moment(x.storedDate).format("MM/DD/YYYY"),
            day: moment(x.storedDate).format("dddd"),
            orders: [x],
          });
        }
      }
    });

    this.filteredOrders = data;
    this.loadedOrders = ordersData.length;
  }

  openModal() {
    const modal = this.modalService.open(CreateJobComponent, {
      backdropClass: "light-blue-backdrop",
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.type = "impound";
  }

  editOrder(order) {
    if (order.orderType == "custom" && order.isCreatedBySuperAdmin) {
      return this.toastr.error("You Are Not Allowed To Perform This Action");
    }
    const modal = this.modalService.open(CreateJobComponent, {
      backdropClass: "light-blue-backdrop",
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.type = "impound";
    modal.componentInstance.orderId = order.id;
    modal.componentInstance.returnData.subscribe((res) => {
      if (res.status && res.type === "impound")
        this.loadImpounds();
    })
  }


  onAction(order) {
    this.rowClicked = order.id;
  }

  undoStorage(order) {
    this.rowClicked = order.id
    // order.includeStorage = false;
    // order.storageSiteId = null;
    order.isUndoJob = true;
    order.status = "completed"
    order.completedTime = new Date();
    order.autoStoredCountDays = false;
    order.isStorageToDashboard = true;
    this.WaitService.start();
    this.OrdersService.editOrder(order)
      .then(() => {
        this.WaitService.stop();
        this.toastr.success("Storage Has Been Removed From Order");
        this.loadImpounds();
      })
      .catch((err) => {
        console.log(err);
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  releaseVehicle(order) {
    this.rowClicked = order.id
    const modal = this.modalService.open(ReleaseVehicleComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
      size: "xl",
    });
    modal.componentInstance.orderData = order;
    // modal.result.then((data)=>{
    //   console.log(data,"data released");
    //   // if(data){
    //     const jobModal = this.modalService.open(CreateJobComponent, {
    //       backdropClass: "light-blue-backdrop",
    //       size: "xl",
    //       backdrop: "static",
    //       keyboard: false,
    //     });
    //     jobModal.componentInstance.type = "impound";
    //     jobModal.componentInstance.orderId = tempOrderId;
    //     jobModal.componentInstance.orderReleased=true;
    //   // }
    // })
    modal.result.then((data) => {
      console.log(data, "data");
      if (data) {
        this.loadImpounds();
      }
    });
  }

  towVehicle(order) {
    this.rowClicked = order.id
    let tempId = order.id;
    const modal = this.modalService.open(ConfirmTowComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.orderData = order;
    modal.componentInstance.reason.subscribe((result) => {
      if (result != "success") return;
      this.loadImpounds();
      this.orderItems.accountId = order.accountId;
      this.orderItems.callerName = order.callerName;
      this.orderItems.callerPhone = order.callerPhone;
      this.orderItems.customerEmail = order.customerEmail;
      this.orderItems.customerAddress = order.customerAddress;
      this.orderItems.vehicle = order.vehicle;
      this.orderItems.isOthers = order.isOthers;
      this.orderItems.pickUpAddress = order.storageSiteInfo.address;
      this.orderItems.pickUpLat = order.storageSiteInfo.lat;
      this.orderItems.pickUpLon = order.storageSiteInfo.lng;
      this.orderItems.serviceTime = "asap";
      this.orderItems.notes = order.notes;
      this.orderItems.po = order.po;
      this.orderItems.dispatcher = order.dispatcher;
      this.orderItems.dispatchSiteId = order.dispatchSiteId;
      this.orderItems.isDraft = false;
      this.orderItems.storageOrderId = order.id;
      this.orderItems.isTowService = true;

      const jobModal = this.modalService.open(CreateJobComponent, {
        backdropClass: "light-blue-backdrop",
        size: "xl",
        backdrop: "static",
        keyboard: false,
      });
      delete order.id;
      jobModal.componentInstance.orderTowData = this.orderItems;
      jobModal.result.then((data) => {
        if (data != false) {
          order.id = tempId;
          order.isTowedOut = true;
          order.completedTime = new Date();
          order.autoStoredCountDays = false;
          order.isStorageToDashboard = true;
          this.WaitService.start();
          this.OrdersService.editOrder(order)
            .then((res) => {
              console.log(res, "result");
              this.WaitService.stop();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    });
  }

  changeFilters() {
    this.offset = 0;
    let sites = this.selectedSites.map((x) => x.id);
    this.loadImpounds(sites);
  }

  paginate(offset) {
    if (offset > this.totalOrders) offset = this.totalOrders;
    if (offset <= 0) offset = 0;
    this.offset = offset;

    let sites = this.selectedSites.map((x) => x.id);
    this.loadImpounds(sites);
    this.impoundTable.nativeElement.scrollTo(0, 0);
  }

  // View Order invoice
  redirectToInvoice(uid: string, download: boolean = false) {
    this.OrdersService.redirectToInvoice(uid, download);
  }

  openEditInvoiceModal(data) {
    const modal = this.modalService.open(EditInvoiceComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
      size: "xl",
    });
    modal.componentInstance.invoiceId = data.id;

    modal.result.then(() => this.loadImpounds());
  }

  chargeCard(orderData: any, index: number) {
    this.rowClicked = orderData.id
    this.WaitService.start();
    this.PaymentService.getAddedGateWay()
      .then((response) => {
        this.WaitService.stop();
        let result = JSON.parse(JSON.stringify(response));
        let checkForGateway;
        let expDateCheck;
        result.data.forEach((x) => {
          if (x.isActive) {
            checkForGateway = x.gateWayType;
            expDateCheck = x.expiresAt;
          }
        });
        if (!checkForGateway && !orderData.isCreatedBySuperAdmin)
          this.toastr.error("Looks Like You Have Not Enabled Any Payment Gateway, Please Enable It First")

        // if(checkForGateway && (checkForGateway != 'square') && (checkForGateway != "clover")) this.chargeSpreedleCard(orderData, index);

        if ((checkForGateway && checkForGateway == "square") || (orderData.isCreatedBySuperAdmin) || (orderData.orderType == "app")) {
          var today = moment();
          var expiry = moment(expDateCheck);
          var difference = expiry.diff(today, 'seconds');
          if (difference <= 0) { return this.toastr.error("Authentication Expired") }
          this.chargeSquareCard(orderData, index);
        }

        if (checkForGateway && checkForGateway == "clover")
          this.chargeCloverCard(orderData, index);
      })
      .catch((err) => {
        this.WaitService.stop();
        this.toastr.error(err.error.error);
      });
  }

  chargeCloverCard(orderData: any, index: any) {
    const modalPseudo = this.modalService.open(CloverFormComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalPseudo.componentInstance.orderData = orderData;
    let self = this;
    modalPseudo.componentInstance.returnData.subscribe((res) => {
      self.ordersData[index].invoiceBalance = 0;
    });
  }

  chargeSquareCard(orderData: any, index: any) {
    const modalPseudo = this.modalService.open(SqFormComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modalPseudo.componentInstance.orderData = orderData;
    let self = this;
    modalPseudo.componentInstance.returnData.subscribe((res) => {
      if (res) this.loadImpounds();
    });
  }

  chargeSpreedleCard(orderData: any, index: number) {
    const modal = this.modalService.open(CollectDataComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.customerData.subscribe((res) => {
      if (!res.success) return;

      this.WaitService.start();
      let serviceName =
        orderData.orderType == "app"
          ? orderData.appService.label
          : orderData.service.label;
      SpreedlyExpress.init(environment.SPREEDLY_ENV_KEY, {
        amount: "$" + orderData.invoiceBalance,
        company_name:
          this.currentUser &&
            this.currentUser.companInfo &&
            this.currentUser.companInfo.companyName
            ? this.currentUser.companInfo.companyName
            : "STUK",
        sidebar_top_description: "Service: " + serviceName,
        sidebar_bottom_description: "Your order total",
        full_name: "",
      });
      setTimeout(() => {
        this.WaitService.stop();
        SpreedlyExpress.openView();
      }, 1500);

      SpreedlyExpress.onPaymentMethod((token, paymentMethod) => {
        let data = {
          orderId: orderData.id,
          token: token,
          amount: orderData.invoiceBalance,
          customerData: res,
        };
        this.WaitService.start();
        this.PaymentService.chargeCard(data)
          .then(() => {
            SpreedlyExpress.unload();
            this.WaitService.stop();
            this.toastr.success("Card Has Been Charged");
            this.ordersData[index].invoiceBalance = 0;
            this.loadImpounds();
          })
          .catch((err) => {
            SpreedlyExpress.unload();
            this.toastr.error("Something Went Wrong");
            this.WaitService.stop();
          });
      });
    });
  }

  payCash(order, index: number) {
    this.rowClicked = order.id
    const modal = this.modalService.open(PayCashComponent, {
      backdropClass: "light-blue-backdrop",
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.orderData = order;
    modal.componentInstance.payCash.subscribe((res) => {
      if (!res.success) return;

      this.OrdersService.getJobById(order.id)
        .then((result: any) => {
          if (result.data && result.data.length)
            // this.ordersData[index].invoiceBalance =
            //   result.data[0].invoiceBalance;
          this.loadImpounds();
        })
        .catch((err) => {
          this.toastr.error(err.error.error);
        });
    });
  }

  onDateChange(e, dateType: string) {
    if (dateType == "fromDate") this.fromDate = e;
    else this.endDate = e;
    this.loadImpounds();
  }

  getText(e) {
    this.searchText = e;
    this.changeFilters();
  }

  selectedRow(idx: any) {
    this.rowClicked = idx;
  }
}
